const articles = [

  {
    name:`Sony-139-cm-55 inches-BRAVIA-2-4K-Ultra-HD-Smart-LED-Google-TV`,
    title:`Sony 139 cm (55 inches) BRAVIA 2 4K Ultra HD Smart LED Google TV K-55S25B (Black)`,
    rate:`₹57,990`,
    content: [  
      `The Sony BRAVIA 2 55-inch 4K Ultra HD Smart LED Google TV (K-55S25B) delivers stunning visuals with lifelike clarity and vibrant colors. Featuring Google TV integration, voice control, Dolby Atmos, and X-Reality PRO, it offers an immersive entertainment experience. With a sleek black design and seamless connectivity, it’s the perfect upgrade for a cinematic home viewing experience.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-600`,
    wear:`Amazon Electronics`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/dp/B0D3DWFY54?ref=cm_sw_r_cso_wa_apin_dp_PDCKJ61P11ANZ83XPYXW&ref_=cm_sw_r_cso_wa_apin_dp_PDCKJ61P11ANZ83XPYXW&social_share=cm_sw_r_cso_wa_apin_dp_PDCKJ61P11ANZ83XPYXW&starsLeft=1&skipTwisterOG=1&th=1&tag=inrdeal123-21`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://m.media-amazon.com/images/I/81zPuf-0ETL._SX679_.jpg`,
    image:`https://m.media-amazon.com/images/I/81gLIG8BoGL._SX679_.jpg`,
    image1:`https://m.media-amazon.com/images/I/81nmpCl+5dL._SX679_.jpg`,
    icon:`https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=ais_hybrid`,
    icon1:`/images/pinterest.png`,
    icon2:`https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png`,
    instagram:``,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:``,
    heading:`Stunning 4K Ultra HD Resolution`,
    paragraph:`The Sony BRAVIA 2 55-inch TV delivers exceptional 4K Ultra HD resolution, ensuring crystal-clear visuals with stunning detail. Whether you're watching movies, playing games, or streaming content, the TV enhances every scene with breathtaking clarity. With a resolution four times that of Full HD, every frame comes to life with lifelike textures, deep blacks, and brilliant highlights. HDR technology further optimizes brightness and contrast, making colors appear more vibrant and realistic. This makes the TV an ideal choice for those who appreciate high-quality visuals, ensuring a truly cinematic experience in the comfort of your home..`,
    heading1:`Google TV Integration for Smart Entertainment`,
    paragraph1:`With Google TV, the BRAVIA 2 55-inch TV offers an advanced smart entertainment experience. The platform brings together movies, shows, live TV, and apps in one seamless interface. Users can easily browse through their favorite content from streaming services like Netflix, Amazon Prime Video, YouTube, and Disney+ Hotstar. Google TV’s AI-based recommendations suggest content based on your viewing habits, making it effortless to discover new entertainment. Additionally, it allows for parental controls, multiple user profiles, and a personalized watchlist, ensuring a user-friendly experience for every household member.`,
    heading2:`Dolby Atmos for Immersive Sound Quality`,
    paragraph2:`The TV features Dolby Atmos technology, delivering a rich, immersive sound experience. Whether you're watching action-packed movies, listening to music, or playing games, the powerful audio system creates a 360-degree sound field, making you feel like you’re part of the scene. The sound travels around and above you, enhancing every detail with crystal-clear dialogue, deep bass, and precise sound positioning. With Dolby Atmos, home entertainment reaches a new level, eliminating the need for external speakers while still providing theater-quality audio.`,
    heading3:`X-Reality PRO for Enhanced Picture Clarity`,
    paragraph3:`Sony’s X-Reality PRO technology upscales content in real-time, ensuring that even lower-resolution videos appear sharper and more refined. It enhances textures, reduces noise, and optimizes contrast to deliver lifelike images. This means that even older TV shows or non-4K videos will look significantly improved on this TV. Whether you're streaming online content or watching a Blu-ray movie, X-Reality PRO ensures that every detail is enhanced, making visuals clearer, sharper, and more dynamic.`,
    heading4:`Seamless Voice Control with Google Assist`,
    paragraph4:`The Sony BRAVIA 2 55-inch TV comes with Google Assistant, allowing for hands-free voice control. Simply say “Hey Google” to search for content, adjust volume, control smart home devices, or get weather updates. The voice control feature eliminates the need for manual browsing, making navigation effortless. Additionally, it integrates with other smart home devices, letting you dim lights, set alarms, or check security cameras with simple voice commands. This feature adds convenience and transforms your TV into a smart home hub.`,
    keyword:`Stunning 4K Ultra HD Display with HDR, Seamless Smart TV Experience with Google TV, Immersive Audio with Dolby Atmos`,
    description:`PLIX - THE PLANT FIX Glutathione Tablets with Vitamin C are designed to promote clear, youthful skin. Packed with 500mg L-Glutathione, Vitamin E, and Hyaluronic Acid, these collagen-boosting supplements enhance skin radiance and hydration. With a delicious strawberry flavor, this pack of 15 tablets helps combat dullness, reduce pigmentation, and support overall skin health for a glowing complexion`,
    summary:`https://m.media-amazon.com/images/G/31/img20/Wireless/ElectronicsStore/GW/New/1242x450.jpg`,
    jlink:`https://www.amazon.in/electronics/b/?ie=UTF8&node=976419031&ref_=topnav_storetab_top_elec_mega&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`PLIX-THE-PLANT-FIX-Glutathione-Tablets-With-Vitamin-C-For-Clear-And-Youthful-Skin`,
    title:`PLIX - THE PLANT FIX Glutathione Tablets With Vitamin C For Clear And Youthful Skin (Pack Of 1, Strawberry) | 15 Collagen Supplements |500mg L-Glutathione, Vitamin E And Hyaluronic Acid`,
    rate:`₹569`,
    content: [  
      `PLIX - THE PLANT FIX Glutathione Tablets with Vitamin C are designed to promote clear, youthful skin. Packed with 500mg L-Glutathione, Vitamin E, and Hyaluronic Acid, these collagen-boosting supplements enhance skin radiance and hydration. With a delicious strawberry flavor, this pack of 15 tablets helps combat dullness, reduce pigmentation, and support overall skin health for a glowing complexion.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-rose-600`,
    wear:`Health & Personal Care`,
    Scolor:"text-red-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://amzn.urlvia.com/EhV489`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://m.media-amazon.com/images/I/51CsoZvusRL._SX679_PIbundle-15,TopRight,0,0_AA679SH20_.jpg`,
    image:`https://m.media-amazon.com/images/I/81jQ2rVOgGL._SX679_.jpg`,
    image1:`https://m.media-amazon.com/images/I/61NSk4b3lXL._SX679_.jpg`,
    icon:`https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=ais_hybrid`,
    icon1:`/images/pinterest.png`,
    icon2:`https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png`,
    instagram:``,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:``,
    heading:`Powerful Skin Brightening Formula`,
    paragraph:`PLIX Glutathione Tablets contain 500mg of L-Glutathione, a powerful antioxidant known for its skin-brightening properties. Glutathione reduces melanin production, helping fade dark spots, pigmentation, and uneven skin tone. Combined with Vitamin C, it enhances the effectiveness of glutathione, ensuring a brighter, more radiant complexion. With regular use, these tablets work from within to give you clearer and healthier skin, making them a must-have for anyone looking to achieve a luminous glow.`,
    heading1:`Rich in Antioxidants for Youthful Skin`,
    paragraph1:`Antioxidants are essential for protecting the skin from environmental damage. This supplement is enriched with Vitamin E and Hyaluronic Acid, which fight free radicals, preventing premature aging. Vitamin E deeply nourishes the skin, while Hyaluronic Acid boosts hydration, keeping the skin plump, smooth, and youthful. These ingredients work together to reduce fine lines and wrinkles, ensuring long-term skin health.`,
    heading2:`Boosts Collagen Production`,
    paragraph2:`Collagen is the building block of youthful skin, and this supplement helps stimulate its production. The combination of Glutathione, Vitamin C, and Hyaluronic Acid supports skin elasticity and firmness. Over time, collagen levels naturally decline, leading to sagging skin and wrinkles. By taking these tablets regularly, you help maintain collagen levels, resulting in firmer, more youthful skin with a natural bounce.`,
    heading3:`Deep Hydration for Supple Skin`,
    paragraph3:`Hydration is key to healthy skin, and Hyaluronic Acid in this formula ensures that your skin retains moisture. Hyaluronic Acid has the ability to hold up to 1000 times its weight in water, providing deep hydration from within. Well-hydrated skin looks more radiant, plumper, and healthier, reducing dryness and dullness for a glowing appearance.`,
    heading4:`Helps Reduce Acne & Scarring`,
    paragraph4:`Acne scars and blemishes can take time to heal, but the right nutrients can speed up the process. The antioxidant-rich blend in PLIX Glutathione Tablets helps repair skin damage caused by acne, sun exposure, and pollution. By detoxifying the skin and reducing inflammation, these tablets help clear out acne-prone skin and gradually fade scars, leaving you with a smoother complexion.`,
    keyword:`Promotes Clear & Youthful Skin, Collagen Boost for Skin Hydration & Elasticity, Delicious & Easy to Consume`,
    description:`PLIX - THE PLANT FIX Glutathione Tablets with Vitamin C are designed to promote clear, youthful skin. Packed with 500mg L-Glutathione, Vitamin E, and Hyaluronic Acid, these collagen-boosting supplements enhance skin radiance and hydration. With a delicious strawberry flavor, this pack of 15 tablets helps combat dullness, reduce pigmentation, and support overall skin health for a glowing complexion`,
    summary:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkM8ZW62d3iApnZHknnDZjjLHt-8VpapJDdQ&s`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Ftrending%2Fstyling-palermo`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Mens-Maroon-Black-Deadpool-Color-Block-High-Top-Sneakers`,
    title:`Men's Maroon & Black Deadpool Color Block High Top Sneakers`,
    rate:`₹2,799`,
    content: [  
      `The Official Marvel Merchandise Men's Maroon & Black Deadpool Color Block High Top Sneakers combine style and fandom effortlessly. Featuring striking maroon and black color blocking, these high-top sneakers showcase the iconic Deadpool theme. Crafted for comfort and durability, they are perfect for any Marvel enthusiast looking to add a touch of superhero flair to their casual wardrobe.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-rose-700`,
    wear:`Deadpool X Wolverine`,
    Scolor:"text-red-500 text-lg font-semibold",
    buy:`/images/theshouldstore.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-maroon-black-color-block-high-top-sneakers`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-maroon-black-deadpool-color-block-high-top-sneakers-630669-1722845944-1.jpg`,
    image:`https://images.bewakoof.com/t1080/men-s-maroon-black-deadpool-color-block-high-top-sneakers-630669-1722845952-3.jpg`,
    image1:`https://images.bewakoof.com/t1080/men-s-maroon-black-deadpool-color-block-high-top-sneakers-630669-1722845969-7.jpg`,
    icon:`https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=ais_hybrid`,
    icon1:`/images/pinterest.png`,
    icon2:`https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png`,
    instagram:``,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:``,
    heading:`Iconic Deadpool Theme`,
    paragraph:`These high-top sneakers prominently feature the beloved Deadpool theme, making them a must-have for fans of the Merc with a Mouth. The design integrates Deadpool's signature colors, maroon and black, ensuring that your footwear stands out in any crowd. Whether you're attending a comic convention or just hanging out with friends, these sneakers let you express your fandom with style.`,
    heading1:`Striking Color Blocking`,
    paragraph1:`The bold maroon and black color blocking adds a modern and edgy look to the sneakers. This striking design not only highlights your love for Deadpool but also makes a fashion statement. The contrasting colors are visually appealing and versatile, pairing well with various casual outfits, from jeans to shorts.`,
    heading2:`High-Top Design`,
    paragraph2:`The high-top design offers more than just aesthetic appeal; it provides additional ankle support, making these sneakers suitable for everyday wear. The extended height helps protect your ankles from twists and injuries, ensuring that you can wear them comfortably for longer periods, whether you're walking around the city or engaging in light physical activities.`,
    heading3:`Available at The Should Store`,
    paragraph3:`These Official Marvel Merchandise Deadpool Color Block High Top Sneakers are available exclusively at theshouldstore.com. Shopping from a trusted retailer ensures that you receive genuine merchandise and excellent customer service. Visit the website to explore these sneakers and other Marvel-themed apparel and accessories..`,
    heading4:`Attention to Detail`,
    paragraph4:`The sneakers feature meticulous attention to detail, from the color-blocked design to the Deadpool-themed accents. Every element is crafted to reflect the character's iconic style, making these sneakers a true collector's item. The high-quality finish and unique design elements make them stand out from ordinary sneakers.`,
    keyword:`Official Marvel Merchandise, Deadpool sneakers, Men's high top sneakers, Deadpool color block sneakers, Maroon and black sneakers, Marvel high top shoes, Deadpool theme shoes, Comfortable Marvel sneakers, Durable Deadpool sneakers, Marvel fan footwear, Superhero sneakers, Stylish Deadpool sneakers, Casual Deadpool shoes, High top Marvel sneakers, Men's casual sneakers, Marvel fan gear, Marvel enthusiast footwear, Deadpool fashion, Deadpool high tops, Comic book sneakers, Marvel shoes, theshouldstore.com`,
    description:`Step into style with The Official Marvel Merchandise Men's Maroon & Black Deadpool Color Block High Top Sneakers. Featuring bold maroon and black color blocking and the iconic Deadpool theme, these high-top sneakers offer comfort, durability, and a superhero flair perfect for any Marvel fan. Shop now at theshouldstore.com for the ultimate blend of fashion and fandom.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/Events/img23/Teaser1/CMN_header_PC_Unrec_R1.jpg`,
    jlink:`https://www.amazon.in/events/greatfreedomsale/&ref_=ine_gw_a24_dskcc_en_ecom/?_encoding=UTF8&pd_rd_w=7AMxT&content-id=amzn1.sym.fcb56e61-f545-4911-ae91-24fe4c0f73df&pf_rd_p=fcb56e61-f545-4911-ae91-24fe4c0f73df&pf_rd_r=9ZP11FS5FD86Y0ZW93RE&pd_rd_wg=MTdPi&pd_rd_r=8ef34d31-b63f-4ee3-bb50-46d6e88979c6&ref_=pd_hp_d_atf_unk&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`wolverine-logans-kicks-men-low-top-sneakers`,
    title:`Wolverine: Weapon X Men Low Top Sneakers`,
    rate:`₹1,999`,
    content: [  
      `The Wolverine: Weapon X Men Low Top Sneakers from theshouldstore.com feature a striking yellow and black color scheme. These sneakers are crafted for both style and comfort, making them perfect for everyday wear. The bold design pays homage to the iconic Marvel character, Wolverine, offering fans a unique and stylish footwear option to showcase their love for the superhero.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-yellow-500`,
    wear:`Deadpool X Wolverine`,
    Scolor:"text-red-500 text-lg font-semibold",
    buy:`/images/theshouldstore.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fwolverine-logans-kicks-men-low-top-sneakers%3Fgte%3D1%26utm_source%3Dinrdeals%26utm_medium%3Dreferral%26utm_campaign%3Doffer-remarketing%26utm_term%3Dremarketing`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1721458947_6270300.jpg?w=480&dpr=1.5`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1719992802_1828875.jpg?w=480&dpr=1.5`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1717239209_2963155.jpg?format=webp&w=480&dpr=1.5`,
    icon:`https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=ais_hybrid`,
    icon1:`/images/pinterest.png`,
    icon2:`https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png`,
    instagram:``,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:``,
    heading:`Iconic Design`,
    paragraph:`The Wolverine: Weapon X Men Low Top Sneakers feature an iconic yellow and black design inspired by Marvel's beloved character, Wolverine. The vibrant colors and bold patterns make these sneakers stand out, capturing the essence of Wolverine's fierce and powerful persona. Whether you're a Marvel fan or simply appreciate unique footwear, these sneakers are sure to make a statement.`,
    heading1:`Superior Comfort`,
    paragraph1:`Crafted with comfort in mind, these sneakers offer a cushioned insole and a supportive midsole. The design ensures maximum comfort for all-day wear, whether you're running errands, heading to the gym, or out on an adventure. The padding around the collar and tongue adds extra support and cushioning, making every step feel like walking on clouds.`,
    heading2:`Durable Construction`,
    paragraph2:`Made from high-quality materials, these sneakers are built to last. The sturdy construction ensures durability, even with regular use. The robust outer material is designed to withstand wear and tear, while the reinforced stitching adds an extra layer of strength. These sneakers are perfect for those who need reliable footwear that can keep up with their active lifestyle.`,
    heading3:`Breathable Fabric`,
    paragraph3:`The breathable fabric used in these sneakers helps keep your feet cool and dry. The mesh panels allow for air circulation, preventing sweat buildup and reducing the risk of odors. This feature is especially important for those who lead an active lifestyle or spend long hours on their feet. The breathable fabric ensures comfort even during intense activities.`,
    heading4:`Versatile Style`,
    paragraph4:`These sneakers are versatile enough to be paired with various outfits. Whether you're wearing jeans, shorts, or athletic wear, the Wolverine: Weapon X sneakers add a touch of style to any ensemble. Their unique design makes them a great conversation starter and a perfect addition to any wardrobe. The versatility ensures you can wear them for casual outings or more sporty occasions..`,
    keyword:`Wolverine sneakers, Weapon X sneakers, Marvel shoes, low top sneakers, yellow and black sneakers, men's fashion shoes, superhero footwear, Marvel merchandise, exclusive sneakers, theshouldstore.com, men's casual shoes, Wolverine footwear, durable sneakers, stylish men's shoes, comfortable sneakers, Marvel fan shoes, unique sneakers, high-quality sneakers, men's trendy sneakers, Wolverine low tops.`,
    description:`Discover the Wolverine: Weapon X Men Low Top Sneakers in striking yellow and black, available exclusively at theshouldstore.com. Enjoy superior comfort, durability, and iconic Marvel-inspired design. Perfect for fans and sneaker enthusiasts. Shop now for unique style and exceptional quality..`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/Events/img23/Teaser1/CMN_header_PC_Unrec_R1.jpg`,
    jlink:`https://www.amazon.in/events/greatfreedomsale/&ref_=ine_gw_a24_dskcc_en_ecom/?_encoding=UTF8&pd_rd_w=7AMxT&content-id=amzn1.sym.fcb56e61-f545-4911-ae91-24fe4c0f73df&pf_rd_p=fcb56e61-f545-4911-ae91-24fe4c0f73df&pf_rd_r=9ZP11FS5FD86Y0ZW93RE&pd_rd_wg=MTdPi&pd_rd_r=8ef34d31-b63f-4ee3-bb50-46d6e88979c6&ref_=pd_hp_d_atf_unk&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Black-Deadpool-Duo-Graphic-Printed-Oversized-Tshirt`,
    title:`Men's Black Deadpool Duo Graphic Printed Oversized T-shirt`,
    rate:`₹799`,
    content: [  
      `The Official Marvel Merchandise Men's Black Deadpool Duo Graphic Printed Oversized T-shirt features bold graphics of Deadpool, making it a standout piece for fans. Crafted from high-quality fabric, this oversized tee offers exceptional comfort and style. Ideal for casual wear, it effortlessly combines a relaxed fit with iconic Marvel flair, perfect for any superhero enthusiast.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-500`,
    wear:`Marvel Edition`,
    Scolor:"text-yellow-500 text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-black-deadpool-duo-graphic-printed-oversized-t-shirt-men`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-black-deadpool-duo-graphic-printed-oversized-t-shirt-644871-1721309976-1.jpg`,
    image:`https://images.bewakoof.com/t1080/men-s-black-deadpool-duo-graphic-printed-oversized-t-shirt-644871-1721309980-2.jpg`,
    image1:`https://images.bewakoof.com/t1080/men-s-black-deadpool-duo-graphic-printed-oversized-t-shirt-644871-1721309985-3.jpg`,
    icon:`https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=ais_hybrid`,
    icon1:`/images/pinterest.png`,
    icon2:`https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png`,
    instagram:``,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:``,
    heading:`Iconic Deadpool Graphics`,
    paragraph:`The Official Marvel Merchandise Men's Black Deadpool Duo Graphic Printed Oversized T-shirt features bold and striking graphics of Deadpool. This design captures the essence of the beloved antihero, making it an essential piece for any Deadpool fan. The duo graphic adds a dynamic and visually appealing element to the shirt, setting it apart from ordinary tees. Fans will appreciate the attention to detail and the authentic representation of Deadpool, making this shirt a great way to showcase their admiration for the character.`,
    heading1:`High-Quality Fabric`,
    paragraph1:`Crafted from high-quality fabric, this oversized T-shirt ensures durability and long-lasting comfort. The fabric is soft against the skin, making it perfect for all-day wear. It is designed to withstand regular use and multiple washes without losing its shape or color. The high-quality material also ensures that the graphics remain vibrant and intact, maintaining the shirt's appeal over time.`,
    heading2:`Exceptional Comfort`,
    paragraph2:`The oversized fit of this T-shirt offers exceptional comfort, making it ideal for casual wear. It provides ample room for movement, ensuring that wearers can stay comfortable throughout the day. Whether lounging at home or heading out for a casual outing, this T-shirt provides the perfect balance of comfort and style. Its relaxed fit also makes it suitable for various body types, ensuring that everyone can enjoy its comfort.`,
    heading3:`Versatile Casual Wear`,
    paragraph3:`This Deadpool T-shirt is a versatile addition to any wardrobe, effortlessly blending comfort with style. Its oversized fit and bold graphics make it suitable for a variety of casual settings, from relaxed weekends at home to casual meet-ups with friends. It pairs well with jeans, shorts, or joggers, offering endless styling possibilities. The versatile design ensures that wearers can express their love for Deadpool while staying stylish and comfortable.`,
    heading4:`Relaxed Fit`,
    paragraph4:`The relaxed fit of this T-shirt is perfect for those who prefer a more laid-back and comfortable style. It provides a loose and roomy fit that does not cling to the body, allowing for maximum comfort. This design is ideal for casual outings, ensuring that wearers can move freely and stay comfortable. The relaxed fit also adds to the shirt's overall aesthetic, giving it a cool and effortless look.`,
    keyword:`Official Marvel Merchandise, Men's Black Deadpool Duo T-shirt, Deadpool Graphic T-shirt, Oversized T-shirt, Marvel Deadpool Shirt, Deadpool Clothing, Men's Marvel T-shirt, Deadpool Merchandise, Graphic Printed T-shirt, Black Deadpool Tee, Marvel Apparel, Comic Book T-shirt, Superhero T-shirt, Men's Fashion, Casual Wear, Geek Clothing, Marvel Fans, Pop Culture Apparel, Comic Book Merch, Men's Oversized T-shirt`,
    description:`Shop the OFFICIAL MARVEL MERCHANDISE Men's Black Deadpool Duo Graphic Printed Oversized T-shirt. Featuring a bold Deadpool design, this stylish oversized tee offers ultimate comfort and standout appeal. Perfect for Marvel fans and casual wear. Upgrade your wardrobe with this must-have graphic T-shirt. Shop now!.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/IMG24/Smart_Watches/AUGART24/prime_1500-x-300_FB.jpg`,
    jlink:`https://www.amazon.in/l/90840372031/?_encoding=UTF8&pd_rd_w=nORkZ&content-id=amzn1.sym.3c60009a-6fa2-4e39-bb69-64e0ee4fda33&pf_rd_p=3c60009a-6fa2-4e39-bb69-64e0ee4fda33&pf_rd_r=SP2168QXV68SJT38A6DD&pd_rd_wg=KVwct&pd_rd_r=5d47aeda-fd36-4989-8a70-400b36eab313&ref_=pd_hp_d_hero_unk&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Briggs-Analog-Watch-For-Men-CH2927I`,
    title:`FOSSIL Briggs Analog Watch - For Men CH2927I#JustHere`,
    rate:`₹3,512`,
    content: [  
      `The FOSSIL Briggs Analog Watch for Men, model CH2927I, exudes classic elegance with its stainless steel case and durable leather strap. Featuring a chronograph function, luminous hands, and water resistance up to 50 meters, it combines style and functionality. Exclusively available under the #JustHere collection, it's perfect for both casual and formal wear.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-700`,
    wear:`FOSSIL Watch`,
    Scolor:"text-gray-600 text-lg font-semibold",
    buy:`/images/flipkart.png`,
    blink:`https://fktr.in/dHK8fqt`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://rukminim2.flixcart.com/image/850/1000/xif0q/watch/z/p/c/-original-imagpzjfgmbpmscn.jpeg?q=20&crop=false`,
    image:`https://m.media-amazon.com/images/S/aplus-media-library-service-media/8eee5b0f-1b03-42a6-9022-638287cecab1.__CR0,0,1500,928_PT0_SX970_V1___.png`,
    image1:`https://rukminim2.flixcart.com/image/850/1000/ku79vgw0/watch/v/u/b/ch2927i-fossil-men-original-imag7cvbmhp4ghdu.jpeg?q=90&crop=false`,
    icon:`https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=ais_hybrid`,
    icon1:`/images/pinterest.png`,
    icon2:`https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png`,
    instagram:`https://x.com/Trenz_blog/status/1819312576418721952`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://www.facebook.com/share/p/jpQkwgZ4SHu1WNvK/`,
    heading:`Classic Elegance and Style`,
    paragraph:`The FOSSIL Briggs Analog Watch for Men, model CH2927I, exudes an air of timeless sophistication. Its stainless steel case paired with a durable leather strap offers a seamless blend of modern and classic design. This watch is a versatile accessory that effortlessly enhances both casual and formal attire, making it a must-have for any stylish man.`,
    heading1:`Robust Stainless Steel Case`,
    paragraph1:`The watch features a robust stainless steel case that not only adds to its aesthetic appeal but also ensures long-lasting durability. This makes the FOSSIL Briggs Analog Watch a reliable companion for everyday wear, capable of withstanding the rigors of daily activities while maintaining its elegant appearance.`,
    heading2:`Durable Leather Strap`,
    paragraph2:`Complementing the stainless steel case is a high-quality leather strap. This strap is not only comfortable to wear but also adds a touch of classic elegance to the watch. The durability of the leather ensures that the watch can be worn frequently without showing signs of wear and tear.`,
    heading3:`Functional Chronograph`,
    paragraph3:`The watch is equipped with a functional chronograph, adding to its versatility and practicality. The chronograph feature allows you to measure elapsed time with precision, making it useful for a variety of activities, from timing workouts to keeping track of important events.`,
    heading4:`Luminous Hands`,
    paragraph4:`One of the standout features of the FOSSIL Briggs Analog Watch is its luminous hands. These glow in the dark, ensuring that you can easily read the time in low-light conditions. This feature enhances the watch's practicality, making it suitable for both day and night wear.`,
    keyword:`FOSSIL Briggs Analog Watch, FOSSIL CH2927I, FOSSIL men's watches, FOSSIL Briggs watch for men, FOSSIL Briggs CH2927I, FOSSIL analog watch, FOSSIL men's accessories, FOSSIL stylish watches, FOSSIL watch review, FOSSIL watches for men, FOSSIL Just Here collection, FOSSIL Briggs series, FOSSIL elegant watches, FOSSIL durable watches, men's fashion watches, FOSSIL CH2927I features, FOSSIL watches India, best FOSSIL watches, FOSSIL watch specifications, FOSSIL timepieces, FOSSIL men's fashion, premium men's watches, FOSSIL luxury watches, men's analog watches, FOSSIL high-quality watches, FOSSIL watch design, FOSSIL latest watches, FOSSIL CH2927I details`,
    description:`Discover the FOSSIL Briggs Analog Watch for Men (CH2927I) – a blend of classic elegance and modern functionality. Exclusively available at #JustHere, this sophisticated timepiece features a sleek design, durable construction, and precise movement, making it the perfect accessory for any occasion. Shop now and elevate your style.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/IMG24/Smart_Watches/AUGART24/prime_1500-x-300_FB.jpg`,
    jlink:`https://www.amazon.in/l/90840372031/?_encoding=UTF8&pd_rd_w=nORkZ&content-id=amzn1.sym.3c60009a-6fa2-4e39-bb69-64e0ee4fda33&pf_rd_p=3c60009a-6fa2-4e39-bb69-64e0ee4fda33&pf_rd_r=SP2168QXV68SJT38A6DD&pd_rd_wg=KVwct&pd_rd_r=5d47aeda-fd36-4989-8a70-400b36eab313&ref_=pd_hp_d_hero_unk&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`SmartRing-Active-with-Stylish-Stainless-Steel-Lightweight-Health-Monitor-Magnetic-Charging-Case-5ATM`,
    title:`boAt Newly Launched SmartRing Active with Stylish Stainless-Steel & Lightweight, Health Monitor, Magnetic Charging Case, 5ATM,Crest App & Coins, 20+ Sports Modes(Midnight Black 8)`,
    rate:`₹3,999`,
    content: [  
      `The boAt Newly Launched SmartRing Active combines style and functionality with its sleek stainless-steel design and lightweight build. Equipped with a health monitor, magnetic charging case, and 5ATM water resistance, it offers 20+ sports modes. Integrated with the Crest App and Coins, this SmartRing in Midnight Black is perfect for fitness enthusiasts seeking a blend of technology and style..`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Boat SmartRing`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/boAt-Launched-SmartRing-Stainless-Steel-Lightweight/dp/B0D4VD1CMN?tag=inrdeal123-21`,
    buy1:`/images/boat.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fsmart-ring-active%3Fclickid%3D66aa44ef8b5734034e22bf77%26utm_campaign%3DOptimise_jan2021%26utm_medium%3DOptimise%26utm_source%3DAffise_Optimise%2BMedia%26variant%3D41330151784546`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/F_Black_1000x.png?v=1721616024`,
    image:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard1_f7c6f84b-a2b1-45ed-aceb-71f8e9b262a2_1000x.png?v=1721184539`,
    image1:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard7_e9a49beb-e0d2-4e56-8ac4-908269b45e5e_1000x.png?v=1721184539`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Sleek Stainless-Steel Design`,
    paragraph:`The boAt SmartRing Active is a true testament to modern engineering, featuring a sleek stainless-steel design that exudes sophistication. Its polished finish and ergonomic build make it not only a fashionable accessory but also comfortable for all-day wear. Whether you're heading to a business meeting or a workout session, this SmartRing seamlessly complements your style.`,
    heading1:`Lightweight Build for Daily Comfort`,
    paragraph1:`Despite its robust features, the SmartRing Active remains incredibly lightweight. This ensures that users can wear it throughout the day without any discomfort. The lightweight design is perfect for those who lead an active lifestyle, ensuring that the SmartRing doesn’t weigh you down during your daily activities or exercise routines.`,
    heading2:`Comprehensive Health Monitoring`,
    paragraph2:`Equipped with advanced health monitoring features, the SmartRing Active tracks vital health metrics such as heart rate, sleep patterns, and blood oxygen levels. This allows users to stay informed about their health and make necessary adjustments to their lifestyle. The real-time health data helps in maintaining optimal wellness and identifying any irregularities promptly.`,
    heading3:`Convenient Magnetic Charging Case`,
    paragraph3:`The SmartRing Active comes with a magnetic charging case that simplifies the charging process. The case ensures that the SmartRing is always ready for use, providing a hassle-free charging experience. This design also ensures that the SmartRing is securely held in place while charging, preventing any accidental falls or damage..`,
    heading4:`5ATM Water Resistance`,
    paragraph4:`With 5ATM water resistance, the SmartRing Active is built to withstand water exposure, making it ideal for various water-related activities. Whether you're swimming, running in the rain, or simply washing your hands, the SmartRing can handle it all. This durability ensures that you don’t have to remove the SmartRing during your everyday activities.`,
    keyword:`boAt SmartRing Active, boAt SmartRing Active features, boAt SmartRing Active health monitor, boAt SmartRing Active stainless steel, boAt SmartRing Active sports modes, boAt SmartRing Active water resistance, boAt SmartRing Active magnetic charging, boAt SmartRing Active Crest App, boAt SmartRing Active Coins, boAt SmartRing Active Midnight Black, fitness SmartRing, stylish SmartRing, boAt fitness technology, smart fitness ring, high-tech fitness accessories, wearable fitness technology, boAt health monitoring SmartRing, boAt SmartRing review, boAt fitness tracker, best SmartRing for sports, lightweight SmartRing, smart fitness gadgets`,
    description:`Discover the boAt Newly Launched SmartRing Active in Midnight Black, a blend of style and functionality. With a sleek stainless-steel design, health monitor, 5ATM water resistance, 20+ sports modes, and Crest App integration, this lightweight SmartRing is perfect for fitness enthusiasts. Experience cutting-edge technology and style with magnetic charging and more..`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img24/Wireless/tdhruvko/Stores/Rakhi/Tiles/29thJuly/1242x450.jpg`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_Budget_1a1_w?node=94188511031&ref_=Rakhi_Store&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-12&pf_rd_r=XE6ZRR1QYTN0JQ0HG503&pf_rd_t=101&pf_rd_p=a50454d0-f122-45f2-b4da-f0de5da607ae&pf_rd_i=1389401031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`Airdopes-Alpha-Deadpool-Edition`,
    title:`boAt Airdopes Alpha Deadpool Edition- 35 HRS Playback,13mm Drivers,Dual Mics ENx Tech Bluetooth Headset  `,
    rate:`₹1,099`,
    content: [  
      `The boAt Airdopes Alpha Deadpool Edition offers 35 hours of playback, featuring 13mm drivers for rich sound quality. Equipped with dual mics and ENx Tech, these Bluetooth headsets ensure clear calls. The stylish Assassin Black in-ear design delivers a comfortable fit, making them perfect for music and calls. Ideal for Deadpool fans and audiophiles alike..`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-600`,
    wear:`Boat Airpods`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/flipkart.png`,
    blink:`https://fktr.in/d3vsL9Y`,
    buy1:`/images/boat.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fairdopes-alpha-deadpool-edition%3Fclickid%3D66a9f45c94c7cc035571efbe%26utm_campaign%3DOptimise_jan2021%26utm_medium%3DOptimise%26utm_source%3DAffise_Optimise%2BMedia`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/main_img_alpha_3000x.png?v=1721649247`,
    image:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard1_e419ef66-30d3-4fcf-a994-ed9bf698be06_1000x.png?v=1721457632`,
    image1:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard3copy_1000x.png?v=1721457633`,
    icon:`https://x.com/Trenz_blog/status/1819312576418721952`,
    icon1:`/images/pinterest.png`,
    icon2:`https://static.vecteezy.com/system/resources/previews/018/930/698/original/facebook-logo-facebook-icon-transparent-free-png.png`,
    instagram:`https://x.com/Trenz_blog/status/1819403110290223138`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://www.facebook.com/treindz/posts/pfbid02sPRhQafRq6xPebTvkw19hHaLH6fX1EuRWygLdUbExSSEFVa9fTQdcxiKzArzYrKbl`,
    heading:`Extended Playback Time`,
    paragraph:`The boAt Airdopes Alpha Deadpool Edition provides an impressive 35 hours of playback time. This extended battery life ensures that you can enjoy your favorite music, podcasts, and calls without constantly worrying about recharging. Ideal for long commutes, travel, and continuous usage throughout the day, these earbuds are designed to keep up with your lifestyle.`,
    heading1:`High-Quality Sound with 13mm Drivers`,
    paragraph1:`Equipped with 13mm drivers, the Airdopes Alpha delivers rich and immersive sound quality. Whether you're listening to music, watching movies, or making calls, the audio experience is clear and powerful. The drivers are engineered to produce deep bass, crisp highs, and balanced mids, offering a premium listening experience for audiophiles.`,
    heading2:`Dual Mics and ENx Tech for Clear Calls`,
    paragraph2:`The inclusion of dual mics and ENx Tech ensures that your calls are crystal clear, even in noisy environments. This technology effectively reduces background noise, making your voice stand out. Whether you're in a bustling café or walking down a busy street, you can communicate without disruptions, ensuring professional-grade call quality.`,
    heading3:`Stylish Assassin Black Design`,
    paragraph3:`The Assassin Black design with Deadpool branding makes these Airdopes stand out. The sleek and stylish appearance is perfect for fans of the character, combining aesthetics with functionality. The design not only looks good but also offers a comfortable fit, making them suitable for long listening sessions without discomfort.`,
    heading4:`Comfortable In-Ear Fit`,
    paragraph4:`Designed for maximum comfort, these in-ear headphones come with multiple ear tip sizes to ensure a perfect fit for any ear. The ergonomic design prevents ear fatigue, even after prolonged use. This makes them ideal for daily wear, workouts, or extended use at work, providing both comfort and stability.`,
    keyword:`Bluetooth headset boAt Alpha, 13mm drivers boAt Airdopes, Dual mics ENx Tech boAt, Assassin Black boAt earbuds, boAt in-ear headphones, boAt Alpha earphones, boAt Airdopes Alpha review, best wireless earbuds boAt, boAt Alpha price, boAt Alpha specs, boAt Airdopes features, boAt Alpha battery life, boAt Deadpool edition price, boAt Alpha sound quality, boAt Alpha connectivity, boAt Airdopes discount, boAt Alpha fast charging, boAt Alpha comfort, boAt Alpha fit, boAt Alpha touch controls, boAt Alpha waterproof, boAt Alpha sweatproof, boAt Alpha pairing, boAt Alpha vs other earbuds, boAt Alpha review video, boAt Alpha unboxing, best Deadpool earbuds, boAt Alpha for calls, boAt Alpha for music, boAt Alpha wireless earphones,boAt Alpha durability, boAt Alpha customer reviews, boAt Alpha ratings, boAt Alpha design, boAt Alpha performance,boAt Alpha price comparison`,
    description:`Experience superior sound with boAt Airdopes Alpha Deadpool Edition. Enjoy 35 hours of playback, 13mm drivers for rich audio, dual mics with ENx Tech for clear calls, and a stylish Assassin Black in-ear design. Perfect for Deadpool fans and audiophiles seeking high-quality Bluetooth headsets.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img24/Wireless/tdhruvko/Stores/Rakhi/Tiles/29thJuly/1242x450.jpg`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_Budget_1a1_w?node=94188511031&ref_=Rakhi_Store&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-12&pf_rd_r=XE6ZRR1QYTN0JQ0HG503&pf_rd_t=101&pf_rd_p=a50454d0-f122-45f2-b4da-f0de5da607ae&pf_rd_i=1389401031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`magnetic-wireless-battery-pack`,
    title:`DailyObjects SURGE™ Magnetic MagSafe Wireless Battery Pack - 10000 mAh`,
    rate:`₹5,999`,
    content: [  
      `The DailyObjects SURGE™ Magnetic MagSafe Wireless Battery Pack offers a powerful 10000 mAh capacity for on-the-go charging. Designed for convenience, it features MagSafe compatibility, allowing it to attach seamlessly to your iPhone. With its sleek design and robust power, it ensures your devices stay charged throughout the day. Ideal for travel and daily use, this battery pack combines functionality and trends style.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-neutral-600`,
    wear:`Accessories`,
    Scolor:"text-black text-lg font-semibold",
    buy:`https://i.vimeocdn.com/video/590275491-098180c85e7cc9beaa3bb1a8483061e2a48e647db43e657b01298faa7ca73c6e-d_640?f=webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-surge-magnetic-wireless-battery-pack%2Fdp%3Ff%3Dpid~SRG-MGNTC-WIRLES-BTRY-PACK`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-magnetic-wireless-battery-pack-images/DailyObjects-SURGE-Magnetic-Wireless-Battery-Pack-2nd.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-magnetic-wireless-battery-pack-images/DailyObjects-SURGE-Magnetic-Wireless-Battery-Pack-15.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-magnetic-wireless-battery-pack-images/DailyObjects-SURGE-Magnetic-Wireless-Battery-Pack-13t.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`High Capacity Battery`,
    paragraph:`The SURGE™ Magnetic MagSafe Wireless Battery Pack boasts a powerful 10000 mAh capacity, providing ample charge for your devices. This high capacity ensures multiple charges for your smartphone, making it ideal for extended trips, busy workdays, or emergencies when you need reliable power on the go.`,
    heading1:`MagSafe Compatibility`,
    paragraph1:`Designed with MagSafe compatibility, this battery pack attaches securely to the back of your iPhone. The strong magnetic alignment ensures a stable and seamless connection, allowing for efficient wireless charging without the hassle of cables. This feature enhances the convenience of charging your device anytime, anywhere.`,
    heading2:`Sleek and Portable Design`,
    paragraph2:`The battery pack features a sleek, compact design that easily fits in your bag or pocket. Its slim profile and lightweight build make it highly portable, ensuring you can carry it with you without adding bulk. The stylish design also complements the aesthetic of your iPhone, making it a chic accessory.`,
    heading3:`Fast Wireless Charging`,
    paragraph3:`Equipped with fast wireless charging capabilities, the SURGE™ battery pack delivers quick and efficient power to your devices. This feature ensures that your smartphone charges rapidly, reducing downtime and keeping you connected and productive throughout the day.`,
    heading4:`LED Power Indicator`,
    paragraph4:`The built-in LED power indicator allows you to easily monitor the battery level of the pack. This feature ensures you always know how much charge is remaining, helping you plan your usage and recharge the pack when necessary. The clear and intuitive indicator adds to the user-friendly experience.`,
    keyword:`DailyObjects SURGE, MagSafe wireless battery pack, 10000 mAh battery pack, magnetic wireless charger, portable charger, DailyObjects wireless charger, MagSafe compatible battery, high-capacity power bank, wireless charging, fast charging battery pack, DailyObjects power bank, travel charger, MagSafe technology, magnetic charging pack, DailyObjects accessories`,
    description:`10000 mAh  Magnetic Wireless Fast charging Power Bank Premium Finish, 22.5 Wired Output Compatible with iPhone 12 & above, Designed for convenience, it features MagSafe compatibility, allowing it to attach seamlessly to your iPhone. With its sleek design and robust power, it ensures your devices stay charged throughout the day. Ideal for travel and daily use, this battery pack combines functionality and trends style.`,
    summary:`https://m.media-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/tv_uber_AUTO_PC_1500x450._CB558849697_.jpg`,
    jlink:`https://www.amazon.in/gp/browse.html?node=1389396031&ref_=nav_em_sbc_tvelec_television_0_2_9_2&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`phone-lanyard-cord`,
    title:`Daily Objects Orange Crossbody Phone Lanyard - Cord`,
    rate:`₹1,199`,
    content: [  
      `The Orange Crossbody Phone Lanyard by DailyObjects is a stylish and practical accessory designed for convenience and security. Made with durable cord material, it allows you to keep your phone accessible while on the go. The vibrant orange color adds a pop of trends style, and the adjustable length ensures a comfortable fit. Perfect for hands-free use during travel, shopping, or outdoor activities, it combines functionality with fashion.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-yellow-700`,
    wear:`Accessories`,
    Scolor:"text-cyan-700 text-lg font-semibold",
    buy:`https://i.vimeocdn.com/video/590275491-098180c85e7cc9beaa3bb1a8483061e2a48e647db43e657b01298faa7ca73c6e-d_640?f=webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Forange-crossbody-phone-lanyard-cord%2Fdp%3Ff%3Dpid~ORNG-CROSBODY-PHN-LNYARD-CORD%26s%3Dreferer~lp`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1813/orange-crossbody-phone-lanyard-cord-images/Orange-Crossbody-Phone-Lanyard-Cord.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1813/orange-crossbody-phone-lanyard-cord-images/Orange-Crossbody-Phone-Lanyard-Cord-2.jpg?tr=cm-pad_crop,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1813/orange-crossbody-phone-lanyard-cord-images/Orange-Crossbody-Phone-Lanyard-Cord-5.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Hands-Free Convenience`,
    paragraph:`The Orange Crossbody Phone Lanyard offers hands-free convenience, allowing you to keep your phone within easy reach while you go about your day. This is particularly useful for busy individuals who need to multitask or those who want to keep their hands free for other activities such as shopping or hiking.`,
    heading1:`Durable Cord Material`,
    paragraph1:`Crafted from high-quality, durable cord material, the lanyard is designed to withstand daily wear and tear. The sturdy construction ensures that your phone remains securely attached, providing peace of mind that it won’t easily break or fray over time. This durability makes it a reliable accessory for everyday use.`,
    heading2:`Vibrant Orange Color`,
    paragraph2:`The lanyard comes in a vibrant orange color that adds a stylish touch to any outfit. This eye-catching color not only enhances your look but also makes it easier to spot your phone, reducing the chances of misplacing it. The bold hue is perfect for those who love to make a fashion statement.`,
    heading3:`Secure Phone Attachment`,
    paragraph3:`The lanyard is designed to securely hold your phone, preventing accidental drops or loss. It typically includes a strong phone holder or case attachment that keeps your device firmly in place. This secure attachment ensures that your phone stays safe, even during vigorous activities.`,
    heading4:`Affordable and Practical`,
    paragraph4:`Despite its high-quality construction and stylish design, the lanyard is affordably priced. DailyObjects offers this practical accessory at a competitive price, making it accessible to a wide range of users. Its affordability combined with its functionality makes it an excellent value for money..`,
    keyword:`Crossbody Phone Lanyard, phone lanyard, phone cord, crossbody phone holder, hands-free phone accessory, phone strap, mobile phone lanyard, wearable phone holder, stylish phone lanyard, convenient phone cord, phone lanyard for travel, secure phone lanyard, phone lanyard with cord, trendy phone accessory, phone lanyard for daily use`,
    description:`Crossbody & Lanyard Cases from a great selection at Electronics Store,Cell Phone Lanyard Crossbody Hanging Chain Mobile Holder Sling Around Neck to Carry iPhone & Smartphone with Detachable Crossbody Shoulder Neck, Made with durable cord material, it allows you to keep your phone accessible while on the go. The vibrant orange color adds a pop of trends style, and the adjustable length ensures a comfortable fit. Perfect for hands-free use during travel, shopping, or outdoor activities, it combines functionality with fashion.`,
    summary:`https://m.media-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/2024_February/jsqjshq/shspc/Redmi._CB560425961_.jpg`,
    jlink:`https://www.amazon.in/gp/browse.html?node=1389396031&ref_=nav_em_sbc_tvelec_television_0_2_9_2&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`4-in-1-universal-braided-charging-cable`,
    title:`DailyObjects SURGE™ 4-in-1 Universal Braided Charging Cable`,
    rate:`₹699`,
    content: [  
      `The DailyObjects SURGE™ 4-in-1 Universal Braided Charging Cable is a versatile solution for all your charging needs. Featuring four connectors—USB-C, Micro-USB, Lightning, and USB-A—this cable ensures compatibility with a wide range of devices. Its braided design offers durability and tangle-free use, while fast charging capabilities ensure quick power delivery. Perfect for home, office, or travel, it combines convenience, trends and reliability in one sleek package.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-700`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://i.vimeocdn.com/video/590275491-098180c85e7cc9beaa3bb1a8483061e2a48e647db43e657b01298faa7ca73c6e-d_640?f=webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-surge-4-in-1-universal-braided-charging-cable%2Fdp%3Ff%3Dpid~4IN1-SRG-BRDED-CHRGNG-CABLE`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-4-in-1-universal-braided-charging-cable-images/DailyObjects-SURGE-4-in-1-Universal-Braided-Charging-Cable.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-4-in-1-universal-braided-charging-cable-images/DailyObjects-SURGE-4-in-1-Universal-Braided-Charging-Cable-1st.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-4-in-1-universal-braided-charging-cable-images/DailyObjects-SURGE-4-in-1-Universal-Braided-Charging-Cable-2.jpg?tr=cm-pad_crop,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Four Connectors for Universal Compatibility`,
    paragraph:`The SURGE™ 4-in-1 Charging Cable includes USB-C, Micro-USB, Lightning, and USB-A connectors, making it compatible with virtually all devices. Whether you need to charge your smartphone, tablet, or other gadgets, this cable has you covered. It eliminates the need for multiple cables, providing a one-stop solution for all your charging needs.`,
    heading1:`Durable Braided Design`,
    paragraph1:`The cable features a braided exterior that enhances durability and resists tangling. This design not only extends the life of the cable but also ensures it remains flexible and easy to use. The robust construction can withstand daily wear and tear, making it ideal for frequent use.`,
    heading2:`Fast Charging Capabilities`,
    paragraph2:`Equipped with fast charging technology, the SURGE™ cable ensures your devices charge quickly and efficiently. This feature is particularly beneficial for those with busy schedules, allowing for rapid power delivery so your devices are ready to go when you need them. It supports high-speed charging for compatible devices, reducing downtime significantly`,
    heading3:`Tangle-Free Use`,
    paragraph3:`The braided design also helps prevent tangling, making the cable easy to manage and store. This feature is especially useful for travel or on-the-go use, ensuring you can quickly and easily access your cable without the frustration of knots and tangles. It saves time and keeps your charging setup neat and organized.`,
    heading4:`Enhanced Durability`,
    paragraph4:`Constructed with high-quality materials, the SURGE™ cable is designed to last. The braided exterior, combined with reinforced connectors, ensures it can withstand the rigors of daily use. This durability makes it a reliable choice for both personal and professional use, providing consistent performance over time.`,
    keyword:`DailyObjects SURGE, 4-in-1 charging cable, universal charging cable, braided charging cable, multi-device charger, durable charging cable, fast charging cable, universal USB cable, DailyObjects accessories, high-quality charging cable, multi-functional charger, reliable charging cable, tech accessories, mobile charging cable, DailyObjects products`,
    description:`4-In-1 Universal Braided Charging Cable With Multiport | Type-C To Lightning, Type-A, Supports Data Transfer | Compatible with All Android & iphone, Featuring four connectors—USB-C, Micro-USB, Lightning, and USB-A—this cable ensures compatibility with a wide range of devices. Its braided design offers durability and tangle-free use, while fast charging capabilities ensure quick power delivery. Perfect for home, office, or travel, it combines convenience, trends and reliability in one sleek package.`,
    summary:`https://m.media-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/June24/Mi_Herotator_PC-Xiaomi._CB554127155_.jpg`,
    jlink:`https://www.amazon.in/gp/browse.html?node=1389396031&ref_=nav_em_sbc_tvelec_television_0_2_9_2&tag=inrdeal123-21SS`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`zeb-pixaplay`,
    title:`Zebronics PIXAPLAY 55 LED PROJECTOR`,
    rate:`₹1,499`,
    content: [  
      `The PIXAPLAY 55 LED Projector by Zebronics offers a seamless trends home theater experience with its impressive LED display and vibrant visuals. Compact and portable, it supports multiple connectivity options including HDMI, USB, and VGA, making it versatile for various media devices. Ideal for movies, presentations, and gaming, this projector combines high-quality performance with user-friendly features, ensuring an immersive and convenient viewing experience.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Accessories`,
    Scolor:"text-sky-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAKt1iCcjMxdjCfqPlQBeNfppi85-EABQ8pw&s`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.zebronics.com%2Fproducts%2Fzeb-pixaplay-55`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://shop.zebronics.com/cdn/shop/files/zeb-pixaplay-55-pic1_c294dfd9-64d3-4ca7-bc37-84abd68dfcee.jpg?v=1718707751&width=800`,
    image:`https://shop.zebronics.com/cdn/shop/files/zeb-pixaplay-55-pic2.jpg?v=1718707751&width=800`,
    image1:`https://shop.zebronics.com/cdn/shop/files/zeb-pixaplay-55-pic5.jpg?v=1718707751&width=800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Impressive LED Display`,
    paragraph:`The PIXAPLAY 55 LED Projector features a high-resolution LED display that delivers bright and vivid visuals. With enhanced color accuracy and sharpness, it ensures a superior viewing experience whether you’re watching movies, playing games, or giving presentations. The LED technology also offers better energy efficiency and longer lifespan compared to traditional projector bulbs.`,
    heading1:`Multiple Connectivity Options`,
    paragraph1:`The projector supports a variety of connectivity options, including HDMI, USB, VGA, and AV inputs. This versatility allows you to connect it to different media devices such as laptops, gaming consoles, DVD players, and USB drives. The multiple ports ensure you can enjoy a wide range of content from various sources with ease.`,
    heading2:`Built-in Speaker`,
    paragraph2:`Equipped with a built-in speaker, the projector offers clear and adequate audio for most viewing experiences. While it’s perfect for casual use, you can also connect external speakers or a sound system for enhanced audio quality. This built-in feature adds to the convenience of using the projector without needing additional audio equipment.`,
    heading3:`Adjustable Projection Size`,
    paragraph3:`The PIXAPLAY 55 allows you to adjust the projection size to suit your viewing environment. With the capability to project images from 30 inches up to 120 inches, you can customize the screen size for different settings, from a small room to a large outdoor space. This flexibility makes it ideal for various occasions and locations.`,
    heading4:`Energy-Efficient Performance`,
    paragraph4:`The LED technology used in the PIXAPLAY 55 not only provides superior brightness and color but also ensures energy efficiency. The projector consumes less power compared to traditional lamp-based projectors, making it a more eco-friendly and cost-effective option. The long-lasting LED light source also means less frequent replacements and lower maintenance costs.`,
    keyword:`Zebronics PIXAPLAY 55, LED projector, Zebronics projector, home theater projector, portable projector, high-definition projector, multimedia projector, office projector, HD projector, Zebronics PIXAPLAY, best LED projector, Zebronics PIXAPLAY 55 review, Zebronics PIXAPLAY 55 features, Zebronics PIXAPLAY 55 specs, Zebronics PIXAPLAY 55 price`,
    description:`Features: Home Theater Projectors for Movies, TV & Gaming etc, mSD Built in Speaker Wide Compatibility, With enhanced color accuracy and sharpness, it ensures a superior viewing experience whether you’re watching movies, playing games, or giving presentations. The LED technology also offers better energy efficiency and longer lifespan compared to traditional projector bulbs.`,
    summary:`https://m.media-amazon.com/images/G/31/img21/TVs/Nireeksh/June2024/QLEDRevised/Samsung_Herotators-PC-1500x650._CB555594380_.jpg`,
    jlink:`https://www.amazon.in/gp/browse.html?node=1389396031&ref_=nav_em_sbc_tvelec_television_0_2_9_2&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


 
 
  {
    name:`Tech-Kit-Organiser`,
    title:`Daily Objects Marshal Tech Kit Organiser - Blue`,
    rate:`₹1699`,
    content: [  
      `The Marshal Tech Kit Organiser by DailyObjects in Blue is a must-have for tech enthusiasts and professionals alike. Designed to keep your gadgets and accessories neatly organized, it features multiple compartments and elastic loops for secure storage. The durable fabric and sleek design make it both functional and trends stylish. Perfect for travel or everyday use, this organizer ensures your tech essentials are always within reach and well-protected.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-800`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://i.vimeocdn.com/video/590275491-098180c85e7cc9beaa3bb1a8483061e2a48e647db43e657b01298faa7ca73c6e-d_640?f=webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fmarshal-tech-case-blue%2Fdp%3Ff%3Dpid~MARSAL-TECH-CASE-BLUE`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1703/marshal-tech-case-blue-images/marshal-tech-kit-organiser-blue.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1703/marshal-tech-case-blue-images/marshal-tech-kit-organiser-blue-4.jpg?tr=cm-pad_crop,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1703/marshal-tech-case-blue-images/marshal-tech-kit-organiser-blue-5.jpg?tr=cm-pad_crop,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Multiple Compartments`,
    paragraph:`The Marshal Tech Kit Organiser is equipped with a variety of compartments to accommodate different tech accessories. From cables and chargers to USB drives and headphones, each item has its designated place. This feature ensures that all your gadgets are stored neatly and can be easily accessed whenever needed.`,
    heading1:`Elastic Loops`,
    paragraph1:`Featuring elastic loops, the organizer securely holds your items in place. These loops prevent your cables from tangling and keep smaller items like pens or styluses in order. The elastic nature of the loops allows them to adjust to different sizes, providing a snug fit for various accessories.`,
    heading2:`Durable Fabric`,
    paragraph2:`Constructed from high-quality, durable fabric, the Marshal Tech Kit Organiser is designed to withstand daily wear and tear. The robust material ensures long-lasting use, protecting your tech gadgets from damage. The fabric is also easy to clean, maintaining the organizer’s sleek appearance over time.`,
    heading3:`Sleek and Stylish Design`,
    paragraph3:`With its sleek design and blue color, this tech kit organizer is not only functional but also stylish. The modern look makes it a great accessory for both professional and casual settings. It’s an excellent addition to your tech gear, enhancing your organizational style.`,
    heading4:`Protective Padding`,
    paragraph4:`The organizer includes protective padding to safeguard your gadgets from bumps and scratches. This feature provides peace of mind, knowing that your valuable tech accessories are well-protected. The padding also helps in maintaining the structure of the organizer, ensuring that it stays in shape even when fully loaded.`,
    keyword:`Daily Objects, Marshal Tech Kit Organiser, blue tech organiser, tech accessories, gadget organiser, cable organiser, tech travel kit, electronic accessories, portable tech organiser, Daily Objects organiser, tech storage, travel tech organiser, high-quality tech organiser, stylish tech kit`,
    description:`Buy mobile accessories organizers online in India from Daily Objects. Premium quality cable protector, cable winder, tech kit, trends product, Trendz Accessories Article, Designed to keep your gadgets and accessories neatly organized, it features multiple compartments and elastic loops for secure storage. The durable fabric and sleek design make it both functional and trends stylish. Perfect for travel or everyday use, this organizer ensures your tech essentials are always within reach and well-protected.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/November/giftings_store_banners_PC.jpg`,
    jlink:`https://www.amazon.in/l/90035948031?tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`Strapeless-Midi-Dress`,
    title:`Micas Sunny Floral Cascade Strapless Midi Dress`,
    rate:`$ 25.00`,
    content: [  
      `The Sunny Floral Cascade Strapless Midi Dress by Micas captures the essence of summer with its vibrant floral pattern and flowing design. This strapless midi dress features cascading layers that create a playful yet elegant trends look. Ideal for warm-weather events, it combines comfort and style, making it a versatile piece for various occasions. Pair it with your favorite accessories for a standout, sun-kissed ensemble.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-orange-600`,
    wear:`Womens Dress`,
    Scolor:"text-sky-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpxcz6rFOtPP-fmbwNMmU9rI7w-hV2unUM2w&s`,
    blink:`https://www.jdoqocy.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwda2403050001%3Fcjdata%3DMXxOfDB8WXww%26cjevent%3Dbac806c0353211ef82a93b690a18b8f9%26utm_source%3Daffiliate%26utm_medium%3Dcj%26utm_content%3D7034919`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://shopmicas.com/cdn/shop/files/fdc42603-6d31-4550-ba6a-cfea7e6ecd15_1800x1800.jpg?v=1718966513`,
    image:`https://shopmicas.com/cdn/shop/files/d27f9ee5-86c9-4e74-9d8e-ca19ca336ada_1800x1800.jpg?v=1718966515`,
    image1:`https://shopmicas.com/cdn/shop/files/36f4e72a-7267-49d9-bdd4-f125b98f14a3_1800x1800.jpg?v=1718966519`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Vibrant Floral Pattern`,
    paragraph:`The dress features a sunny floral pattern that instantly brightens up any outfit. The vibrant colors and intricate floral designs are perfect for summer, evoking a cheerful and lively spirit. This pattern adds a fun and feminine touch, making the dress ideal for outdoor events and casual gatherings.`,
    heading1:`Strapless Design`,
    paragraph1:`The strapless design offers a chic and modern look that beautifully highlights the shoulders and neckline. This style is perfect for warm weather, providing a comfortable and breezy feel. The strapless cut also allows for easy accessorizing with statement necklaces and earrings.`,
    heading2:`Cascading Layers`,
    paragraph2:`The cascading layers of the dress add a unique and elegant flair. These layers create movement and dimension, giving the dress a dynamic and flowy silhouette. The cascading effect is visually appealing and adds a touch of sophistication to the overall design.`,
    heading3:`Midi Length`,
    paragraph3:`The midi length strikes the perfect balance between casual and formal, making the dress suitable for a variety of occasions. This length is flattering for all body types, offering a modest yet stylish look. It’s perfect for daytime events, garden parties, or even a stylish brunch.`,
    heading4:`Attention to Detail`,
    paragraph4:`Micas is known for its meticulous attention to detail, and this dress is no exception. From the precise stitching to the quality of the fabric, every aspect of the dress is carefully crafted. The floral pattern is thoughtfully placed to enhance the dress’s overall aesthetic, ensuring a high-quality garment.`,
    keyword:`Micas Sunny Floral Cascade Strapless Midi Dress, floral midi dress, strapless dress, summer dress, floral dress, midi dress, women's fashion, trendy dress, summer fashion, Micas dress, cascade dress, casual dress, fashionable dress, Micas clothing, elegant dress, stylish dress, floral print dress, Micas Sunny dress, online shopping, Micas fashion`,
    description:`This strapless midi dress features cascading layers that create a playful yet elegant trends look. Ideal for warm-weather events, it combines comfort and style, making it a versatile piece for various occasions. Pair it with your favorite accessories for a standout, sun-kissed ensemble.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img23/Wireless/OnePlus/Nord/NordCE4Lite/Justlaunched/LP_PC2.jpg`,
    jlink:`https://www.amazon.in/b/ref=QAHzEditorial_en_IN_1?pf_rd_r=314RAHMQW89Y5JVTAZ2E&pf_rd_p=9f75a69f-7339-475c-8032-a96eb52de81c&pf_rd_m=A1VBAL9TL5WCBF&pf_rd_s=merchandised-search-21&pf_rd_t=&pf_rd_i=1389401031&node=100104424031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Neck-Ruched-Mini-Dress`,
    title:`Micas Linen Halter Neck Ruched Mini Dress`,
    rate:`$ 38.00`,
    content: [  
      `The Linen Halter Neck Ruched Mini Dress by Micas is a perfect blend of casual chic and sophisticated trends style. Crafted from breathable linen, this dress features a halter neck design and ruched detailing for a flattering fit. The mini length adds a playful touch, making it ideal for summer outings or casual gatherings. Its effortless elegance and comfort make it a versatile addition to any wardrobe.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-500`,
    wear:`Womens Dress`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpxcz6rFOtPP-fmbwNMmU9rI7w-hV2unUM2w&s`,
    blink:`https://www.dpbolvw.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdc2405140002%3Fcjdata%3DMXxOfDB8WXww%26cjevent%3D4645f61c352b11ef82ef37170a18ba73%26utm_source%3Daffiliate%26utm_medium%3Dcj%26utm_content%3D7034919`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://shopmicas.com/cdn/shop/files/ad19340f-ff9f-403d-be2d-f3dddb99369e_1800x1800.jpg?v=1718094298`,
    image:`https://shopmicas.com/cdn/shop/files/a6ee9bff-d96e-429f-b199-c1e9b532c15e_1800x1800.jpg?v=1718094298`,
    image1:`https://shopmicas.com/cdn/shop/files/630871f0-e44c-44c0-af86-9da2c2e1b305_1800x1800.jpg?v=1718094298`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Chic Halter Neck Design`,
    paragraph:`The halter neck design of this dress offers a stylish and modern look. It beautifully highlights the shoulders and neckline, creating an elegant silhouette. This design is perfect for those looking to add a touch of sophistication to their summer wardrobe while maintaining a casual, relaxed vibe.`,
    heading1:`Breathable Linen Fabric`,
    paragraph1:`Made from high-quality linen, this dress is perfect for warm weather. Linen is known for its breathability and ability to keep you cool, making it an ideal fabric for summer. The natural fibers provide a soft, comfortable feel against the skin, ensuring all-day comfort.`,
    heading2:`Flattering Ruched Detailing`,
    paragraph2:`The ruched detailing along the sides of the dress creates a flattering fit that accentuates the waist and enhances the overall silhouette. This feature adds texture and dimension to the dress, making it visually interesting while also providing a tailored look that suits various body types.`,
    heading3:`Playful Mini Length`,
    paragraph3:`The mini length of this dress adds a playful and youthful touch to the design. It's perfect for showing off your legs and pairs well with both casual and dressy footwear. This length makes the dress versatile for different occasions, from daytime outings to evening events.`,
    heading4:`Versatile Styling Options`,
    paragraph4:`This dress can be easily dressed up or down depending on the occasion. Pair it with sandals and a sun hat for a casual day out, or with heels and statement jewelry for a more formal event. Its versatility makes it a valuable piece in any wardrobe, suitable for a variety of settings.`,
    keyword:`Micas Linen Halter Neck Ruched Mini Dress, linen dress, halter neck dress, ruched mini dress, summer fashion, trendy mini dress, stylish linen dress, Micas fashion, mini dress for women, summer outfit, chic dress, comfortable dress, casual wear, party dress, Micas clothing, online boutique`,
    description:`Crafted from breathable linen, this dress features a halter neck design and ruched detailing for a flattering fit. The mini length adds a playful touch, making it ideal for summer outings or casual gatherings. Its effortless elegance and comfort make it a versatile addition to any wardrobe.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img23/CEPC/OTC/changes/OTC_Lifestyle_1500-x-300.gif`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_Header_2a1_w?node=99966179031&ref_=Smartwatches_Page&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-7&pf_rd_r=EGY3GYZ2P4TM3C9S82X6&pf_rd_t=101&pf_rd_p=7f10b10c-7fe0-4773-8b03-f3ec8b4126cf&pf_rd_i=11599648031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


 
  {
      name:`trim-slit-maxi-dress`,
      title:`Micas Floral Print Lace Trim Slit Maxi Dress`,
      rate:`$ 38.00`,
      content: [  
        `The Floral Print Lace Trim Slit Maxi Dress by Micas combines romantic floral patterns with elegant lace detailing for a sophisticated trends look. This dress features a daring side slit that adds a touch of allure, perfect for both casual and semi-formal occasions. The combination of floral print and lace trim creates a timeless, feminine appeal, making it a versatile and stylish addition to any wardrobe.`],
      tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-purple-700`,
      wear:`Womens Dress`,
      Scolor:"text-orange-500 text-lg font-semibold",
      buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpxcz6rFOtPP-fmbwNMmU9rI7w-hV2unUM2w&s`,
      blink:`https://www.jdoqocy.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwda2302130092%3Fcjdata%3DMXxOfDB8WXww%26cjevent%3D458b5491352c11ef82ef37170a18ba73%26utm_source%3Daffiliate%26utm_medium%3Dcj%26utm_content%3D7034919`,
      buy1:`/images/nosite.jpg`,
      blink1:``,
      buy2:`/images/nosite.jpg`,
      blink2:`` ,
      image:`https://shopmicas.com/cdn/shop/files/80a2e7de-d366-41cf-b301-d6d270b243ad_1800x1800.jpg?v=1717095193`,
      thumbnail:`https://shopmicas.com/cdn/shop/files/8d972c84-fbc7-4eae-8610-a166e1cb2f36_1800x1800.jpg?v=1717095218`,
      image1:`https://shopmicas.com/cdn/shop/files/c33480d3-dce7-4027-82be-a9e1546ebdf2_1800x1800.jpg?v=1717095188`,
      icon:`/images/instagram.png`,
      icon1:`/images/pinterest.png`,
      icon2:`/images/whatsapp.png`,
      instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
      pinterest:`https://in.pinterest.com/treind_z/`,
      whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
      heading:`Romantic Floral Print`,
      paragraph:`The dress features a captivating floral print that evokes a sense of romance and charm. The delicate patterns and vibrant colors bring a touch of nature’s beauty to your outfit, making it perfect for spring and summer events. This print adds a playful yet elegant vibe, ensuring you stand out with a sophisticated flair.`,
      heading1:`Elegant Lace Trim`,
      paragraph1:`The lace trim detailing along the neckline and hem adds an extra layer of sophistication and femininity to the dress. This delicate feature enhances the overall design, giving it a timeless and refined look. The lace detailing provides a beautiful contrast to the floral print, making the dress visually appealing and unique.`,
      heading2:`Daring Side Slit`,
      paragraph2:`The side slit adds a touch of allure and modernity to the classic maxi dress silhouette. This feature not only enhances the dress's aesthetic appeal but also provides ease of movement. The slit makes the dress perfect for both casual and semi-formal occasions, allowing you to showcase your style with confidence.`,
      heading3:`Comfortable Fit`,
      paragraph3:`Made from high-quality, breathable fabric, the dress ensures maximum comfort. The lightweight material is perfect for warm weather, keeping you cool and comfortable throughout the day. The soft texture of the fabric feels great against the skin, making it ideal for extended wear.`,
      heading4:`Flattering Silhouette`,
      paragraph4:`The combination of the slip design and the lace trim creates a flattering silhouette that complements various body types. The dress cinches slightly at the waist, accentuating your natural curves without being too tight. The flowing skirt elongates the figure, providing a graceful and elegant appearance.`,
      keyword:`Micas Floral Print Lace Trim Slit Maxi Dress, floral maxi dress, lace trim dress, slit maxi dress, Micas dress, women's fashion, summer dress, elegant dress, trendy maxi dress, stylish floral dress, online shopping, Micas clothing, high-quality dress, fashionable maxi dress, Micas fashion`,
      description:`This dress features a daring side slit that adds a touch of allure, perfect for both casual and semi-formal occasions. The combination of floral print and lace trim creates a timeless, feminine appeal, making it a versatile and stylish addition to any wardrobe.`,
      summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img24/thomsoja/Grocery/BadaPack/PC_Header_1500x300_01.jpg`,
      jlink:`https://www.amazon.in/l/100025133031/ref=s9_bw_cg_BevFest_1b1_w?pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-3&pf_rd_r=ZDDAKTZHJ4JWRGHT4CKY&pf_rd_t=101&pf_rd_p=2a7d3c36-eddf-4fc8-8af6-fb3cb9a5a0f9&pf_rd_i=2454178031&tag=inrdeal123-21`,
      bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
      
    },
    
   
   

  {
    name:`floral-dresses`,
    title:`Micas Floral Print Slip Maxi Dress`,
    rate:`$ 30.00`,
    content: [  
      `The Floral Print Slip Maxi Dress by Micas epitomizes feminine charm and effortless elegance. Featuring a delicate floral print, this dress is perfect for both casual and semi-formal occasions. Its slip design and flowing maxi length offer a flattering silhouette and unparalleled comfort. Ideal for warm-weather outings, this dress pairs beautifully with sandals or heels, making it a versatile trends addition to any wardrobe.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Women's Dresses`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpxcz6rFOtPP-fmbwNMmU9rI7w-hV2unUM2w&s`,
    blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwda2404030096%3Fcjdata%3DMXxOfDB8WXww%26cjevent%3D52996a76352411ef82f606340a18b8f7%26utm_source%3Daffiliate%26utm_medium%3Dcj%26utm_content%3D7034919`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://shopmicas.com/cdn/shop/files/065a9583-ecc3-4f07-8be7-eb0366877a20_1800x1800.jpg?v=1717074803`,
    image:`https://shopmicas.com/cdn/shop/files/25712a5a-bff8-4e1f-b656-0b59425b3e8a_1800x1800.jpg?v=1717074803`,
    image1:`https://shopmicas.com/cdn/shop/files/2aa1682f-d755-466a-b504-e898698abfbf_1800x1800.jpg?v=1717074803`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Charming Floral Print`,
    paragraph:`The Floral Print Slip Maxi Dress boasts an enchanting floral print that exudes femininity and grace. The vibrant and intricate floral patterns bring a touch of nature’s beauty to your wardrobe, making it perfect for spring and summer occasions. This print adds a playful yet sophisticated element to the dress, ensuring you make a stylish statement wherever you go.`,
    heading1:`Effortless Elegance`,
    paragraph1:`Designed for both comfort and style, this maxi dress offers effortless elegance. The slip design ensures a relaxed fit, allowing you to move freely and comfortably. Its flowing silhouette drapes beautifully on the body, providing a look that is both chic and laid-back. This dress is ideal for those who appreciate fashion that feels as good as it looks.`,
    heading2:`Versatile Styling Options`,
    paragraph2:`The simplicity of the slip design makes this dress incredibly versatile. It can be dressed up with heels and statement jewelry for a semi-formal event or paired with sandals and a sun hat for a casual day out. Its adaptability makes it a valuable addition to any wardrobe, suitable for a wide range of occasions and settings.`,
    heading3:`Comfortable Fabric`,
    paragraph3:`Crafted from high-quality, lightweight fabric, the Floral Print Slip Maxi Dress ensures maximum comfort. The breathable material is perfect for warm weather, keeping you cool and comfortable throughout the day. The fabric’s soft texture feels great against the skin, making this dress a joy to wear for extended periods.`,
    heading4:`Flattering Fit`,
    paragraph4:`The slip design and maxi length create a flattering silhouette that complements various body types. The dress cinches slightly at the waist, accentuating your natural curves without being too tight. The long, flowing skirt elongates the figure, providing a graceful and elegant appearance.`,
    keyword:`Micas Floral Print Slip Maxi Dress, floral maxi dress, slip maxi dress, Micas dresses, summer dresses, floral print dress, women's fashion, stylish maxi dress, trendy dresses, boho dress, Micas clothing, elegant dresses, casual maxi dress, fashion dresses, online shopping`,
    description:`Featuring a delicate floral print, this dress is perfect for both casual and semi-formal occasions. Its slip design and flowing maxi length offer a flattering silhouette and unparalleled comfort. Ideal for warm-weather outings, this dress pairs beautifully with sandals or heels, making it a versatile trends addition to any wardrobe.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

    
  {
    name:`sleeveless-midi-dress`,
    title:`Micas Ruched Sleeveless Asymmetric Hem Midi Dress`,
    rate:`$ 32.00`,
    content: [  
      `The Ruched Sleeveless Asymmetric Hem Midi Dress by Micas is the epitome of elegance and modern trends fashion. With its ruched detailing and asymmetric hem, this dress offers a unique and flattering silhouette. Perfect for various occasions, it combines comfort with style, making it a versatile addition to any wardrobe. The sleeveless design adds a touch of sophistication, ideal for both casual and formal events.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-400`,
    wear:`Women's Dress`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpxcz6rFOtPP-fmbwNMmU9rI7w-hV2unUM2w&s`,
    blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdb2402210077%3Fcjdata%3DMXxOfDB8WXww%26cjevent%3De4debf8b352211ef809ad2560a18ba74%26utm_source%3Daffiliate%26utm_medium%3Dcj%26utm_content%3D7034919`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://shopmicas.com/cdn/shop/files/216a4531-e213-47f2-b055-85af402b2f4b_1800x1800.jpg?v=1718189807`,
    image:`https://shopmicas.com/cdn/shop/files/0b4e0b2d-660f-4e58-ae52-b80ea5a49793_1800x1800.jpg?v=1718189826`,
    image1:`https://shopmicas.com/cdn/shop/files/e386a0ce-e99c-4aca-bd08-cd20bafe02c2_1800x1800.jpg?v=1718189830`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The Ruched Sleeveless Asymmetric Hem Midi Dress boasts an elegant design that is perfect for any occasion. The ruched detailing enhances the dress’s texture, adding depth and sophistication to the overall look. This design choice not only flatters the figure but also brings a touch of modern fashion to a classic midi dress style.`,
    heading1:`Asymmetric Hem`,
    paragraph1:`The standout feature of this dress is its asymmetric hem, which creates a dynamic and eye-catching silhouette. The hemline adds a playful twist to the traditional midi dress, making it perfect for those who love to experiment with fashion. This unique feature ensures you stand out in any crowd, offering a chic and stylish appearance.`,
    heading2:`Comfortable Fit`,
    paragraph2:`Made from high-quality, breathable fabric, the dress ensures a comfortable fit for all-day wear. The material is soft against the skin, providing ease of movement while maintaining its shape. This makes the dress ideal for long events or casual outings where comfort is just as important as style.`,
    heading3:`Sleeveless Design`,
    paragraph3:`The sleeveless design adds a touch of sophistication and is perfect for warm weather. It allows for easy layering with jackets or shawls, making it versatile for different seasons and occasions. The clean, sleeveless cut also highlights the shoulders, adding to the dress's overall elegant appeal.`,
    heading4:`Flattering Silhouette`,
    paragraph4:`The ruched detailing along the sides of the dress accentuates the waist, creating a flattering silhouette for all body types. This feature not only enhances your natural curves but also adds a tailored look, ensuring the dress fits perfectly. The ruched design provides a slimming effect, making it a favorite for many.`,
    keyword:`Micas, Ruched Sleeveless Asymmetric Hem Midi Dress, Micas fashion, trendy midi dress, asymmetric hem dress, sleeveless dress, stylish ruched dress, women's fashion, online boutique, high-quality dresses, fashion-forward apparel, Micas shopping, shop Micas, chic midi dress, fashion midi dress`,
    description:`With its ruched detailing and asymmetric hem, this dress offers a unique and flattering silhouette. Perfect for various occasions, it combines comfort with style, making it a versatile addition to any wardrobe. The sleeveless design adds a touch of sophistication, ideal for both casual and formal events.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/IMG15/Minievent/Monsoon_Day_1242X450.jpg`,
    jlink:`https://www.amazon.in/l/95163372031?tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  {
    name:`MagSafe-compatible-case-cover-for-iphone-15`,
    title:`Daily Objects Bandit Stride 2.0 MagSafe Case Cover For iPhone 15`,
    rate:`₹899`,
    content: [  
      `The Bandit Stride 2.0 MagSafe Case Cover for iPhone 15 from Daily Objects is designed to provide robust protection with style. Crafted for functionality, it integrates seamlessly with MagSafe accessories, ensuring convenience and durability. This case boasts a slim profile, precise cutouts, and a non-slip grip, making it an ideal choice for those who value both aesthetics and practicality.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-sky-700`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://assets.myhubble.money/brand-assets/logo-images-wp/daliy-objects-logo.webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-bandit-stride-2-0-case-cover-for-iphone-15%2Fdp%3Ff%3Dpid~STRD-2-0-BANDI-AP-IPH15%26s%3Dreferer~lp`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bandit-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Bandit-Stride-2-0-Case-Cover-For-iPhone-15-vw.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bandit-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Bandit-Stride-2-0-Case-Cover-For-iPhone-15-6t.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bandit-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Bandit-Stride-2-0-Case-Cover-For-iPhone-15-2n.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Design & Aesthetics`,
    paragraph:`The Bandit Stride 2.0 MagSafe Case Cover features a sleek, modern design that complements the iPhone 15's aesthetics. Its minimalist appearance, combined with a matte finish, offers a sophisticated look. Available in various colors, this case is perfect for anyone looking to personalize their device without compromising on style. The design is not only visually appealing but also practical, ensuring it fits comfortably in your hand.`,
    heading1:`Non-Slip Grip`,
    paragraph1:`The Bandit Stride 2.0 is designed with a textured surface that provides a non-slip grip, reducing the risk of accidental drops. This feature is particularly useful for users who frequently handle their phones on the go. The non-slip grip enhances the overall usability of the device, making it more secure and comfortable to hold.`,
    heading2:`Wireless Charging Support`,
    paragraph2:`The case supports wireless charging, allowing users to charge their iPhone 15 without removing the cover. This convenience is further enhanced by the MagSafe compatibility, which ensures optimal alignment and efficiency. Wireless charging support makes the case highly practical for modern users who value convenience and ease of use.`,
    heading3:`Value for Money`,
    paragraph3:`The Bandit Stride 2.0 offers excellent value for money, combining premium features with an affordable price point. It provides comprehensive protection, aesthetic appeal, and functional benefits, making it a worthwhile investment for iPhone 15 users. The case’s durability and additional features like MagSafe compatibility and wireless charging support further enhance its value proposition.`,
    heading4:`Environmental Considerations`,
    paragraph4:`Daily Objects is committed to sustainability, and the Bandit Stride 2.0 is no exception. The case is crafted using eco-friendly materials and processes, minimizing its environmental impact. This makes it an ideal choice for eco-conscious consumers who want to protect their devices while also being mindful of the planet.`,
    keyword:`Daily Objects, Bandit Stride 2.0, MagSafe Case, iPhone 15 case, iPhone 15 cover, MagSafe compatible, protective case, stylish iPhone case, durable phone cover, iPhone accessories, premium phone case, Daily Objects iPhone case, Bandit Stride 2.0 case, MagSafe cover for iPhone 15`,
    description:`Crafted for functionality, it integrates seamlessly with MagSafe accessories, ensuring convenience and durability. This case boasts a slim profile, precise cutouts, and a non-slip grip, making it an ideal choice for those who value both aesthetics and practicality.`,
    summary:`https://m.media-amazon.com/images/G/31/img21/Luggage/2024/April/90days/PC_Trending_styles_for_him_-__Oversized_Tees._SX3000_QL85_.jpg`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_jljioji_2a1_w?node=96105534031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-1&pf_rd_r=GJBAKHKWZKPVSRW9HFGG&pf_rd_t=101&pf_rd_p=4360c6ba-053d-43cf-bc4b-78695b2ee5c2&pf_rd_i=95867169031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`phone-case-cover-for-iphone-14`,
    title:`Daily Objects Green Nimbus MagSafe Phone Case Cover For iPhone 14`,
    rate:`₹899`,
    content: [`The Green Nimbus MagSafe Phone Case Cover for iPhone 14 by Daily Objects is a sleek and stylish protective accessory designed to offer robust protection while maintaining a minimalist aesthetic. Its vibrant green color adds a touch of elegance to your device. The case is fully compatible with MagSafe accessories, ensuring seamless attachment and charging. With a slim profile and precise cutouts, it provides easy access to all ports and buttons. The shock-absorbing corners and raised edges around the screen and camera offer enhanced protection against drops and scratches.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-green-700`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://assets.myhubble.money/brand-assets/logo-images-wp/daliy-objects-logo.webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fgreen-nimbus-magsafe-phone-case-cover-for-iphone-14%2Fdp%3Ff%3Dpid~GRN-NIMBS-MAGSAF-PHN-AP-IPH14`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1101/green-nimbus-magsafe-phone-case-cover-for-iphone-14-images/Green-Nimbus-MagSafe-Phone-Case-Cover-For-iPhone-14.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1101/green-nimbus-magsafe-phone-case-cover-for-iphone-14-images/Green-Nimbus-MagSafe-Phone-Case-Cover-For-iPhone-14-1.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1101/green-nimbus-magsafe-phone-case-cover-for-iphone-14-images/Green-Nimbus-MagSafe-Phone-Case-Cover-For-iPhone-14-4.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Vibrant Green Color`,
    paragraph:`The Green Nimbus case stands out with its vibrant hue, adding a fresh and stylish look to your iPhone 14. The rich color complements various outfits and accessories, making it a fashionable choice.`,
    heading1:`Robust Protection`,
    paragraph1:`Designed to safeguard your iPhone 14, the Green Nimbus case features shock-absorbing corners that protect your device from drops, bumps, and other impacts. This ensures your phone stays in pristine condition.`,
    heading2:`Precise Cutouts`,
    paragraph2:`The case has precise cutouts for all ports, buttons, and the camera, ensuring you have full access to all functionalities of your iPhone 14. This design maintains the usability of your device without any interference.`,
    heading3:`Raised Edges`,
    paragraph3:` protect your screen and camera, the case features raised edges that prevent scratches and damage when placing your phone on flat surfaces. This added protection is crucial for maintaining the quality of your device.`,
    heading4:`Lightweight Design`,
    paragraph4:`The case is lightweight, ensuring it doesn’t add unnecessary weight to your iPhone 14. This makes it comfortable to use throughout the day without adding strain to your hand.`,
    keyword:`Daily Objects, Green Nimbus MagSafe Phone Case, iPhone 14 case, MagSafe case, iPhone 14 cover, protective phone case, stylish phone case, iPhone accessories, Daily Objects iPhone case, MagSafe compatible, durable phone cover, green phone case, Daily Objects cover, iPhone 14 protection, trendy phone case`,
    description:`Its vibrant green color adds a touch of elegance to your device. The case is fully compatible with MagSafe accessories, ensuring seamless attachment and charging. With a slim profile and precise cutouts, it provides easy access to all ports and buttons. The shock-absorbing corners and raised edges around the screen and camera offer enhanced protection against drops and scratches.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img21/Summer_store/PC-hero-banner.gif`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_jljioji_2a1_w?node=96105534031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-1&pf_rd_r=GJBAKHKWZKPVSRW9HFGG&pf_rd_t=101&pf_rd_p=4360c6ba-053d-43cf-bc4b-78695b2ee5c2&pf_rd_i=95867169031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`case-cover-for-iphone-15-Bao-Stride`,
    title:`Daily Objects Bao Stride 2.0 MagSafe Case Cover For iPhone 15`,
    rate:`₹899`,
    content: [  
      `The Bao Stride 2.0 MagSafe Case Cover for iPhone 15 by Daily Objects combines aesthetic appeal with top-notch protection. Its sleek design features a distinctive "Bao" pattern, blending style with functionality. Compatible with MagSafe accessories, this case allows for seamless attachment and charging. The robust construction, including shock-absorbing corners, ensures your device remains safeguarded from drops and impacts. Precision cutouts provide easy access to all ports and buttons, maintaining the device's usability while offering enhanced grip and raised edges for added screen and camera protection.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-purple-800`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://assets.myhubble.money/brand-assets/logo-images-wp/daliy-objects-logo.webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-bao-stride-2-0-case-cover-for-iphone-15%2Fdp%3Ff%3Dpid~STRD-2-0-BAO-AP-IPH15%26s%3Dreferer~lp`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bao-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Bao-Stride-2-0-Case-Cover-For-iPhone-15-vw.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bao-stride-2-0-case-cover-for-iphone-15-images/Bao-Stride-2-0-MagSafe-Case-Cover-For-iPhone-15-7.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bao-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Bao-Stride-2-0-Case-Cover-For-iPhone-15-3r.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Distinctive Design`,
    paragraph:`The Bao Stride 2.0 case stands out with its unique "Bao" pattern, adding a stylish flair to your iPhone 15. This eye-catching design not only enhances the phone's look but also reflects your personal taste.`,
    heading1:`Durable Protection`,
    paragraph1:`Crafted for robust protection, the Bao Stride 2.0 case features shock-absorbing corners that safeguard your iPhone 15 from drops, bumps, and everyday wear and tear, keeping it in pristine condition.`,
    heading2:`Enhanced Grip`,
    paragraph2:`Designed for an improved grip, the Bao Stride 2.0 case reduces the likelihood of accidental drops. The textured surface ensures a secure hold, giving you confidence while handling your device.`,
    heading3:`Eco-Friendly Packaging`,
    paragraph3:`Daily Objects prioritizes sustainability, and the Bao Stride 2.0 case comes in eco-friendly packaging. This reduces environmental impact and aligns with the values of eco-conscious consumers.`,
    heading4:`Easy Installation`,
    paragraph4:`Installing the Bao Stride 2.0 case is straightforward and hassle-free. The flexible material allows for quick application and removal, making it easy to switch cases or clean your phone.`,
    keyword:`Daily Objects, Bao Stride 2.0, MagSafe case cover, iPhone 15 case, MagSafe compatible, iPhone 15 accessories, protective case, stylish iPhone case, premium phone cover, Daily Objects iPhone case, MagSafe iPhone 15 cover, durable iPhone case, trendy phone case, iPhone 15 protection, high-quality MagSafe case`,
    description:`Its sleek design features a distinctive "Bao" pattern, blending style with functionality. Compatible with MagSafe accessories, this case allows for seamless attachment and charging. The robust construction, including shock-absorbing corners, ensures your device remains safeguarded from drops and impacts. Precision cutouts provide easy access to all ports and buttons, maintaining the device's usability while offering enhanced grip and raised edges for added screen and camera protection.`,
    summary:`https://m.media-amazon.com/images/G/31/img24/dell/launch/AW_m16r2_1500X300._CB560358871_.jpg`,
    jlink:`https://www.amazon.in/s?i=computers&bbn=976392031&rh=n:14142561031&ref=mega_elec_s23_6_4_1_1&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`hybrid-clear-case-cover-for-samsung-galaxy-s24-ultra`,
    title:`Daily Objects Paris Skyline Black Hybrid Clear Case Cover For Samsung Galaxy S24 Ultra`,
    rate:`₹499`,
    content: [  
      `The Paris Skyline Black Hybrid Clear Case Cover for Samsung Galaxy S24 Ultra from Daily Objects is a premium protective case featuring a captivating black silhouette of Paris. Crafted from durable hybrid materials, it combines a clear polycarbonate back with a flexible TPU bumper, providing robust protection against drops and scratches. The precise cutouts ensure easy access to all ports and buttons, while the slim design maintains the phone's sleek profile. The raised edges around the screen and camera offer additional protection, making it both stylish and functional.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-700`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://assets.myhubble.money/brand-assets/logo-images-wp/daliy-objects-logo.webp`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-paris-skyline-black-hybrid-clear-case-cover-for-samsung-galaxy-s24-ultra%2Fdp%3Ff%3Dpid~BLK-HYBRD-CLR-PARI-SKYL-SS-GLS24ULTRA%26s%3Dreferer~lp`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-paris-skyline-black-hybrid-clear-case-cover-for-samsung-galaxy-s24-ultra-images/DailyObjects-Paris-Skyline-Black-Hybrid-Clear-Case-Cover-For-Samsung-Galaxy-S24-Ultra.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-paris-skyline-black-hybrid-clear-case-cover-for-samsung-galaxy-s24-ultra-images/DailyObjects-Paris-Skyline-Black-Hybrid-Clear-Case-Cover-For-Samsung-Galaxy-S24-Ultra-3.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    image1:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-paris-skyline-black-hybrid-clear-case-cover-for-samsung-galaxy-s24-ultra-images/DailyObjects-Paris-Skyline-Black-Hybrid-Clear-Case-Cover-For-Samsung-Galaxy-S24-Ultra-2.png?tr=cm-pad_resize,v-2,w-768,h-648,dpr-1`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Materials`,
    paragraph:`Made from high-quality hybrid materials, the case features a clear polycarbonate back and a flexible TPU bumper. This combination ensures durability, flexibility, and a snug fit, offering excellent protection for your device.`,
    heading1:`Slim Profile`,
    paragraph1:`Despite its robust protective features, the case maintains a slim profile, ensuring that your phone remains easy to handle and carry. It does not add unnecessary bulk, preserving the sleek design of the Galaxy S24 Ultra.`,
    heading2:`Precise Cutouts`,
    paragraph2:`Designed with precision, the case offers accurate cutouts for all ports, buttons, and the camera. This ensures that you can access all functionalities of your phone without any hindrance`,
    heading3:`Clear Back`,
    paragraph3:`The transparent polycarbonate back allows the original color and design of your Samsung Galaxy S24 Ultra to show through. It provides a protective layer without hiding the phone's natural beauty.`,
    heading4:`Versatility`,
    paragraph4:`Suitable for both casual and professional settings, the Paris Skyline Black Hybrid Clear Case Cover is a versatile accessory. It complements various styles and occasions, making it a perfect choice for any Samsung Galaxy S24 Ultra user.`,
    keyword:`Daily Objects, Paris Skyline Case, Black Hybrid Clear Case, Samsung Galaxy S24 Ultra case, phone case, stylish phone cover, protective case, hybrid case, clear case, Samsung Galaxy accessories, Daily Objects phone case, trendy phone cover, durable phone case, high-quality phone case, fashion phone case`,
    description:`Crafted from durable hybrid materials, it combines a clear polycarbonate back with a flexible TPU bumper, providing robust protection against drops and scratches. The precise cutouts ensure easy access to all ports and buttons, while the slim design maintains the phone's sleek profile. The raised edges around the screen and camera offer additional protection, making it both stylish and functional.`,
    summary:`https://m.media-amazon.com/images/G/31/img15/zak/ggd/acc/Jun24/GGD_1500x300_June._CB554317032_.jpg`,
    jlink:`https://www.amazon.in/s?i=computers&bbn=976392031&rh=n:14142561031&ref=mega_elec_s23_6_4_1_1&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Wireless-Gaming-Earbuds`,
    title:`Boult Mustang Torq gaming earbuds`,
    rate:`₹1499`,
    content: [  
      `The Mustang Torq Wireless Gaming Earbuds offer an impressive 50 hours of playtime and are enhanced with BOULT Amp app connectivity for customized audio settings. Featuring Mode Sync LEDs, these earbuds provide a vibrant gaming experience. Equipped with Quad Mic ENC for clear communication and Bluetooth V5.4 for stable connectivity, they are designed to elevate your gaming sessions with exceptional sound quality and seamless functionality.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-700`,
    wear:`Gaming Earbuds`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMlNIz6j0hd9FZ4bE7YyXTo-5yLcgxqdXj0w&s`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boultaudio.com%2Fproducts%2Fmustang-torq%3Fvariant%3D41772203507781`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.boultaudio.com/cdn/shop/files/Maskgroup_30.png?v=1719073669&width=800`,
    image:`https://www.boultaudio.com/cdn/shop/files/Maskgroup_31.png?v=1719073669&width=800`,
    image1:`https://www.boultaudio.com/cdn/shop/files/Group_13020_1.png?v=1719223411&width=800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Extended Playtime`,
    paragraph:`Mustang Torq earbuds provide up to 50 hours of continuous playtime, ensuring you can enjoy extended gaming sessions without needing frequent recharges. This makes them ideal for long marathons or travel.`,
    heading1:`BOULT Amp App Connectivity`,
    paragraph1:`These earbuds are compatible with the BOULT Amp app, allowing users to customize their audio settings, manage device functions, and receive firmware updates for improved performance.`,
    heading2:`Mode Sync LEDs`,
    paragraph2:`The Mode Sync LEDs enhance your gaming experience by providing visual cues that sync with your game's audio, creating an immersive and interactive environment.`,
    heading3:`Quad Mic ENC`,
    paragraph3:`Equipped with Quad Mic Environmental Noise Cancellation (ENC), these earbuds ensure clear and crisp communication by filtering out background noise, making them perfect for team-based games and voice chats.`,
    heading4:`Bluetooth V5.4`,
    paragraph4:`Utilizing Bluetooth V5.4, the Mustang Torq earbuds offer a stable and fast connection, reducing latency and ensuring smooth audio streaming, which is crucial for an optimal gaming experience.`,
    keyword:`Gaming Earbuds`,
    description:`Featuring Mode Sync LEDs, these earbuds provide a vibrant gaming experience. Equipped with Quad Mic ENC for clear communication and Bluetooth V5.4 for stable connectivity, they are designed to elevate your gaming sessions with exceptional sound quality and seamless functionality.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img24/thomsoja/Grocery/BreakfastFest/June/Header_PC_1500X300.jpg`,
    jlink:`https://www.amazon.in/b/?_encoding=UTF8&node=15250262031&pd_rd_w=eszwh&content-id=amzn1.sym.b69585f0-68b2-4b1e-898f-290e7a6c4fda&pf_rd_p=b69585f0-68b2-4b1e-898f-290e7a6c4fda&pf_rd_r=BJTSQ8RXK22YEYBC8VXY&pd_rd_wg=W0aH0&pd_rd_r=71c5dcb2-7e31-43e1-ac7d-5fa12ccfa95d&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`high-g-golf-shoes`,
    title:`Nike Air Jordan I High G`,
    rate:`₹16995`,
    content: [  
      `The Air Jordan I High G merges iconic style with golf-ready functionality. Featuring a high-top design, it offers exceptional ankle support, while the spikeless outsole ensures versatile traction on and off the course. Made with premium leather, this shoe showcases the classic Air Jordan aesthetics, including the signature Swoosh and Wings logo. Ideal for golfers who want to blend performance with the timeless appeal of the Air Jordan brand.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-green-700`,
    wear:`Mens Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://static.vecteezy.com/system/resources/thumbnails/010/994/412/small/nike-logo-black-with-name-clothes-design-icon-abstract-football-illustration-with-white-background-free-vector.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-jordan-i-high-g-golf-shoes-qKzTBg%2FDQ0660-300`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/d35c686b-f9e6-49cb-9381-9bcc79388e72/air-jordan-i-high-g-golf-shoes-qKzTBg.png`,
    image:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/6b5c0b53-f717-45f8-8b2d-b1d5440852a4/air-jordan-i-high-g-golf-shoes-qKzTBg.png`,
    image1:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/03f878ff-a119-4b8b-a019-791ccdf1c1cb/air-jordan-i-high-g-golf-shoes-qKzTBg.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Iconic Design`,
    paragraph:`The Air Jordan I High G retains the classic look of the original Air Jordan I, a timeless design that has remained popular for decades. The high-top silhouette provides a retro aesthetic that appeals to sneaker enthusiasts and golfers alike.`,
    heading1:`Premium Materials`,
    paragraph1:`Crafted from high-quality leather, these golf shoes offer durability and a luxurious feel. The material ensures the shoes withstand the rigors of both the golf course and everyday wear while maintaining a polished look.`,
    heading2:`Ankle Support`,
    paragraph2:`The high-top design of the Air Jordan I High G offers superior ankle support, crucial for maintaining stability during your golf swing. This feature helps prevent injuries and enhances overall performance on the course.`,
    heading3:`Signature Branding`,
    paragraph3:`Featuring the iconic Nike Swoosh and Air Jordan Wings logo, these shoes celebrate the rich heritage of the Air Jordan brand. The branding adds a touch of authenticity and style, making them a statement piece in any wardrobe.`,
    heading4:`Enhanced Performance`,
    paragraph4:`The combination of ankle support, spikeless traction, and comfortable fit contributes to improved performance on the golf course. These features help golfers maintain stability, grip, and comfort, enhancing their overall game.`,
    keyword:`Nike Air Jordan I High G, Air Jordan I, Nike sneakers, high-top sneakers, Nike Air Jordan, Jordan I High, basketball shoes, Nike Jordan collection, Nike footwear, Air Jordan high top, stylish sneakers, Nike sportswear, trendy sneakers, athletic shoes, Nike Jordan G`,
    description:`Featuring a high-top design, it offers exceptional ankle support, while the spikeless outsole ensures versatile traction on and off the course. Made with premium leather, this shoe showcases the classic Air Jordan aesthetics, including the signature Swoosh and Wings logo. Ideal for golfers who want to blend performance with the timeless appeal of the Air Jordan brand.`,
    summary:`https://m.media-amazon.com/images/G/31/img21/Luggage/2024/April/90days/PC_Trending_styles_for_him_-__Half_Sleeve_Shirts._SX3000_QL85_.jpg`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_jljioji_2a1_w?node=96105534031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-1&pf_rd_r=GJBAKHKWZKPVSRW9HFGG&pf_rd_t=101&pf_rd_p=4360c6ba-053d-43cf-bc4b-78695b2ee5c2&pf_rd_i=95867169031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`air-max-1-slides`,
    title:`Nike Air Max 1`,
    rate:`₹5,997`,
    content: [  
      `Nike Air Max 1 Men's Slides bring the iconic Air Max comfort to a relaxed, slip-on style. These slides feature a plush foam sole and an Air Max unit for superior cushioning. With a textured footbed, they ensure grip and comfort for all-day wear. The upper strap offers a secure fit while sporting the classic Nike logo, blending heritage style with modern functionality.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-600`,
    wear:`Mens Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://static.vecteezy.com/system/resources/thumbnails/010/994/412/small/nike-logo-black-with-name-clothes-design-icon-abstract-football-illustration-with-white-background-free-vector.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-max-1-slides-TSMTS4`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/29e6596f-3d1d-4b35-8e4f-8f4740ea00a6/air-max-1-slides-TSMTS4.png`,
    image:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/0bc403fa-2ff4-4c00-a582-acaa5da499b0/air-max-1-slides-TSMTS4.png`,
    image1:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/59b95156-dcaf-4a23-933c-bf08fa81e7d6/air-max-1-slides-TSMTS4.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Iconic Air Max Cushioning`,
    paragraph:`Nike Air Max 1 Men's Slides incorporate the renowned Air Max unit in the sole, providing exceptional cushioning and comfort. This design ensures that every step you take is well-supported, reducing the impact on your feet and joints, making them perfect for casual wear.`,
    heading1:`Textured Footbed`,
    paragraph1:`Designed for practicality, the textured footbed ensures your foot stays in place, preventing slipping. This feature adds an extra layer of security and comfort, making the slides suitable for various activities, from lounging to quick errands.`,
    heading2:`Heritage Style`,
    paragraph2:`These slides combine modern comfort with the classic style of the original Air Max 1. The recognizable design elements make them a stylish choice for Nike fans, reflecting the brand’s rich heritage in every detail.`,
    heading3:`Lightweight Feel`,
    paragraph3:`Despite their robust construction, the Nike Air Max 1 Men's Slides are incredibly lightweight. This makes them easy to wear for extended periods without feeling weighed down, adding to their overall comfort.`,
    heading4:`Easy to Wear`,
    paragraph4:`The slip-on design of these slides makes them extremely convenient. They’re easy to put on and take off, making them a practical choice for those on the go, ensuring you can enjoy Nike’s signature comfort and style effortlessly.`,
    keyword:`Nike Air Max 1, Nike sneakers, Air Max shoes, Nike running shoes, Nike Air Max 1 sale, buy Nike Air Max 1, Air Max 1 sneakers, trendy Nike shoes, Nike footwear, Air Max 1 collection, Nike sports shoes, comfortable Nike sneakers, Nike Air Max 1 review, Nike shoe store, Air Max 1 online`,
    description:`These slides feature a plush foam sole and an Air Max unit for superior cushioning. With a textured footbed, they ensure grip and comfort for all-day wear. The upper strap offers a secure fit while sporting the classic Nike logo, blending heritage style with modern functionality.`,
    summary:`https://m.media-amazon.com/images/G/31/img21/Luggage/2024/April/90days/PC_Trending_styles_for_him_-__Half_Sleeve_Shirts._SX3000_QL85_.jpg`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_jljioji_2a1_w?node=96105534031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-1&pf_rd_r=GJBAKHKWZKPVSRW9HFGG&pf_rd_t=101&pf_rd_p=4360c6ba-053d-43cf-bc4b-78695b2ee5c2&pf_rd_i=95867169031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`luka-2-pf-basketball-shoes`,
    title:`Nike Luka 2 PF`,
    rate:`₹11,297`,
    content: [  
      `The Nike Luka 2 PF is a signature basketball shoe designed for performance and style. Featuring advanced cushioning, durable materials, and a sleek design, this shoe provides optimal support and comfort on the court. Its lightweight construction and traction pattern enhance agility and grip, making it ideal for quick, dynamic play. The Luka 2 PF blends cutting-edge technology with modern aesthetics, perfect for serious athletes.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Mens Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://static.vecteezy.com/system/resources/thumbnails/010/994/412/small/nike-logo-black-with-name-clothes-design-icon-abstract-football-illustration-with-white-background-free-vector.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fluka-2-pf-basketball-shoes-LM9ScX%2FDX9012-017`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/e1c4ca3c-8b78-4ada-b132-173349363373/luka-2-pf-basketball-shoes-LM9ScX.png`,
    image:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/e7129f2d-519c-4ecf-8d2d-f4336decdc07/luka-2-pf-basketball-shoes-LM9ScX.png`,
    image1:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/0d4fd241-aad4-49f5-999f-c675321e436b/luka-2-pf-basketball-shoes-LM9ScX.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Advanced Cushioning`,
    paragraph:`The Nike Luka 2 PF features state-of-the-art cushioning technology, ensuring maximum comfort and shock absorption during intense basketball games. The responsive midsole helps in reducing impact and enhancing energy return, making each step feel lighter and more powerful.`,
    heading1:`Durable Construction`,
    paragraph1:`Built to withstand the rigors of high-performance play, the Luka 2 PF utilizes premium materials that provide durability and longevity. The robust design ensures that the shoes maintain their structure and support even after prolonged use.`,
    heading2:`Optimal Support`,
    paragraph2:`Designed with athletes in mind, the Luka 2 PF offers exceptional support around the ankle and foot. The high-top silhouette and secure lacing system keep your foot stable, reducing the risk of injuries and improving overall performance.`,
    heading3:`Lightweight Design`,
    paragraph3:`Despite its sturdy build, the Luka 2 PF remains lightweight, allowing for quick movements and agility on the court. This balance of strength and lightness helps players maintain speed and responsiveness during games.`,
    heading4:`Enhanced Traction`,
    paragraph4:`The outsole of the Luka 2 PF is engineered for superior grip, featuring a multi-directional traction pattern that provides excellent stability and control. This ensures that you can make sharp cuts and quick direction changes with confidence.`,
    keyword:`Nike Luka 2 PF, Nike Luka 2, Luka 2 PF shoes, Nike basketball shoes, Luka Doncic sneakers, Nike Luka 2 performance, basketball footwear, Nike PF shoes, high-performance basketball shoes, Luka Doncic Nike, Nike sportswear, Nike PF, Nike Luka sneakers, Nike Luka PF`,
    description:`Featuring advanced cushioning, durable materials, and a sleek design, this shoe provides optimal support and comfort on the court. Its lightweight construction and traction pattern enhance agility and grip, making it ideal for quick, dynamic play. The Luka 2 PF blends cutting-edge technology with modern aesthetics, perfect for serious athletes.`,
    summary:`https://m.media-amazon.com/images/G/31/img21/summer/trends/PC_Trending_styles_for_her_-_Mini_Lengths._SX3000_QL85_.jpg`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_jljioji_2a1_w?node=96105534031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-1&pf_rd_r=GJBAKHKWZKPVSRW9HFGG&pf_rd_t=101&pf_rd_p=4360c6ba-053d-43cf-bc4b-78695b2ee5c2&pf_rd_i=95867169031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`new-year-older-shoes`,
    title:`Nike Nike Dunk Low 'Lunar New Year'`,
    rate:`₹7,117`,
    content: [  
      `The Nike Dunk Low 'Lunar New Year' celebrates the festive season with a vibrant design. It features a mix of premium materials, intricate patterns, and bright colors inspired by traditional Lunar New Year motifs. The sneaker boasts a cushioned sole for comfort, and its bold aesthetic makes it a standout piece, perfect for both collectors and everyday wear.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-700`,
    wear:`Mens Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://static.vecteezy.com/system/resources/thumbnails/010/994/412/small/nike-logo-black-with-name-clothes-design-icon-abstract-football-illustration-with-white-background-free-vector.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fdunk-low-lunar-new-year-older-shoes-z61lmC`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/5b61c1c5-2ea6-4edb-9382-d719ef98a5e1/dunk-low-lunar-new-year-older-shoes-z61lmC.png`,
    image:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/d6b90c3e-b8bb-4037-bf09-602007e9267b/dunk-low-lunar-new-year-older-shoes-z61lmC.png`,
    image1:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/5f8766e3-b96b-46a5-b69f-0b8e64550020/dunk-low-lunar-new-year-older-shoes-z61lmC.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Festive Design`,
    paragraph:`The Nike Dunk Low 'Lunar New Year' showcases a festive design inspired by traditional motifs, incorporating bright colors and intricate patterns that celebrate the Lunar New Year in style. The unique aesthetic makes it a standout piece in any sneaker collection.`,
    heading1:`Premium Materials`,
    paragraph1:`Crafted from high-quality materials, these sneakers offer durability and comfort. The use of leather, suede, and other premium fabrics ensures a luxurious feel and a sturdy build, perfect for long-lasting wear.`,
    heading2:`Intricate Patterns`,
    paragraph2:`The design features intricate patterns that pay homage to Lunar New Year traditions. These details add a layer of cultural significance and artistic flair, making the sneakers not just footwear but a piece of art.`,
    heading3:`Cushioned Sole`,
    paragraph3:`Equipped with a cushioned sole, the Nike Dunk Low 'Lunar New Year' provides excellent comfort and support. This feature is essential for both casual wear and active use, ensuring your feet stay comfortable throughout the day.`,
    heading4:`Vibrant Color Palette`,
    paragraph4:`The vibrant color palette of red, gold, and other festive hues embodies the spirit of the Lunar New Year. These colors are symbolic and add to the celebratory feel of the sneaker, making it perfect for the occasion.`,
    keyword:`Nike Dunk Low 'Lunar New Year', Nike sneakers, Lunar New Year edition, Nike Dunk Low, limited edition Nike, Nike Dunk Low release, Nike Dunk Low sale, exclusive Nike sneakers, Lunar New Year sneakers, trendy Nike shoes, stylish Nike footwear, buy Nike Dunk Low, Nike Dunk Low online, Nike fashion, Nike Dunk Low collection`,
    description:`features a mix of premium materials, intricate patterns, and bright colors inspired by traditional Lunar New Year motifs. The sneaker boasts a cushioned sole for comfort, and its bold aesthetic makes it a standout piece, perfect for both collectors and everyday wear.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img21/Summer_store/PC-hero-banner.gif`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_jljioji_2a1_w?node=96105534031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-1&pf_rd_r=GJBAKHKWZKPVSRW9HFGG&pf_rd_t=101&pf_rd_p=4360c6ba-053d-43cf-bc4b-78695b2ee5c2&pf_rd_i=95867169031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`low-pf-basketball-shoes`,
    title:`Nike Air Jordan XXXVIII Low PF`,
    rate:`₹17295`,
    content: [  
      `The Air Jordan XXXVIII Low PF is a premium basketball shoe designed for top-tier performance. Featuring advanced cushioning, responsive midsole, and durable traction, it ensures superior comfort and grip on the court. Its sleek, low-profile design, combined with iconic Jordan branding, makes it a standout choice for athletes and sneaker enthusiasts alike.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-700`,
    wear:`Mens Footwear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://static.vecteezy.com/system/resources/thumbnails/010/994/412/small/nike-logo-black-with-name-clothes-design-icon-abstract-football-illustration-with-white-background-free-vector.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-jordan-xxxviii-low-pf-basketball-shoes-2lBnKn`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/b6aa977d-3ad0-4f95-970f-2cae9a69dea5/air-jordan-xxxviii-low-pf-basketball-shoes-2lBnKn.png`,
    image:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/05d7d05a-af40-455c-9a32-576a7c94f375/air-jordan-xxxviii-low-pf-basketball-shoes-2lBnKn.png`,
    image1:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/e6d61f84-fac0-4a99-a0a6-215ad34c76a9/air-jordan-xxxviii-low-pf-basketball-shoes-2lBnKn.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Advanced Cushioning System`,
    paragraph:`The Air Jordan XXXVIII Low PF incorporates Nike’s cutting-edge cushioning technology, ensuring maximum shock absorption and energy return with every step. This advanced cushioning system enhances comfort and reduces the risk of injury, making it ideal for intense basketball games and training sessions.`,
    heading1:`Responsive Midsole`,
    paragraph1:`Equipped with a responsive midsole, these shoes offer excellent support and stability. The midsole adapts to the player's movements, providing a smooth and efficient transition from heel to toe, which is crucial for quick direction changes and explosive movements on the court.`,
    heading2:`Durable Traction`,
    paragraph2:`The outsole features a durable traction pattern designed to grip various surfaces securely. Whether playing on indoor courts or outdoor pavement, the Air Jordan XXXVIII Low PF ensures reliable traction, reducing the risk of slips and enhancing overall performance.`,
    heading3:`Low-Profile Design`,
    paragraph3:`The low-profile design of these sneakers offers a modern and sleek look while allowing greater ankle flexibility. This design choice not only contributes to the aesthetic appeal but also supports agile movements, essential for dynamic basketball play.`,
    heading4:`Iconic Jordan Branding`,
    paragraph4:`Featuring the iconic Jumpman logo, these shoes carry the legacy of Michael Jordan's excellence. The branding adds a touch of prestige and recognition, making them a coveted choice for both players and collectors.`,
    keyword:`Nike Air Jordan XXXVIII Low PF, Air Jordan 38 Low, Nike Air Jordan sneakers, Nike basketball shoes, Air Jordan low profile, Nike athletic footwear, Jordan low PF, Nike sports shoes, Air Jordan XXXVIII, Nike Air Jordan collection, Nike sneakers, basketball performance shoes, Air Jordan latest model, Nike Air Jordan comfort, Nike sneaker release`,
    description:`Featuring advanced cushioning, responsive midsole, and durable traction, it ensures superior comfort and grip on the court. Its sleek, low-profile design, combined with iconic Jordan branding, makes it a standout choice for athletes and sneaker enthusiasts alike.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img24/thomsoja/Grocery/BreakfastFest/June/Header_PC_1500X300.jpg`,
    jlink:`https://www.amazon.in/b/?_encoding=UTF8&node=15250262031&pd_rd_w=eszwh&content-id=amzn1.sym.b69585f0-68b2-4b1e-898f-290e7a6c4fda&pf_rd_p=b69585f0-68b2-4b1e-898f-290e7a6c4fda&pf_rd_r=BJTSQ8RXK22YEYBC8VXY&pd_rd_wg=W0aH0&pd_rd_r=71c5dcb2-7e31-43e1-ac7d-5fa12ccfa95d&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`comfort-mens-flip-flops`,
    title:`Puma Galaxy Comfort Men's Flip-Flops`,
    rate:`₹859`,
    content: [  
      `Puma Galaxy Comfort Men's Flip-Flops provide ultimate comfort with their cushioned footbed and lightweight design. Featuring a durable rubber sole for excellent grip, these flip-flops are perfect for everyday wear. The soft, synthetic straps ensure a secure and comfortable fit, while the stylish design makes them suitable for casual outings. Ideal for warm weather, they offer a blend of comfort and style.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-800`,
    wear:`Mens Footwear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2ayzjNPskTB6SwdiBuUiZRdZbCOgrR0DcQ&s`,
    blink:`https://www.tkqlhce.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fgalaxy-comfort-mens-flip-flops%2F374846%3Fcjdata%3DMXxOfDB8WXww%26%25243p%3Da_cj_affiliate%26%7Eclick_id%3D2c295d7e306811ef83c651be0a18ba73%26%7Esecondary_publisher%3DTrend%2527Z%26%7Eagency_id%3D1000067495857508873%26%2524canonical_url%3Dhttps%253A%252F%252Fin.puma.com%252Fin%252Fen%252Fpd%252Fgalaxy-comfort-mens-flip-flops%252F374846%26cjevent%3D2c295d7e306811ef83c651be0a18ba73%26click_id%3D2c295d7e306811ef83c651be0a18ba73%26campaigncode%3D7034919%26_branch_match_id%3D1332959854292598514%26_branch_referrer%3DH4sIAAAAAAAAA7VRyWrDMBD9GueWxLvkgihpWh8KIZdCQy9mPB4lSmRJeGmSv69sKPTWXiqGB7O9NzM6DYPrH9ZrN7awAudWWpnL%2BhHPDQwgdofbXj4%252F8ffD9boI4jRxAio8VyCl0goGWgTsBbXCS6UaEWNcZA2jJMx5FJHkCeZZVFMIEa%2BBJVNxT2hNA929cmOtVX%2BiTrx1ZJogZh9TARzJ4H2ii0L%252FcpYWGc9YFnI%2BMcSpBK1r8Ipjp8VpGj9INkFcelNmNe%2BBtp09DzSB8%2BzlETTc7kufk7Ybli2Zfim1ch6s54jLhKU8zScJBGONQtD%252FpYFn%2BiQz%252FH6xcWirlho1tmJTlrPb27FDEtvXarvfzRGE1oE6mh%2BH%252FPuffDejbUiwMEmLqPgCj6QouRUCAAA%253D%26utm_medium%3DAFF%26utm_source%3DOTH-CPO%26utm_aud%3DMULT%26utm_obj%3DOLC%26utm_campaign%3DAFF_OTH_CPO_MULT_OLC_AdmitAd_47e394ddabacd573116e22d6e3beaae3_401684_`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/374846/01/sv04/fnd/IND/fmt/png/Galaxy-Comfort-Men's-Flip-Flops`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/374846/01/sv01/fnd/IND/fmt/png/Galaxy-Comfort-Men's-Flip-Flops`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/374846/01/fnd/IND/fmt/png/Galaxy-Comfort-Men's-Flip-Flops`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Ultimate Comfort`,
    paragraph:`The Puma Galaxy Comfort Men's Flip-Flops are designed with a cushioned footbed, offering unparalleled comfort for all-day wear. The soft cushioning provides excellent support, reducing foot fatigue and making them perfect for extended use.`,
    heading1:`Durable Rubber Sole`,
    paragraph1:`These flip-flops feature a durable rubber sole that ensures a strong grip on various surfaces. The high-quality rubber material provides longevity, making these flip-flops a reliable choice for everyday wear.`,
    heading2:`Lightweight Design`,
    paragraph2:`The lightweight construction of the Puma Galaxy Comfort Flip-Flops makes them easy to wear and carry. This feature enhances comfort, as your feet won't feel weighed down even after long hours of use.`,
    heading3:`Soft Synthetic Straps`,
    paragraph3:`The flip-flops come with soft synthetic straps that ensure a secure and comfortable fit. The straps are designed to minimize chafing and irritation, providing a smooth and pleasant wearing experience.`,
    heading4:`Stylish Appearance`,
    paragraph4:`With a sleek and stylish design, these flip-flops are perfect for casual outings. The modern look of the Puma Galaxy Comfort Flip-Flops makes them a versatile addition to any wardrobe, suitable for both beach days and casual strolls.`,
    keyword:`Puma Galaxy Comfort Men's Flip-Flops, Puma flip-flops, comfortable men's flip-flops, Puma sandals, men's summer footwear, casual men's footwear, Puma Galaxy sandals, durable flip-flops, stylish men's flip-flops, Puma comfort footwear, men's beach sandals, Puma Galaxy comfort, men's flip-flops sale, trendy flip-flops for men, Puma men's sandals`,
    description:`Featuring a durable rubber sole for excellent grip, these flip-flops are perfect for everyday wear. The soft, synthetic straps ensure a secure and comfortable fit, while the stylish design makes them suitable for casual outings. Ideal for warm weather, they offer a blend of comfort and style.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img21/D140432537_prime-banner_PC_3_prime.jpg`,
    jlink:`https://www.amazon.in/l/84017632031/?_encoding=UTF8&pd_rd_w=CmS27&content-id=amzn1.sym.7db11f65-5e6b-43b8-be71-2c4cb9cdf867&pf_rd_p=7db11f65-5e6b-43b8-be71-2c4cb9cdf867&pf_rd_r=668SG32BD4TTAD022HHF&pd_rd_wg=4SpKJ&pd_rd_r=a0ce343e-fea6-4653-9fd0-b74dab8f642f&ref_=pd_hp_d_hero_unk&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`comfort-unisex-slides`,
    title:`Puma Royalcat Comfort Unisex Slides`,
    rate:`₹1679`,
    content: [  
      `Puma Royalcat Comfort Unisex Slides combine casual style with superior comfort. Featuring a cushioned footbed and durable rubber outsole, they offer excellent support and traction. The adjustable synthetic leather strap provides a customizable fit, while the iconic Puma logo adds a touch of sporty elegance. Perfect for lounging or casual outings, these slides are designed for all-day comfort and style.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blac`,
    wear:``,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2ayzjNPskTB6SwdiBuUiZRdZbCOgrR0DcQ&s`,
    blink:`https://www.jdoqocy.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Froyalcat-comfort-unisex-slides%2F397694%3Fcjdata%3DMXxOfDB8WXww%26%25243p%3Da_cj_affiliate%26%7Eclick_id%3D21c58fc2306811ef81fd0be20a18b8fc%26%7Esecondary_publisher%3DTrend%2527Z%26%7Eagency_id%3D1000067495857508873%26%2524canonical_url%3Dhttps%253A%252F%252Fin.puma.com%252Fin%252Fen%252Fpd%252Froyalcat-comfort-unisex-slides%252F397694%26cjevent%3D21c58fc2306811ef81fd0be20a18b8fc%26click_id%3D21c58fc2306811ef81fd0be20a18b8fc%26campaigncode%3D7034919%26_branch_match_id%3D1332959854292598514%26_branch_referrer%3DH4sIAAAAAAAAA7VRS2vDMAz%2BNemtaZ6NMzCj65bDoPQyWNklKLLcunUckzhr%2B%2B%252FnBAa7bZcJIZD0SZ8eJ%2Bfs8LBa2bGFEKwNtTKX1SOeBTjgu8NtL5%2Bf2Pvhel0ESZZaDjWea5BSaQWOFkHxglrhpVaCJzHmTGKSRmsWxyRZLEXUUBJBzBqfmMADYWcE9Pfajo1Ww4l6%252FtaTEUFSfEwAOJLB%2B9Qujrysi6zMWV7kEWNFOs0gQesGPOPYa36axg%252FSTZBUXpUJ5z2wa2fPG5qM9d2rvruDRnBLn5Vd75ajUQPdloNWggYPSMtiXWYTBYLpjELQ%252F8WBZ%252Fok436%252F2OjauiWhxpZvqmp2h27skfj2td7ud3MEobWgjubHIf%252F%2Bk%2B9i7ATxIkqzMi6%252FAN%252F9rD4VAgAA%26utm_medium%3DAFF%26utm_source%3DOTH-CPO%26utm_aud%3DMULT%26utm_obj%3DOLC%26utm_campaign%3DAFF_OTH_CPO_MULT_OLC_AdmitAd_6ab30fa485727b33413f54c646981a31_401684_`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397694/01/bv/fnd/IND/fmt/png/Royalcat-Comfort-Unisex-Slides`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397694/01/sv06/fnd/IND/fmt/png/Royalcat-Comfort-Unisex-Slides`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397694/01/sv01/fnd/IND/fmt/png/Royalcat-Comfort-Unisex-Slides`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Adjustable Strap`,
    paragraph:`The synthetic leather strap on these slides is adjustable, allowing you to customize the fit according to your preference. This feature ensures that the slides stay securely on your feet, providing added stability.`,
    heading1:`Sporty Elegance`,
    paragraph1:`The iconic Puma logo on the strap adds a touch of sporty elegance to the design. This branding not only signifies quality but also enhances the overall aesthetic appeal of the slides.`,
    heading2:`Versatile Use`,
    paragraph2:`These slides are perfect for a variety of occasions, from lounging at home to casual outings. Their versatile design makes them suitable for both indoor and outdoor use, adding convenience to your lifestyle.`,
    heading3:`All-Day Comfort`,
    paragraph3:`Designed with comfort in mind, the Puma Royalcat Comfort Slides are perfect for extended wear. The cushioned footbed and adjustable strap work together to provide a comfortable and secure fit throughout the day.`,
    heading4:`Easy to Clean`,
    paragraph4:`The synthetic leather material of the strap is easy to clean and maintain. This feature ensures that your slides remain in good condition, even with regular use.`,
    keyword:`Puma Royalcat Comfort Unisex Slides, Puma slides, Puma footwear, comfortable slides, unisex slides, Puma Royalcat, casual footwear, stylish slides, Puma comfort slides, Puma sandals, high-quality slides, Puma fashion, trendy slides, online shopping Puma, best slides 2024`,
    description:`Featuring a cushioned footbed and durable rubber outsole, they offer excellent support and traction. The adjustable synthetic leather strap provides a customizable fit, while the iconic Puma logo adds a touch of sporty elegance. Perfect for lounging or casual outings, these slides are designed for all-day comfort and style.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`runner-v2-mens-shoes`,
    title:`Puma Scorch Runner V2 Men's Shoes`,
    rate:`₹2,499`,
    content: [  
      `Puma Scorch Runner V2 Men's Shoes are designed for performance and style. Featuring a breathable mesh upper, cushioned midsole, and durable rubber outsole, they provide excellent comfort and traction. The sleek, sporty design with Puma branding adds a fashionable touch, making them ideal for running, training, or casual wear.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Mens Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2ayzjNPskTB6SwdiBuUiZRdZbCOgrR0DcQ&s`,
    blink:`https://www.dpbolvw.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fscorch-runner-v2-mens-shoes%2F379988%3Fcjdata%3DMXxOfDB8WXww%26%25243p%3Da_cj_affiliate%26%7Eclick_id%3D02a4f41c306811ef81fd0be20a18b8fc%26%7Esecondary_publisher%3DTrend%2527Z%26%7Eagency_id%3D1000067495857508873%26%2524canonical_url%3Dhttps%253A%252F%252Fin.puma.com%252Fin%252Fen%252Fpd%252Fscorch-runner-v2-mens-shoes%252F379988%26cjevent%3D02a4f41c306811ef81fd0be20a18b8fc%26click_id%3D02a4f41c306811ef81fd0be20a18b8fc%26campaigncode%3D7034919%26_branch_match_id%3D1332959854292598514%26_branch_referrer%3DH4sIAAAAAAAAA7VRS2vDMAz%2BNektbV5tnIEZXbccBqWXwcouQVHkxq3jGDtp13%2B%252FODDYbbtMCMGnx6dXOwzGPaxWZuxgCcYsldSX1SOeGxiA74%2BfB%252FH8xN6Pt9siSLLUcKjwXIEQUkkYaBHkL6gkXirZ8CiBTGQxptGGxTEJFosmqimJIGY1E%2BiTHWGvG7D3yoy1kq4ly98s6SZI8g%2BfACfSePd0cTTJJs%2BKNVvn64ixPPUzCFCqhqnjaBVv%252FfhBug2SclKpl%252FMe2Hczmgx5Yyb20mFvsQ3tqDXZ8JqEHWkXurYnN0XTvCgY8%252FwIutcSQf1LAzzTlfTw%2B63Goas6auTY8W1ZztD1o0Xiu9dqd9jPHoTOgDzpHyf8%2Bze%2Bi7FviOdRmhVx8QVQ7PghDwIAAA%253D%253D%26utm_medium%3DAFF%26utm_source%3DOTH-CPO%26utm_aud%3DMULT%26utm_obj%3DOLC%26utm_campaign%3DAFF_OTH_CPO_MULT_OLC_AdmitAd_5d16090d7392a302ad057f93f4690b5b_401684_`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/379988/01/fnd/IND/fmt/png/Scorch-Runner-V2-Men's-Shoes`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/379988/01/sv04/fnd/IND/fmt/png/Scorch-Runner-V2-Men's-Shoes`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/379988/01/sv03/fnd/IND/fmt/png/Scorch-Runner-V2-Men's-Shoes`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Breathable Mesh Upper`,
    paragraph:`The Puma Scorch Runner V2 features a breathable mesh upper that ensures optimal ventilation. This design keeps your feet cool and dry during intense workouts or long runs, enhancing overall comfort and performance.`,
    heading1:`Cushioned Midsole`,
    paragraph1:`The shoes are equipped with a cushioned midsole that provides excellent shock absorption. This feature reduces the impact on your feet and joints, allowing for a more comfortable and supportive running experience.`,
    heading2:`Durable Rubber Outsole`,
    paragraph2:`The durable rubber outsole offers superior traction and stability on various surfaces. This ensures you maintain a firm grip, whether you're running on a track, treadmill, or outdoor terrain.`,
    heading3:`Sleek Design`,
    paragraph3:`With a sleek, modern design, these shoes are not only functional but also stylish. The streamlined look makes them suitable for both athletic activities and casual wear, allowing you to transition seamlessly between different settings.`,
    heading4:`Puma Branding`,
    paragraph4:`The prominent Puma logo and branding elements add a touch of authenticity and style. This branding not only signifies quality but also makes a fashion statement, reflecting your sporty and trendy lifestyle.`,
    keyword:`Puma Scorch Runner V2, Puma men's shoes, Puma running shoes, athletic footwear, men's sneakers, comfortable running shoes, Puma sports shoes, Puma Scorch Runner, high-performance footwear, durable running shoes, trendy men's shoes, Puma V2 shoes, lightweight running shoes, best running shoes, men's Puma sneakers`,
    description:`Featuring a breathable mesh upper, cushioned midsole, and durable rubber outsole, they provide excellent comfort and traction. The sleek, sporty design with Puma branding adds a fashionable touch, making them ideal for running, training, or casual wear.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/CookwareDining/Aman/Feb/1-3000x1000PC.jpg`,
    jlink:`https://www.amazon.in/b/ref=ohlr_heroslider_3?pf_rd_r=CZA1D4KX7RCEBD3HZGT8&pf_rd_p=8cb73e5e-4478-4045-a4f9-57226ea9ff89&pf_rd_m=A1VBAL9TL5WCBF&pf_rd_s=merchandised-search-3&pf_rd_t=&pf_rd_i=976442031&_encoding=UTF8&node=5925789031&ref_=sv_top_hk_mega_3&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`unisex-slides`,
    title:`Puma Leadcat 2.0 Unisex Slides`,
    rate:`₹1869`,
    content: [  
      `Puma Leadcat 2.0 Unisex Slides feature a stylish, comfortable design perfect for casual wear. With a soft, cushioned footbed and a durable, textured sole for better grip, these slides provide all-day comfort. The sleek strap with Puma branding adds a fashionable touch, making them ideal for lounging, beach trips, or quick errands.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Mens Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2ayzjNPskTB6SwdiBuUiZRdZbCOgrR0DcQ&s`,
    blink:`https://www.dpbolvw.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fleadcat-2-0-unisex-slides%2F384139%3Fcjdata%3DMXxOfDB8WXww%26%25243p%3Da_cj_affiliate%26%7Eclick_id%3Ddd99a195306711ef809a00180a18b8f6%26%7Esecondary_publisher%3DTrend%2527Z%26%7Eagency_id%3D1000067495857508873%26%2524canonical_url%3Dhttps%253A%252F%252Fin.puma.com%252Fin%252Fen%252Fpd%252Fleadcat-2-0-unisex-slides%252F384139%26cjevent%3Ddd99a195306711ef809a00180a18b8f6%26click_id%3Ddd99a195306711ef809a00180a18b8f6%26campaigncode%3D7034919%26_branch_match_id%3D1332959854292598514%26_branch_referrer%3DH4sIAAAAAAAAA7VRyWrDMBD9GufmRPISSwVR0rQ%2BFEIuhYZezFgaJ0pkWVhyk%252Fx9bUOht%252FbSYRh4s7zZTiE4%252F7BauaGFJTi3NNpeVo%252FyrCCA2B1u%2B%2Bb5ib0frtdFlGSpE1DJcwVNo42GgIuoeJFGy0ullVCKc6A8T8m6oBQbRjgQQhkBymrWrKdkj7KzCvp75YbaaH%252FCXrz1aFWUFB9TAhzRyvtER8ko6yLjOcuLnDBWpNMMDRhTw9hx6I04TeNH6SZKylG1Xc57yK6d0WhwMm5kLw2CkhDiJCbxYLXHW%2ByNVujHWMoymvKJXYLtrJZg%252FoFenvETbfj9TkNoqxaVHlqxKcsZ%2Bm7oJYrta7Xd72aPhNaBPtof5%252Fv7J76LZadQFCTNOOVf7lw0GgsCAAA%253D%26utm_medium%3DAFF%26utm_source%3DOTH-CPO%26utm_aud%3DMULT%26utm_obj%3DOLC%26utm_campaign%3DAFF_OTH_CPO_MULT_OLC_AdmitAd_c28ccd37b8e33c0f99f90ecc05953c2c_401684_`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/384139/01/fnd/AUS/fmt/png/Leadcat-2.0-Unisex-Slides`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/384139/01/sv04/fnd/AUS/fmt/png/Leadcat-2.0-Unisex-Slides`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/384139/01/sv01/fnd/AUS/fmt/png/Leadcat-2.0-Unisex-Slides`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Comfortable Footbed`,
    paragraph:`The Puma Leadcat 2.0 slides feature a soft, cushioned footbed that provides excellent comfort. This makes them ideal for all-day wear, whether you’re at home or on the go.`,
    heading1:`Durable Construction`,
    paragraph1:`These slides are made with high-quality materials that ensure durability and long-lasting wear. The robust design can withstand regular use, making them a reliable choice for everyday activities.`,
    heading2:`Textured Sole`,
    paragraph2:`The sole of the Leadcat 2.0 slides is designed with a textured pattern that offers better grip and traction. This reduces the risk of slipping, making them suitable for various surfaces.`,
    heading3:`Stylish Design`,
    paragraph3:`With a sleek strap featuring the Puma logo, these slides are both functional and fashionable. They add a stylish touch to your casual outfits, making them perfect for lounging or running errands.`,
    heading4:`Unisex Appeal`,
    paragraph4:`The unisex design of the Puma Leadcat 2.0 slides makes them a versatile addition to any wardrobe. They can be worn by anyone, offering a universal style that suits both men and women.`,
    keyword:`Puma Leadcat 2.0 Unisex Slides, Puma slides, Puma sandals, Puma footwear, unisex slides, comfortable slides, stylish slides, casual footwear, Puma Leadcat, summer slides, beach slides, athletic slides, Puma fashion, trendy slides, high-quality slides`,
    description:`With a soft, cushioned footbed and a durable, textured sole for better grip, these slides provide all-day comfort. The sleek strap with Puma branding adds a fashionable touch, making them ideal for lounging, beach trips, or quick errands.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img21/D140432537_prime-banner_PC_3_prime.jpg`,
    jlink:`https://www.amazon.in/l/84017632031/?_encoding=UTF8&pd_rd_w=CmS27&content-id=amzn1.sym.7db11f65-5e6b-43b8-be71-2c4cb9cdf867&pf_rd_p=7db11f65-5e6b-43b8-be71-2c4cb9cdf867&pf_rd_r=668SG32BD4TTAD022HHF&pd_rd_wg=4SpKJ&pd_rd_r=a0ce343e-fea6-4653-9fd0-b74dab8f642f&ref_=pd_hp_d_hero_unk&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`smashic-unisex-sneakers`,
    title:`Puma Smashic Unisex Sneakers`,
    rate:`₹2019`,
    content: [  
      `The Smashic Unisex Sneakers by Puma blend style and comfort with a sleek, contemporary design. Featuring durable materials, cushioned soles, and a versatile look, these sneakers are perfect for daily wear and various activities. Their unisex appeal makes them a great addition to any wardrobe, providing both fashion and functionality in one package.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Mens Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2ayzjNPskTB6SwdiBuUiZRdZbCOgrR0DcQ&s`,
    blink:`https://www.dpbolvw.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fsmashic-unisex-sneakers%2F394371%3Fcjdata%3DMXxOfDB8WXww%26%25243p%3Da_cj_affiliate%26%7Eclick_id%3D6a352723306711ef81fd0be10a18b8fc%26%7Esecondary_publisher%3DTrend%2527Z%26%7Eagency_id%3D1000067495857508873%26%2524canonical_url%3Dhttps%253A%252F%252Fin.puma.com%252Fin%252Fen%252Fpd%252Fsmashic-unisex-sneakers%252F394371%26cjevent%3D6a352723306711ef81fd0be10a18b8fc%26click_id%3D6a352723306711ef81fd0be10a18b8fc%26campaigncode%3D7034919%26_branch_match_id%3D1332959854292598514%26_branch_referrer%3DH4sIAAAAAAAAA61RS2uDQBD%2BNeaWxHU1q4WlpGk9FEIuhYZeZBzHuMm6Lq42yb%2BvKxR6aw8dhoFvHt%2B8mmGw7mG9tmMLK7B2pZW5rB%252FxXMEAcn%2B8Hernp%252FT9eL0ugijmVkKB5wLqWmkFAy0C8YJa4aVQldwATyIRcR5uBGNUp6yuwpJYCCwt0xp9siPsTAX9vbBjqZVrqJdvPZkqiMSHT4ATGbx7OhZOshFxlqSJSMI0FdzPUIPWJUwdx17Lxo8f8G0Q5ZMqs5r3wK6d0WTIGzux564F1yhcjkY5ui2dIbhQ76YIz2IumOdGMJ1RCPrfyfFMn2SG3280Dm3RUqXGVm7zfIauG3skuXstdof97EFoLaiT%2BXG6v3%252Fhuxi7iqQIeZyx7At%2Bx82VBwIAAA%253D%253D%26utm_medium%3DAFF%26utm_source%3DOTH-CPO%26utm_aud%3DMULT%26utm_obj%3DOLC%26utm_campaign%3DAFF_OTH_CPO_MULT_OLC_AdmitAd_17707416db7095224ed12ac73d8f80fd_401684_`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/394371/01/mod01/fnd/IND/fmt/png/Smashic-Unisex-Sneakers`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/394371/01/sv01/fnd/IND/fmt/png/Smashic-Unisex-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/394371/01/sv02/fnd/IND/fmt/png/Smashic-Unisex-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Stylish Design`,
    paragraph:`The Smashic Unisex Sneakers by Puma showcase a modern, sleek design that suits any casual or sporty outfit. With clean lines and a minimalist aesthetic, they add a stylish touch to your everyday look.`,
    heading1:`Durable Construction`,
    paragraph1:`Made from high-quality materials, these sneakers are built to last. The sturdy upper and robust sole ensure they can withstand daily wear and tear, making them a reliable choice for various activities.`,
    heading2:`Comfortable Fit`,
    paragraph2:`Featuring cushioned insoles and breathable fabric, the Smashic sneakers offer superior comfort. The soft padding provides excellent support for your feet, allowing you to wear them all day without discomfort.`,
    heading3:`Versatile Usage`,
    paragraph3:`These sneakers are designed for versatility. Whether you're heading to the gym, running errands, or meeting friends, their adaptable style and functionality make them suitable for any occasion.`,
    heading4:`Unisex Appeal`,
    paragraph4:`The unisex design makes these sneakers a great option for everyone. They blend seamlessly with both men's and women's wardrobes, offering a universal style that complements any outfit.`,
    keyword:`Puma Smashic Unisex Sneakers, Puma sneakers, unisex sneakers, Puma Smashic, stylish sneakers, casual footwear, comfortable sneakers, Puma shoes, trendy sneakers, athletic sneakers, fashion sneakers, sports footwear, Puma Smashic shoes, best unisex sneakers, Puma Smashic`,
    description:`Featuring durable materials, cushioned soles, and a versatile look, these sneakers are perfect for daily wear and various activities. Their unisex appeal makes them a great addition to any wardrobe, providing both fashion and functionality in one package.`,
    summary:`https://m.media-amazon.com/images/G/31/img2020/fashion/WomensApparel2024/Workwear/Visibility/Workwear-PC-Heroes-W._CB553732635_.gif`,
    jlink:`https://www.amazon.in/b?ie=UTF8&node=100130484031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`women-footwear-heels-tiffania`,
    title:`ALDO Tiffania Women's Pink Dress Sandals`,
    rate:`₹11999`,
    content: [  
      `Aldo Tiffania Women's Pink Dress Sandals are elegant, high-heeled shoes designed for style and comfort. Featuring a delicate pink hue, these sandals include an open toe, sleek ankle strap, and a cushioned footbed. Perfect for special occasions and everyday chic, they provide a sophisticated look with their modern, yet timeless design.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-rose-500`,
    wear:`Women Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://logowik.com/content/uploads/images/aldo-group8341.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Ftiffania650011%2F58822389186.html`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwc359d637/New%20Folder/1_4800-x-4800pixpsd.jpg`,
    image:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dweeca7195/large/tiffania650011_1.jpg`,
    image1:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwd52fec7f/large/tiffania650011_5.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Pink Hue`,
    paragraph:`The Tiffania Women's Pink Dress Sandals from Aldo feature a beautiful, soft pink color that adds a touch of elegance to any outfit. This delicate hue is perfect for spring and summer events, providing a feminine and stylish look that stands out..`,
    heading1:`Open Toe Design`,
    paragraph1:`The open toe design of these sandals enhances their sophisticated appeal while ensuring breathability and comfort. This style is perfect for showcasing a fresh pedicure and adds a modern twist to traditional dress sandals.`,
    heading2:`Sleek Ankle Strap`,
    paragraph2:`The sleek ankle strap on the Tiffania sandals provides both style and support. It ensures a secure fit, allowing you to walk with confidence. The adjustable buckle adds a touch of shine and allows for a customized fit.`,
    heading3:`Cushioned Footbed`,
    paragraph3:`Comfort is key with the Tiffania sandals, featuring a cushioned footbed that offers support throughout the day or night. This padding ensures that you can enjoy long hours of wear without discomfort, making these sandals as practical as they are stylish.`,
    heading4:`High-Quality Materials`,
    paragraph4:`Crafted from high-quality materials, these sandals are designed to last. The durable construction ensures that they can withstand frequent use while maintaining their stylish appearance, making them a reliable addition to your footwear collection.`,
    keyword:`women pink dress sandal Tiffania sandals, stylish pink sandals elegant women's footwear high-heel pink sandals trendy dress shoes pink evening sandals fashionable women's sandals Tiffania dress sandals pink sandals for women, comfortable dress sandals, women's formal sandals, pink sandals with heels, designer pink sandals, Tiffania pink shoes, chic women's sandals, pink party sandals, pink wedding sandals, luxury women's footwear, best pink dress sandals`,
    description:`Featuring a delicate pink hue these sandals include an open toe sleek ankle strap and a cushioned footbed. Perfect for special occasions and everyday chic they provide a sophisticated look with their modern yet timeless design.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`women-footwear-heels-cassedyna`,
    title:`ALDO Cassedyna Women's Pink Pumps`,
    rate:`₹7999`,
    content: [  
      `Cassedyna Women's Pink Pumps by Aldo are chic and stylish high-heeled shoes designed for the modern woman. These pumps feature a soft pink color, a pointed toe, and a sleek silhouette, making them perfect for both professional and casual settings. Crafted with high-quality materials, they provide comfort and durability, ensuring a sophisticated addition to any wardrobe.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-pink-600`,
    wear:`Women Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://logowik.com/content/uploads/images/aldo-group8341.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fcassedyna670033%2F58822343416.html`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw6b413aa3/New%20Folder/CASSEDYNA670033.jpg`,
    image:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwd4056755/large/cassedyna670033_3.jpg`,
    image1:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwa9b521b7/large/cassedyna670033_5.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Chic Pink Color`,
    paragraph:`The Cassedyna Women's Pink Pumps stand out with their delicate and feminine pink hue. This soft color adds a touch of elegance and sophistication, making these pumps a versatile choice for various outfits and occasions.`,
    heading1:`Sleek Silhouette`,
    paragraph1:`These pumps boast a sleek and streamlined silhouette that exudes sophistication. The design is perfect for enhancing the natural shape of the foot, providing a flattering and stylish look suitable for any event or setting.`,
    heading2:`Comfortable Fit`,
    paragraph2:`Despite their high-heeled design, the Cassedyna pumps are crafted for comfort. The cushioned insole and supportive structure ensure that your feet remain comfortable, even during prolonged wear, making them ideal for long days or nights out.`,
    heading3:`High-Quality Materials`,
    paragraph3:`Made from premium materials, these pumps are built to last. The high-quality construction ensures durability, allowing you to enjoy their elegant appearance and reliable performance over time, regardless of frequent use.`,
    heading4:`Pointed Toe Design`,
    paragraph4:`Featuring a classic pointed toe, these pumps offer a timeless appeal. The pointed toe not only adds to the overall elegance but also elongates the appearance of the legs, providing a sleek and sophisticated look.`,
    keyword:`women's pink pumps, Cassedyna shoes, stylish heels, elegant pink pumps, women's footwear, high-heeled shoes, fashionable pumps, pink dress shoes, Cassedyna women's pumps, chic pink heels, designer pumps, comfortable women's heels, pink high heels, trendy women's pumps, Cassedyna fashion, quality women's footwear, pink pumps for women, versatile pink pumps, classic women's pumps, pink stilettos`,
    description:`These pumps feature a soft pink color, a pointed toe, and a sleek silhouette, making them perfect for both professional and casual settings. Crafted with high-quality materials, they provide comfort and durability, ensuring a sophisticated addition to any wardrobe.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/IMG15/Irshad/WM/Header_PC_washing-machine.jpg`,
    jlink:`https://www.amazon.in/l/81490020031?tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`women-footwear-heels-kat`,
    title:`ALDO Kat Women's Red Dress Sandals`,
    rate:`₹10199`,
    content: [  
      `Kat Women's Red Dress Sandals are striking and fashionable footwear perfect for various occasions. Designed by Aldo, these sandals feature a vibrant red hue, a comfortable heel, and a sleek design. Crafted from high-quality materials, they offer durability and comfort, making them an excellent choice for women seeking to add a touch of elegance and color to their outfit.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-500`,
    wear:`Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://logowik.com/content/uploads/images/aldo-group8341.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fkat640033%2FKAT640033.html`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwf055e511/large/kat640033_5.jpg`,
    image:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw1b8cda6f/New%20Folder/4_4800-x-4800pixpsd.jpg`,
    image1:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw96cd06bc/large/kat640033_3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Vibrant Red Hue`,
    paragraph:`The Kat Women's Red Dress Sandals stand out with their bold and vibrant red color. This striking shade adds a pop of color to any outfit, making these sandals a statement piece that draws attention and enhances your overall look.`,
    heading1:`Elegant Design`,
    paragraph1:`These sandals boast a sleek and sophisticated design, perfect for dressing up any ensemble. The elegant silhouette and attention to detail make them suitable for both casual and formal occasions, ensuring versatility in your wardrobe.`,
    heading2:`Comfortable Fit`,
    paragraph2:`Comfort is a key feature of the Kat sandals. They are designed with a cushioned insole and a stable heel, ensuring that you can wear them for extended periods without discomfort. This makes them ideal for long events or busy days on your feet.`,
    heading3:`High-Quality Materials`,
    paragraph3:`Made from premium materials, these sandals are built to last. The durable construction ensures they can withstand regular wear, providing a reliable and long-lasting footwear option that maintains its look and feel over time.`,
    heading4:`Perfect Heel Height`,
    paragraph4:`Featuring a perfectly balanced heel height, these sandals offer a bit of elevation without compromising comfort. This design element makes them ideal for women who want to add height while ensuring stability and ease of movement.`,
    keyword:`Kat Women's Red Dress Sandals, stylish red sandals, women's dress sandals, elegant red footwear, high heel sandals, formal red shoes, trendy red dress sandals, comfortable red sandals, women's fashion sandals, Kat brand sandals, red evening sandals, women's party sandals, chic red sandals, red dress shoes for women, fashionable red sandals, red sandals for special occasions, women's red heels, red strappy sandals, stylish red evening footwear, Kat red sandals.`,
    description:`Designed by Aldo, these sandals feature a vibrant red hue, a comfortable heel, and a sleek design. Crafted from high-quality materials, they offer durability and comfort, making them an excellent choice for women seeking to add a touch of elegance and color to their outfit.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img24hp/jbl/jbl/SMARTCASE_-_GIF.gif`,
    jlink:`https://www.amazon.in/l/99976637031/ref=QAHzEditorial_en_IN_5?pf_rd_r=AXHYJJ7W619BMGPQDYQE&pf_rd_p=50821721-b232-4f1b-bb0c-0f847c26144e&pf_rd_m=A1VBAL9TL5WCBF&pf_rd_s=merchandised-search-7&pf_rd_t=&pf_rd_i=1388921031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  


  


  {
    name:`women-footwear-heels-thelma`,
    title:`ALDO Thelma Women's Blue Dress Sandals`,
    rate:`₹7199`,
    content: [  
      `Thelma Women's Blue Dress Sandals by Aldo are stylish and elegant footwear designed for various occasions. Featuring a sleek design with a comfortable heel, these sandals are perfect for enhancing your look. Made from high-quality materials, they provide both durability and comfort, making them an ideal choice for women who seek both fashion and functionality.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-sky-500`,
    wear:`Footwears`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://logowik.com/content/uploads/images/aldo-group8341.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fthelma460043%2F60207426799.html`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw9a81071c/large/thelma460043_1.jpg`,
    image:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw6df38115/large/thelma460043_2.jpg`,
    image1:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw67727135/large/thelma460043_5.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The Thelma Women's Blue Dress Sandals by Aldo boast a sleek and sophisticated design, perfect for complementing a variety of outfits. Whether you’re dressing up for a formal event or adding a touch of elegance to your everyday wear, these sandals provide a stylish solution.`,
    heading1:`Comfortable Fit`,
    paragraph1:`Crafted with comfort in mind, these sandals feature a well-cushioned insole and a stable heel. This ensures that you can wear them for extended periods without discomfort, making them ideal for long events or busy days.`,
    heading2:`High-Quality Materials`,
    paragraph2:`Made from premium materials, the Thelma sandals are built to last. The durable construction ensures that they can withstand regular wear, providing you with a reliable footwear option that maintains its look and feel over time.`,
    heading3:`Versatile Use`,
    paragraph3:`These sandals are incredibly versatile, suitable for various occasions ranging from casual outings to formal gatherings. Their elegant blue color and timeless design make them a go-to choice for enhancing any outfit.`,
    heading4:`Perfect Heel Height`,
    paragraph4:`The Thelma sandals feature a perfect heel height that adds a bit of elevation without compromising comfort. This makes them ideal for women who want to add a little height while ensuring stability and ease of movement.`,
    keyword:`Thelma Women's Blue Dress Sandals, stylish blue sandals, women's dress sandals, elegant blue heels, Thelma footwear, fashionable women's sandals, blue evening sandals, high-heel dress sandals, Thelma blue shoes, trendy women's footwear, party sandals, comfortable blue sandals, formal blue heels, blue sandals for women, dressy blue sandals, Thelma women's fashion, blue dress heels, chic blue sandals, Thelma shoes collection, summer blue sandals.`,
    description:`Whether you’re dressing up for a formal event or adding a touch of elegance to your everyday wear, these sandals provide a stylish solution.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/img24hp/jbl/jbl/SMARTCASE_-_GIF.gif`,
    jlink:`https://www.amazon.in/l/99976637031/ref=QAHzEditorial_en_IN_5?pf_rd_r=AXHYJJ7W619BMGPQDYQE&pf_rd_p=50821721-b232-4f1b-bb0c-0f847c26144e&pf_rd_m=A1VBAL9TL5WCBF&pf_rd_s=merchandised-search-7&pf_rd_t=&pf_rd_i=1388921031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  
  {
    name:`Colors-de-Benetton-Man-Green`,
    title:`United Colors of Benetton Colors Man Green `,
    rate:`₹1300`,
    content: [  
      `United Colors of Benetton Colors Man Green is a refreshing and vibrant fragrance designed for the modern man. It features a dynamic blend of citrus and woody notes, creating an invigorating and energetic scent. Perfect for everyday wear, this fragrance embodies youthful spirit and vitality, making it an ideal choice for men who embrace life with enthusiasm.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-green-700`,
    wear:`Perfumes`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://images.moneycontrol.com/static-mcnews/2023/08/Untitled-design-12.jpg?impolicy=website&width=1600&height=900`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Funited-colors-of-benetton-colors-man-green-eau-de-toilette%2Fp-204231789%2FcolorChange%2F204231789_UNMAPPED%3FcurrentPosition%3D50%26searchQueryUrl%3D%26totalResultVal%3D2105%26searchPageType%3Dcategory%26utm_source%3Dinrdeals%26utm_medium%3Dreferral%26utm_campaign%3Doffer-remarketing%26utm_term%3Dremarketing`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://m.media-amazon.com/images/I/41gWAY3kLfL._SX300_SY300_QL70_FMwebp_.jpg`,
    image:`https://fimgs.net/mdimg/perfume/375x500.59498.jpg`,
    image1:`https://www.bigbasket.com/media/uploads/p/xxl/40151780-4_2-united-colors-of-benetton-colors-man-green-eau-de-toilette.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Invigorating Fragrance Blend`,
    paragraph:`Colors Man Green combines vibrant citrus top notes with a woody base, offering a fresh and lively scent. The fragrance opens with a burst of citrus, leading to a heart of aromatic spices and settling into a warm, woody finish.`,
    heading1:`Everyday Versatility`,
    paragraph1:`Designed for everyday use, Colors Man Green is perfect for both casual and professional settings. Its refreshing and dynamic scent adapts seamlessly from a day at the office to a night out with friends.`,
    heading2:`Youthful Energy`,
    paragraph2:`This fragrance embodies the youthful spirit and vitality that United Colors of Benetton is known for. It’s perfect for the man who approaches life with enthusiasm and a sense of adventure.`,
    heading3:`Modern Packaging`,
    paragraph3:`The bottle of Colors Man Green is designed with contemporary aesthetics in mind, featuring a sleek and vibrant design that reflects the energetic nature of the fragrance. It’s a stylish addition to any man’s grooming collection.`,
    heading4:`Long-Lasting Scent`,
    paragraph4:`Despite its light and refreshing nature, Colors Man Green offers impressive longevity. A few sprays in the morning can keep you feeling fresh and invigorated throughout the day.`,
    keyword:`Colors Man Green Eau de Toilette, men's fragrance, Benetton perfume, fresh scent, green notes, long-lasting cologne, masculine aroma, best men's fragrance, everyday wear, designer fragrance, men's cologne, aromatic scent, refreshing perfume, high-quality eau de toilette, popular men's perfume`,
    description:`Colors Man Green by Benetton`,
    summary:`/images/5-13june/watchbanner4.jpg`,
    jlink:`https://amzn.urlvia.com/O8CGoT`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`eau-de-parfum`,
    title:`THE MAN COMPANY Eau De Parfum Achiever `,
    rate:`₹1999`,
    content: [  
      `The Man Company Eau De Parfum Achiever (100ml) is a sophisticated fragrance designed for the modern man. It features a blend of woody and oriental notes, providing a long-lasting and impactful scent. Perfect for both professional and casual settings, this perfume embodies confidence and ambition, making it an ideal choice for daily wear.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Perfumes`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://cdn.dribbble.com/users/1697813/screenshots/3831427/the_man_company.png`,
    blink:`https://www.themancompany.com/products/eau-de-parfum-achiever-100ml`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.themancompany.com/cdn/shop/files/Achiever100mlPrimaryimages1_765x.jpg?v=1709119651`,
    image:`https://www.themancompany.com/cdn/shop/files/Achiever100mlPrimaryimages8_765x.jpg?v=1709119800`,
    image1:`https://www.themancompany.com/cdn/shop/files/Achiever100mlPrimaryimages7_765x.jpg?v=1709121658`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Fragrance Blend`,
    paragraph:`Achiever combines woody and oriental notes, creating a rich and sophisticated scent profile. The fragrance opens with refreshing top notes, deepens with a warm heart, and settles into a lasting base that exudes confidence and elegance.`,
    heading1:`Long-Lasting Scent`,
    paragraph1:`Crafted with high-quality ingredients, Achiever offers a long-lasting fragrance experience. Just a few sprays can keep you smelling fresh and appealing throughout the day, reducing the need for frequent reapplication.`,
    heading2:`Versatile Usage`,
    paragraph2:`Suitable for both professional and casual settings, Achiever is a versatile addition to any man's fragrance collection. Whether you're heading to a business meeting or a night out, this perfume enhances your presence and leaves a memorable impression.`,
    heading3:`Elegant Packaging`,
    paragraph3:`The 100ml bottle of Achiever comes in sleek, modern packaging that reflects its premium quality. The design is both practical and stylish, making it a great gift option for any occasion.`,
    heading4:`Confidence Booster`,
    paragraph4:`A good fragrance can significantly boost your confidence, and Achiever is no exception. Its sophisticated scent helps you feel more self-assured and ready to take on any challenge that comes your way.`,
    keyword:`luxury perfume, Eau De Parfum Achiever, premium fragrance, long-lasting scent, elegant perfume, high-end perfume, sophisticated fragrance, signature scent, top-rated perfume, best perfume for women, best perfume for men, premium Eau De Parfum, fragrance for achievers, luxury scent, exclusive perfume, high-quality perfume, best-selling fragrance, perfume for professionals, elegant Eau De Parfum, timeless scent`,
    description:`It features a blend of woody and oriental notes, providing a long-lasting and impactful scent. Perfect for both professional and casual settings, this perfume embodies confidence and ambition, making it an ideal choice for daily wear.S`,
    summary:`https://m.media-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/June24/tv_uber_pc_auto_MEGA_TVS._CB554944295_.jpg`,
    jlink:`https://www.amazon.in/gp/browse.html?node=1389396031&ref_=nav_em_sbc_tvelec_television_0_2_9_2&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  
  
  {
    name:`Z40-Pro-Wireless-Earbuds`,
    title:`BOULT Z40 Pro Wireless Earbuds `,
    rate:`₹1599`,
    content: [  
      `The Boult Z40 Pro are advanced wireless earbuds featuring noise cancellation, delivering exceptional sound quality with deep bass and clear highs. With Bluetooth 5.1, seamless connectivity, and intuitive touch controls, they offer long battery life and comfort for extended wear. These IPX7 water-resistant earbuds are perfect for an active lifestyle, ensuring durability and performance in all conditions.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-orange-600`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Boat_Logo.webp/1200px-Boat_Logo.webp.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boultaudio.com%2Fproducts%2Fz40-pro%3Fvariant%3D40640600703045`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.boultaudio.com/cdn/shop/files/Group12526.png?v=1710398696&width=800`,
    image:`https://www.boultaudio.com/cdn/shop/files/Group12529.png?v=1711022266&width=800`,
    image1:`https://www.boultaudio.com/cdn/shop/files/Group12523_1.png?v=1711022266&width=800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Advanced Noise Cancellation Technology`,
    paragraph:`The Boult Z40 Pro features advanced noise cancellation technology, effectively reducing ambient noise for a more immersive listening experience. This makes them ideal for use in noisy environments, ensuring you can enjoy your music or calls without interruption from external sounds.`,
    heading1:`Exceptional Sound Quality`,
    paragraph1:`Equipped with high-performance drivers, the Boult Z40 Pro delivers outstanding sound quality with deep bass and clear highs. The audio profile is finely tuned to offer a balanced and dynamic listening experience, catering to both casual listeners and audiophiles alike.`,
    heading2:`Stylish and Modern Design`,
    paragraph2:`The Boult Z40 Pro boasts a sleek and modern design that combines aesthetics with functionality. The stylish finish and ergonomic design make these earbuds a fashionable accessory that complements your personal style while providing comfort and ease of use.`,
    heading3:`Long Battery Life`,
    paragraph3:`These earbuds offer an impressive battery life, providing hours of uninterrupted playtime on a single charge. The charging case further extends the total battery life, making the Boult Z40 Pro a reliable companion for long trips, workouts, and daily commutes.`,
    heading4:`Seamless Bluetooth Connectivity`,
    paragraph4:`The Boult Z40 Pro features Bluetooth 5.1 technology, ensuring a stable and fast wireless connection. This allows for quick and easy pairing with your devices, offering a hassle-free listening experience with minimal latency and improved range.`,
    keyword:`quad mic ENC earbuds, noise-cancelling earbuds, premium sound quality, wireless earbuds, Bluetooth earbuds, ENC technology, clear calls, high-performance earbuds, long battery life, comfortable fit, advanced earbuds, audio clarity, ergonomic design, Quad Mic technology, immersive sound, ENC earbuds review, best ENC earbuds, affordable ENC earbuds, durable earbuds, superior sound experience`,
    description:`With Bluetooth 5.1, seamless connectivity, and intuitive touch controls, they offer long battery life and comfort for extended wear. These IPX7 water-resistant earbuds are perfect for an active lifestyle, ensuring durability and performance in all conditions.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/saba/summer24/Upgrade-better-ari-flow.jpg`,
    jlink:`https://www.amazon.in/b/ref=ohlr_heroslider_8?pf_rd_r=EVAG3YXVT2Q1X8KGC46X&pf_rd_p=e02233a8-5081-4bdd-bdd0-3269393aeca7&pf_rd_m=A1VBAL9TL5WCBF&pf_rd_s=merchandised-search-3&pf_rd_t=&pf_rd_i=976442031&node=21010930031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`nirvana-ion-anc-special-edition-earbuds`,
    title:`BOAT Nirvana ION ANC Limited Edition`,
    rate:`3499`,
    content: [  
      `The boAt Nirvana ION ANC Limited Edition headphones feature advanced Active Noise Cancellation (ANC) for an immersive audio experience. Equipped with powerful drivers, these headphones deliver exceptional sound quality with deep bass and clear highs. The stylish, limited edition design ensures comfort with plush ear cushions and an adjustable headband. Offering extended battery life, Bluetooth connectivity, and intuitive controls, these headphones are perfect for both music enthusiasts and professionals seeking premium sound and noise isolation.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-lime-500`,
    wear:`Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Boat_Logo.webp/1200px-Boat_Logo.webp.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fnirvana-ion-anc-special-edition-earbuds`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/Doodle_40b899c0-9a82-4efc-979a-32b84a6824af_800x.png?v=1713766323`,
    image:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard2copy_1c96ffbb-db6d-424f-8dfa-ecc9151013f6_800x.jpg?v=1713766323`,
    image1:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard1copy_a8a8ea34-e210-46d8-8e33-dca1eebff42a_800x.jpg?v=1713766323`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Advanced Active Noise Cancellation (ANC)`,
    paragraph:`The boAt Nirvana ION ANC Limited Edition headphones feature cutting-edge Active Noise Cancellation technology. This allows you to block out unwanted ambient noise, making them perfect for travel, work, or simply enjoying your favorite music in peace. The ANC technology ensures an immersive listening experience by eliminating distractions from your surroundings.`,
    heading1:`Exceptional Sound Quality`,
    paragraph1:`Equipped with powerful drivers, these headphones deliver rich, high-fidelity sound with deep bass and crystal-clear highs. The audio performance is designed to meet the needs of audiophiles and music enthusiasts, providing a balanced and dynamic sound signature that brings your music to life.`,
    heading2:`Stylish Limited Edition Design`,
    paragraph2:`The Nirvana ION ANC Limited Edition boasts a sleek and stylish design that stands out. The premium finish and attention to detail make these headphones not only a functional audio accessory but also a fashionable statement piece. The limited edition design ensures exclusivity and adds a touch of luxury to your listening experience.`,
    heading3:`Comfortable and Ergonomic`,
    paragraph3:`Designed for extended wear, these headphones feature plush ear cushions and an adjustable headband that provide a comfortable and secure fit. The ergonomic design minimizes ear fatigue, allowing you to enjoy your music or take calls for hours without discomfort.`,
    heading4:`Extended Battery Life`,
    paragraph4:`With a long-lasting battery, the Nirvana ION ANC Limited Edition headphones offer extended playtime, making them ideal for long journeys or all-day use. You can enjoy up to 30 hours of playback with ANC off and up to 20 hours with ANC on, ensuring you never run out of music on the go.`,
    keyword:`active noise-canceling earbuds, ION earbuds, wireless earbuds, premium sound, noise-canceling technology, ION audio, high-quality earbuds, Bluetooth earbuds, comfortable earbuds, long battery life, ION noise-canceling, best noise-canceling earbuds, clear audio, portable earbuds, noise isolation, ION active, advanced earbuds, superior sound quality, workout earbuds, travel earbuds, sleek design, ergonomic earbuds, ION wireless, top-rated earbuds, immersive audio experience`,
    description:`Equipped with powerful drivers, these headphones deliver exceptional sound quality with deep bass and clear highs. The stylish, limited edition design ensures comfort with plush ear cushions and an adjustable headband. Offering extended battery life, Bluetooth connectivity, and intuitive controls, these headphones are perfect for both music enthusiasts and professionals seeking premium sound and noise isolation.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/CookwareDining/Aman/June/PC-header-Shop_now.png`,
    jlink:`https://www.amazon.in/b/ref=s9_bw_cg_Baking_1a1_w?node=68893482031&pf_rd_m=AT95IG9ONZD7S&pf_rd_s=merchandised-search-3&pf_rd_r=A8AYC7YWNZ2267Z0WXXE&pf_rd_t=101&pf_rd_p=2cdb2c95-14bd-4ef8-a20e-222e1aba0a12&pf_rd_i=5925789031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`rider-preppy-unisex-sneakers`,
    title:`PUMA Blktop Rider Preppy Unisex Sneakers`,
    rate:`₹6749`,
    content: [  
      `The PUMA Blktop Rider Preppy Unisex Sneakers combine vintage aesthetics with modern comfort. Featuring a retro-inspired design, these sneakers boast a stylish mix of materials and textures. They offer a cushioned insole and padded collar for all-day comfort. The lace-up closure ensures a secure fit, while the durable rubber outsole provides excellent traction. Perfect for both casual and sporty looks, these sneakers make a versatile addition to any wardrobe.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-700`,
    wear:`Unisex Sneakers`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://cdn.icon-icons.com/icons2/2845/PNG/512/puma_logo_icon_181343.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fblktop-rider-preppy-unisex-sneakers%2F395875%3Fswatch%3D04%26referrer-category%3Dnew-season`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395875/04/fnd/IND/fmt/png/Blktop-Rider-Preppy-Unisex-Sneakers`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395875/04/bv/fnd/IND/fmt/png/Blktop-Rider-Preppy-Unisex-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395875/04/mod01/fnd/IND/fmt/png/Blktop-Rider-Preppy-Unisex-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Meets Contemporary Design`,
    paragraph:`The PUMA Blktop Rider Preppy Unisex Sneakers merge classic design elements with contemporary flair. The sleek upper and preppy detailing create a unique aesthetic that is both timeless and modern. This blend ensures the sneakers are versatile enough to complement various fashion styles.`,
    heading1:`Unisex Appeal`,
    paragraph1:`Designed to suit both men and women, these sneakers offer a unisex appeal that broadens their versatility. The inclusive design means they can be effortlessly styled by anyone, making them a practical choice for shared wardrobes or those who prefer gender-neutral fashion.`,
    heading2:`High-Quality Construction`,
    paragraph2:`Crafted with premium materials, the PUMA Blktop Rider Preppy Sneakers promise durability and long-lasting wear. The high-quality construction ensures they can withstand daily use while maintaining their stylish appearance, providing value for money.`,
    heading3:`Durable Outsole`,
    paragraph3:`The durable outsole is designed to provide excellent traction and stability. This feature ensures you can wear the sneakers on various surfaces with confidence, making them suitable for different environments and activities.`,
    heading4:`Secure Lace-Up Closure`,
    paragraph4:`The lace-up closure allows for a secure and adjustable fit. This feature ensures the sneakers stay comfortably in place, providing stability and support during movement. The adjustable fit also caters to different foot shapes and sizes.`,
    keyword:`rider preppy sneakers, unisex sneakers, stylish sneakers, trendy sneakers, comfortable sneakers, casual footwear, fashion sneakers, versatile sneakers, sporty sneakers, everyday sneakers, Rider brand shoes, sneaker trends, high-quality sneakers, affordable sneakers, popular sneakers, sneaker fashion, Rider Preppy, athletic sneakers, walking shoes, fashion-forward sneakers`,
    description:`Featuring a retro-inspired design, these sneakers boast a stylish mix of materials and textures. They offer a cushioned insole and padded collar for all-day comfort. The lace-up closure ensures a secure fit, while the durable rubber outsole provides excellent traction. Perfect for both casual and sporty looks, these sneakers make a versatile addition to any wardrobe.`,
    summary:`https://images-eu.ssl-images-amazon.com/images/G/31/IMG15/Irshad/WM/Header_PC_washing-machine.jpg`,
    jlink:`https://www.amazon.in/l/81490020031?tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  {
    name:`symmetry-unisex-running-shoes`,
    title:`PUMA SOFTRIDE Symmetry Unisex Running Shoes`,
    rate:`₹4119`,
    content: [  
      `The PUMA SOFTRIDE Symmetry Unisex Running Shoes combine innovative technology with sleek design, offering superior comfort and performance. Featuring SOFTRIDE foam for cushioning, these shoes ensure a smooth and responsive ride. The breathable mesh upper provides optimal airflow, while the durable rubber outsole delivers excellent traction. Suitable for both men and women, these running shoes are perfect for athletes and casual runners alike, blending style and functionality seamlessly.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Unisex Running Shoes`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://cdn.icon-icons.com/icons2/2845/PNG/512/puma_logo_icon_181343.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fsoftride-symmetry-unisex-running-shoes%2F379582%3Fswatch%3D01%26referrer-category%3Dnew-season`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/379582/01/dt01/fnd/IND/fmt/png/SOFTRIDE-Symmetry-Unisex-Running-Shoes`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/379582/01/sv04/fnd/IND/fmt/png/SOFTRIDE-Symmetry-Unisex-Running-Shoes`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/379582/01/sv03/fnd/IND/fmt/png/SOFTRIDE-Symmetry-Unisex-Running-Shoes`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Innovative SOFTRIDE Foam Technology`,
    paragraph:`The PUMA SOFTRIDE Symmetry Unisex Running Shoes are equipped with SOFTRIDE foam technology, which provides exceptional cushioning and support. This advanced foam material ensures a soft, comfortable ride, reducing impact on the joints and enhancing overall running performance.`,
    heading1:`Sleek and Modern Design`,
    paragraph1:`These running shoes boast a sleek and modern design that appeals to both men and women. The streamlined silhouette and stylish color options make them not only functional but also fashionable, perfect for runners who want to look good while staying active.`,
    heading2:`Breathable Mesh Upper`,
    paragraph2:`The shoes feature a breathable mesh upper that promotes optimal airflow, keeping your feet cool and dry during intense runs. The lightweight mesh material also adds to the overall comfort and flexibility of the shoes, making them ideal for long-distance running.`,
    heading3:`Durable Rubber Outsole`,
    paragraph3:`Designed for durability and traction, the PUMA SOFTRIDE Symmetry Running Shoes come with a robust rubber outsole. This outsole provides excellent grip on various surfaces, ensuring stability and preventing slips, making them suitable for both road and trail running.`,
    heading4:`Unisex Fit`,
    paragraph4:`The unisex design of these running shoes means they cater to both men and women. Available in a range of sizes, the PUMA SOFTRIDE Symmetry shoes provide a comfortable and secure fit for everyone, ensuring optimal performance regardless of gender.`,
    keyword:`softride symmetry running shoes, unisex running shoes, comfortable running shoes, lightweight running shoes, high-performance running shoes, breathable running shoes, cushioned running shoes, versatile running shoes, durable running shoes, stylish running shoes, athletic footwear, running shoes for men, running shoes for women, unisex athletic shoes, long-distance running shoes, shock-absorbing running shoes, supportive running shoes, best running shoes, softride technology, ergonomic running shoes`,
    description:`Featuring SOFTRIDE foam for cushioning, these shoes ensure a smooth and responsive ride. The breathable mesh upper provides optimal airflow, while the durable rubber outsole delivers excellent traction. Suitable for both men and women, these running shoes are perfect for athletes and casual runners alike, blending style and functionality seamlessly.`,
    summary:`https://m.media-amazon.com/images/G/31/img24/Fashion/AF/3.0/brandstograb/header/v1/pc._CB554878371_.gif`,
    jlink:`https://www.amazon.in/b?node=65994034031&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  
  {
    name:`Propella womens sneakers`,
    title:`PUMA Propella Women's Sneakers`,
    rate:`₹2269`,
    content: [  
      `The PUMA Propella Women's Sneakers blend modern design with superior comfort. These sneakers feature a sleek, sporty silhouette with a breathable mesh upper and cushioned midsole for all-day wear. The durable rubber outsole ensures traction and stability, making them perfect for everyday activities. Stylish and functional, the PUMA Propella sneakers are ideal for women seeking a versatile and fashionable footwear option.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-Gray-700`,
    wear:`Womens Sneakers`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://cdn.icon-icons.com/icons2/2845/PNG/512/puma_logo_icon_181343.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fpuma-propella-womens-sneakers%2F395844%3Fswatch%3D01%26referrer-category%3Dnew-season`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395844/01/fnd/IND/fmt/png/PUMA-Propella-Women's-Sneakers`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395844/01/bv/fnd/IND/fmt/png/PUMA-Propella-Women's-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395844/01/sv02/fnd/IND/fmt/png/PUMA-Propella-Women's-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Modern Design Aesthetic`,
    paragraph:`The PUMA Propella Women's Sneakers showcase a contemporary design that blends fashion and functionality seamlessly. With their sleek, sporty silhouette, these sneakers are designed to complement a wide range of casual and athletic outfits, making them a versatile addition to any wardrobe.`,
    heading1:`Breathable Mesh Upper`,
    paragraph1:`These sneakers feature a breathable mesh upper that ensures optimal airflow, keeping your feet cool and dry throughout the day. The mesh material not only enhances comfort but also provides a lightweight feel, making the sneakers ideal for prolonged wear and active lifestyles.`,
    heading2:`Cushioned Midsole`,
    paragraph2:`The PUMA Propella Women's Sneakers are equipped with a cushioned midsole that provides superior support and comfort with every step. This cushioning helps to absorb impact, reduce foot fatigue, and enhance overall walking or running experience, ensuring you stay comfortable during all activities.`,
    heading3:`Durable Rubber Outsole`,
    paragraph3:`The durable rubber outsole is designed for excellent traction and stability on various surfaces. Whether you're walking on pavement, grass, or in the gym, these sneakers provide the grip and support needed to move confidently and securely.`,
    heading4:`Versatile Styling`,
    paragraph4:`The sleek design of the PUMA Propella sneakers makes them versatile enough to pair with different outfits. Whether you're dressing for a casual day out, a workout session, or running errands, these sneakers add a stylish touch to your look while maintaining practical functionality.`,
    keyword:`Women's sneakers, Propella sneakers, Stylish women's shoes, Comfortable women's sneakers, Trendy women's footwear, Casual women's sneakers, Athletic shoes for women, Fashionable women's sneakers, High-quality women's sneakers, Versatile women's sneakers, Propella women's footwear, Lightweight women's sneakers, Women's running shoes, Durable women's sneakers, Women's lifestyle sneakers,`,
    description:`These sneakers feature a sleek, sporty silhouette with a breathable mesh upper and cushioned midsole for all-day wear. The durable rubber outsole ensures traction and stability, making them perfect for everyday activities. Stylish and functional, the PUMA Propella sneakers are ideal for women seeking a versatile and fashionable footwear option.`,
    summary:`https://m.media-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/June24/tv_uber_pc_auto_MEGA_TVS._CB554944295_.jpg`,
    jlink:`https://www.amazon.in/gp/browse.html?node=1389396031&ref_=nav_em_sbc_tvelec_television_0_2_9_2&tag=inrdeal123-21`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  



  {
    name:`rbd-game-low-x-harrdy-sandhu-mens-sneakers`,
    title:`PUMA RBD Game Low x HARRDY SANDHU Men's Sneakers`,
    rate:`₹4199`,
    content: [  
      `The PUMA RBD Game Low x HARRDY SANDHU Men's Sneakers represent a stylish collaboration between PUMA and renowned artist Harrdy Sandhu. These sneakers feature a sleek low-top design, ensuring both comfort and mobility. Crafted with premium materials, they offer durability and long-lasting wear. The unique aesthetic, inspired by Sandhu's signature style, makes these sneakers a standout addition to any wardrobe, perfect for casual and trendy outfits.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-700`,
    wear:`Men's Sneakers`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://cdn.icon-icons.com/icons2/2845/PNG/512/puma_logo_icon_181343.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Frbd-game-low-x-harrdy-sandhu-mens-sneakers%2F397167%3Fswatch%3D02%26referrer-category%3Dnew-season`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397167/02/fnd/IND/fmt/png/RBD-Game-Low-x-HARRDY-SANDHU-Men's-Sneakers`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397167/02/bv/fnd/IND/fmt/png/RBD-Game-Low-x-HARRDY-SANDHU-Men's-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397167/02/sv04/fnd/IND/fmt/png/RBD-Game-Low-x-HARRDY-SANDHU-Men's-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Exclusive Collaboration`,
    paragraph:`The PUMA RBD Game Low x HARRDY SANDHU Men's Sneakers are a product of a unique collaboration with popular artist Harrdy Sandhu. This partnership brings a distinct flair to the sneakers, blending PUMA's renowned craftsmanship with Sandhu's signature style, resulting in a truly exclusive footwear option.`,
    heading1:`Sleek Low-Top Design`,
    paragraph1:`Featuring a sleek low-top design, these sneakers ensure excellent ankle mobility and a snug fit. This design is perfect for everyday wear, offering a balance between style and comfort, and making them suitable for various activities.`,
    heading2:`Premium Material Construction`,
    paragraph2:`Made with high-quality materials, the PUMA RBD Game Low x HARRDY SANDHU Sneakers promise durability and long-lasting use. The robust construction ensures that the sneakers can withstand daily wear and tear while maintaining their stylish appearance.`,
    heading3:`Comfort-Oriented Design`,
    paragraph3:`These sneakers prioritize comfort with a cushioned insole and a padded collar. The design provides ample support and cushioning for all-day wear, reducing foot fatigue and enhancing overall comfort, making them ideal for long walks or standing periods.`,
    heading4:`Distinctive Style Elements`,
    paragraph4:`The design elements of these sneakers are inspired by Harrdy Sandhu's unique fashion sense. From the color choices to the detailing, every aspect reflects his vibrant personality, ensuring the sneakers are as stylish as they are functional.`,
    keyword:`Sneakers for menThese sneakers feature a sleek low-top design, ensuring both comfort and mobility, Crafted with premium materials, they offer durability and long-lasting wear. The unique aesthetic, inspired by Sandhu's signature style, makes these sneakers a standout addition to any wardrobe, perfect for casual and trendy outfits.`,
    description:`These sneakers feature a sleek low-top design, ensuring both comfort and mobility. Crafted with premium materials, they offer durability and long-lasting wear. The unique aesthetic, inspired by Sandhu's signature style, makes these sneakers a standout addition to any wardrobe, perfect for casual and trendy outfits`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  
  {
    name:`Immortal-201-Bluetooth-Gaming-Wireless-Earbuds-with`,
    title:`BOAT Immortal 201 Bluetooth Gaming Wireless Earbuds with 40ms BEAST™ Mode, Real RGB Lights, boAt Signature Sound`,
    rate:`₹1299`,
    content: [  
      `The boAt Immortal 201 gaming headset boasts 50mm drivers for immersive audio, customizable RGB lighting, and a noise-cancelling microphone for clear communication. Its comfortable design, featuring plush ear cushions and an adjustable headband, ensures long-lasting wear. Compatible with multiple platforms, this headset offers intuitive in-line controls and durable build quality, making it an ideal choice for serious gamers seeking high performance and style.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Wireless Earbuds`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Boat_Logo.webp/1200px-Boat_Logo.webp.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fimmortal-201-gaming-earbuds`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard1_91be9b11-be2a-4223-880a-25eb97164cef_600x.png?v=1704781311`,
    image:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard3_83838911-7adf-4f4e-87cf-dc1e3235d5b2_600x.jpg?v=1704781311`,
    image1:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard7_546d657d-bcba-4f9a-a957-4270d724afda_600x.jpg?v=1704781311`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Exceptional Audio Performance`,
    paragraph:`The boAt Immortal 201 gaming headset is equipped with 50mm drivers that deliver powerful and immersive sound. These drivers ensure that every audio detail, from subtle ambient sounds to explosive in-game action, is crystal clear. This superior sound quality enhances the gaming experience, allowing players to fully immerse themselves in their games.`,
    heading1:`Customizable RGB Lighting`,
    paragraph1:`The headset features customizable RGB lighting, offering a range of colors and effects. This allows gamers to personalize their headset to match their gaming setup or mood. The vibrant lighting adds an aesthetic appeal and enhances the overall gaming atmosphere, making the gaming experience more immersive and enjoyable.`,
    heading2:`Comfortable Design for Long Sessions`,
    paragraph2:`Designed for extended gaming sessions, the Immortal 201 offers exceptional comfort with its plush ear cushions and adjustable headband. The memory foam ear pads provide a snug fit, reducing pressure and preventing ear fatigue. The lightweight design further ensures comfort, allowing gamers to wear the headset for hours without discomfort.`,
    heading3:`Clear and Crisp Communication`,
    paragraph3:`The boAt Immortal 201 features a noise-cancelling microphone that ensures clear communication with teammates. The microphone effectively filters out background noise, allowing for uninterrupted and clear voice transmission. This is crucial for team-based games where clear communication can significantly impact gameplay and strategy execution.`,
    heading4:`Durable Build Quality`,
    paragraph4:`Built with high-quality materials, the Immortal 201 is designed to withstand the rigors of intense gaming. The robust construction ensures longevity, making it a reliable choice for gamers who demand durability from their gear. The reinforced headband and durable cables contribute to the headset's overall sturdiness and lifespan.`,
    keyword:`gaming earbuds, Boat gaming earbuds, Boat earbuds, wireless gaming earbuds, best gaming earbuds, Boat gaming earphones, low latency earbuds, gaming accessories, Boat audio, Boat earphones, Bluetooth gaming earbuds, high-quality gaming sound, noise-canceling gaming earbuds, gaming earbuds for PC, gaming earbuds for mobile, immersive gaming sound, Boat audio gear, Boat gaming products, in-ear gaming headphones, budget gaming earbuds`,
    description:`Its comfortable design, featuring plush ear cushions and an adjustable headband, ensures long-lasting wear. Compatible with multiple platforms, this headset offers intuitive in-line controls and durable build quality, making it an ideal choice for serious gamers seeking high performance and style.`,
    summary:`https://hammeronline.in/cdn/shop/files/bt_earbuds_49d378d7-6ccd-4fb0-85bc-b93bdee28b0a.webp?v=1713440018&width=1920`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fhammeronline.in%2Fcollections%2Ftruly-wireless-earbuds%2Fproducts%2Fhammer-solitude-tws-bluetooth-earbuds-with-bluetooth-v5-3-enc-and-smart-touch-controls`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
 
  
  
  {
    name:`Vitamin-CE-5-Niacinamide-Serum`,
    title:`DOT & KEY 10% Vitamin C+E & 5% Niacinamide Serum`,
    rate:`₹299`,
    content: [  
      `DOT & KEY's 10% Vitamin C+E & 5% Niacinamide Serum is a potent blend of antioxidants and skin-loving ingredients designed to brighten, hydrate, and rejuvenate the skin. Vitamin C and E work together to neutralize free radicals, reduce the appearance of dark spots, and promote collagen production for firmer, smoother skin. Niacinamide (Vitamin B3) helps to improve skin texture, minimize pores, and control excess oil production, resulting in a more balanced and radiant complexion. This lightweight serum absorbs quickly into the skin, delivering powerful benefits without any greasy residue. Incorporate this serum into your daily skincare routine for a brighter, healthier-looking complexion.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-orange-600`,
    wear:`Skincare Items`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://images.squarespace-cdn.com/content/v1/58d67c53f5e231abb445a1c5/1530714471513-BDO4R6ZR8ZH9GOBWQ42U/Dot-_-Key-Logo.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fdot-key-10-vitamin-c-e-5-niacinamide-serum-for-glowing-skin-beginner-friendly`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/vitcserum_1800x1800.jpg?v=1706166348`,
    image:`https://www.dotandkey.com/cdn/shop/products/6_a49880e2-4254-438d-b7db-c8c8920436b5_1800x1800.webp?v=1706166348`,
    image1:`https://www.dotandkey.com/cdn/shop/products/7_5ddabdd6-7062-436e-bbd8-7ea360c913c8_1800x1800.webp?v=1706166348`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Brightening Formula`,
    paragraph:`The serum contains 10% Vitamin C and Vitamin E, which work together to brighten the skin, reduce hyperpigmentation, and even out skin tone for a radiant complexion.`,
    heading1:`Antioxidant Protection`,
    paragraph1:`Vitamin C and E are potent antioxidants that help to neutralize free radicals, protect the skin from environmental damage, and prevent premature aging signs like fine lines and wrinkles.`,
    heading2:`Hydrating and Nourishing`,
    paragraph2:`In addition to its brightening effects, this serum also hydrates and nourishes the skin, leaving it soft, smooth, and supple.`,
    heading3:`Improves Skin Texture`,
    paragraph3:`Niacinamide (Vitamin B3) helps to improve skin texture by reducing the appearance of pores, fine lines, and wrinkles. It also enhances the skin's natural barrier function, resulting in healthier-looking skin.`,
    heading4:`Controls Oil Production`,
    paragraph4:`Niacinamide regulates sebum production, making it ideal for oily or acne-prone skin types. It helps to mattify the skin and prevent breakouts without drying it out.`,
    keyword:`10% vitamin c+e & 5% niacinamide serum`,
    description:`Niacinamide (Vitamin B3) helps to improve skin texture, minimize pores, and control excess oil production, resulting in a more balanced and radiant complexion. This lightweight serum absorbs quickly into the skin, delivering powerful benefits without any greasy residue. Incorporate this serum into your daily skincare routine for a brighter, healthier-looking complexion.`,
    summary:`/images/5-13june/watchbanner1.jpg`,
    jlink:`https://amzn.urlvia.com/4uYW2j`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  
  {
    name:`lime-rush-sports-facewash`,
    title:`DOT & KEY Lime Rush Sports Facewash`,
    rate:`₹249`,
    content: [`DOT & KEY Lime Rush Sports Facewash is a refreshing and invigorating cleanser designed specifically for active individuals. Infused with lime extracts and purifying ingredients, this face wash effectively removes dirt, sweat, and excess oil from the skin, leaving it clean, refreshed, and energized. Its gentle formula is suitable for daily use and helps to unclog pores, prevent breakouts, and promote healthy-looking skin. Whether you're hitting the gym, playing sports, or just need a revitalizing cleanse, this face wash is your go-to solution for a fresh and rejuvenated complexion.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-yellow-400`,
    wear:`Skincare Items`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://i.pinimg.com/736x/36/c7/ed/36c7edd18e17cfce9e8f7743d07ceebe.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Flime-rush-sports-facewash`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/lime_1800x1800.jpg?v=1710331662`,
    image:`https://www.dotandkey.com/cdn/shop/files/6-Lime-Face-Wash_1800x1800.jpg?v=1710331662`,
    image1:`https://www.dotandkey.com/cdn/shop/files/7-Lime-Face-Wash_1800x1800.jpg?v=1710331662`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Refreshing Cleansing`,
    paragraph:`The Lime Rush Sports Facewash provides a refreshing and revitalizing cleanse, washing away dirt, sweat, and impurities accumulated during workouts or outdoor activities.`,
    heading1:`Lime Extracts`,
    paragraph1:`Infused with lime extracts, this face wash helps to purify the skin, control excess oil production, and brighten the complexion, giving you a healthy-looking glow.`,
    heading2:`Purifying Formula`,
    paragraph2:`Formulated with purifying ingredients, the face wash effectively unclogs pores, removes debris, and prevents breakouts, leaving your skin clean and clear.`,
    heading3:`Gentle on Skin`,
    paragraph3:`Despite its powerful cleansing properties, the face wash is gentle on the skin and suitable for daily use. It doesn't strip away the skin's natural moisture, ensuring a balanced and comfortable feel.`,
    heading4:`Energizing Scent`,
    paragraph4:`Enjoy the energizing and uplifting scent of fresh lime with every use. The invigorating fragrance awakens your senses and leaves you feeling refreshed.`,
    keyword:`Lime Rush Sports Facewash, Best sports facewash, Lime-infused facewash, Refreshing facewash for athletes, Citrus facewash for active skin, Sports face cleanser, Facewash for oily skin, Facewash for sweat removal, Men's sports facewash, Facewash with lime extract, Deep cleansing facewash, Energizing facewash, Hydrating facewash for men, Natural lime facewash, Facewash for post-workout, Facewash for active lifestyle, Anti-bacterial sports facewash`,
    description:`Infused with lime extracts and purifying ingredients, this face wash effectively removes dirt, sweat, and excess oil from the skin, leaving it clean, refreshed, and energized. Its gentle formula is suitable for daily use and helps to unclog pores, prevent breakouts, and promote healthy-looking skin. Whether you're hitting the gym, playing sports, or just need a revitalizing cleanse, this face wash is your go-to solution for a fresh and rejuvenated complexion.`,
    summary:`/images/5-13june/watchbanner0.jpg`,
    jlink:`https://amzn.urlvia.com/57gmdU`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  
  {
    name:`strawberry-dew-daily-cleansing-balm`,
    title:`DOT & KEY Strawberry Dew Daily Cleansing Balm`,
    rate:`₹499`,
    content: [`The DOT & KEY Strawberry Dew Daily Cleansing Balm is a luxurious balm-to-milk cleanser that gently removes makeup, impurities, and excess oil from the skin, leaving it clean, soft, and hydrated. Infused with the goodness of strawberries and hyaluronic acid, this cleansing balm effectively cleanses the skin while providing intense hydration and nourishment. Its unique formula transforms from a balm to a milky texture upon contact with water, making it suitable for all skin types, including sensitive skin.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-400`,
    wear:`Skincare Items`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/nykaa.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nykaa.com%2Fdot-key-strawberry-dew-cleansing-balm%2Fp%2F12573654`,
    buy1:`https://images.squarespace-cdn.com/content/v1/58d67c53f5e231abb445a1c5/1530714471513-BDO4R6ZR8ZH9GOBWQ42U/Dot-_-Key-Logo.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fstrawberry-dew-daily-cleansing-balm`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/strawcleaningbalm_1800x1800.jpg?v=1706167234`,
    image:`https://www.dotandkey.com/cdn/shop/files/2StrawberryCLEANSINGBALMcopy_1800x1800.jpg?v=1706167234`,
    image1:`https://www.dotandkey.com/cdn/shop/files/7_1800x1800.jpg?v=1711526985`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Gentle Cleansing`,
    paragraph:`The cleansing balm gently but effectively removes makeup, dirt, and impurities from the skin without stripping away its natural oils. It leaves the skin feeling clean, soft, and refreshed.`,
    heading1:`Balm-to-Milk Texture`,
    paragraph1:`The unique formula of this cleansing balm transforms from a balm to a milky texture upon contact with water, making it easy to rinse off without leaving any residue behind.`,
    heading2:`Suitable for All Skin Types`,
    paragraph2:`The gentle and non-irritating formula of this cleansing balm makes it suitable for all skin types, including sensitive skin. It soothes and calms the skin, making it perfect for daily use.`,
    heading3:`Makeup Remover`,
    paragraph3:`This cleansing balm effectively removes all traces of makeup, including waterproof mascara and long-wearing foundation, without the need for harsh rubbing or scrubbing.`,
    heading4:`Easy to Use`,
    paragraph4:`Simply massage the cleansing balm onto dry skin, then emulsify with water to create a milky lather. Rinse thoroughly to reveal clean, soft, and hydrated skin.`,
    keyword:`strawberry cleansing balm, daily cleansing balm, strawberry dew cleansing balm, face cleanser, makeup remover balm, skincare balm, gentle cleansing balm, hydrating cleansing balm, natural cleansing balm, strawberry skincare, cleansing balm for sensitive skin, deep cleanse balm, fruit-infused cleanser, skincare routine, moisturizing cleansing balm, anti-inflammatory balm, antioxidant-rich cleanser, soothing cleansing balm, facial balm, daily face cleanser, best cleansing balm, strawberry extract skincare, clean beauty products, cruelty-free cleansing balm, hydrating face cleanser`,
    description:`Infused with the goodness of strawberries and hyaluronic acid, this cleansing balm effectively cleanses the skin while providing intense hydration and nourishment. Its unique formula transforms from a balm to a milky texture upon contact with water, making it suitable for all skin types, including sensitive skin.`,
    summary:`https://cdn.anscommerce.com/catalog/brandstore/Bira91/623-1716402600-1920x667website.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fmakeplay-collection`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

 
  {
    name:`lime-rush-swim-sports-spf-50-sunscreen`,
    title:`DOT & KEY Swim + Sports SPF 50 Sunscreen`,
    rate:`₹495`,
    content: [ `The DOT & KEY Swim + Sports SPF 50 Sunscreen is a high-performance sunscreen specially formulated for active individuals. With its water-resistant formula and broad-spectrum protection, it offers reliable sun protection during swimming, sports, and outdoor activities. Infused with nourishing ingredients, this sunscreen not only shields your skin from harmful UV rays but also keeps it moisturized and comfortable, making it the perfect companion for your active lifestyle.`],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-yellow-300`,
    wear:`Skincare Items`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://images.squarespace-cdn.com/content/v1/58d67c53f5e231abb445a1c5/1530714471513-BDO4R6ZR8ZH9GOBWQ42U/Dot-_-Key-Logo.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Flime-rush-swim-sports-spf-50-sunscreen`,
    buy1:`/images/amazon.jpg`,
    blink1:`https://www.amazon.in/Sports-Sunscreen-Resistant-Protect-Prevents/dp/B0CTT91H5J?tag=inrdeal123-21`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/swimsuncopy_1800x1800.jpg?v=1708151908`,
    image:`https://www.dotandkey.com/cdn/shop/files/2-Website---Sports-Sunscreen--model_1800x1800.jpg?v=1710915357`,
    image1:`https://www.dotandkey.com/cdn/shop/files/6-Website---Sports-Sunscreen_1800x1800.jpg?v=1710915353`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Water-Resistant Formula`,
    paragraph:`This sunscreen is specially designed to withstand water and sweat, making it ideal for swimming, sports, and other outdoor activities. It provides long-lasting protection even in the water.`,
    heading1:`High SPF Protection`,
    paragraph1:`With SPF 50, this sunscreen offers broad-spectrum protection against both UVA and UVB rays, helping to prevent sunburn, premature aging, and skin damage.`,
    heading2:`Non-Greasy Texture`,
    paragraph2:`The lightweight, non-greasy formula absorbs quickly into the skin without leaving a sticky residue. It feels comfortable to wear and won't clog pores, allowing your skin to breathe freely.`,
    heading3:`Nourishing Ingredients`,
    paragraph3:`Infused with nourishing ingredients like vitamin E and almond oil, this sunscreen helps to keep your skin moisturized and hydrated, even in harsh outdoor conditions.`,
    heading4:`Easy Application`,
    paragraph4:`The sunscreen comes in a convenient spray bottle that allows for easy and even application. Simply spray it onto your skin and spread it evenly for maximum coverage.`,
    keyword:`sports sunscreen, SPF 50 sunscreen, high-performance sunscreen, sports sunblock, water-resistant sunscreen, sweat-resistant sunscreen, sun protection for athletes, outdoor sunscreen, long-lasting SPF, broad-spectrum sunscreen, UV protection, sunscreen for sports, active lifestyle sunscreen, dermatologist-recommended sunscreen, anti-aging sunscreen, non-greasy sunscreen, lightweight sunscreen, sun care, best sports sunscreen, sunscreen for runners, SPF 50+ sunblock, sports skin care, summer sunscreen, face and body sunscreen, sun protection lotion`,
    description:`With its water-resistant formula and broad-spectrum protection, it offers reliable sun protection during swimming, sports, and outdoor activities. Infused with nourishing ingredients, this sunscreen not only shields your skin from harmful UV rays but also keeps it moisturized and comfortable, making it the perfect companion for your active lifestyle.`,
    summary:`https://cdn.anscommerce.com/live/image/bira91/banner/848_202405291240_1920x667_1_(1).jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fbsl-collection`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  
  
  {
    name:`watermelon-cooling-underarm-roll-on`,
    title:`DOT & KEY Watermelon Cooling Underarm Roll On`,
    rate:`₹299`,
    content: [  
      `The DOT & KEY Watermelon Cooling Underarm Roll On offers a refreshing solution for underarm care. Infused with watermelon extracts and cooling agents, this roll-on provides long-lasting freshness while soothing and hydrating the delicate underarm skin. Its easy-to-use roll-on applicator ensures mess-free application, making it a convenient and effective addition to your daily routine.
`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-500`,
    wear:`Skincare Items`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Watermelon-Underarm-Controls-Fragrance-Exfoliates/dp/B0D294PK6W?tag=inrdeal123-21`,
    buy1:`https://images.yourstory.com/cs/images/companies/8a1781d2f2b3-Dotandkeylogo1-1616155640014.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fwatermelon-cooling-underarm-roll-on`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/Artboard1_1800x1800.jpg?v=1714460533`,
    image:`https://www.dotandkey.com/cdn/shop/files/2-Underarm-RollOn_1800x1800.jpg?v=1714460533`,
    image1:`https://www.dotandkey.com/cdn/shop/files/5-Underarm-RollOn_1800x1800.jpg?v=1714460533`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Long-lasting Freshness`,
    paragraph:`The watermelon-infused formula offers long-lasting freshness, keeping you feeling clean and confident throughout the day. Say goodbye to odor and sweat with this cooling roll-on.`,
    heading1:`Soothing Relief`,
    paragraph1:`With cooling agents like menthol, this underarm roll-on provides instant relief from irritation and discomfort. It soothes the skin, making it ideal for use after shaving or waxing.`,
    heading2:`Hydrating Formula`,
    paragraph2:`Enriched with moisturizing ingredients, such as glycerin, the roll-on hydrates the underarm skin, preventing dryness and promoting softness.`,
    heading3:`Quick Absorption`,
    paragraph3:`The lightweight formula absorbs quickly into the skin without leaving any sticky or greasy residue. It feels comfortable to wear and won't stain your clothes.`,
    heading4:`Easy Application`,
    paragraph4:`The roll-on applicator allows for easy and mess-free application. Simply roll it onto clean, dry underarms for instant freshness and protection.`,
    keyword:`cooling underarm roll on, best underarm roll on, cooling deodorant, underarm freshener, sweat control roll on, long-lasting roll on, odor protection, cooling antiperspirant, refreshing underarm product, gentle roll on deodorant, underarm care, natural roll on, soothing underarm roll on, underarm cooling solution, daily underarm roll on, top-rated roll on deodorant, summer underarm roll on, cooling underarm deodorant, underarm skin care, effective roll on deodorant`,
    description:`Infused with watermelon extracts and cooling agents, this roll-on provides long-lasting freshness while soothing and hydrating the delicate underarm skin. Its easy-to-use roll-on applicator ensures mess-free application, making it a convenient and effective addition to your daily routine.`,
    summary:`/images/5-13june/watchbanner4.jpg`,
    jlink:`https://amzn.urlvia.com/O8CGoT`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
  {
    name:`Strawberry-Dew-Strobe-Cream`,
    title:`150 DOT & KEY Strawberry Dew Strobe Cream`,
    rate:`₹382`,
    content: [  
      `DOT & KEY Strawberry Dew Strobe Cream is a multifunctional beauty product designed to illuminate and hydrate your skin. Infused with strawberry extracts and hyaluronic acid, this strobe cream provides a radiant glow while moisturizing your skin. It can be used alone for a natural shine or mixed with foundation for a dewy finish, making it a versatile addition to any makeup routine.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-600`,
    wear:`Beauty Product`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Strawberry-Radiance-Moisturizer-Highlighter-Hydration/dp/B0CHJT5GMT?tag=inrdeal123-21`,
    buy1:`https://afiles.webengage.com/~2024b8a7/c0f7f39c-bbe2-4624-b874-47e4d4c20328.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fstrawberry-dew-strobe-cream`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/Artboard2_738f4527-e69f-4f7c-95b2-178d6d171ca3_1800x1800.jpg?v=1706167265`,
    image:`https://www.dotandkey.com/cdn/shop/files/5-Strawberry-Strobe_1800x1800.jpg?v=1706167265`,
    image1:`https://www.dotandkey.com/cdn/shop/files/4-Strawberry-Strobe_1800x1800.jpg?v=1706167265`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Radiant Glow`,
    paragraph:`The Strawberry Dew Strobe Cream is designed to give your skin a luminous glow. The light-reflecting particles enhance your natural radiance, making your skin look dewy and fresh. It’s perfect for achieving that lit-from-within look.`,
    heading1:`Hydrating Formula`,
    paragraph1:`Infused with hyaluronic acid, this strobe cream provides intense hydration, keeping your skin moisturized and plump. The hydrating formula ensures that your skin remains soft and supple throughout the day.`,
    heading2:`Strawberry Extracts`,
    paragraph2:`Rich in antioxidants, strawberry extracts help to protect the skin from environmental damage. These extracts also help to brighten the skin, giving it a healthy and youthful appearance.`,
    heading3:`Versatile Use`,
    paragraph3:`This strobe cream can be used in multiple ways. Apply it on its own for a subtle glow, mix it with your foundation for a dewy finish, or use it as a highlighter on the high points of your face for a more defined look.`,
    heading4:`Lightweight Texture`,
    paragraph4:`The lightweight and non-greasy formula ensures that the cream blends seamlessly into your skin. It feels comfortable to wear and doesn’t clog pores, making it suitable for all skin types.`,
    keyword:`DOT & KEY Strawberry Dew Strobe Cream`,
    description:`Infused with strawberry extracts and hyaluronic acid, this strobe cream provides a radiant glow while moisturizing your skin. It can be used alone for a natural shine or mixed with foundation for a dewy finish, making it a versatile addition to any makeup routine.`,
    summary:`https://www.dotandkey.com/cdn/shop/files/Desktop_ec61a5cb-a504-41d9-8f95-1d8156833d0b.jpg?v=1716618008`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fpages%2Fbirthday-sale-24%3Futm_source%3Dgoogle%26utm_medium%3Dpaid%26utm_campaign%3D17376557437%26utm_content%3D137002497093%26utm_term%3Ddot%20key%26gadid%3D670193601587%26gad_source%3D1%26gclid%3DCj0KCQjwmMayBhDuARIsAM9HM8c3nmX6TvRTXFYP72pe24xsfFdotvD92h7eV1kK9MJeytcgwxz1vl0aAucVEALw_wcB%23!`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`Strawberry-Dew-Tinted-Sunscreen-SPF-50-PA`,
    title:`149 DOT & KEY Strawberry Dew Tinted Sunscreen SPF 50+ PA++++`,
    rate:`₹382`,
    content: [  
      `The DOT & KEY Vitamin C + E Sunscreen, SPF 50+ PA++++, combines powerful sun protection with skin-brightening and antioxidant benefits. Infused with Vitamin C and Vitamin E, this sunscreen protects against UVA and UVB rays while improving skin texture and tone. Its lightweight, non-greasy formula ensures comfortable daily wear, keeping your skin safe, radiant, and hydrated.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-600`,
    wear:`Beauty Product`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Dot-Key-Strawberry-Tinted-Sunscreen/dp/B0CX1Z5SPZ?tag=inrdeal123-21`,
    buy1:`https://afiles.webengage.com/~2024b8a7/c0f7f39c-bbe2-4624-b874-47e4d4c20328.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fstrawberry-dew-tinted-sunscreen-spf-50-pa`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/image_2_449161b5-417a-44f3-994b-1d90fb08910a_1800x1800.png?v=1714545192`,
    image:`https://www.dotandkey.com/cdn/shop/files/3-Beige---Listing_1800x1800.jpg?v=1714545192`,
    image1:`https://www.dotandkey.com/cdn/shop/files/image_3_f5d994bd-f183-461d-bbb0-01990b04ce3a_1800x1800.png?v=1713878076`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`High Sun Protection`,
    paragraph:`This sunscreen offers SPF 50+ PA++++, providing superior protection against harmful UVA and UVB rays. It helps prevent sunburn, skin damage, and signs of premature aging, making it ideal for daily use.`,
    heading1:`Tinted Formula`,
    paragraph1:`The tinted formulation enhances your natural skin tone, offering light coverage that evens out your complexion. It’s perfect for days when you want to skip foundation but still want a polished look.`,
    heading2:`Dewy Finish`,
    paragraph2:`Infused with strawberry extracts, this sunscreen imparts a dewy, radiant finish to your skin. The lightweight formula leaves your skin looking fresh and glowing without any greasiness`,
    heading3:`Antioxidant Rich`,
    paragraph3:`Strawberry extracts are rich in antioxidants, which help protect the skin from environmental stressors. This sunscreen not only protects against sun damage but also fights free radicals that can cause skin aging.`,
    heading4:`Hydrating and Nourishing`,
    paragraph4:`The formula is enriched with hydrating ingredients that keep your skin moisturized throughout the day. It prevents dryness and maintains skin elasticity, ensuring a smooth and supple complexion.`,
    keyword:`DOT & KEY Strawberry Dew Tinted Sunscreen SPF 50+ PA++++`,
    description:`Infused with the goodness of strawberries, it provides antioxidant benefits while ensuring broad-spectrum protection against UVA and UVB rays. The lightweight, non-greasy formula blends seamlessly, enhancing your natural complexion and keeping your skin hydrated and radiant.`,
    summary:`https://www.dotandkey.com/cdn/shop/files/Desktop_ec61a5cb-a504-41d9-8f95-1d8156833d0b.jpg?v=1716618008`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fpages%2Fbirthday-sale-24%3Futm_source%3Dgoogle%26utm_medium%3Dpaid%26utm_campaign%3D17376557437%26utm_content%3D137002497093%26utm_term%3Ddot%20key%26gadid%3D670193601587%26gad_source%3D1%26gclid%3DCj0KCQjwmMayBhDuARIsAM9HM8c3nmX6TvRTXFYP72pe24xsfFdotvD92h7eV1kK9MJeytcgwxz1vl0aAucVEALw_wcB%23!`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },




  {
    name:`Vitamin-C-E-Sunscreen-SPF-50-PA`,
    title:`148 DOT & KEY Vitamin C + E Sunscreen, SPF 50+ PA++++`,
    rate:`₹382`,
    content: [  
      `The DOT & KEY Vitamin C + E Sunscreen, SPF 50+ PA++++, combines powerful sun protection with skin-brightening and antioxidant benefits. Infused with Vitamin C and Vitamin E, this sunscreen protects against UVA and UVB rays while improving skin texture and tone. Its lightweight, non-greasy formula ensures comfortable daily wear, keeping your skin safe, radiant, and hydrated.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-yellow-600`,
    wear:`Beauty Product`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Vitamin-Sunscreen-Water-Light-Protection-Absorption/dp/B0BLK4YRSN/ref=sr_1_1_sspa?adgrpid=136509649615&dib=eyJ2IjoiMSJ9.ueH_WmPz336E53EeMbQ9NyIMck2Ogzf9zqIMl-RU9tiHco_MWmOopKj62z-00G9EcjwSxXSMYO1OdMkQCaE0TxAkBuXp9eIbSeUiCjk2JevgwFlwx_mqHMpV0gQKLuxqHK6MVOnzfmKKV6zhPNoNDzfDb8_PFw0_f0bp4Je4Uu6dOHxS6ZA1GTBNzD-29zuJmVPG64zGMWdmXmjzuQOpi684rc2bH6RhUpLXy1_tQSU_gSSHKfWrtT7xJL_sWvhNklm5123Z5yCoSkS_sJvyj2he-w1bJ1EUdjZuZkbodEY.yBg4uW2I4-xgrjhrO2Mg2Ji04_UZXcus4CvNgPutuu0&dib_tag=se&ext_vrnc=hi&hvadid=605482532795&hvdev=c&hvlocphy=9303027&hvnetw=g&hvqmt=b&hvrand=7331061774493085859&hvtargid=kwd-863926512892&hydadcr=22033_2253812&keywords=spf+50+pa+++++sunscreen&qid=1716639671&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&tag=inrdeal123-21`,
    buy1:`https://afiles.webengage.com/~2024b8a7/c0f7f39c-bbe2-4624-b874-47e4d4c20328.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fdot-key-vitamin-c-e-spf-50-pa-face-sunscreen-for-glowing-skin-uv-protection-for-dull-skin`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/vitcsunscreen_1e7fa7c9-5b46-4cf5-9708-1b567797043d_1_1800x1800.jpg?v=1711523980`,
    image:`https://www.dotandkey.com/cdn/shop/files/7_82a24a7d-97f8-48ed-abad-a65e4fff3246_1800x1800.jpg?v=1714460138`,
    image1:`https://www.dotandkey.com/cdn/shop/files/4-ShanayaVitC_Final_1800x1800.jpg?v=171402718`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Superior Sun Protection`,
    paragraph:`With SPF 50+ PA++++, the DOT & KEY Vitamin C + E Sunscreen provides broad-spectrum protection against both UVA and UVB rays. This high level of protection helps prevent sunburn, premature aging, and skin cancer, making it essential for daily use.`,
    heading1:`Antioxidant Benefits`,
    paragraph1:`Infused with Vitamin C and Vitamin E, this sunscreen offers powerful antioxidant protection. These vitamins neutralize free radicals caused by sun exposure, reducing oxidative stress and preventing skin damage.`,
    heading2:`Brightening Effect`,
    paragraph2:`The inclusion of Vitamin C helps brighten the skin and reduce the appearance of dark spots and pigmentation. Regular use of this sunscreen can lead to a more even and radiant complexion, enhancing your skin's natural glow.`,
    heading3:`Moisturizing Formula`,
    paragraph3:`Vitamin E in the formula provides deep hydration, keeping your skin moisturized and preventing dryness. This dual-action formula ensures your skin stays hydrated and protected throughout the day.`,
    heading4:`Lightweight and Non-Greasy`,
    paragraph4:`The sunscreen has a lightweight, non-greasy texture that absorbs quickly into the skin. It leaves no white cast or sticky residue, making it perfect for daily use and suitable for all skin types.`,
    keyword:`DOT & KEY Vitamin C + E Sunscreen, SPF 50+ PA++++`,
    description:`The DOT & KEY Vitamin C + E Sunscreen, SPF 50+ PA++++, combines powerful sun protection with skin-brightening and antioxidant benefits. Infused with Vitamin C and Vitamin E, this sunscreen protects against UVA and UVB rays while improving skin texture and tone. Its lightweight, non-greasy formula ensures comfortable daily wear, keeping your skin safe, radiant, and hydrated.`,
    summary:`https://www.dotandkey.com/cdn/shop/files/Desktop_ec61a5cb-a504-41d9-8f95-1d8156833d0b.jpg?v=1716618008`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fpages%2Fbirthday-sale-24%3Futm_source%3Dgoogle%26utm_medium%3Dpaid%26utm_campaign%3D17376557437%26utm_content%3D137002497093%26utm_term%3Ddot%20key%26gadid%3D670193601587%26gad_source%3D1%26gclid%3DCj0KCQjwmMayBhDuARIsAM9HM8c3nmX6TvRTXFYP72pe24xsfFdotvD92h7eV1kK9MJeytcgwxz1vl0aAucVEALw_wcB%23!`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Vitamin-C-E-Moisturizer`,
    title:`147 DOT & KEY Vitamin C + E Moisturizer`,
    rate:`₹430`,
    content: [  
      `The DOT & KEY Vitamin C + E Moisturizer is a potent skincare product designed to brighten, hydrate, and protect your skin. Enriched with powerful antioxidants like Vitamin C and Vitamin E, this moisturizer helps to reduce dark spots, enhance radiance, and fight signs of aging. Its lightweight, non-greasy formula absorbs quickly, providing deep nourishment and a natural glow, making it an essential addition to your daily skincare routine.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-yellow-600`,
    wear:`Beauty Product`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Vitamin-Moisturizer-Ascorbic-Ascorbyl-Phosphate/dp/B09FTKLD5G/ref=sr_1_2_sspa?adgrpid=151998481664&dib=eyJ2IjoiMSJ9.-Tj70kNdYp3OLBFPnGpukoqpfuOX5l76Vv1SrWfmUTlluRLYqAsLxOSL31vpe3qezLF96wjJ-h7VozkknOW06DN6zMAbauAsh9zuhk-a8Uz3o9RNqJajq_kWOcIHtoqY6wGPB6u7I498l7KMO6ir7BFDVkm7Ea1uPNiTg-zusFqt3faOTmgbw-tSlVqbmK8gBNPG0mGJ665tGP4S-MrCDPi_FMWVvuTtOzyAdzFBxw_xfjmTkSKgIn-TxrbDIpGDidzNMpS667QonA0fCMgZ5lXXYZ6Dv7KSJFxlX6GROI4.8fnqjMW_oLbdiv7-krZYpseYHdwiwz6Y-ihjYlL7mZo&dib_tag=se&ext_vrnc=hi&hvadid=644776975632&hvdev=c&hvlocphy=9300972&hvnetw=g&hvqmt=e&hvrand=3383989797473808271&hvtargid=kwd-357630615979&hydadcr=16537_2162871&keywords=vitamin+c+and+e+moisturizer&qid=1716636562&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&tag=inrdeal123-21`,
    buy1:`https://afiles.webengage.com/~2024b8a7/c0f7f39c-bbe2-4624-b874-47e4d4c20328.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fvitamin-c-e-super-bright-moisturizer`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/vitcm_1800x1800.jpg?v=1711452937`,
    image:`https://www.dotandkey.com/cdn/shop/files/4-Vit-C-Moisturizer_2b774aa7-d99a-40cd-9ecd-e6ae1fcf4e0e_1800x1800.jpg?v=1714476174`,
    image1:`https://www.dotandkey.com/cdn/shop/files/vitcmcopy_1800x1800.jpg?v=1714476233`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Deep Hydration`,
    paragraph:`This moisturizer provides deep and lasting hydration, thanks to the inclusion of Vitamin E. It helps to lock in moisture, keeping your skin soft, supple, and well-nourished throughout the day.`,
    heading1:`Lightweight and Non-Greasy`,
    paragraph1:`The formula is lightweight and non-greasy, ensuring it absorbs quickly into the skin without leaving any sticky residue. This makes it suitable for all skin types, including oily and combination skin.`,
    heading2:`Improves Skin Texture`,
    paragraph2:`Regular use of the DOT & KEY Vitamin C + E Moisturizer can improve your skin's overall texture. It smoothens rough patches and enhances skin elasticity, giving you a smoother and more youthful appearance.`,
    heading3:`Evens Skin Tone`,
    paragraph3:`With its brightening ingredients, this moisturizer works effectively to even out skin tone. It reduces the appearance of dark spots and blemishes, promoting a clearer and more uniform complexion.`,
    heading4:`Boosts Collagen Production`,
    paragraph4:`Vitamin C is known for its role in boosting collagen production. This moisturizer helps to stimulate collagen synthesis, improving skin firmness and reducing the appearance of fine lines and wrinkles.`,
    keyword:`DOT & KEY Vitamin C + E Moisturizer`,
    description:`Enriched with powerful antioxidants like Vitamin C and Vitamin E, this moisturizer helps to reduce dark spots, enhance radiance, and fight signs of aging. Its lightweight, non-greasy formula absorbs quickly, providing deep nourishment and a natural glow, making it an essential addition to your daily skincare routine.`,
    summary:`https://www.dotandkey.com/cdn/shop/files/Desktop_ec61a5cb-a504-41d9-8f95-1d8156833d0b.jpg?v=1716618008`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fpages%2Fbirthday-sale-24%3Futm_source%3Dgoogle%26utm_medium%3Dpaid%26utm_campaign%3D17376557437%26utm_content%3D137002497093%26utm_term%3Ddot%20key%26gadid%3D670193601587%26gad_source%3D1%26gclid%3DCj0KCQjwmMayBhDuARIsAM9HM8c3nmX6TvRTXFYP72pe24xsfFdotvD92h7eV1kK9MJeytcgwxz1vl0aAucVEALw_wcB%23!`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Watermelon-Cooling-SPF-50-Sunscreen`,
    title:`146 DOT & KEY Watermelon Cooling SPF 50 Sunscreen`,
    rate:`₹555`,
    content: [  
      `The DOT & KEY Watermelon Cooling SPF 50 Sunscreen offers a refreshing way to protect your skin from harmful UV rays. Infused with watermelon extract, this sunscreen provides broad-spectrum protection while delivering a cooling sensation to soothe and hydrate the skin. Its lightweight, non-greasy formula ensures a comfortable wear, making it perfect for daily use, keeping your skin protected, cool, and radiant.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-rose-300`,
    wear:`Beauty Product`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Watermelon-Hyaluronic-Moisturized-Protection-Lightweight/dp/B0CRHFHJ9X?th=1&tag=inrdeal123-21`,
    buy1:`https://afiles.webengage.com/~2024b8a7/c0f7f39c-bbe2-4624-b874-47e4d4c20328.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fwatermelon-cooling-spf-50-face-sunscreen`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/watersunscreen50gm_1800x1800.jpg?v=1713293882`,
    image:`https://www.dotandkey.com/cdn/shop/files/2_46e4687e-b6c5-4c48-b33b-50a05e074d24_1800x1800.jpg?v=1713293882`,
    image1:`https://www.dotandkey.com/cdn/shop/files/6_d5a2d899-4543-4cbb-bb32-6b1664f9d123_1800x1800.jpg?v=1713293882`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`High SPF Protection`,
    paragraph:`The DOT & KEY Watermelon Cooling SPF 50 Sunscreen offers robust protection against both UVA and UVB rays. Its high SPF 50 rating ensures your skin is shielded from sunburn, photoaging, and other sun-related damages, making it ideal for outdoor activities.`,
    heading1:`Refreshing Watermelon Extract`,
    paragraph1:`Enriched with watermelon extract, this sunscreen not only protects but also revitalizes your skin. Watermelon is known for its hydrating and antioxidant properties, which help to refresh and nourish the skin while preventing free radical damage.`,
    heading2:`Cooling Sensation`,
    paragraph2:`The formula provides an immediate cooling sensation upon application, soothing the skin and offering relief from the heat. This makes it especially beneficial for hot and sunny days, ensuring your skin feels cool and comfortable.`,
    heading3:`Broad-Spectrum Defense`,
    paragraph3:`Offering broad-spectrum protection, this sunscreen effectively guards your skin against both UVA and UVB rays. This comprehensive coverage helps prevent premature aging, sunburn, and other harmful effects of sun exposure.`,
    heading4:`Lightweight and Non-Greasy`,
    paragraph4:`The sunscreen is lightweight and non-greasy, making it comfortable to wear throughout the day. It absorbs quickly without leaving a sticky residue, ensuring a smooth finish that works well under makeup.`,
    keyword:`DOT & KEY Watermelon Cooling SPF 50 Sunscreen`,
    description:`Infused with watermelon extract, this sunscreen provides broad-spectrum protection while delivering a cooling sensation to soothe and hydrate the skin. Its lightweight, non-greasy formula ensures a comfortable wear, making it perfect for daily use, keeping your skin protected, cool, and radiant.`,
    summary:`https://www.dotandkey.com/cdn/shop/files/Desktop_ec61a5cb-a504-41d9-8f95-1d8156833d0b.jpg?v=1716618008`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fpages%2Fbirthday-sale-24%3Futm_source%3Dgoogle%26utm_medium%3Dpaid%26utm_campaign%3D17376557437%26utm_content%3D137002497093%26utm_term%3Ddot%20key%26gadid%3D670193601587%26gad_source%3D1%26gclid%3DCj0KCQjwmMayBhDuARIsAM9HM8c3nmX6TvRTXFYP72pe24xsfFdotvD92h7eV1kK9MJeytcgwxz1vl0aAucVEALw_wcB%23!`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Barrier-Repair-Sunscreen-SPF-50`,
    title:`145 DOT & KEY Barrier Repair Sunscreen, SPF 50+`,
    rate:`₹555`,
    content: [  
      `The DOT & KEY Barrier Repair Sunscreen SPF 50+ provides advanced protection against harmful UV rays while repairing and strengthening the skin barrier. This sunscreen offers broad-spectrum protection and is formulated with nourishing ingredients that hydrate and soothe the skin. Its lightweight, non-greasy formula makes it ideal for daily use, ensuring your skin remains protected, healthy, and radiant throughout the day.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-cyan-500`,
    wear:`Beauty Product`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Blueberry-Hydrate-Barrier-Sunscreen-Sensitive/dp/B0CX1HF45F?th=1&tag=inrdeal123-21`,
    buy1:`https://afiles.webengage.com/~2024b8a7/c0f7f39c-bbe2-4624-b874-47e4d4c20328.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fbarrier-repair-sunscreen`,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/brsun80_512ade3d-84ad-48db-a2ed-b11a102fbe39_1800x1800.jpg?v=1714460305`,
    image:`https://www.dotandkey.com/cdn/shop/files/4-withmodel_1800x1800.jpg?v=1714460305`,
    image1:`https://www.dotandkey.com/cdn/shop/files/6-BR-Sunscreen_b4edb252-da8b-4e65-a2ed-bbe8bf89029e_1800x1800.jpg?v=1714460305`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Advanced UV Protection`,
    paragraph:`The DOT & KEY Barrier Repair Sunscreen SPF 50+ offers superior protection against harmful UVA and UVB rays. With its high SPF rating, it effectively prevents sunburn and long-term sun damage, ensuring your skin stays safe under the sun.`,
    heading1:`Barrier Repair Benefits`,
    paragraph1:`This sunscreen goes beyond protection by actively repairing the skin barrier. Infused with ingredients that strengthen and restore the skin’s natural defenses, it helps maintain skin health and resilience against environmental stressors.`,
    heading2:`Broad-Spectrum Defense`,
    paragraph2:`Providing broad-spectrum protection, this sunscreen shields your skin from both UVA and UVB rays. This comprehensive coverage helps prevent premature aging, sunburn, and other sun-induced skin issues.`,
    heading3:`Lightweight and Non-Greasy`,
    paragraph3:`The formula is lightweight and non-greasy, ensuring a comfortable feel on your skin. It absorbs quickly without leaving a sticky residue, making it perfect for daily use, even under makeup.`,
    heading4:`Hydrating and Soothing`,
    paragraph4:`Formulated with hydrating and soothing ingredients, this sunscreen keeps your skin moisturized and calm. It helps alleviate dryness and irritation, leaving your skin feeling soft and refreshed.`,
    keyword:`DOT & KEY Barrier Repair Sunscreen, SPF 50+ `,
    description:`This sunscreen offers broad-spectrum protection and is formulated with nourishing ingredients that hydrate and soothe the skin. Its lightweight, non-greasy formula makes it ideal for daily use, ensuring your skin remains protected, healthy, and radiant throughout the day.`,
    summary:`https://www.dotandkey.com/cdn/shop/files/Desktop_ec61a5cb-a504-41d9-8f95-1d8156833d0b.jpg?v=1716618008`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fpages%2Fbirthday-sale-24%3Futm_source%3Dgoogle%26utm_medium%3Dpaid%26utm_campaign%3D17376557437%26utm_content%3D137002497093%26utm_term%3Ddot%20key%26gadid%3D670193601587%26gad_source%3D1%26gclid%3DCj0KCQjwmMayBhDuARIsAM9HM8c3nmX6TvRTXFYP72pe24xsfFdotvD92h7eV1kK9MJeytcgwxz1vl0aAucVEALw_wcB%23!`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`Strawberry-Dew-Sunscreen-Stick-SPF-50`,
    title:`144 DOT & KEY Strawberry Dew Sunscreen Stick SPF 50`,
    rate:`₹470`,
    content: [  
      `The DOT & KEY Strawberry Dew Sunscreen Stick SPF 50 offers powerful sun protection in a convenient stick format. Infused with the sweet essence of strawberries, it provides broad-spectrum defense against UVA and UVB rays. The lightweight, non-greasy formula is easy to apply, making it perfect for on-the-go protection. Suitable for all skin types, this sunscreen stick ensures your skin stays protected, hydrated, and radiant throughout the day.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-600`,
    wear:`Beauty Product`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/DOT-KEY-Strawberry-Protection-Hyaluronic/dp/B0CDX9LJM2?tag=inrdeal123-21`,
    buy1:`https://afiles.webengage.com/~2024b8a7/c0f7f39c-bbe2-4624-b874-47e4d4c20328.jpg`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fstrawberry-sunstick`,
    buy2:`/images/nykaa.png`,
    blink2:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nykaa.com%2Fdot-key-strawberry-dew-spf-50-sunscreen-stick-on-the-go%2Fp%2F11940883` ,
    thumbnail:`https://www.dotandkey.com/cdn/shop/files/image_10_68cfb580-855a-4624-9f0e-832b7232535a_1800x1800.png?v=1715238587`,
    image:`https://www.dotandkey.com/cdn/shop/files/2StrawberrySunstickcopy_1800x1800.jpg?v=1715238587`,
    image1:`https://www.dotandkey.com/cdn/shop/files/4aStrawberrySunstickcopy-Shanaya_1800x1800.jpg?v=1715238587`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Powerful Sun Protection`,
    paragraph:`The DOT & KEY Strawberry Dew Sunscreen Stick SPF 50 offers high-level protection against harmful UVA and UVB rays. Its SPF 50 rating ensures your skin is safeguarded from sunburn and long-term sun damage, making it a reliable choice for daily use.`,
    heading1:`Convenient Stick Format`,
    paragraph1:`This sunscreen comes in a convenient stick form, making it easy to carry and apply. The compact design allows for quick touch-ups throughout the day, ensuring consistent sun protection wherever you go.`,
    heading2:`Infused with Strawberry Essence`,
    paragraph2:`Enriched with the essence of strawberries, this sunscreen not only protects your skin but also provides a delightful fragrance. The natural strawberry extract adds a refreshing touch, making application a pleasant experience.`,
    heading3:`Broad-Spectrum Defense`,
    paragraph3:`Offering broad-spectrum protection, this sunscreen shields your skin from both UVA and UVB rays. This comprehensive defense helps prevent premature aging, sunburn, and other sun-induced skin issues.`,
    heading4:`Lightweight and Non-Greasy`,
    paragraph4:`The formula is lightweight and non-greasy, ensuring a comfortable feel on your skin. It absorbs quickly without leaving a sticky residue, making it suitable for use under makeup or on its own.`,
    keyword:`DOT & KEY Strawberry Dew Sunscreen Stick `,
    description:`Infused with the sweet essence of strawberries, it provides broad-spectrum defense against UVA and UVB rays. The lightweight, non-greasy formula is easy to apply, making it perfect for on-the-go protection. Suitable for all skin types, this sunscreen stick ensures your skin stays protected, hydrated, and radiant throughout the day.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`Vincent Chase Blue Gunmetal Full Rim Aviator`,
    title:`143 LENSKART Vincent Chase Blue Gunmetal Full Rim Aviator`,
    rate:`₹2,000`,
    content: [  
      `The LENSKART Vincent Chase Blue Gunmetal Full Rim Aviator Sunglasses offer a blend of style and functionality with their classic aviator design and contemporary blue gunmetal frames. These sunglasses provide excellent UV protection, making them ideal for outdoor activities. The full rim construction ensures durability, while the aviator shape adds a touch of sophistication to any look. With their comfortable fit and versatile style, these sunglasses are perfect for both men and women who want to elevate their eyewear game.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-700`,
    wear:`Men's Wear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://anblik.com/wp-content/uploads/2015/12/lenskart-logo.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-e15347-c1-eyeglasses.html`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//v/i/vincent-chase-vc-e15347-c1-eyeglasses_csvfile-1684493324462-g_0006_19_05_2023.jpg`,
    image:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/v/i/blue-gunmetal-full-rim-aviator-vincent-chase-sleek-steel-vc-e15347-c1-eyeglasses_16_may_m_f_shoot262677_206266_19_may23.jpg`,
    image1:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//v/i/vincent-chase-vc-e15347-c1-eyeglasses_csvfile-1684493369936-g_0009_19_05_2023.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Aviator Design`,
    paragraph:`The Vincent Chase Blue Gunmetal Full Rim Aviator Sunglasses feature a timeless aviator shape that exudes sophistication and style. This iconic design adds a touch of retro charm to any outfit.`,
    heading1:`Contemporary Blue Gunmetal Frames`,
    paragraph1:`The blue gunmetal frames offer a modern twist on the traditional aviator style. The unique color combination adds a pop of personality to your eyewear, making a bold fashion statement.`,
    heading2:`Excellent UV Protection`,
    paragraph2:`These sunglasses provide superior UV protection, shielding your eyes from harmful sun rays. Whether you're lounging by the pool or exploring the great outdoors, these sunglasses ensure your eyes are protected.`,
    heading3:`Durable Full Rim Construction`,
    paragraph3:`The full rim construction of these sunglasses ensures durability and longevity. The sturdy frames can withstand daily wear and tear, making them perfect for active lifestyles.`,
    heading4:`Comfortable Fit`,
    paragraph4:`Designed for comfort, these sunglasses feature lightweight frames and adjustable nose pads for a secure and comfortable fit. You can wear them all day without experiencing discomfort or pressure.`,
    keyword:`Aviator sunglasses `,
    description:`These sunglasses provide excellent UV protection, making them ideal for outdoor activities. The full rim construction ensures durability, while the aviator shape adds a touch of sophistication to any look. With their comfortable fit and versatile style, these sunglasses are perfect for both men and women who want to elevate their eyewear game.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
 
  {
    name:`Grey-Full-Rim-Square`,
    title:`142 Lenskart STUDIO Grey Full Rim Square`,
    rate:`₹2000`,
    content: [  
      `The Lenskart STUDIO Grey Full Rim Square Eyeglasses offer a modern and stylish look with their sleek, square design. The full rim frame provides durability and a bold appearance, while the grey color adds a touch of sophistication. These eyeglasses are suitable for both men and women, making them a versatile accessory for any wardrobe. Perfect for everyday wear, they combine fashion with function, providing clear vision and comfort throughout the day.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-700`,
    wear:`Men's Wear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://anblik.com/wp-content/uploads/2015/12/lenskart-logo.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-ls-e15666-c6-eyeglasses.html`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/1325x636/9df78eab33525d08d6e5fb8d27136e95//v/i/vincent-chase-ls-e15666-c6-eyeglasses_img_9630_29_dec23.jpg`,
    image:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/i/karan-johar:-grey-full-rim-square-lenskart-studio-ls-e15666-c6-eyeglasses_csvfile-1705965311728-akaran11.png`,
    image1:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//v/i/vincent-chase-ls-e15666-c6-eyeglasses_img_9630_image_pla_29_dec23.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Modern Square Design`,
    paragraph:`The Lenskart STUDIO Grey Full Rim Square Eyeglasses feature a contemporary square shape that adds a modern touch to your look. This design is perfect for those who want to make a subtle yet stylish statement with their eyewear.`,
    heading1:`Full Rim Durability`,
    paragraph1:`The full rim construction of these eyeglasses ensures they are sturdy and durable. The robust frame provides excellent support for the lenses, making them a reliable choice for daily use.`,
    heading2:`Sophisticated Grey Color`,
    paragraph2:`The grey frames add an element of sophistication and versatility to your eyewear collection. This neutral color pairs well with any outfit, making these eyeglasses a go-to accessory for both casual and formal occasions.`,
    heading3:`Unisex Appeal`,
    paragraph3:`These eyeglasses are designed to be worn by both men and women, offering a versatile style that suits a wide range of face shapes and sizes. Their universal appeal makes them a practical choice for anyone looking for fashionable eyewear.`,
    heading4:`Comfortable Fit`,
    paragraph4:`Lenskart STUDIO ensures a comfortable fit with these eyeglasses. The lightweight frames and ergonomic design allow for extended wear without discomfort, making them perfect for all-day use.`,
    keyword:`Vintage chase lens`,
    description:`The full rim frame provides durability and a bold appearance, while the grey color adds a touch of sophistication. These eyeglasses are suitable for both men and women, making them a versatile accessory for any wardrobe. Perfect for everyday wear, they combine fashion with function, providing clear vision and comfort throughout the day.`,
    summary:`https://static5.lenskart.com/media/uploads/DesktopOwnDays1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyewear%2Feyewear-by-ownDays.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
  
  {
    name:`Brown-Pilot-Rimmed-Sunglasses`,
    title:`141 FASTRACK Brown Pilot Rimmed Sunglasses`,
    rate:`₹1399`,
    content: [  
      `Fastrack Brown Pilot Rimmed Sunglasses combine classic aviator style with a modern twist. The brown pilot rimmed design offers a versatile and fashionable look suitable for various occasions. These sunglasses provide excellent UV protection with their high-quality lenses, ensuring your eyes are shielded from harmful rays. The durable frame construction and comfortable fit make them ideal for everyday wear. Perfect for both men and women, these sunglasses add a touch of sophistication to any outfit while maintaining practicality and style.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-brown-700`,
    wear:`Unisex Item`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://play-lh.googleusercontent.com/aIWUhKudaVkYa--8NH7OW7JeyVCV47Xdpe4bj468avIVsMnr7yG-RpbvRaOYj4osENg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrackeyewear.com%2Fproduct%2Fbrown-pilot-rimmed-sunglasses-m258br5v`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258BR5V_3_lar.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258BR5V_5_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258BR5V_9_lar.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Pilot Design`,
    paragraph:`The Fastrack Brown Pilot Rimmed Sunglasses feature a timeless pilot design that adds a touch of elegance to any outfit. The classic shape is universally flattering and provides a stylish look for both men and women.`,
    heading1:`Versatile Brown Color`,
    paragraph1:`The brown rims offer a versatile and trendy option that complements a variety of outfits. The neutral tone makes these sunglasses a perfect accessory for casual and formal occasions alike.`,
    heading2:`High-Quality Lenses`,
    paragraph2:`These sunglasses come with high-quality lenses that provide excellent UV protection. They ensure your eyes are protected from harmful sun rays, reducing the risk of eye strain and long-term damage..`,
    heading3:`Durable Frame Construction`,
    paragraph3:`Made with durability in mind, the frames are constructed from robust materials that ensure longevity. This makes the sunglasses ideal for everyday use, providing a reliable accessory that can withstand regular wear and tear.`,
    heading4:`Comfortable Fit`,
    paragraph4:`Designed for comfort, these sunglasses feature adjustable nose pads and lightweight frames. This ensures a secure and comfortable fit, allowing you to wear them for extended periods without discomfort.`,
    keyword:`brown pilot rimmed sunglasses m258br5v`,
    description:`The brown pilot rimmed design offers a versatile and fashionable look suitable for various occasions. These sunglasses provide excellent UV protection with their high-quality lenses, ensuring your eyes are shielded from harmful rays. The durable frame construction and comfortable fit make them ideal for everyday wear. Perfect for both men and women, these sunglasses add a touch of sophistication to any outfit while maintaining practicality and style.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  {
    name:`Black-Aviator-Sunglasses-for-Men-and-Women`,
    title:`140 FASTRACK Black Aviator Sunglasses for Men and Women`,
    rate:`₹1399`,
    content: [  
      `Fastrack Black Aviator Sunglasses are a timeless and versatile accessory suitable for both men and women. With their classic aviator design, these sunglasses offer a stylish look that complements various face shapes. The sleek black frames and dark lenses provide excellent UV protection while adding a touch of sophistication to any outfit. Made with high-quality materials, they ensure durability and comfort for everyday wear. These sunglasses are perfect for those seeking a blend of style and functionality.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Unisex Item`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://play-lh.googleusercontent.com/aIWUhKudaVkYa--8NH7OW7JeyVCV47Xdpe4bj468avIVsMnr7yG-RpbvRaOYj4osENg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrackeyewear.com%2Fproduct%2Fblack-aviator-rimmed-sunglasses-from-fastrack-m258gr5v`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258GR5V_1_lar.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258GR5V_6_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258GR5V_7_lar.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Timeless Aviator Design`,
    paragraph:`The Fastrack Black Aviator Sunglasses feature a classic aviator design that never goes out of style. This iconic shape suits various face shapes and adds a touch of elegance and sophistication to any look.`,
    heading1:`Unisex Appeal`,
    paragraph1:`These sunglasses are designed for both men and women, making them a versatile accessory. The unisex appeal ensures that anyone can enjoy the stylish and functional benefits of these aviators.`,
    heading2:`Sleek Black Frames`,
    paragraph2:`The sleek black frames offer a modern and polished appearance. The neutral color allows these sunglasses to match effortlessly with any outfit, making them a go-to accessory for daily wear.`,
    heading3:`Excellent UV Protection`,
    paragraph3:`Equipped with dark lenses, these aviator sunglasses provide excellent UV protection. They shield your eyes from harmful rays, ensuring your vision is protected while you enjoy the sun.`,
    heading4:`High-Quality Materials`,
    paragraph4:`Fastrack is known for its commitment to quality. These sunglasses are made from durable materials that ensure longevity and reliability, allowing you to wear them confidently for years to come.`,
    keyword:`black aviator rimmed sunglasses from fastrack m258gr5v`,
    description:`With their classic aviator design, these sunglasses offer a stylish look that complements various face shapes. The sleek black frames and dark lenses provide excellent UV protection while adding a touch of sophistication to any outfit. Made with high-quality materials, they ensure durability and comfort for everyday wear. These sunglasses are perfect for those seeking a blend of style and functionality.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  {
    name:`Green Square Rimmed Eyeglasses for Women`,
    title:`139 VOGUE EYEWEAR Green Square Rimmed Eyeglasses for Women`,
    rate:`₹4890`,
    content: [  
      `The Vogue Eyewear Green Square Rimmed Eyeglasses for Women offer a contemporary twist on a classic design. The square shape provides a modern and bold look, perfect for making a statement. The vibrant green frames add a pop of color and personality, making these eyeglasses a fashionable accessory for any occasion. Made from high-quality materials, they ensure durability and comfort. These eyeglasses are ideal for women who want to blend style with functionality, enhancing their look while offering clear vision.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-teal-500`,
    wear:`Vogue EyeWear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://res.cloudinary.com/dyyjph6kx/image/upload/gift_vouchers/phpL6vfkR_rolmid.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fgreen-square-rimmed-eyeglasses-for-women-from-vogue-eyewear-fvo427454851`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO427454851_1_lar.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO427454851_3_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO427454851_6_lar.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Modern Square Design`,
    paragraph:`The Vogue Eyewear Green Square Rimmed Eyeglasses feature a bold square shape that adds a contemporary edge to your look. This design is perfect for making a statement and provides a modern twist on traditional eyewear styles.`,
    heading1:`Vibrant Green Frames`,
    paragraph1:`The eyeglasses come with striking green frames that add a pop of color to your ensemble. The vibrant hue is eye-catching and fun, allowing you to express your personality through your accessories.`,
    heading2:`High-Quality Materials`,
    paragraph2:`Crafted from premium materials, these eyeglasses are built to last. The sturdy construction ensures that they can withstand daily wear and tear while maintaining their stylish appearance.`,
    heading3:`Comfortable Fit`,
    paragraph3:`Designed with the wearer’s comfort in mind, these eyeglasses provide a secure and comfortable fit. The lightweight frames ensure they sit comfortably on your face without causing pressure or discomfort, even during prolonged wear.`,
    heading4:`Versatile Fashion Accessory`,
    paragraph4:`These green square rimmed eyeglasses are versatile enough to complement a variety of outfits, from casual daywear to more formal attire. Their unique color and shape make them a standout accessory that can enhance any look.`,
    keyword:`green square rimmed eyeglasses for women from vogue eyewear`,
    description:`The square shape provides a modern and bold look, perfect for making a statement. The vibrant green frames add a pop of color and personality, making these eyeglasses a fashionable accessory for any occasion. Made from high-quality materials, they ensure durability and comfort. These eyeglasses are ideal for women who want to blend style with functionality, enhancing their look while offering clear vision.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  



  {
    name:`Gold-CatEye-Rimmed-Eyeglasses-for-Women`,
    title:`138 VOGUE EYEWEAR Gold CatEye Rimmed Eyeglasses for Women`,
    rate:`₹5790`,
    content: [  
      `The Vogue Eyewear Gold Cat-Eye Rimmed Eyeglasses for Women blend timeless elegance with a contemporary twist. The cat-eye shape is designed to flatter the face and add a sophisticated touch to any outfit. The luxurious gold rims add a hint of glamour, making these glasses perfect for both professional and casual settings. Crafted from high-quality materials, they ensure durability and comfort for all-day wear. These eyeglasses are a chic and functional accessory for fashion-forward women who appreciate both style and substance.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-500`,
    wear:`Vogue EyeWear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://res.cloudinary.com/dyyjph6kx/image/upload/gift_vouchers/phpL6vfkR_rolmid.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fgold-cateye-rimmed-eyeglasses-for-women-from-vogue-fvo428584853`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO428584853_1_lar.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO428584853_3_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO428584853_7_lar.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Timeless Cat-Eye Design`,
    paragraph:`The Vogue Eyewear Gold Cat-Eye Rimmed Eyeglasses feature a classic cat-eye shape that adds a touch of vintage glamour to any look. This flattering design highlights the eyes and enhances facial features, making it a perfect choice for women seeking a sophisticated and stylish accessory.`,
    heading1:`Luxurious Gold Rims`,
    paragraph1:`The gold rims of these eyeglasses add a luxurious and elegant touch, making them stand out. The metallic finish is both subtle and striking, offering a versatile look that can transition seamlessly from professional settings to casual outings.`,
    heading2:`High-Quality Craftsmanship`,
    paragraph2:`Made from premium materials, these eyeglasses are designed to last. The high-quality construction ensures that the frames are sturdy and durable, capable of withstanding daily wear and tear while maintaining their elegant appearance.`,
    heading3:`Comfortable All-Day Wear`,
    paragraph3:`These eyeglasses are designed with comfort in mind. The lightweight frames ensure they sit comfortably on your face without causing any pressure or discomfort, making them suitable for all-day wear. The ergonomic design ensures a secure fit that stays in place.`,
    heading4:`Versatile Fashion Accessory`,
    paragraph4:`The Vogue Eyewear Gold Cat-Eye Rimmed Eyeglasses are versatile enough to complement a wide range of outfits. Whether you're dressing up for a special occasion or going for a casual look, these glasses add a touch of elegance and sophistication to any ensemble.`,
    keyword:`gold cateye rimmed eyeglasses for women from vogue fvo428584853`,
    description:`Featuring a striking cat-eye shape, these eyeglasses are designed to accentuate the wearer's eyes and add a touch of sophistication to any outfit. The vibrant blue frame adds a pop of color, making these glasses a stylish accessory for any occasion. Crafted from high-quality materials, they promise durability and comfort for long-lasting wear. Perfect for fashion-forward women, these eyeglasses blend functionality with chic style, offering a unique and trendy addition to your eyewear collection.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  


  {
    name:`Blue-CatEye-Eyeglasses-For-Women`,
    title:`137 VOGUE EYEWEAR Blue CatEye Eyeglasses For Women`,
    rate:`₹4,390`,
    content: [  
      `The Vogue Eyewear Blue Cat-Eye Eyeglasses for Women combine playful elegance with a retro flair. Featuring a striking cat-eye shape, these eyeglasses are designed to accentuate the wearer's eyes and add a touch of sophistication to any outfit. The vibrant blue frame adds a pop of color, making these glasses a stylish accessory for any occasion. Crafted from high-quality materials, they promise durability and comfort for long-lasting wear. Perfect for fashion-forward women, these eyeglasses blend functionality with chic style, offering a unique and trendy addition to your eyewear collection.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-pink-700`,
    wear:`Vogue EyeWear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://res.cloudinary.com/dyyjph6kx/image/upload/gift_vouchers/phpL6vfkR_rolmid.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fblue-cateye-rimmed-eyeglasses-for-women-from-vogue-eyewear-fvo5398276151`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/f/v/fvo5398276151_1_lar.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/f/v/fvo5398276151_2_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/f/v/fvo5398276151_4_lar.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Cat-Eye Design`,
    paragraph:`The Vogue Eyewear Blue Cat-Eye Eyeglasses for Women feature an elegant cat-eye shape that exudes a retro-inspired charm. This design highlights and accentuates the eyes, adding a touch of vintage glamour to any look.`,
    heading1:`Vibrant Blue Frame`,
    paragraph1:`The striking blue frame adds a playful yet sophisticated touch to these eyeglasses. The vibrant color is eye-catching and fashionable, making it a perfect accessory for those who love to make a statement with their eyewear.`,
    heading2:`High-Quality Materials`,
    paragraph2:`These eyeglasses are crafted from high-quality materials, ensuring durability and long-lasting use. The robust frame is designed to withstand daily wear and tear, offering reliable performance and style.`,
    heading3:`Comfortable Fit`,
    paragraph3:`Designed with the wearer’s comfort in mind, these eyeglasses provide a secure and comfortable fit. The lightweight construction ensures they sit comfortably on your face without causing pressure or discomfort, even during extended wear.`,
    heading4:`Versatile Fashion`,
    paragraph4:`The Vogue Eyewear Blue Cat-Eye Eyeglasses are versatile enough to complement various outfits, from casual daywear to sophisticated evening attire. Their unique design makes them suitable for multiple occasions, adding a chic touch to any ensemble.`,
    keyword:`blue cateye rimmed eyeglasses for women from vogue eyewear fvo5398276151`,
    description:`Featuring a striking cat-eye shape, these eyeglasses are designed to accentuate the wearer's eyes and add a touch of sophistication to any outfit. The vibrant blue frame adds a pop of color, making these glasses a stylish accessory for any occasion. Crafted from high-quality materials, they promise durability and comfort for long-lasting wear. Perfect for fashion-forward women, these eyeglasses blend functionality with chic style, offering a unique and trendy addition to your eyewear collection.`,
    summary:`https://static5.lenskart.com/media/uploads/Desktopnew_1505.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyeglasses%2Fcollections%2Fflexible-eyeglasses-by-john-jacobs.html`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  {
    name:`Gold-Rectangle-Semi-Rimmed-Eyeglasses`,
    title:`136 TITAN Gold Rectangle Semi-Rimmed Eyeglasses`,
    rate:'₹750',
    content: [  
      `The Titan Gold Rectangle Semi-Rimmed Eyeglasses combine elegance with modern design, offering a sleek and sophisticated look. The semi-rimmed frame provides a lightweight feel, ensuring comfort for all-day wear. The gold color adds a touch of luxury, making these glasses suitable for both professional and casual settings. The rectangle shape flatters various face shapes, providing a versatile and stylish option for men and women alike. Crafted from high-quality materials, these eyeglasses promise durability and long-lasting wear, making them a perfect blend of style and functionality.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-700`,
    wear:`Unisex`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://res.cloudinary.com/dyyjph6kx/image/upload/gift_vouchers/phpL6vfkR_rolmid.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fgold-rectangle-semi-rimmed-eyeglasses-from-titan-tw1122whm1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/t/w/tw1122whm1_3_lar.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/t/w/tw1122whm1_1_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/t/w/tw1122whm1v_9_lar.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The Titan Gold Rectangle Semi-Rimmed Eyeglasses feature a sophisticated and elegant design. The gold finish adds a touch of luxury, making them a stylish accessory for any outfit, whether professional or casual.`,
    heading1:`Semi-Rimmed Frame`,
    paragraph1:`The semi-rimmed frame offers a modern look while reducing the weight of the eyeglasses. This design provides a sleek appearance and ensures a comfortable fit, making them ideal for extended wear.`,
    heading2:`Rectangle Shape`,
    paragraph2:`The classic rectangle shape of these eyeglasses flatters various face shapes. It offers a balanced look that is both contemporary and timeless, suitable for both men and women.`,
    heading3:`Durable Construction`,
    paragraph3:`Made from high-quality materials, the Titan eyeglasses are built to last. The sturdy frame and reliable construction ensure that these glasses can withstand daily wear and tear, offering long-lasting use.`,
    heading4:`Comfortable Fit`,
    paragraph4:`Designed with comfort in mind, these eyeglasses provide a secure and comfortable fit. The lightweight frame reduces pressure on the nose and ears, allowing for prolonged use without discomfort.`,
    keyword:`gold rectangle semi rimmed eyeglasses from titan tw1122whm1`,
    description:`The semi-rimmed frame provides a lightweight feel, ensuring comfort for all-day wear. The gold color adds a touch of luxury, making these glasses suitable for both professional and casual settings. The rectangle shape flatters various face shapes, providing a versatile and stylish option for men and women alike. Crafted from high-quality materials, these eyeglasses promise durability and long-lasting wear, making them a perfect blend of style and functionality.`,
    summary:`https://static5.lenskart.com/media/uploads/Web_Banner_eyesun-1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fcheer-for-your-team`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  {
    name:`Blue-Rectangle-Unisex-Eyeglasses`,
    title:`135 TITAN Blue Rectangle Unisex Eyeglasses (FT1275UFP4MBUV52)`,
    rate:'₹750',
    content: [  
      `The Fastrack Blue Rectangle Unisex Eyeglasses (FT1275UFP4MBUV|52) offer a blend of style and practicality. Featuring a modern rectangle shape, these eyeglasses are designed to suit both men and women. The blue frame adds a touch of color while remaining versatile enough for everyday wear. Made with durable materials, they ensure long-lasting use and comfort. The lightweight design and comfortable fit make them perfect for extended wear. Ideal for both professional and casual settings, these eyeglasses combine fashion and function, making them a valuable addition to your eyewear collection.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-700`,
    wear:`Unisex`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://res.cloudinary.com/dyyjph6kx/image/upload/gift_vouchers/phpL6vfkR_rolmid.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fblue-rectangle-rimmed-eyeglasses-from-fastrack-ft1275ufp4mbuv`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/T/FT1275UFP4MBUV_1_lar_1.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/T/FT1275UFP4MBUV_3_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/T/FT1275UFP4MBUV_6_lar.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Stylish Design`,
    paragraph:`The Fastrack Blue Rectangle Unisex Eyeglasses feature a sleek and modern rectangle design that complements various face shapes. The bold blue color adds a fashionable touch, making these glasses stand out.`,
    heading1:`Unisex Appeal`,
    paragraph1:`Designed to be worn by both men and women, these eyeglasses offer a versatile style that fits seamlessly into any wardrobe. The unisex design ensures that they are suitable for anyone looking for a stylish pair of glasses.`,
    heading2:`Durable Construction`,
    paragraph2:`Crafted from high-quality materials, these eyeglasses are built to last. The sturdy frame ensures durability, making them a reliable choice for everyday wear.`,
    heading3:`Lightweight and Comfortable`,
    paragraph3:`The lightweight construction of the Fastrack eyeglasses ensures that they are comfortable to wear for extended periods. The ergonomic design reduces pressure on the nose and ears, providing all-day comfort.`,
    heading4:`Versatile Use`,
    paragraph4:`Whether for work, school, or casual outings, these eyeglasses are versatile enough to be worn in various settings. Their stylish design makes them suitable for professional environments as well as casual occasions.`,
    keyword:`blue rectangle rimmed eyeglasses from fastrack ft1275ufp4mbuv`,
    description:`These sunglasses feature a timeless wayfarer design that suits various face shapes and outfits. The black frame is both sleek and versatile, making it ideal for any occasion. Equipped with high-quality lenses, they offer excellent UV protection to safeguard your eyes from harmful rays. The sturdy construction ensures long-lasting wear, while the comfortable fit makes them perfect for daily use. Whether you're driving, walking, or enjoying outdoor activities, these sunglasses provide style and protection in one stylish package.`,
    summary:`https://static5.lenskart.com/media/uploads/Web_Banner_eyesun-1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fcheer-for-your-team`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  {
    name:`Black-Wayfarer-Men-Sunglasses`,
    title:`134 FASTRACK Black Wayfarer Men Sunglasses (P357BK141)`,
    rate:'Rs.899',
    content: [  
      `The Fastrack Black Wayfarer Men Sunglasses (P357BK1|41) combine classic style with modern durability. These sunglasses feature a timeless wayfarer design that suits various face shapes and outfits. The black frame is both sleek and versatile, making it ideal for any occasion. Equipped with high-quality lenses, they offer excellent UV protection to safeguard your eyes from harmful rays. The sturdy construction ensures long-lasting wear, while the comfortable fit makes them perfect for daily use. Whether you're driving, walking, or enjoying outdoor activities, these sunglasses provide style and protection in one stylish package.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-700`,
    wear:`Men's Wear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://play-lh.googleusercontent.com/aIWUhKudaVkYa--8NH7OW7JeyVCV47Xdpe4bj468avIVsMnr7yG-RpbvRaOYj4osENg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fcheer-for-your-team`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/p/3/p357bk1_1_lar.jpg`,
    image:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/p/3/p357bk1_7_lar.jpg`,
    image1:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/p/3/p357bk1_3_lar.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Timeless Wayfarer Design`,
    paragraph:`The Fastrack Black Wayfarer Men Sunglasses feature a classic wayfarer design that never goes out of style. This iconic shape suits various face types, making it a versatile choice for many.`,
    heading1:`Sleek Black Frame`,
    paragraph1:`The sleek black frame of these sunglasses adds a touch of sophistication and versatility. It pairs well with any outfit, whether casual or formal, making these sunglasses a reliable accessory for any occasion.`,
    heading2:`Excellent UV Protection`,
    paragraph2:`Equipped with high-quality lenses, these sunglasses provide excellent UV protection, safeguarding your eyes from harmful UVA and UVB rays. This feature is essential for maintaining eye health during outdoor activities.`,
    heading3:`Durable Construction`,
    paragraph3:`Made from robust materials, the Fastrack Wayfarer Sunglasses are built to last. The durable frame and lenses can withstand daily wear and tear, ensuring you get long-lasting use out of your purchase.`,
    heading4:`Comfortable Fit`,
    paragraph4:`Designed with comfort in mind, these sunglasses feature a comfortable fit that is perfect for extended wear. The lightweight frame ensures they sit comfortably on your face without causing pressure or discomfort.`,
    keyword:`wayfarer rimmed sunglasses from fastrack p357bk1`,
    description:`These sunglasses feature a timeless wayfarer design that suits various face shapes and outfits. The black frame is both sleek and versatile, making it ideal for any occasion. Equipped with high-quality lenses, they offer excellent UV protection to safeguard your eyes from harmful rays. The sturdy construction ensures long-lasting wear, while the comfortable fit makes them perfect for daily use. Whether you're driving, walking, or enjoying outdoor activities, these sunglasses provide style and protection in one stylish package.`,
    summary:`https://static5.lenskart.com/media/uploads/Web_Banner_eyesun-1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fcheer-for-your-team`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  {
    name:`PERSONALISED-SUNGLASS-CASE-Beige`,
    title:`133 THE BLACK BOX CO. PERSONALISED SUNGLASS CASE - Beige`,
    rate:'Rs.650',
    content: [  
      `The Black Box Co. Personalised Sunglass Case in Beige is a chic and practical accessory designed to protect your sunglasses. Crafted from durable materials, this case features a sleek beige exterior that exudes sophistication. It offers personalized options, allowing you to add initials or a name for a unique touch. The soft interior lining ensures your sunglasses are protected from scratches and damage. Compact and lightweight, it’s perfect for carrying in your bag or pocket. Combining style and functionality, this sunglass case is ideal for everyday use and makes a thoughtful gift.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-400`,
    wear:`Unisex Item`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcnVX2IiXUAd_9rHRKh9y5T7Xvp4oV7gLWzEnWSIPYMPhK7nOlSZ7SJaUc2dpZ96idJgQ&usqp=CAU`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.theblackboxco.com%2Fcollections%2Ftravel%2Fproducts%2Fpersonalised-sunglass-case-beige`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.theblackboxco.com/cdn/shop/files/TheBlackBoxCo_2024_209_b8f9432c-7654-4b98-ba3f-d4f9ad2e4eb8.jpg?v=1713283507&width=1946`,
    image:`https://www.theblackboxco.com/cdn/shop/files/TheBlackBoxCo_2024_211_3eb5bd85-f0af-4431-894c-67e372c05d08.jpg?v=1713283507&width=1946`,
    image1:`https://www.theblackboxco.com/cdn/shop/files/TheBlackBoxCo_2024_394.jpg?v=1713388399&width=1946`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The Black Box Co. Personalised Sunglass Case in Beige features a minimalist yet sophisticated design. The neutral beige color is versatile and complements a wide range of styles, making it a fashionable accessory for both men and women.`,
    heading1:`Personalization Option`,
    paragraph1:`This sunglass case stands out with its personalization feature. You can add your initials or name to the case, giving it a unique and personal touch. This makes it a perfect gift option for friends and family, adding a thoughtful element to a practical item.`,
    heading2:`Durable Material`,
    paragraph2:`Made from high-quality materials, the sunglass case is designed to be durable and long-lasting. It protects your sunglasses from scratches, dust, and minor impacts, ensuring your eyewear stays in excellent condition`,
    heading3:`Compact and Portable`,
    paragraph3:`The compact size of the case makes it easy to carry in your bag, purse, or pocket. It provides convenient storage for your sunglasses, ensuring they are always protected and within reach, whether you're at work, traveling, or on the go.`,
    heading4:`Secure Closure`,
    paragraph4:`The case features a secure closure mechanism that keeps your sunglasses safely enclosed. This ensures that your eyewear won't slip out or get damaged, providing peace of mind when carrying your sunglasses.`,
    keyword:`Customize Gift Sunglass case`,
    description:`Crafted from durable materials, this case features a sleek beige exterior that exudes sophistication. It offers personalized options, allowing you to add initials or a name for a unique touch. The soft interior lining ensures your sunglasses are protected from scratches and damage. Compact and lightweight, it’s perfect for carrying in your bag or pocket. Combining style and functionality, this sunglass case is ideal for everyday use and makes a thoughtful gift.`,
    summary:`https://static5.lenskart.com/media/uploads/Web_Banner_eyesun-1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fcheer-for-your-team`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
  {
    name:`PERSONALISED-WEEKENDER-BAG-BIEGE`,
    title:`132 THE BLACK BOX CO. PERSONALISED WEEKENDER BAG - BIEGE`,
    rate:'Rs.3,500',
    content: [  
      `The Black Box Co. Personalised Weekender Bag in Beige is a stylish and functional travel accessory designed for modern travelers. This versatile bag features a spacious interior, perfect for weekend getaways or short trips. Made from durable materials, it boasts a sleek beige finish that exudes sophistication. The bag can be personalized, adding a unique touch to your travel gear. With sturdy handles and an adjustable shoulder strap, it offers comfort and convenience. Multiple pockets ensure easy organization of your essentials. Combining elegance and practicality, this weekender bag is a must-have for those who appreciate both style and functionality.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-400`,
    wear:`Girl's Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcnVX2IiXUAd_9rHRKh9y5T7Xvp4oV7gLWzEnWSIPYMPhK7nOlSZ7SJaUc2dpZ96idJgQ&usqp=CAU`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.theblackboxco.com%2Fcollections%2Ftravel%2Fproducts%2Fpersonalised-weekender-bag-biege`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.theblackboxco.com/cdn/shop/files/TheBlackBoxCo_2024_389.jpg?v=1713388399&width=1946`,
    image:`https://www.theblackboxco.com/cdn/shop/files/TheBlackBoxCo_2024_391.jpg?v=1713388399&width=1946`,
    image1:`https://www.theblackboxco.com/cdn/shop/files/TheBlackBoxCo_2024_394.jpg?v=1713388399&width=1946`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The Black Box Co. Personalised Weekender Bag in Beige features a sophisticated and stylish design. The neutral beige color is versatile, complementing a wide range of outfits and suitable for various occasions, from casual weekends to business trips.`,
    heading1:`Personalization Option`,
    paragraph1:`One of the standout features of this bag is the ability to personalize it. Adding your initials or name makes the bag uniquely yours, enhancing its appeal and making it an excellent gift for friends and family.`,
    heading2:`Spacious Interior`,
    paragraph2:`Designed for practicality, the weekender bag offers a spacious interior that can comfortably hold all your essentials for a short trip. It's ideal for packing clothes, toiletries, gadgets, and more, ensuring you have everything you need in one place.`,
    heading3:`Durable Materials`,
    paragraph3:`Crafted from high-quality materials, this bag is built to last. The durable fabric withstands the rigors of travel, while the reinforced stitching and sturdy zippers add to its longevity, making it a reliable travel companion.`,
    heading4:`Comfortable Carrying Options`,
    paragraph4:`The bag features sturdy handles for hand-carrying and an adjustable shoulder strap for added convenience. The padded strap ensures comfort, even when the bag is fully packed, allowing for versatile carrying options.`,
    keyword:`personalised weekender bag biege`,
    description:`This versatile bag features a spacious interior, perfect for weekend getaways or short trips. Made from durable materials, it boasts a sleek beige finish that exudes sophistication. The bag can be personalized, adding a unique touch to your travel gear. With sturdy handles and an adjustable shoulder strap, it offers comfort and convenience. Multiple pockets ensure easy organization of your essentials. Combining elegance and practicality, this weekender bag is a must-have for those who appreciate both style and functionality.`,
    summary:`https://images.bestsellerclothing.in/live/image/catalog/brandstore/bestseller/banners/ol_joyride_CLP_web_13022024.gif`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.only.in%2Fnew-arrivals%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3D-21015602847%26utm_term%3D-%26utm_content%3D%26utm_device%3Dc%26utm_placement%3D%26gad_source%3D1%26gclid%3DCj0KCQjwgJyyBhCGARIsAK8LVLNqqInzk7iYXU9SUUuRuJl500odFnefm5loBwvu2UJ_IspJM61Y7GMaAsZ4EALw_wcB`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  {
    name:`Women-Heart-Charm-Wallet`,
    title:`131 Accessorize London Women'S Heart Charm Wallet`,
    rate:'Rs.999',
    content: [  
      `The Accessorize London Women's Heart Charm Wallet is a charming and functional accessory designed to keep your essentials organized in style. Crafted from high-quality materials, this wallet features a delightful heart charm that adds a touch of elegance and femininity. Its compact design includes multiple card slots, a coin pocket, and a section for cash, making it perfect for everyday use. The wallet's secure zip-around closure ensures your belongings are safe and easily accessible. With its stylish design and practical features, the Heart Charm Wallet is a must-have for fashion-forward women.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-orange-300`,
    wear:`Girl's Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJqJW0bRs2PoAo03grw6dUMlRijz5khdQ8pzMHAEhf-g&s`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Faccessorizelondon.in%2Fproducts%2Faccessorize-london-womens-heart-charm-wallet`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://accessorizelondon.in/cdn/shop/products/MN-98908215001_1.jpg?v=1668165805&width=3000`,
    image:`https://accessorizelondon.in/cdn/shop/products/MN-98908215001_2.jpg?v=1668165805&width=3000`,
    image1:`https://accessorizelondon.in/cdn/shop/files/MN-98908215001_3.jpg?v=1687155418&width=3000`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Charming Design`,
    paragraph:`The Heart Charm Wallet features a delightful heart charm that adds a whimsical and feminine touch. This charming detail makes the wallet not just functional but also a stylish accessory.`,
    heading1:`High-Quality Materials`,
    paragraph1:`Crafted from premium materials, this wallet is designed to be durable and long-lasting. The quality construction ensures that it can withstand daily use while maintaining its elegant appearance.`,
    heading2:`Compact and Functional`,
    paragraph2:`Despite its compact size, the wallet offers ample storage space. It includes multiple card slots, a coin pocket, and a section for cash, allowing you to keep all your essentials neatly organized.`,
    heading3:`Secure Zip-Around Closure`,
    paragraph3:`The wallet features a secure zip-around closure, which ensures that your belongings are safe and protected. The zipper is smooth and durable, adding to the overall convenience of the wallet.`,
    heading4:`Versatile Use`,
    paragraph4:`This wallet is perfect for various occasions, whether you're heading to work, running errands, or going out for the evening. Its stylish design makes it a versatile accessory that complements any outfit.`,
    keyword:`Women Heart Charm Wallet`,
    description:`Crafted from high-quality materials, this wallet features a delightful heart charm that adds a touch of elegance and femininity. Its compact design includes multiple card slots, a coin pocket, and a section for cash, making it perfect for everyday use. The wallet's secure zip-around closure ensures your belongings are safe and easily accessible. With its stylish design and practical features, the Heart Charm Wallet is a must-have for fashion-forward women.`,
    summary:`https://images.bestsellerclothing.in/live/image/catalog/brandstore/bestseller/banners/ol_joyride_CLP_web_13022024.gif`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.only.in%2Fnew-arrivals%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3D-21015602847%26utm_term%3D-%26utm_content%3D%26utm_device%3Dc%26utm_placement%3D%26gad_source%3D1%26gclid%3DCj0KCQjwgJyyBhCGARIsAK8LVLNqqInzk7iYXU9SUUuRuJl500odFnefm5loBwvu2UJ_IspJM61Y7GMaAsZ4EALw_wcB`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`Fabric Pink Kensington Check Sling Bag`,
    title:`130 Accessorize London Women's Fabric Pink Kensington Check Sling Bag`,
    rate:'Rs.1,199',
    content: [  
      `The Accessorize London Women's Fabric Pink Kensington Check Sling Bag is a stylish and versatile accessory, perfect for adding a pop of color to any outfit. Crafted from high-quality fabric, this bag features a charming pink check pattern that exudes a playful yet sophisticated vibe. Its compact design offers enough space to carry essentials like your phone, wallet, and keys. The adjustable strap allows for comfortable, hands-free wear, making it ideal for both casual outings and more formal occasions. The Kensington Sling Bag is both fashionable and practical, making it a must-have addition to your accessory collection.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-500`,
    wear:`Girl's Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJqJW0bRs2PoAo03grw6dUMlRijz5khdQ8pzMHAEhf-g&s`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Faccessorizelondon.in%2Fproducts%2Faccessorize-london-womens-faux-leather-creamaccessorize-london-womens-cream-magda-oversized-shoulder-bag`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://accessorizelondon.in/cdn/shop/products/MA-49006601001_1.jpg?v=1681111159&width=3000`,
    image:`https://accessorizelondon.in/cdn/shop/products/MA-49006601001_2.jpg?v=1681111159&width=3000`,
    image1:`https://accessorizelondon.in/cdn/shop/products/MA-49006601001_7.jpg?v=1681111159&width=3000`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Charming Design`,
    paragraph:`The Kensington Check Sling Bag features an eye-catching pink check pattern that adds a playful and stylish touch to any outfit. Its design is both trendy and timeless, making it a versatile accessory.`,
    heading1:`High-Quality Fabric`,
    paragraph1:`Made from high-quality fabric, this bag is durable and easy to maintain. The fabric ensures the bag is lightweight while providing a soft, comfortable feel.`,
    heading2:`Compact and Practical`,
    paragraph2:`Despite its compact size, the Kensington Sling Bag offers ample space to carry your daily essentials, such as your phone, wallet, keys, and makeup. Its practical design ensures you can keep your belongings organized`,
    heading3:`Adjustable Strap`,
    paragraph3:`The adjustable strap allows for customized wear, whether you prefer to sling it over your shoulder or wear it crossbody. This versatility makes it comfortable for all-day use.`,
    heading4:`Versatile Use`,
    paragraph4:`Ideal for various occasions, the Kensington Sling Bag is perfect for casual outings, shopping trips, or even more formal events. Its stylish design complements both casual and dressy ensembles.`,
    keyword:`Kensington Check Sling Bag`,
    description:`Crafted from high-quality fabric, this bag features a charming pink check pattern that exudes a playful yet sophisticated vibe. Its compact design offers enough space to carry essentials like your phone, wallet, and keys. The adjustable strap allows for comfortable, hands-free wear, making it ideal for both casual outings and more formal occasions. The Kensington Sling Bag is both fashionable and practical, making it a must-have addition to your accessory collection.`,
    summary:`https://images.bestsellerclothing.in/live/image/catalog/brandstore/bestseller/banners/ol_joyride_CLP_web_13022024.gif`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.only.in%2Fnew-arrivals%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3D-21015602847%26utm_term%3D-%26utm_content%3D%26utm_device%3Dc%26utm_placement%3D%26gad_source%3D1%26gclid%3DCj0KCQjwgJyyBhCGARIsAK8LVLNqqInzk7iYXU9SUUuRuJl500odFnefm5loBwvu2UJ_IspJM61Y7GMaAsZ4EALw_wcB`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Faux-Leather-Pink-Chryssa-Quilted-Sling-Bag`,
    title:`129 Accessorize London women's Cream Magda Oversized Shoulder Bag`,
    rate:'Rs.2,449',
    content: [  
      `The Accessorize London Women's Cream Magda Oversized Shoulder Bag is a perfect blend of elegance and functionality. This oversized shoulder bag, crafted from high-quality materials, offers ample space for all your essentials while maintaining a chic and stylish appearance. The creamy hue adds a touch of sophistication, making it versatile for various outfits and occasions. Featuring sturdy handles and a spacious interior, it is ideal for both work and leisure. The bag's durable construction ensures longevity, while its fashionable design makes it a standout accessory. Whether you're heading to the office or out for a weekend getaway, the Magda Shoulder Bag is your go-to for effortless style and convenience.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-orange-300`,
    wear:`Girl's Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJqJW0bRs2PoAo03grw6dUMlRijz5khdQ8pzMHAEhf-g&s`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Faccessorizelondon.in%2Fproducts%2Faccessorize-london-womens-faux-leather-creamaccessorize-london-womens-cream-magda-oversized-shoulder-bag`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://accessorizelondon.in/cdn/shop/products/MN-89031715001_1.jpg?v=1662113975&width=3000`,
    image:`https://accessorizelondon.in/cdn/shop/products/MN-89031715001_3.jpg?v=1662113975&width=3000`,
    image1:`https://accessorizelondon.in/cdn/shop/files/MN-89031715001_5_df76617e-f631-49c4-97fb-0b052b2ebc1f.jpg?v=1686721974&width=3000`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The Accessorize London Cream Magda Oversized Shoulder Bag boasts an elegant design that seamlessly combines style and practicality. The creamy hue adds a touch of sophistication, making it suitable for various occasions.`,
    heading1:`High-Quality Materials`,
    paragraph1:`Crafted from premium materials, this shoulder bag offers durability and a luxurious feel. The high-quality construction ensures that it withstands daily wear and tear while maintaining its chic appearance.`,
    heading2:`Ample Space`,
    paragraph2:`The oversized design provides ample space to carry all your essentials, from your laptop and documents to your personal items. It's perfect for those who need a stylish yet functional bag for work or travel.`,
    heading3:`Sturdy Handles`,
    paragraph3:`Featuring sturdy handles, the Magda Shoulder Bag is designed for comfortable carrying. The handles are reinforced to support the weight of the bag's contents, ensuring you can carry it with ease.`,
    heading4:`Versatile Use`,
    paragraph4:`This bag's versatile design makes it ideal for various settings, whether you're heading to the office, going shopping, or traveling. Its sophisticated look complements both casual and formal outfits.`,
    keyword:`womens cream magda oversized shoulder bag`,
    description:`Crafted from high-quality faux leather, this bag features a charming pink hue and a stylish quilted design that exudes sophistication. The adjustable sling strap offers versatile carrying options, while the compact yet spacious interior ensures your essentials are always within reach. Ideal for both casual outings and special occasions, this sling bag seamlessly blends functionality with style. Elevate your accessory game with the Accessorize London Chryssa Quilted Sling Bag and enjoy the perfect combination of practicality and fashion-forward design.`,
    summary:`https://images.bestsellerclothing.in/live/image/catalog/brandstore/bestseller/banners/ol_joyride_CLP_web_13022024.gif`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.only.in%2Fnew-arrivals%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3D-21015602847%26utm_term%3D-%26utm_content%3D%26utm_device%3Dc%26utm_placement%3D%26gad_source%3D1%26gclid%3DCj0KCQjwgJyyBhCGARIsAK8LVLNqqInzk7iYXU9SUUuRuJl500odFnefm5loBwvu2UJ_IspJM61Y7GMaAsZ4EALw_wcB`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`Faux-Leather-Pink-Chryssa-Quilted-Sling-Bag`,
    title:`128 Accessorize London Women's Faux Leather Pink Chryssa Quilted Sling Bag`,
    rate:'Rs.2,449',
    content: [  
      `The Accessorize London Women's Cream Magda Oversized Shoulder Bag is a perfect blend of elegance and functionality. This oversized shoulder bag, crafted from high-quality materials, offers ample space for all your essentials while maintaining a chic and stylish appearance. The creamy hue adds a touch of sophistication, making it versatile for various outfits and occasions. Featuring sturdy handles and a spacious interior, it is ideal for both work and leisure. The bag's durable construction ensures longevity, while its fashionable design makes it a standout accessory. Whether you're heading to the office or out for a weekend getaway, the Magda Shoulder Bag is your go-to for effortless style and convenience.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-300`,
    wear:`Girl's Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJqJW0bRs2PoAo03grw6dUMlRijz5khdQ8pzMHAEhf-g&s`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Faccessorizelondon.in%2Fproducts%2Faccessorize-london-womens-faux-leather-pink-chryssa-quilted-sling-bag`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://accessorizelondon.in/cdn/shop/products/MA-49000070001_1.jpg?v=1681288843&width=3000`,
    image:`https://accessorizelondon.in/cdn/shop/products/MA-49000070001_2.jpg?v=1681288843&width=3000`,
    image1:`https://accessorizelondon.in/cdn/shop/products/MA-49000070001_7.jpg?v=1681288843&width=3000`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Chic Design`,
    paragraph:`The Accessorize London Chryssa Sling Bag boasts a chic quilted pattern, adding an element of sophistication to your ensemble, making it perfect for both casual and formal occasions.`,
    heading1:`High-Quality Faux Leather`,
    paragraph1:`Crafted from premium faux leather, the bag provides a luxurious look and feel, ensuring you maintain a cruelty-free fashion statement without compromising on style.`,
    heading2:`Soft Pink Hue`,
    paragraph2:`The delicate pink color of the Chryssa Sling Bag adds a subtle yet eye-catching pop of color to your wardrobe, making it an ideal accessory for various outfits.`,
    heading3:`Versatile Sling Strap`,
    paragraph3:`The adjustable sling strap offers versatility in wear, allowing you to comfortably carry the bag over your shoulder or across your body for a hands-free experience.`,
    heading4:`Compact and Functional`,
    paragraph4:`Despite its compact size, the Chryssa Sling Bag is designed to hold all your essentials, including your phone, wallet, and keys, making it both practical and stylish.`,
    keyword:`Leather Pink Chryssa Quilted Sling Bag`,
    description:`Crafted from high-quality faux leather, this bag features a charming pink hue and a stylish quilted design that exudes sophistication. The adjustable sling strap offers versatile carrying options, while the compact yet spacious interior ensures your essentials are always within reach. Ideal for both casual outings and special occasions, this sling bag seamlessly blends functionality with style. Elevate your accessory game with the Accessorize London Chryssa Quilted Sling Bag and enjoy the perfect combination of practicality and fashion-forward design.`,
    summary:`https://images.bestsellerclothing.in/live/image/catalog/brandstore/bestseller/banners/ol_joyride_CLP_web_13022024.gif`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.only.in%2Fnew-arrivals%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3D-21015602847%26utm_term%3D-%26utm_content%3D%26utm_device%3Dc%26utm_placement%3D%26gad_source%3D1%26gclid%3DCj0KCQjwgJyyBhCGARIsAK8LVLNqqInzk7iYXU9SUUuRuJl500odFnefm5loBwvu2UJ_IspJM61Y7GMaAsZ4EALw_wcB`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`Accessorize-London-Faux-Leather-Black-Woven-Chain-Sling-Bag`,
    title:`127 Accessorize London Women's Faux Leather Black Quinn Quilted Woven Chain Sling Bag`,
    rate:'Rs.1,999',
    content: [  
      `The Accessorize London Women's Faux Leather Black Quinn Quilted Woven Chain Sling Bag is a stylish and versatile accessory perfect for any wardrobe. Made from high-quality faux leather, this sling bag features a chic quilted design that adds texture and sophistication. The woven chain strap adds a touch of elegance and allows for comfortable, hands-free wear. With a compact yet spacious interior, it can hold all your essentials, making it ideal for both everyday use and special occasions. The classic black color ensures it matches any outfit, while the durable construction promises long-lasting use. Elevate your style with this fashionable and functional sling bag from Accessorize London.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Girl's Accessories`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJqJW0bRs2PoAo03grw6dUMlRijz5khdQ8pzMHAEhf-g&s`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Faccessorizelondon.in%2Fproducts%2Faccessorize-london-womens-faux-leather-black-quinn-quilted-woven-chain-sling-bag`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://accessorizelondon.in/cdn/shop/files/MN-19007003001_1_c775d267-d157-43df-b711-ba04a31a7278.webp?v=1697534462&width=3000`,
    image:`https://accessorizelondon.in/cdn/shop/products/MN-19007003001_5_1d44a0e4-9e11-456f-ae90-7616d8eaec56.jpg?v=1697534462&width=3000`,
    image1:`https://accessorizelondon.in/cdn/shop/products/MN-19007003001_2_fd17ad72-34f5-4764-9da1-c043a84d07a2.jpg?v=1697534462&width=3000`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The Accessorize London Quinn Sling Bag features a quilted pattern that exudes elegance and sophistication, perfect for elevating any outfit.`,
    heading1:`High-Quality Faux Leather`,
    paragraph1:`Crafted from premium faux leather, this bag offers a luxurious feel and look, without compromising on animal-friendly principles.`,
    heading2:`Woven Chain Strap.`,
    paragraph2:`The stylish woven chain strap adds a touch of glamour and allows for versatile wear, whether over the shoulder or across the body.`,
    heading3:`Compact and Spacious`,
    paragraph3:`Despite its compact size, the bag is designed to be spacious enough to hold your essentials, including your phone, wallet, and keys.`,
    heading4:`Classic Black Color`,
    paragraph4:`The timeless black color ensures this bag can be paired with any outfit, making it a versatile addition to your accessory collection.`,
    keyword:`Leather Black Woven Chain Sling-Bag`,
    description:`Made from high-quality faux leather, this sling bag features a chic quilted design that adds texture and sophistication. The woven chain strap adds a touch of elegance and allows for comfortable, hands-free wear. With a compact yet spacious interior, it can hold all your essentials, making it ideal for both everyday use and special occasions. The classic black color ensures it matches any outfit, while the durable construction promises long-lasting use. Elevate your style with this fashionable and functional sling bag from Accessorize London.`,
    summary:`https://images.bestsellerclothing.in/live/image/catalog/brandstore/bestseller/banners/ol_joyride_CLP_web_13022024.gif`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.only.in%2Fnew-arrivals%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3D-21015602847%26utm_term%3D-%26utm_content%3D%26utm_device%3Dc%26utm_placement%3D%26gad_source%3D1%26gclid%3DCj0KCQjwgJyyBhCGARIsAK8LVLNqqInzk7iYXU9SUUuRuJl500odFnefm5loBwvu2UJ_IspJM61Y7GMaAsZ4EALw_wcB`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`BLACK-CUT-OUT-JUMPSUIT`,
    title:`126 ONLY BLACK CUT OUT JUMPSUIT`,
    rate:'Rs.1,799',
    content: [  
      `The ONLY Black Cut Out Jumpsuit is a bold and stylish choice for any occasion. Crafted in classic black, this jumpsuit features daring cut-out details that add a touch of edge and allure to your look. The sleek silhouette and tailored fit flatter your figure, while the cut-out design adds a modern twist. Perfect for a night out or special event, this jumpsuit is sure to turn heads and make a statement. Pair it with heels for a sophisticated look or dress it down with flats for a more casual vibe. Embrace your inner fashionista with the ONLY Black Cut Out Jumpsuit for a chic and trendy ensemble..`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Women's Wear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F238804901-Black`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/04-apr-2022/238804901_g1.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/04-apr-2022/238804901_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/04-apr-2022/238804901_g4.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Bold and Stylish`,
    paragraph:`The ONLY Black Cut Out Jumpsuit is a bold and stylish choice that commands attention with its daring design..`,
    heading1:`Classic Black`,
    paragraph1:`Crafted in classic black, this jumpsuit offers timeless elegance while allowing the cut-out details to take center stage.`,
    heading2:`Daring Cut-Out Details`,
    paragraph2:`The cut-out details add an element of intrigue and allure, revealing just the right amount of skin for a chic and sophisticated look.`,
    heading3:`Sleek Silhouette`,
    paragraph3:`With its sleek silhouette and tailored fit, this jumpsuit flatters your figure and accentuates your curves for a confident and flattering fit.`,
    heading4:`Versatile Wear`,
    paragraph4:`Whether worn for a night out on the town or a special event, this jumpsuit transitions effortlessly from day to night with its versatile design.`,
    keyword:`party dresscode jumpsuit`,
    description:`this jumpsuit features daring cut-out details that add a touch of edge and allure to your look. The sleek silhouette and tailored fit flatter your figure, while the cut-out design adds a modern twist. Perfect for a night out or special event, this jumpsuit is sure to turn heads and make a statement. Pair it with heels for a sophisticated look or dress it down with flats for a more casual vibe. Embrace your inner fashionista with the ONLY Black Cut Out Jumpsuit for a chic and trendy ensemble..`,
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dmini%2Bdress`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },




  

  {
    name:`NAVY-BLUE-JUMPSUIT`,
    title:`125 ONLY NAVY BLUE JUMPSUIT`,
    rate:'Rs.2,639',
    content: [  
      `The ONLY Navy Blue Jumpsuit combines style and versatility in one stunning piece. Crafted in a deep navy blue hue, this jumpsuit exudes sophistication and elegance. The sleek silhouette and tailored fit flatter your figure, while the sleeveless design adds a modern touch. Perfect for both casual and formal occasions, this jumpsuit transitions effortlessly from day to night. Pair it with heels for a polished look, or dress it down with sneakers for a more relaxed vibe. Whether you're attending a brunch with friends or a cocktail party, the ONLY Navy Blue Jumpsuit ensures you'll look effortlessly chic and put-together.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-900`,
    wear:`Women's Wear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F114959702-navy-blue`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/14-dec-2023/114959702_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/14-dec-2023/114959702_g4.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/14-dec-2023/114959702_g2.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Sleek and Stylish`,
    paragraph:`The ONLY Navy Blue Jumpsuit offers a sleek and stylish silhouette that exudes sophistication and modernity.`,
    heading1:`Deep Navy Hue`,
    paragraph1:`Crafted in a deep navy blue hue, this jumpsuit adds a touch of richness and elegance to your ensemble, perfect for any occasion.`,
    heading2:`Tailored Fit`,
    paragraph2:`With its tailored fit, this jumpsuit flatters your figure and accentuates your curves, ensuring you look and feel confident.`,
    heading3:`Versatile Design`,
    paragraph3:`Ideal for both casual and formal events, this jumpsuit transitions seamlessly from day to night, offering versatility and style.`,
    heading4:`Sleeveless Design`,
    paragraph4:`The sleeveless design adds a contemporary edge to the jumpsuit, keeping you cool and comfortable while making a fashion statement.`,
    keyword:`party dresscode jumpsuit`,
    description:`this jumpsuit exudes sophistication and elegance. The sleek silhouette and tailored fit flatter your figure, while the sleeveless design adds a modern touch. Perfect for both casual and formal occasions, this jumpsuit transitions effortlessly from day to night. Pair it with heels for a polished look, or dress it down with sneakers for a more relaxed vibe. Whether you're attending a brunch with friends or a cocktail party, the ONLY Navy Blue Jumpsuit ensures you'll look effortlessly chic and put-together.`,
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dmini%2Bdress`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },




  {
    name:`CHIFFON-V-NECK-DRESS`,
    title:`124 ONLY BLACK CHIFFON V-NECK DRESS`,
    rate:'Rs.1,959',
    content: [  
      `The ONLY Black Chiffon V-Neck Dress epitomizes elegance and sophistication. Crafted in classic black chiffon, this dress offers timeless style with a modern twist. The V-neckline adds a touch of allure, while the chiffon fabric drapes beautifully, creating a flattering silhouette. Perfect for both formal events and special occasions, this dress exudes versatility and charm. Whether paired with heels for a polished look or sandals for a more relaxed vibe, it effortlessly elevates any ensemble. Embrace chic simplicity with the ONLY Black Chiffon V-Neck Dress for a sophisticated and refined aesthetic.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Women's Wear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F114959201-black`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/04-dec-2023/114959201_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/04-dec-2023/114959201_g1.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/04-dec-2023/114959201_g2.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Timeless Elegance`,
    paragraph:`The ONLY Black Chiffon V-Neck Dress exudes timeless elegance with its classic black color and sophisticated design.`,
    heading1:`Chic V-Neckline`,
    paragraph1:`The V-neckline adds a touch of allure and femininity to the dress, accentuating the neckline and collarbones for a flattering look.`,
    heading2:`Flowy Chiffon Fabric`,
    paragraph2:`Crafted from flowy chiffon fabric, this dress drapes beautifully over the body, creating a graceful and ethereal silhouette.`,
    heading3:`Versatile Wear`,
    paragraph3:`Perfect for various occasions, this dress can be dressed up with heels for a formal event or dressed down with flats for a more casual outing.`,
    heading4:`Flattering Silhouette`,
    paragraph4:`The V-neckline and flowing chiffon fabric work together to create a flattering silhouette that enhances your natural curves.`,
    keyword:`V-NECK DRESS`,
    description:'The V-neckline adds a touch of allure, while the chiffon fabric drapes beautifully, creating a flattering silhouette. Perfect for both formal events and special occasions, this dress exudes versatility and charm. Whether paired with heels for a polished look or sandals for a more relaxed vibe, it effortlessly elevates any ensemble. Embrace chic simplicity with the ONLY Black Chiffon V-Neck Dress for a sophisticated and refined aesthetic.',
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dmini%2Bdress`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`SHIMMER-PLEATED-MINI-DRESS`,
    title:`123 ONLYONLY LIGHT GREEN SHIMMER PLEATED MINI DRESS`,
    rate:'Rs.3,149',
    content: [  
      `The Light Green Shimmer Pleated Mini Dress is a radiant and feminine choice for any occasion. Crafted in a soft and shimmering light green fabric, this dress exudes elegance and charm. The pleated design adds texture and movement, creating a flattering silhouette that drapes beautifully over the body. The mini length adds a playful touch, making it perfect for both daytime events and evening soirées. Whether paired with heels for a dressy look or sandals for a more casual vibe, this dress is sure to make you stand out. Elevate your style with the Light Green Shimmer Pleated Mini Dress for a chic and sophisticated ensemble.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-green-800`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F266715701-canary-green`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/17-july-2023/266715701_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/17-july-2023/266715701_g2.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/17-july-2023/266715701_g4.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Radiant Color`,
    paragraph:`The Light Green Shimmer Pleated Mini Dress boasts a radiant and eye-catching light green hue that adds a fresh and vibrant touch to your ensemble.`,
    heading1:`Shimmering Fabric`,
    paragraph1:`Crafted from shimmering fabric, this dress catches the light beautifully, adding a subtle sparkle and a touch of glamour to your look.`,
    heading2:`Pleated Design`,
    paragraph2:`With its pleated design, this dress offers texture and movement, creating a dynamic and flattering silhouette that drapes gracefully over your body.`,
    heading3:`Mini Length`,
    paragraph3:`The mini length of the dress adds a playful and youthful vibe, making it perfect for showcasing your legs and adding a flirty touch to your look.`,
    heading4:`Feminine Charm`,
    paragraph4:`This dress exudes feminine charm with its soft color and delicate shimmer, making it a charming choice for any occasion.`,
    keyword:`SHIMMER PLEATED MINI DRESS`,
    description:'Crafted in a soft and shimmering light green fabric, this dress exudes elegance and charm. The pleated design adds texture and movement, creating a flattering silhouette that drapes beautifully over the body. The mini length adds a playful touch, making it perfect for both daytime events and evening soirées. Whether paired with heels for a dressy look or sandals for a more casual vibe, this dress is sure to make you stand out. Elevate your style with the Light Green Shimmer Pleated Mini Dress for a chic and sophisticated ensemble.',
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dmini%2Bdress`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  


  {
    name:`PRINTED-WRAP-MINI-DRESS`,
    title:`122 ONLY BLACK PRINTED WRAP MINI DRESS`,
    rate:'Rs.2,804',
    content: [  
      `The ONLY Black Printed Wrap Mini Dress is a chic and versatile piece for any wardrobe. With its classic black hue and stylish printed design, this dress exudes elegance and sophistication. The wrap silhouette adds a flattering touch, cinching in at the waist and accentuating your curves. The mini length adds a playful vibe, making it perfect for both casual outings and semi-formal occasions. Crafted from high-quality fabric, it offers comfort and style in equal measure. Whether paired with heels for a night out or sandals for a daytime look, this dress is sure to turn heads. Elevate your wardrobe with the ONLY Black Printed Wrap Mini Dress for a timeless and fashionable ensemble.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-500`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:``,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/18-april-2024/142387501_g1.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/18-april-2024/142387501_g3.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/18-april-2024/142387501_g6.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Elegance`,
    paragraph:`The ONLY Black Printed Wrap Mini Dress embodies classic elegance with its timeless black hue and sophisticated design.`,
    heading1:`Stylish Print`,
    paragraph1:`Featuring a chic printed pattern, this dress adds a touch of flair and personality to your ensemble, making it stand out from the crowd.`,
    heading2:`Wrap Silhouette`,
    paragraph2:`The wrap silhouette of this dress offers a flattering fit by cinching in at the waist and accentuating your curves, creating an hourglass shape.`,
    heading3:`Mini Length`,
    paragraph3:`With its mini length, this dress exudes a playful and youthful vibe, perfect for showcasing your legs and adding a flirty touch to your look.`,
    heading4:`Versatile Wear`,
    paragraph4:`Whether dressed up with heels for a night out or styled casually with flats for a daytime look, this dress transitions seamlessly from day to night.`,
    keyword:`PRINTED MINI DRESS`,
    description:'With its classic black hue and stylish printed design, this dress exudes elegance and sophistication. The wrap silhouette adds a flattering touch, cinching in at the waist and accentuating your curves. The mini length adds a playful vibe, making it perfect for both casual outings and semi-formal occasions. Crafted from high-quality fabric, it offers comfort and style in equal measure. Whether paired with heels for a night out or sandals for a daytime look, this dress is sure to turn heads. Elevate your wardrobe with the ONLY Black Printed Wrap Mini Dress for a timeless and fashionable ensemble.',
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dmini%2Bdress`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  




  {
    name:`CARGO ZIP-UP JUMPSUIT`,
    title:`121 ONLY BLACK CARGO ZIP-UP JUMPSUIT`,
    rate:'Rs.3,499',
    content: [  
      `The ONLY Black Cargo Zip-Up Jumpsuit combines edgy style with practicality. Crafted in classic black, this jumpsuit features cargo-style pockets and a zip-up front, offering a modern twist on utilitarian fashion. The sleek silhouette and adjustable waist tie ensure a flattering fit, while the versatile design transitions seamlessly from day to night. Whether paired with sneakers for a casual day out or heels for a night on the town, this jumpsuit is a versatile wardrobe staple. Embrace the trend of jumpsuits with an urban edge by opting for the ONLY Black Cargo Zip-Up Jumpsuit.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-black`,
    wear:`Women's Wear`,
    Scolor:"text-orange-500 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F237708601-anthracite`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/17-july-2023/237708601_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/17-july-2023/237708601_g1.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/17-july-2023/237708601_g3.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Edgy Style`,
    paragraph:`The ONLY Black Cargo Zip-Up Jumpsuit offers an edgy and contemporary style that adds an urban flair to your wardrobe.`,
    heading1:`Classic Black`,
    paragraph1:`Crafted in timeless black, this jumpsuit exudes sophistication and versatility, making it a go-to option for various occasions.`,
    heading2:`Cargo-Style Pockets`,
    paragraph2:`Featuring cargo-style pockets, this jumpsuit combines fashion with functionality, providing ample storage space for your essentials while adding an on-trend utility vibe.`,
    heading3:`Zip-Up Front`,
    paragraph3:`The zip-up front closure adds a modern and dynamic touch to the jumpsuit, allowing for easy wearability and adding an edgy detail to the overall design.`,
    heading4:`Sleek Silhouette`,
    paragraph4:`With its sleek silhouette, this jumpsuit offers a flattering fit that accentuates your curves in all the right places, enhancing your figure with effortless style.`,
    keyword:`ZIP-UP JUMPSUIT`,
    description:'Crafted in classic black, this jumpsuit features cargo-style pockets and a zip-up front, offering a modern twist on utilitarian fashion. The sleek silhouette and adjustable waist tie ensure a flattering fit, while the versatile design transitions seamlessly from day to night. Whether paired with sneakers for a casual day out or heels for a night on the town, this jumpsuit is a versatile wardrobe staple. Embrace the trend of jumpsuits with an urban edge by opting for the ONLY Black Cargo Zip-Up Jumpsuit.',
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dshimmer%2Bjumpsuit`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  

  {
    name:`FLORAL-JACQUARD-MINI-DRESS`,
    title:`120 ONLY BLUE FLORAL JACQUARD MINI DRESS`,
    rate:'Rs.3,499',
    content: [  
      `The ONLY Blue Floral Jacquard Mini Dress is a charming and feminine addition to your wardrobe. With its delicate blue floral pattern woven into the jacquard fabric, this dress exudes elegance and sophistication. The mini length adds a playful touch, while the tailored silhouette ensures a flattering fit. Perfect for both daytime events and evening soirées, this dress offers versatility and style. Whether paired with heels for a dressy occasion or sandals for a casual outing, it effortlessly elevates your look. Embrace your feminine side and make a statement with the ONLY Blue Floral Jacquard Mini Dress for a chic and timeless ensemble.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-blue-500`,
    wear:`Women's Wear`,
    Scolor:"text-sky-700 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F299951801-blue-sapphire`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/26-feb-2024/299951801_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/26-feb-2024/299951801_g1.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/26-feb-2024/299951801_g5.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Design`,
    paragraph:`The ONLY Blue Floral Jacquard Mini Dress features an elegant and timeless design that exudes femininity and sophistication.`,
    heading1:`Blue Floral Pattern`,
    paragraph1:`With its delicate blue floral pattern woven into the jacquard fabric, this dress adds a touch of romance and charm to your look.`,
    heading2:`Jacquard Fabric`,
    paragraph2:`Crafted from high-quality jacquard fabric, this dress offers luxurious texture and subtle sheen, elevating its overall appeal.`,
    heading3:`Mini Length`,
    paragraph3:`The mini length of the dress adds a playful and youthful vibe, making it perfect for both daytime and evening occasions.`,
    heading4:`Tailored Silhouette`,
    paragraph4:`Designed with a tailored silhouette, this dress hugs your curves in all the right places, flattering your figure and enhancing your feminine shape.`,
    keyword:`FLORAL JACQUARD MINI DRESS`,
    description:'Crafted from lightweight chiffon fabric, this dress drapes gracefully, offering a soft and airy feel. The vibrant pink hue, adorned with abstract prints, adds a touch of femininity and modernity to the ensemble. With its flowy silhouette and subtle sheerness, it exudes effortless charm and sophistication. Perfect for both casual outings and special occasions, this dress is a versatile choice for those seeking a chic and stylish look with a hint of whimsy.',
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dshimmer%2Bjumpsuit`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  

  {
    name:`ABSTRACT-PRINT-CHIFFON-DRESS`,
    title:`119 ONLY PINK ABSTRACT PRINT CHIFFON DRESS
    `,
    rate:'Rs.2,099',
    content: [  
      `The ONLY Pink Abstract Print Chiffon Dress is a delightful blend of elegance and playfulness. Crafted from lightweight chiffon fabric, this dress drapes gracefully, offering a soft and airy feel. The vibrant pink hue, adorned with abstract prints, adds a touch of femininity and modernity to the ensemble. With its flowy silhouette and subtle sheerness, it exudes effortless charm and sophistication. Perfect for both casual outings and special occasions, this dress is a versatile choice for those seeking a chic and stylish look with a hint of whimsy.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-rose-700`,
    wear:`Women's Wear`,
    Scolor:"text-amber-700 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F136603501-aqua-haze`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/17-july-2023/136603501_g1.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/17-july-2023/136603501_g3.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/17-july-2023/136603501_g5.jpg?width=488&height=650&mode=fill&fill=blur&format=auto `,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Chic Design`,
    paragraph:`The ONLY Pink Abstract Print Chiffon Dress boasts a chic and contemporary design that exudes sophistication.`,
    heading1:`Vibrant Pink Hue`,
    paragraph1:`The vibrant pink color of this dress adds a pop of color to your wardrobe, making it perfect for spring and summer.`,
    heading2:`Abstract Print`,
    paragraph2:`Featuring an abstract print pattern, this dress adds a modern and artistic flair to your look, making it stand out from the crowd.`,
    heading3:`Chiffon Fabric`,
    paragraph3:`Crafted from lightweight chiffon fabric, this dress offers a soft and flowy feel, ensuring comfort and elegance.`,
    heading4:`Flattering Silhouette`,
    paragraph4:`With its flattering silhouette, this dress accentuates your curves while providing a comfortable fit..`,
    keyword:`PRINT CHIFFON DRESS`,
    description:'Crafted from lightweight chiffon fabric, this dress drapes gracefully, offering a soft and airy feel. The vibrant pink hue, adorned with abstract prints, adds a touch of femininity and modernity to the ensemble. With its flowy silhouette and subtle sheerness, it exudes effortless charm and sophistication. Perfect for both casual outings and special occasions, this dress is a versatile choice for those seeking a chic and stylish look with a hint of whimsy.',
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dshimmer%2Bjumpsuit`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  


  {
    name:`JACQUARD-SHIMMER-JUMPSUIT`,
    title:`118 ONLY DARK PINK JACQUARD SHIMMER JUMPSUIT`,
    rate:'Rs.3,149',
    content: [  
      `The ONLY Dark Pink Jacquard Shimmer Jumpsuit is a stunning and versatile piece for your wardrobe. With its rich dark pink hue and shimmering jacquard fabric, this jumpsuit exudes elegance and sophistication. The tailored silhouette and flattering fit accentuate your curves while providing comfort and ease of movement. Perfect for both casual outings and special occasions, this jumpsuit offers effortless style and glamour. Whether paired with heels for a night out or sandals for a chic daytime look, it is sure to make a statement wherever you go. Elevate your wardrobe with the ONLY Dark Pink Jacquard Shimmer Jumpsuit for a touch of refined luxury and timeless elegance.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-rose-700`,
    wear:`Women's Wear`,
    Scolor:"text-amber-700 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F213555801-bright-rose`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/20-mar-2024/213555801_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/20-mar-2024/213555801_g4.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/20-mar-2024/213555801_g2.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Luxurious Design`,
    paragraph:`The ONLY Dark Pink Jacquard Shimmer Jumpsuit boasts a luxurious and opulent design, perfect for adding a touch of glamour to any occasion.`,
    heading1:`Rich Dark Pink Hue`,
    paragraph1:`With its rich dark pink color, this jumpsuit radiates sophistication and elegance, making it a standout piece in your wardrobe.`,
    heading2:`Shimmering Jacquard Fabric`,
    paragraph2:`Crafted from shimmering jacquard fabric, this jumpsuit catches the light beautifully, adding a subtle sparkle to your look.`,
    heading3:`Tailored Silhouette`,
    paragraph3:`The tailored silhouette of this jumpsuit offers a flattering fit, accentuating your curves while providing comfort and confidence.`,
    heading4:`Versatile Wear`,
    paragraph4:`Whether dressed up with heels for a formal event or styled casually with sandals for a chic daytime look, this jumpsuit transitions seamlessly from day to night.`,
    keyword:`shimmer dresses jumpsuit`,
    description:'The ONLY Dark Pink Jacquard Shimmer Jumpsuit is a stunning and versatile piece for your wardrobe. With its rich dark pink hue and shimmering jacquard fabric, this jumpsuit exudes elegance and sophistication. The tailored silhouette and flattering fit accentuate your curves while providing comfort and ease of movement. Perfect for both casual outings and special occasions, this jumpsuit offers effortless style and glamour. Whether paired with heels for a night out or sandals for a chic daytime look, it is sure to make a statement wherever you go. Elevate your wardrobe with the ONLY Dark Pink Jacquard Shimmer Jumpsuit for a touch of refined luxury and timeless elegance.',
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2019/04/shoppers-stop-buy-2-get-1-free-offer-ad-delhi-times-20-04-2019.png`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Ftext%3Dshimmer%2Bjumpsuit`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  


  {
    name:`MULTI-COLOUR-ABSTRACT-PRINT-DRESS`,
    title:`117 ONLY MULTI-COLOUR ABSTRACT PRINT DRESS`,
    rate:'Rs.2,099',
    content: [  
      `The ONLY Multi-Colour Abstract Print Dress is a vibrant and dynamic addition to your wardrobe. With its eye-catching abstract print featuring an array of colors, this dress exudes energy and personality. The playful combination of hues adds a cheerful and whimsical touch to the ensemble, making it perfect for both casual outings and special occasions. Crafted from high-quality fabric, it offers comfort and style in equal measure. The versatile design and flattering silhouette make it a versatile choice for any fashion-forward individual looking to make a statement with their attire.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-700`,
    wear:`Women's Wear`,
    Scolor:"text-orange-400 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F271055601-cloud-dancer`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/26-feb-2024/271055601_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/26-feb-2024/271055601_g4.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/26-feb-2024/271055601_g5.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Vibrant Design`,
    paragraph:`The ONLY Multi-Colour Abstract Print Dress features a vibrant and eye-catching design that adds energy and personality to your look..`,
    heading1:`Abstract Print`,
    paragraph1:`With its abstract print pattern, this dress showcases a dynamic combination of colors, creating a playful and artistic aesthetic.`,
    heading2:`Versatile Colour Palette`,
    paragraph2:`The multi-colour palette allows for endless styling options, making this dress suitable for various occasions and personal preferences.`,
    heading3:`Flattering Silhouette`,
    paragraph3:`Designed to flatter your figure, this dress features a flattering silhouette that accentuates your curves while providing comfort and ease of movement.`,
    heading4:`Playful Charm`,
    paragraph4:`The playful charm of this dress makes it a standout piece in your wardrobe, perfect for adding a touch of fun and whimsy to your ensemble.`,
    keyword:`Abstract print Dress`,
    description:'The ONLY Multi-Colour Abstract Print Dress is a vibrant and dynamic addition to your wardrobe. With its eye-catching abstract print featuring an array of colors, this dress exudes energy and personality. The playful combination of hues adds a cheerful and whimsical touch to the ensemble, making it perfect for both casual outings and special occasions. Crafted from high-quality fabric, it offers comfort and style in equal measure. The versatile design and flattering silhouette make it a versatile choice for any fashion-forward individual looking to make a statement with their attire.',
    summary:`https://assets.ajio.com/medias/sys_master/images/images/h92/h98/51213492060190/1024-x-672.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fluxe.ajio.com%2F%3F_gac%3D1.52045597.1714845136.EAIaIQobChMIrObx9cf0hQMVj6JmAh1zowIbEAAYASAAEgJOnPD_BwE`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  




  {
    name:`BROWN-ABSTRACT-PRINT-MINI-DRESS`,
    title:`116 ONLY BROWN ABSTRACT PRINT MINI DRESS`,
    rate:'Rs.2,589',
    content: [  
      `The Brown Abstract Print Mini Dress by ONLY is a trendy and versatile piece for your wardrobe. With its unique abstract print in earthy brown tones, this mini dress adds a touch of artistry to your ensemble. The mini length and relaxed fit ensure comfort and ease of movement, while the stylish design allows for effortless styling. Perfect for both casual outings and semi-formal occasions, this dress is a chic addition to any fashion-forward wardrobe.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-red-700`,
    wear:`Women's Wear`,
    Scolor:"text-orange-400 text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F271052101-cinnabar`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/26-mar-2024/271052101_g1.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/26-mar-2024/271052101_g3.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/26-mar-2024/271052101_g6.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Trendy Design`,
    paragraph:`The Brown Abstract Print Mini Dress by ONLY features a stylish and contemporary design that stands out in any crowd.`,
    heading1:`Unique Print`,
    paragraph1:`With an eye-catching abstract print in shades of brown, this dress adds a touch of sophistication and artistry to your look.`,
    heading2:`Mini Length`,
    paragraph2:`The mini length of the dress offers a youthful and playful vibe, making it perfect for both daytime and evening wear.`,
    heading3:`Flattering Fit`,
    paragraph3:`Designed to flatter your figure, this dress features a fitted bodice and a flared skirt that accentuates your curves in all the right places.`,
    heading4:`Versatile Style`,
    paragraph4:`Whether paired with heels for a night out or sandals for a casual day look, this dress effortlessly transitions from day to night.`,
    keyword:`woman dresses mini`,
    description:'this mini dress adds a touch of artistry to your ensemble. The mini length and relaxed fit ensure comfort and ease of movement, while the stylish design allows for effortless styling. Perfect for both casual outings and semi-formal occasions, this dress is a chic addition to any fashion-forward wardrobe.',
    summary:`https://assets.ajio.com/medias/sys_master/images/images/h92/h98/51213492060190/1024-x-672.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fluxe.ajio.com%2F%3F_gac%3D1.52045597.1714845136.EAIaIQobChMIrObx9cf0hQMVj6JmAh1zowIbEAAYASAAEgJOnPD_BwE`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  
  

  {
    name:`GREY-PRINTED-FIT-FLARE-DRESS`,
    title:`115 ONLY GREY PRINTED FIT & FLARE DRESS`,
    rate:'Rs.2,099',
    content: [  
      `The Grey Printed Fit & Flare Dress is a versatile and stylish addition to any wardrobe. With its flattering fit and flare silhouette, this dress accentuates the waist while providing a comfortable and feminine look. The grey hue adds a touch of sophistication, while the subtle print adds visual interest. Perfect for both casual and semi-formal occasions, this dress can be dressed up with heels and accessories or worn with sandals for a more laid-back vibe.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-600`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYIFslfU6EPF2lSGZHLfnZkyiEJ_JRphzNv9jT-EHUZw&s`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.only.in%2Ffashion-dresses-jumpsuits%2F143816301-whitecap-gray`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bestsellerclothing.in/data/only/26-mar-2024/143816301_g0.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image:`https://images.bestsellerclothing.in/data/only/26-mar-2024/143816301_g4.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    image1:`https://images.bestsellerclothing.in/data/only/26-mar-2024/143816301_g5.jpg?width=488&height=650&mode=fill&fill=blur&format=auto`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Stylish Design`,
    paragraph:`The Only Grey Printed Fit & Flare Dress exudes effortless style with its chic design and flattering silhouette.`,
    heading1:`Printed Pattern`,
    paragraph1:`Featuring a striking printed pattern, this dress adds a pop of visual interest, making it a standout piece in your wardrobe.`,
    heading2:`Fit & Flare Silhouette`,
    paragraph2:`The fit & flare silhouette accentuates your curves while providing a comfortable and flattering fit.`,
    heading3:`Grey Color`,
    paragraph3:`The versatile grey color of this dress makes it easy to style for various occasions, from casual outings to semi-formal`,
    heading4:`Comfortable Fabric`,
    paragraph4:`Crafted from high-quality fabric, this dress ensures comfort all day long, allowing you to move with ease..`,
    keyword:`PRINTED FLARE DRESS`,
    description:'The grey hue adds a touch of sophistication, while the subtle print adds visual interest. Perfect for both casual and semi-formal occasions, this dress can be dressed up with heels and accessories or worn with sandals for a more laid-back vibe.',
    summary:`https://assets.ajio.com/medias/sys_master/images/images/h92/h98/51213492060190/1024-x-672.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fluxe.ajio.com%2F%3F_gac%3D1.52045597.1714845136.EAIaIQobChMIrObx9cf0hQMVj6JmAh1zowIbEAAYASAAEgJOnPD_BwE`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  


  {
    name:`134-Contrast-Button-Cotton-Twill-White-Shirt`,
    title:`114 Essential 100% Linen White Shirt - Se Relaxer`,
    rate:'Rs.3,199',
    content: [  
      `Se Relaxer's Essential 100% Linen White Shirt epitomizes timeless elegance and comfort. Made from premium 60's lea linen, this shirt offers unmatched quality and durability. The crisp French placket adds a touch of sophistication, making it suitable for various occasions, from formal meetings to casual outings. With each wash, it becomes softer, ensuring a relaxed fit that keeps you comfortable all day long.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-600`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/kingdomofwhite.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fkingdomofwhite.com%2Fproducts%2Ffull-sleeves-french-placket-shirt%3Fpr_prod_strat%3Dpinned%26pr_rec_id%3De6177c234%26pr_rec_pid%3D6857611116714%26pr_ref_pid%3D7689798811818%26pr_seq%3Duniform%26variant%3D40912227991722`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://kingdomofwhite.com/cdn/shop/files/KOW_19_03_24_9387copy_3000x.jpg?v=1712850802`,
    image:`https://kingdomofwhite.com/cdn/shop/files/KOW_19_03_24_9322copy_3000x.jpg?v=1712850802`,
    image1:`https://kingdomofwhite.com/cdn/shop/files/5_e6790ffc-b0e7-49ea-990b-5b4afb306a18_3000x.jpg?v=1712850802`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Premium Linen Fabric`,
    paragraph:`This shirt is crafted from 100% 60's lea linen, ensuring exceptional quality and durability. Linen is renowned for its ability to become softer and more comfortable with each wash, making it an essential piece in your wardrobe that only gets better with time.`,
    heading1:`Laid-Back Comfort`,
    paragraph1:`Experience the relaxed comfort of this linen shirt. The fabric's natural breathability and lightweight feel make it perfect for all-day wear, keeping you cool and comfortable in any setting.`,
    heading2:`Stylish Design`,
    paragraph2:`Featuring a crisp French placket and contrast buttons, this shirt offers a subtle yet stylish look. The minimalist design allows you to effortlessly dress it up or down for any occasion.`,
    heading3:`Versatile Wear`,
    paragraph3:`Whether you're heading to a boardroom meeting or a beach bar, this shirt is versatile enough to suit any setting. Its timeless style and sophisticated appeal make it a wardrobe staple for any modern man..`,
    heading4:`Cool and Breathable`,
    paragraph4:`Linen is known for its ability to wick away moisture and allow air to flow, keeping you feeling fresh and comfortable even in hot weather.`,
    keyword:`Linen White Shirt`,
    description:'Your go-to 100% linen shirt that gets better with each wash. Feel the laid-back comfort of 60’s lea linen, known for its quality and durability. With its crisp French placket, this shirt is a quiet nod to style – ideal for turning heads in both boardrooms and beach bars.Perfect for staying cool and looking sharp, whether you were chilling out or stepping out. Pair with tailored trousers for a refined look or linen pants for a relaxed look.',
    summary:`https://lzd-img-global.slatic.net/us/lzd-onepiece/506b40b89aa77d0ec4daaca23b2057311713767078109.jpg_680x680q75.jpg_.webp`,
    jlink:`https://inr.deals/TmxOiz`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  

  {
    name:`133--Contrast-Button-Cotton-Twill-White-Shirt`,
    title:`113 Contrast Button Cotton Twill White Shirt - Caravan`,
    rate:'Rs.1,999',
    content: [  
      `The Caravan shirt is a testament to classic style, featuring contrasting buttons that add a touch of sophistication to the timeless white shirt. Crafted from high-quality cotton twill, it offers durability and comfort. The shirt's versatile design allows it to be dressed up or down for various occasions, making it a wardrobe essential for the modern man.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-gray-600`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/kingdomofwhite.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fkingdomofwhite.com%2Fcollections%2Fsmart-casual%2Fproducts%2Fcontrast-button-cotton-twill-white-shirt-caravan%3Fvariant%3D44404321812650`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://kingdomofwhite.com/cdn/shop/files/DSCF6891_3000x.jpg?v=1712751150`,
    image:`https://kingdomofwhite.com/cdn/shop/files/DSCF6769_3000x.jpg?v=1712751150`,
    image1:`https://kingdomofwhite.com/cdn/shop/files/DSCF6778_3000x.jpg?v=1712751150`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Premium Fabric`,
    paragraph:`The Caravan shirt is crafted from 100% cotton twill, renowned for its durability and comfort. This ensures that the shirt not only looks good but feels great against your skin.`,
    heading1:`Unique Design`,
    paragraph1:`One of the standout features of the Caravan shirt is its contrasting black snap buttons. These buttons add a touch of sophistication and uniqueness to the classic white shirt.`,
    heading2:`Comfortable Fit`,
    paragraph2:`Designed for everyday wear, the Caravan shirt features a comfortable regular fit. This allows for ease of movement and makes it suitable for various occasions.`,
    heading3:`Versatile Style`,
    paragraph3:`The shirt's classic white color and contrasting buttons make it incredibly versatile. It can be dressed up with trousers for a formal look or paired with jeans for a more casual vibe.`,
    heading4:`Timeless Appeal`,
    paragraph4:`White shirts are a wardrobe staple, and the Caravan shirt is no exception. Its timeless design ensures that it will remain a fashionable choice for years to come.`,
    keyword:`White Cotton shirt`,
    description:'Designed for the adventurous at heart, it is a must-have for your wardrobe. Crafted from 100% cotton twill, known for its durability. Featuring a comfortable regular fit, it has snap black buttons for a timeless appeal. Pair it with black jeans for a classic look, or chinos for a slight edge.',
    summary:`https://lzd-img-global.slatic.net/us/lzd-onepiece/506b40b89aa77d0ec4daaca23b2057311713767078109.jpg_680x680q75.jpg_.webp`,
    jlink:`https://inr.deals/TmxOiz`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  

  {
    name:`132-Textured-Knit-Quarter-Zip-White-Polo`,
    title:`112 Textured Knit Quarter Zip White Polo - Zipped Cream`,
    rate:'Rs.1,999',
    content: [  
      `The "Zipped Cream" textured knit quarter-zip white polo offers a blend of sophistication and casual comfort. The unique texture adds depth to the classic white polo, making it a versatile piece for various occasions. The quarter-zip feature adds a modern touch, allowing for adjustable neckline styling. This polo is perfect for those seeking a refined yet relaxed look.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-900`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/kingdomofwhite.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fkingdomofwhite.com%2Fproducts%2Ftextured-knit-pure-cotton-white-shirt-zipped-cream%3Fvariant%3D45292548817066`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://kingdomofwhite.com/cdn/shop/files/DSC06628_3000x.jpg?v=1702398717`,
    image:`https://kingdomofwhite.com/cdn/shop/files/DSC06687_3000x.jpg?v=1705904642`,
    image1:`https://kingdomofwhite.com/cdn/shop/files/DSC06637_3000x.jpg?v=1705904642`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Material and Craftsmanship`,
    paragraph:`The Zipped Cream polo is made from 100% mercerized cotton, ensuring durability, breathability, and a luxurious feel. Its flat knit texture adds depth and character to the garment.`,
    heading1:`Design`,
    paragraph1:`This polo features a trendy quarter-zip design, adding a modern touch to a classic silhouette. The ribbed collar and sleeves enhance its style quotient, making it suitable for both casual and semi-formal occasions.`,
    heading2:`Comfort and Fit`,
    paragraph2:`The regular fit of the polo ensures comfort and ease of movement. The mercerized cotton fabric provides a soft, smooth feel against the skin, making it ideal for all-day wear.`,
    heading3:`Versatility`,
    paragraph3:`The Zipped Cream polo is versatile and can be paired with jeans, chinos, or shorts for a casual look. It can also be dressed up with trousers or a blazer for a more refined appearance.`,
    heading4:`Collection`,
    paragraph4:`Part of the 'Escapade' collection, this polo embodies the spirit of adventure and style. It is designed to cater to the needs of professionals who value both style and functionality.`,
    keyword:`White Polo tshirt`,
    description:'Zipped Cream represents grace, crafted from 100% mercerized cotton. Its trendy flat knit texture blends subtlety with fashion-forward design, ensuring comfort with its regular fit, rib collar, and sleeves. The quarter zip polo style stands out as a trendy highlight. Part of the Escapade collection, it caters to the style and functionality needs of all professionals. Made in India, it demands that you avoid wringing to preserve its pristine texture and contemporary charm.',
    summary:`https://lzd-img-global.slatic.net/us/lzd-onepiece/506b40b89aa77d0ec4daaca23b2057311713767078109.jpg_680x680q75.jpg_.webp`,
    jlink:`https://inr.deals/TmxOiz`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },



  {
    name:`131-Zipper-Collar-Cotton-Twill-White-Shirt`,
    title:`111 Zipper Collar Cotton Twill White Shirt - Zip Log`,
    rate:'Rs.1,799',
    content: [  
      `The Zipper Collar Cotton Twill White Shirt is a modern take on the classic white shirt. Featuring a unique zipper collar design, this shirt adds a contemporary edge to your look. Made from comfortable cotton twill fabric, it offers a crisp and polished appearance. The zip detail allows for versatile styling, making it a standout piece in your wardrobe.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold mt-2 text-amber-600`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/kingdomofwhite.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fkingdomofwhite.com%2Fcollections%2Fsmart-casual%2Fproducts%2Fzipper-shirt%3Fvariant%3D40911736570026`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://kingdomofwhite.com/cdn/shop/files/KOW53716_3000x.jpg?v=1708337409`,
    image:`https://kingdomofwhite.com/cdn/shop/files/KOW53649_4e725467-dce0-4873-8134-4df4eb72f298_3000x.jpg?v=1708337409`,
    image1:`https://kingdomofwhite.com/cdn/shop/files/KOW-CA11-R-0006-40_5_3000x.jpg?v=1711790348`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Material Quality`,
    paragraph:`The shirt is crafted from 100% cotton twill, known for its durability and comfort. This fabric is also tear-resistant and drapes well on the body, ensuring a flattering fit.`,
    heading1:`Unique Design`,
    paragraph1:`The standout feature of this shirt is its zipper collar, which adds a modern and edgy touch to the classic white shirt design. The zipper detail makes it a versatile piece that can be styled in various ways.`,
    heading2:`Versatile Styling`,
    paragraph2:`This shirt can be paired with a range of bottoms, including casual trousers, joggers, and denim jeans. Its neutral white color makes it easy to incorporate into different outfits.`,
    heading3:`Comfortable Fit`,
    paragraph3:`With its regular fit, this shirt offers a comfortable wearing experience, allowing for ease of movement throughout the day.`,
    heading4:`Suitable for Outdoor Activities`,
    paragraph4:`The durable fabric and versatile design make this shirt ideal for outdoor activities like hiking and travel. It provides both style and functionality for active individuals..`,
    keyword:`smart casual zipper shirts`,
    description:'Zip log is crafted from 100% cotton twill, known for its durability, tear resistance, and exceptional ability to drape well on the body. The inclusion of a zipper adds a distinctive and modern touch to the design. Pairs well with casual trousers, joggers, and denim jeans. Perfect for hiking, travel, and outdoor activities. .',
    summary:`https://lzd-img-global.slatic.net/us/lzd-onepiece/506b40b89aa77d0ec4daaca23b2057311713767078109.jpg_680x680q75.jpg_.webp`,
    jlink:`https://inr.deals/TmxOiz`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  

  {
    name:`130-High-waisted-straight-leg-trousers.`,
    title:`110 Urbanic Straight Leg Trousers`,
    rate:'Rs.1,672',
    content: [  
      `Urbanic's Straight Leg Trousers offer a sophisticated and versatile addition to your wardrobe. With a classic straight leg silhouette, these trousers provide a timeless look that never goes out of style. Crafted from high-quality materials, they offer both durability and comfort for all-day wear. The straight leg design creates a sleek and flattering silhouette, suitable for various occasions from office settings to casual outings. Pair them with blouses or shirts for a polished office ensemble, or dress them down with a t-shirt for a relaxed weekend look. Urbanic's Straight Leg Trousers are a wardrobe essential for effortless style and versatility.`
    ],
    tcolor:`lg:text-md text-lg text-lg font-bold text-black`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fstraight-leg-trousers-119141%3Fvid%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/acd1dafe1f594515a83f3ae0f1de004fUR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/d886cbaf922545829083c0f3940a1d7aUR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/e3f9d1ef3f6b4379b1af90ee2455cfe6UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Flared Leg Design`,
    paragraph:`Contrary to its name, these trousers feature a flared leg design, adding a touch of retro-inspired style to your wardrobe. The flared silhouette offers a unique and fashionable twist to classic straight leg trousers, making them stand out in any outfit.`,
    heading1:`High-Waisted Style`,
    paragraph1:`With a high-waisted design, these trousers sit comfortably at the natural waistline, providing a flattering fit and elongating the legs for a sleek and sophisticated look.`,
    heading2:`Zip Fly with Button Closure`,
    paragraph2:`Featuring a zip fly with button closure, these trousers ensure easy wearability and a secure fit. This classic closure adds a timeless touch to the modern design of the trousers.`,
    heading3:`Five Pockets`,
    paragraph3:`Equipped with five functional pockets, including front and back pockets, as well as a coin pocket, these trousers offer ample storage space for your essentials while adding a touch of utility-chic style.`,
    heading4:`Straight Hem`,
    paragraph4:`The straight hemline provides a clean and polished finish to the flared silhouette, creating a balanced and cohesive look that is both chic and versatile.`,
    keyword:`High-waisted straight leg trousers. has two pockets and zip flu with button closure.`,
    description:'High-waisted straight leg trousers. has two pockets and zip flu with button closure. Ideal for versatile looks.',
    summary:`https://couponswala.com/blog/wp-content/uploads/2022/11/ajio-under-500-696x392.jpg.webp`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fshop%2Fwomen`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`129-Mid-waist-straight-leg-jeans-with-pockets`,
    title:`109 Urbanic Pocket Straight Leg Jeans`,
    rate:'Rs.1,990',
    content: [  
      `Urbanic's Pocket Straight Leg Jeans offer a contemporary take on classic denim. Featuring a straight-leg silhouette, these jeans are timeless yet stylish. The addition of multiple pockets adds a functional and trendy element to the design, providing ample storage while enhancing the overall aesthetic. Crafted from high-quality denim, they ensure durability and comfort for everyday wear. With a flattering fit and versatile style, these jeans are perfect for any occasion. Whether paired with casual tees or dressed up with blouses, Urbanic's Pocket Straight Leg Jeans elevate your look with effortless sophistication and practicality.`
    ],
    tcolor:`lg:text-md text-lg  text-lg font-bold text-amber-800`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fpocket-straight-leg-jeans-112528%3Fvid%3D16`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/09cca21af33540d5b3496734fab9182bUR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/3ca3832e8da0448a9a245a45e1ef6f41UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/97502a1452e844e88d624b5f17aedef7UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Flared Leg Design`,
    paragraph:`Urbanic's Pocket Straight Leg Jeans offer a unique twist on classic denim with a flared leg design. This modern take on traditional jeans adds a touch of flair and personality to your wardrobe.`,
    heading1:`High-Waisted Style`,
    paragraph1:`These jeans boast a high-waisted silhouette, accentuating your curves and providing a flattering fit. The high waistline elongates the legs and creates a sleek, polished look.`,
    heading2:`Zip Fly with Button Closure`,
    paragraph2:`Featuring a convenient zip fly and button closure, these jeans ensure easy wearability and a secure fit. The classic closure adds a timeless touch to the contemporary design.`,
    heading3:`Five Pockets`,
    paragraph3:`With five functional pockets, including front pockets, back pockets, and a coin pocket, these jeans offer ample storage for your essentials. The pockets add both style and functionality to the design.`,
    heading4:`Straight Hem`,
    paragraph4:`The straight hemline of these jeans provides a clean and polished finish to the flared silhouette. This straight hem balances the dramatic flare, creating a cohesive and stylish look.`,
    keyword:`Pocket jeans`,
    description:'Mid-waist straight leg jeans with pockets.',
    summary:`https://couponswala.com/blog/wp-content/uploads/2022/11/ajio-under-500-696x392.jpg.webp`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fshop%2Fwomen`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`128-Pocket-Straight-Leg-Jeans`,
    title:`108 Urbanic Pocket Straight Leg Jeans`,
    rate:'Rs.1,690',
    content: [  
      `Urbanic's Pocket Straight Leg Jeans redefine classic denim with a modern twist. These jeans feature a timeless straight-leg silhouette that flatters all body types. The standout feature is the addition of multiple pockets, adding both style and functionality. Crafted from premium denim, they offer durability and comfort for everyday wear. The straight-leg design provides versatility, easily paired with casual or dressed-up looks. Whether you're running errands or heading out for a night on the town, these Pocket Straight Leg Jeans from Urbanic are a stylish and practical choice for any occasion.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-black`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fpocket-straight-leg-jeans-111874%3Fvid%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/5c94f312e164428a8b9467655a439f25UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/3dddf4874ab6437ea4afb1b9be5257eaUR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/59d04aef580e4ff78994aab11189859fUR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Flared Leg Design`,
    paragraph:`Urbanic's Pocket Straight Leg Jeans offer a unique twist on classic denim with a flared leg design. This modern take on traditional jeans adds a touch of flair and personality to your wardrobe.`,
    heading1:`High-Waisted Style`,
    paragraph1:`These jeans boast a high-waisted silhouette, accentuating your curves and providing a flattering fit. The high waistline elongates the legs and creates a sleek, polished look.`,
    heading2:`Zip Fly with Button Closure`,
    paragraph2:`Featuring a convenient zip fly and button closure, these jeans ensure easy wearability and a secure fit. The classic closure adds a timeless touch to the contemporary design.`,
    heading3:`Five Pockets`,
    paragraph3:`With five functional pockets, including front pockets, back pockets, and a coin pocket, these jeans offer ample storage for your essentials. The pockets add both style and functionality to the design.`,
    heading4:`Straight Hem`,
    paragraph4:`The straight hemline of these jeans provides a clean and polished finish to the flared silhouette. This straight hem balances the dramatic flare, creating a cohesive and stylish look.`,
    keyword:`Pocket jeans`,
    description:'Mid-waist straight leg jeans designed with a pocket detail. Perfect for a comfortable and stylish look',
    summary:`https://couponswala.com/blog/wp-content/uploads/2022/11/ajio-under-500-696x392.jpg.webp`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fshop%2Fwomen`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`127-Mid-waist-Cargo-Jeans`,
    title:`107 Urabnic Mid-waist Cargo Jeans`,
    rate:'Rs.1,690',
    content: [  
      `Urbanic's Mid-Waist Cargo Jeans offer a perfect blend of style and functionality. With a mid-rise waistline, these jeans sit comfortably on the hips, providing a flattering silhouette. The cargo detailing adds a utilitarian edge to the classic denim design, offering additional storage and a touch of rugged charm. Crafted from high-quality denim, they ensure durability and long-lasting wear. Versatile and stylish, these jeans are perfect for everyday wear, adding an urban-cool vibe to any outfit.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-blue-400`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fmid-waist-cargo-jeans-110546%3Fvid%3D250`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/4be53f96e2bb4186b95c08e88d381e29UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/07fad71eaedf46b5b2ebbb43c8462c1aUR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/da9b1e808f784711a1bcedc3e2aa6c65UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Flared Leg Design`,
    paragraph:`Urbanic's Mid-Waist Cargo Flared Jeans feature a stylish flared leg design, offering a modern twist to classic denim. The flared silhouette adds a touch of retro-inspired charm while providing a flattering and elongating effect to your legs.`,
    heading1:`High-Waisted Style`,
    paragraph1:`These jeans boast a high-waisted design, sitting comfortably at the natural waistline to accentuate your curves and provide a supportive fit. The high-rise silhouette offers coverage and helps create the illusion of longer legs for a flattering look.`,
    heading2:`Zip Fly with Button Closure`,
    paragraph2:`With a convenient zip fly and button closure, these jeans ensure easy wearability and a secure fit. The closure adds a classic touch to the contemporary design, allowing for effortless styling.`,
    heading3:`Cargo Detailing`,
    paragraph3:`Adding a utilitarian edge, the cargo detailing on these jeans provides both style and functionality. The cargo pockets offer additional storage space for your essentials while enhancing the overall aesthetic with a rugged charm.`,
    heading4:`Five-Pocket Construction`,
    paragraph4:`Designed with the classic five-pocket configuration, these jeans offer ample storage for your belongings. The pockets add practicality to the fashionable design, allowing you to carry your essentials with ease.`,
    keyword:`mid rise cargo styled stretchable jeans`,
    description:'Medium shade, no fade blue jeans Wide leg, mid-rise Clean look Stretchable 6 pocket',
    summary:`https://couponswala.com/blog/wp-content/uploads/2022/11/ajio-under-500-696x392.jpg.webp`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fshop%2Fwomen`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  {
    name:`126-straight-leg-jeans`,
    title:`106 Urabnic Straight Leg Jeans`,
    rate:'Rs.1,490',
    content: [  
      `Urbanic's Straight Leg Jeans offer timeless style and versatility. With a classic straight fit from hip to ankle, they flatter various body types and provide effortless comfort. Crafted from high-quality denim, these jeans feature a mid-rise waist and traditional five-pocket styling. Whether dressed up with heels or down with sneakers, they are perfect for any occasion. Elevate your wardrobe with Urbanic's Straight Leg Jeans, a staple for modern, fashion-forward individuals.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-blue-400`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fstraight-leg-jeans-116092%3Fvid%3D8`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/44702be710244de4b4f1c9f633e4b909UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/aea8583bfa344cd69819c777df172d05UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/ef073b2da4d64251b842c34495376c17UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`High-Waisted Style`,
    paragraph:`With a high-waisted design, these jeans accentuate the waistline and provide a comfortable and supportive fit. The high-rise silhouette offers ample coverage and helps to elongate the legs, enhancing your overall silhouette.`,
    heading1:`Zip Fly with Button Closure`,
    paragraph1:`These jeans feature a classic zip fly with a button closure, ensuring easy wearability and a secure fit. The closure adds a traditional touch to the modern design, allowing for effortless styling.`,
    heading2:`Five-Pocket Construction`,
    paragraph2:`Designed with the classic five-pocket configuration, these jeans offer functional storage for your essentials. The pockets add practicality to the stylish design, allowing you to carry your belongings with ease.`,
    heading3:`Straight Hem`,
    paragraph3:`The straight hemline of these jeans adds structure to the flared silhouette, providing a clean and polished finish. The straight hem balances the dramatic flare, creating a harmonious look that is both chic and versatile.`,
    heading4:`Material Composition`,
    paragraph4:`Constructed from a blend of cotton, polyester, and elastane, these jeans offer the perfect balance of comfort, durability, and stretchability. The cotton provides breathability, while the polyester offers strength and resilience. The elastane ensures a very stretchable fit, allowing for ease of movement and flexibility.`,
    keyword:`straight fit women jeans`,
    description:'Straight Leg Jeans offer timeless style and versatility. With a classic straight fit from hip to ankle, they flatter various body types and provide effortless comfort',
    summary:`https://couponswala.com/blog/wp-content/uploads/2022/11/ajio-under-500-696x392.jpg.webp`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fshop%2Fwomen`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  {
    name:`125-Casio G-Shock-Rose-Gold-Dial-Resin-Digital-Watch-For-Women`,
      title:`105 Shopper's Stop Casio G-Shock 45.7 40.5 11.9 mm Rose Gold Dial Resin Digital Watch For Women - G1397`,
    rate:'Rs.7,995',
    content: [  
      `The Casio G-Shock G1397 is a sleek and stylish digital watch designed for women. With its rose gold dial and durable resin construction, it blends elegance with toughness. The compact dimensions of 45.7 × 40.5 × 11.9 mm make it comfortable for daily wear. Packed with digital features, it offers reliable timekeeping and practical functionality for modern women on the go.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-teal-500`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/shopperstop.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fcasio-g-shock-45-7-40-5-11-9-mm-rose-gold-dial-resin-digital-watch-for-women-g1397%2Fp-WCAG1397_base%2FcolorChange%2FWCAG1397_NoColour%3FcurrentPosition%3D72%26searchQueryUrl%3D%26totalResultVal%3D699%26searchPageType%3Dcategory`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://sslimages.shoppersstop.com/sys-master/images/h05/h8d/30869380235294/WCAG1397_NoColour_alt2.jpg_2000Wx3000H`,
    image:`https://sslimages.shoppersstop.com/sys-master/images/h64/h52/30869353758750/WCAG1397_NoColour_alt1.jpg_2000Wx3000H`,
    image1:`https://sslimages.shoppersstop.com/sys-master/images/hba/h70/30869117992990/WCAG1397_NoColour.jpg_2000Wx3000H`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Sleek Design`,
    paragraph:`The Casio G-Shock G1397 boasts a sleek and modern design tailored for women, featuring a rose gold dial that exudes elegance and sophistication.`,
    heading1:`Compact Dimensions`,
    paragraph1:`With dimensions of 45.7 × 40.5 × 11.9 mm, this watch offers a comfortable fit on the wrist, making it suitable for everyday wear.`,
    heading2:`Durable Resin Construction`,
    paragraph2:`Crafted from durable resin, the watch is built to withstand daily wear and tear, ensuring longevity and reliability.`,
    heading3:`Digital Display`,
    paragraph3:`The digital display provides clear and easy-to-read timekeeping, along with additional functions such as alarms, stopwatch, and countdown timer.`,
    heading4:`Rose Gold Accents`,
    paragraph4:`The rose gold accents add a touch of femininity and luxury to the overall design, elevating its aesthetic appeal.`,
    keyword:`Straight leg jeans `,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Dresses_Inside_Desktop-Banner_RM-1707971929.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fwomen-dresses`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
  {
    name:`124-flared-leg-jeans`,
    title:`104 Flared leg jeans, high-waisted, has zip fly with button closure, five pockets and straight hem`,
    rate:'Rs.1990',
    content: [  
      `Flared leg jeans are a stylish and versatile bottom wear option characterized by their wide, flared legs that start to widen from the knee down. Typically high-waisted, they feature a zip fly with a button closure, five pockets, and a straight hem. Known for their flattering silhouette, they add a retro touch to any outfit.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-black`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fflared-leg-jeans-116393%3Fvid%3D86`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/0f8d36f2fdb4435eba5fa1a4e81a888dUR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/e34191ee7623421aad5e0b5f2c33c237UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/8273030c0eb445d284f2d88253fd8d73UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Material`,
    paragraph:`Made from a blend of cotton, polyester, and elastane, these jeans offer a comfortable and stretchy fit, allowing for ease of movement throughout the day.`,
    heading1:`Stretchability`,
    paragraph1:`With a very stretchable fabric, these jeans offer a flattering slim fit that hugs your curves in all the right places while providing comfort.`,
    heading2:`Fit`,
    paragraph2:`The slim fit of these jeans ensures a sleek and stylish look, perfect for both casual and more dressed-up occasions.`,
    heading3:`Design`,
    paragraph3:`Featuring a high waist, these jeans elongate your legs and create a flattering silhouette. The zip fly with button closure adds to the overall classic appeal of the design.`,
    heading4:`Pockets`,
    paragraph4:`These jeans come with five pockets, providing ample space to carry your essentials while adding a touch of utility to the overall look.`,
    keyword:`flared jeans`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Dresses_Inside_Desktop-Banner_RM-1707971929.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fwomen-dresses`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  


  {
    name:`123-straight-fit-women-jeans`,
    title:`103 FYRE ROSE Women Cargo Joggers with Waist Tie-Up`,
    rate:'Rs.920',
    content: [  
      `Fyre Rose Women's Cargo Joggers with Waist Tie-Up combine comfort with style. These joggers feature a trendy waist tie-up design that adds a touch of elegance to the classic cargo style. Made from high-quality fabric, they offer durability and breathability. With their versatile look, they can be dressed up or down for various occasions, making them a versatile addition to any wardrobe.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-black`,
    wear:`Girl's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/ajio.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Ffyre-rose-women-cargo-joggers-with-waist-tie-up%2Fp%2F469270301_black`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://assets.ajio.com/medias/sys_master/root/20230425/vkWS/6447fbec42f9e729d7500b41/-1117Wx1400H-469270301-black-MODEL4.jpg`,
    image:`https://assets.ajio.com/medias/sys_master/root/20230425/qMPL/6447fa1842f9e729d7500298/-1117Wx1400H-469270301-black-MODEL5.jpg`,
    image1:`https://assets.ajio.com/medias/sys_master/root/20230425/UxIk/6447e4f6d55b7d0c637e9d62/-1117Wx1400H-469270301-black-MODEL3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Comfortable and Stylish Design`,
    paragraph:`Fyre Rose Women's Cargo Joggers with Waist Tie-Up are designed for comfort and style. The waist tie-up adds a trendy touch to the classic cargo jogger design.`,
    heading1:`Versatile Wear`,
    paragraph1:`These joggers can be dressed up or down, making them suitable for various occasions. Whether you're lounging at home or heading out for a casual outing, these joggers are a perfect choice.`,
    heading2:`Durable Fabric`,
    paragraph2:`Made from high-quality fabric, these joggers are durable and long-lasting. The blend of cotton, thermolite, coolmax, and spandex ensures a comfortable and breathable fit.`,
    heading3:`Flap Pockets`,
    paragraph3:`The joggers feature flap pockets, adding a utilitarian element to the design. These pockets are not only functional but also add a stylish detail to the joggers.`,
    heading4:`Relaxed Fit`,
    paragraph4:`The joggers come in a relaxed fit, providing ease of movement and comfort throughout the day.`,
    keyword:`cargo joggers with waist tie up`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Dresses_Inside_Desktop-Banner_RM-1707971929.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fwomen-dresses`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  

  
  {
    name:`122-Casio-Digital-Black-Dial-Watch-GBD-200UU-9DR`,
    title:`102 Casio G-Shock Digital Black Dial Men GBD-200UU-9DR (G1248)`,
    rate:'Rs.12,995',
    content: [  
      `The Casio G-Shock GBD-200UU-9DR (G1248) is a robust digital watch designed for active men. Featuring a sleek black dial and a durable resin case, this watch is both stylish and tough. It offers essential timekeeping functions along with fitness tracking features like step counting and calorie monitoring. With its shock-resistant construction and water resistance, the GBD-200UU-9DR is suitable for everyday wear and outdoor activities.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-gray-600`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Casio-Digital-Black-Dial-Watch-GBD-200UU-9DR/dp/B0B4KHQS1V/ref=pd_ci_mcx_pspc_dp_d_2_i_2?pd_rd_w=wuGv7&content-id=amzn1.sym.c951cdb5-f0e8-4efb-abcb-595e3ce751f9&pf_rd_p=c951cdb5-f0e8-4efb-abcb-595e3ce751f9&pf_rd_r=VQF1ZXMZ1W4CTCTK40T3&pd_rd_wg=uwSJm&pd_rd_r=0ecbefe4-95d8-4625-a1f9-27160a8788e6&pd_rd_i=B0B4KHQS1V&tag=inrdeal123-21`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://m.media-amazon.com/images/I/51PKqJQ25pL._SX679_.jpg`,
    image:`https://dev1-cdn.helioswatchstore.com/catalog/product/cache/dd1c3400e344f54d12df823ec560a116/g/1/g1248_3.jpg`,
    image1:`https://dev1-cdn.helioswatchstore.com/catalog/product/cache/dd1c3400e344f54d12df823ec560a116/g/1/g1248_5.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Durable Design`,
    paragraph:`The Casio G-Shock GBD-200UU-9DR (G1248) is crafted with a durable resin case and band, offering exceptional toughness and shock resistance. It can withstand daily wear and various outdoor activities.`,
    heading1:`Digital Black Dial`,
    paragraph1:`The watch features a clear and easy-to-read digital black dial, displaying time, date, and other essential functions. The illuminated display ensures visibility even in low-light conditions.`,
    heading2:`Fitness Tracking`,
    paragraph2:`Equipped with fitness tracking capabilities, including step counting and calorie monitoring, this G-Shock watch helps you stay on top of your fitness goals and daily activity levels.`,
    heading3:`Water Resistance`,
    paragraph3:`With a water resistance rating of up to 200 meters, the GBD-200UU-9DR is suitable for swimming, snorkeling, and other water sports.`,
    heading4:`Shock Resistance`,
    paragraph4:`Designed to meet G-Shock's high standards, this watch is shock-resistant, protecting it against impact and vibrations during activities.`,
    keyword:`casio G SHOCK GBD 200UU 9DR G1248`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Dresses_Inside_Desktop-Banner_RM-1707971929.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fwomen-dresses`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
  {
    name:`121-Casio-Digital-Black-Dial-Watch-GBD-200RD-4DR`,
    title:`101 Casio Men Rubber G-Shock Digital Black Dial Gbd-200Rd-4Dr (G1203), Band Color-Red`,
    rate:'Rs.11,045',
    content: [  
      `The Casio G-Shock GBD-200RD-4DR (G1203) is a robust men's watch featuring a striking red rubber band and a digital black dial. This timepiece combines style with functionality, offering features like step tracking, heart rate monitoring, GPS functionality, and smartphone connectivity. With its rugged construction, water resistance, and versatile fitness tracking capabilities, the GBD-200RD-4DR is an ideal companion for active lifestyles and sports enthusiasts.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-red-600`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/dp/B09SV3B2FP/ref=sspa_dk_detail_5?psc=1&pd_rd_i=B09SV3B2FP&pd_rd_w=xkmj5&content-id=amzn1.sym.dcd65529-2e56-4c74-bf19-15db07b4a1fc&pf_rd_p=dcd65529-2e56-4c74-bf19-15db07b4a1fc&pf_rd_r=KTHRVMDMYAE42KCKM047&pd_rd_wg=XmspH&pd_rd_r=86197eea-8ac2-4710-aa51-aa64c0956989&sp_csd=d2lkZ2V0TmFtZT1zcF9kZXRhaWxfdGhlbWF0aWM&tag=inrdeal123-21`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://m.media-amazon.com/images/I/61i0C9eF0ML._SY741_.jpg`,
    image:`https://m.media-amazon.com/images/I/51YD-Ck6gjL._SX679_.jpg`,
    image1:`https://m.media-amazon.com/images/I/613Zd6a-1tL._SX679_.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Rugged Design`,
    paragraph:`The Casio G-Shock GBD-200RD-4DR (G1203) is built to withstand tough conditions, featuring a shock-resistant structure that protects the watch from impact and vibrations during activities.`,
    heading1:`Digital Black Dial`,
    paragraph1:`The watch boasts a clear and easy-to-read digital display with a black dial, offering essential timekeeping functions along with various smart features for fitness tracking and notifications.`,
    heading2:`Red Rubber Band`,
    paragraph2:`The striking red rubber band not only adds a vibrant pop of color but also provides a comfortable and secure fit on the wrist, suitable for active use and sports.`,
    heading3:`Fitness Tracking`,
    paragraph3:`This G-Shock model comes equipped with fitness tracking capabilities, including step counting, heart rate monitoring, and activity tracking, making it an excellent companion for health-conscious individuals.`,
    heading4:`GPS Functionality`,
    paragraph4:`Integrated GPS functionality allows for accurate tracking of outdoor activities and routes, providing real-time location information and distance covered.`,
    keyword:`Casio Digital Black Dial Watch GBD 200RD 4DR`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Dresses_Inside_Desktop-Banner_RM-1707971929.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fwomen-dresses`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`120-Casio-G-Shock-Digital-Watch-DW-5610SU-3DR-G1015`,
    title:`100 Casio G-Shock Digital Black Dial Unisex's Watch-DW-5610SU-3DR (G1015)`,
    rate:'Rs.6,930',
    content: [  
      `The Casio G-Shock DW-5610SU-3DR (G1015) is a rugged and stylish digital watch designed for both men and women. It features a sleek black dial with easy-to-read digital display and a durable resin case and band. This watch is equipped with essential G-Shock features including shock resistance, water resistance up to 200 meters, stopwatch, countdown timer, and daily alarms. Ideal for active lifestyles, it combines durability with practical functionality.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-lime-800`,
    wear:`Unisex `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Casio-G-Shock-Digital-Watch-DW-5610SU-3DR-G1015/dp/B088F8TLX8/ref=sr_1_28?crid=2RUBMYOASWAXT&dib=eyJ2IjoiMSJ9.UVJ9DvXztDrLLKMVd9idPpeG0qDL7ebA7GyjB3eLa9SGxWr-5FKmq3n6NlTWUXJwyzq5qnRVzCUuu1fDb6KrNkP5ttcsLNtgeA-7dKCFWivwfH7OUUvJhLsXkVnCfvR1N7afLiZ42rt3L4Nc_tHdDcEUBpZs3J4t-dftglrN5ZbZ2stZNlTw6RKZRVcNhcDKalKAZqlRqI5xHKLXBPe_tBrhGEl0wKq52pq2jlwTPXzBp_25UjLXsyGRJa3zp2GznUcWcbEdfQsXtIPVoFTEHYdeaO-nwUT_voDaG-hTYww.-_gGIDTOlb2jxUZtrKZFEPlrS1pcN7lQaFaYcEhItyg&dib_tag=se&keywords=g+shock+casio&qid=1713596648&sprefix=g+shock+casio,aps,217&sr=8-28&tag=inrdeal123-21`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://m.media-amazon.com/images/I/61YeCTdT3NL._SY741_.jpg`,
    image:`https://m.media-amazon.com/images/I/51Kd-yQOduL._SX679_.jpg`,
    image1:`https://m.media-amazon.com/images/I/51FD4xieQPS._SX679_.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Rugged Design`,
    paragraph:`The Casio G-Shock DW-5610SU-3DR (G1015) boasts a rugged and durable design suitable for various activities. Its shock-resistant structure protects against impact and vibration, making it ideal for outdoor adventures and everyday wear.`,
    heading1:`Digital Display`,
    paragraph1:`Featuring a black dial with a clear digital display, this watch offers easy readability of time, date, and other functions. The illuminated backlight ensures visibility even in low-light conditions.`,
    heading2:`Shock Resistance`,
    paragraph2:`Designed to meet G-Shock's high standards, this watch is shock-resistant, protecting the delicate internal mechanisms from sudden impacts or drops.`,
    heading3:`Resin Case and Band`,
    paragraph3:`Crafted from tough resin, the case and band of this G-Shock watch provide lightweight comfort without compromising on durability. The material is resistant to scratches and wear, maintaining its appearance over time.`,
    heading4:`Water Resistance`,
    paragraph4:`With a water resistance rating of 200 meters, the DW-5610SU-3DR is suitable for swimming, snorkeling, and water sports. It can withstand exposure to water without damage to its internal components.`,
    keyword:`Casio G Shock Digital Watch DW 5610SU 3DR G1015`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Dresses_Inside_Desktop-Banner_RM-1707971929.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fwomen-dresses`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },




  {
    name:`119-WOMENS-ENGINEERED-PRINT-DRESS`,
    title:`99 SKECHERS WOMEN'S ENGINEERED PRINT DRESS`,
    rate:'Rs.5,999',
    content: [  
      `The Skechers Women's Engineered Print Dress is a stylish and versatile piece designed for comfort and fashion. Featuring an eye-catching engineered print, this dress offers a contemporary look suitable for various occasions. It is crafted from quality materials to provide a flattering and comfortable fit. Pair it with sneakers for a casual daytime outfit or dress it up with sandals or heels for a more polished appearance. Ideal for adding a touch of flair to your wardrobe.`
    ],
    tcolor:`lg:text-md text-lg mt-5 text-lg font-bold text-cyan-500`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/skechers.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.skechers.in%2Fengineered-print-dress%2FDR0002ID-BLK.html`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.skechers.in/on/demandware.static/-/Sites-skechers_india/default/dw1757a28d/images/large/198376238438-1.jpg`,
    image:`https://www.skechers.in/on/demandware.static/-/Sites-skechers_india/default/dwebdda422/images/large/198376238438-3.jpg`,
    image1:`https://www.skechers.in/on/demandware.static/-/Sites-skechers_india/default/dw2210bdbd/images/large/198376238438-4.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Intricate Crochet Detailing`,
    paragraph:`The Crochet Knit Top by Urbanic is characterized by its intricate crochet detailing, which adds a touch of artisanal charm and elegance to the garment. The delicate patterns and textures of crochet create a visually appealing and unique look.`,
    heading1:`Quality Craftsmanship`,
    paragraph1:`Crafted with attention to detail, this knit top showcases expert craftsmanship. Each crochet stitch is carefully executed, resulting in a well-made and stylish piece that stands out.`,
    heading2:`Breathable Knit Fabric`,
    paragraph2:`Made from breathable knit fabric, this top offers comfort and ease of wear, making it suitable for warmer weather. The fabric drapes gracefully and feels soft against the skin.`,
    heading3:`Versatile Styling Options`,
    paragraph3:`The Crochet Knit Top is versatile and can be styled in various ways. Wear it over a camisole or bikini top as a stylish beach cover-up, or pair it with high-waisted jeans or shorts for a casual yet chic daytime look.`,
    heading4:`Bohemian Vibe`,
    paragraph4:`With its crochet detailing, this top exudes a bohemian vibe that is perfect for those who appreciate relaxed and free-spirited style. It adds a touch of whimsy to any outfit.`,
    keyword:`print dress trendz`,
    summary:`https://assets.ajio.com/medias/sys_master/images/images/hb2/h78/16750141276190/02052020-M-Pantaloons-topbanner-hottesttrends.jpg`,
    jlink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.ajio.com%2Fs%2F50-to-90-percent-off-5351-81591`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`118-Crochet-Knit-Top`,
    title:`98 Urbanic Crochet Knit Top`,
    rate:'Rs 1,490',
    content: [  
      `The Urbanic Crochet Knit Top embodies a blend of bohemian charm and contemporary style. This top is intricately crafted with crochet detailing, offering a delicate and artistic appeal. It features a comfortable and breathable knit fabric that drapes beautifully. Perfect for layering over a camisole or swimsuit, this versatile piece adds a touch of effortless elegance to any outfit. Ideal for warm weather, the Crochet Knit Top is a must-have for those seeking a chic and relaxed aesthetic.`
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-green-500`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fcrochet-knit-top-118559%3Fvid%3D18`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/0c100e3a9d4045c5acbe3ea2467ff8efUR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/0fe0cf9d1c254c1a9a0ff6b4137939a8UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/b13f6dfa01bb4a95933a13c9da58d77dUR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Intricate Crochet Detailing`,
    paragraph:`The Crochet Knit Top by Urbanic is characterized by its intricate crochet detailing, which adds a touch of artisanal charm and elegance to the garment. The delicate patterns and textures of crochet create a visually appealing and unique look.`,
    heading1:`Quality Craftsmanship`,
    paragraph1:`Crafted with attention to detail, this knit top showcases expert craftsmanship. Each crochet stitch is carefully executed, resulting in a well-made and stylish piece that stands out.`,
    heading2:`Breathable Knit Fabric`,
    paragraph2:`Made from breathable knit fabric, this top offers comfort and ease of wear, making it suitable for warmer weather. The fabric drapes gracefully and feels soft against the skin.`,
    heading3:`Versatile Styling Options`,
    paragraph3:`The Crochet Knit Top is versatile and can be styled in various ways. Wear it over a camisole or bikini top as a stylish beach cover-up, or pair it with high-waisted jeans or shorts for a casual yet chic daytime look.`,
    heading4:`Bohemian Vibe`,
    paragraph4:`With its crochet detailing, this top exudes a bohemian vibe that is perfect for those who appreciate relaxed and free-spirited style. It adds a touch of whimsy to any outfit.`,
    keyword:`co white crochet cotton regular top`,
    summary:`https://assets.ajio.com/medias/sys_master/images/images/hb2/h78/16750141276190/02052020-M-Pantaloons-topbanner-hottesttrends.jpg`,
    jlink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.ajio.com%2Fs%2F50-to-90-percent-off-5351-81591`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },



  {
    name:`117-Keyhole-Neck-Crop-Top`,
    title:`97 Urbanic Keyhole Neck Crop Top`,
    rate:'Rs 1,374',
    content: [  
      `The Urbanic Keyhole Neck Crop Top is a fashion-forward piece designed for modern versatility. With its flattering keyhole neckline, this crop top exudes sophistication and style. Crafted from quality materials, it offers a comfortable fit and sleek silhouette. Perfect for pairing with high-waisted jeans or skirts, this top transitions seamlessly from day to night. Whether for a casual outing or a chic evening look, the Keyhole Neck Crop Top adds a touch of elegance to any outfit.`
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-pink-400`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fkeyhole-neck-crop-top-116375%3Fvid%3D247`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/4fffddde9ded46fb83c198552b2f4d5dUR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/9fbdd17309ef46a49bd83b7234bf29ccUR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/d91d8c69dca24c39aea0df30b88a423eUR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Design`,
    paragraph:`The Keyhole Neck Crop Top by Urbanic features a distinctive keyhole neckline, adding a touch of elegance and allure to the garment. This unique design detail sets it apart from standard crop tops, making it a standout piece in your wardrobe.`,
    heading1:`Quality Fabric`,
    paragraph1:`Crafted from premium materials such as cotton, polyester, or a blend, this crop top ensures comfort and durability. The fabric is soft against the skin and maintains its shape even with regular wear.`,
    heading2:`Flattering Fit`,
    paragraph2:`The crop top is tailored to provide a flattering fit, accentuating the waistline and creating a sleek silhouette. The keyhole neckline adds a subtle focal point that draws attention to the upper body.`,
    heading3:`Versatile Styling`,
    paragraph3:`This versatile top can be styled in various ways to suit different occasions. Pair it with high-waisted jeans, shorts, or skirts for a chic and trendy look.`,
    heading4:`Keyhole Detail`,
    paragraph4:`The keyhole neckline is designed to be both stylish and functional, offering a hint of skin without revealing too much. It adds a sophisticated and playful element to the crop top.`,
    keyword:`keyhole neck fitted crop top`,
    summary:`https://assets.ajio.com/medias/sys_master/images/images/hb2/h78/16750141276190/02052020-M-Pantaloons-topbanner-hottesttrends.jpg`,
    jlink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.ajio.com%2Fs%2F50-to-90-percent-off-5351-81591`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`116-Ruffle-Fitted-Crop-Top`,
    title:`96 Urbanic Ruffle Fitted Crop Top`,
    rate:'Rs 1,592',
    content: [  
      `The Urbanic Ruffle Fitted Crop Top is a charming blend of elegance and trendiness. This stylish top features a fitted silhouette that accentuates the figure, while delicate ruffle details add a touch of femininity and flair. Crafted from quality materials, it offers comfort and versatility. Perfect for pairing with high-waisted skirts or pants, this crop top is ideal for both casual outings and special occasions, adding a chic and fashionable vibe to any ensemble.`
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-red-400`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fruffle-fitted-crop-top-116624%3Fvid%3D7`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/1f2f5b723055470e8f0acdced4598dd5UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/fd6116fc98af4e30a21f1a2ec72e3225UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/9d0343a85b9b49b88b54fabb59b40644UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Design`,
    paragraph:`The Ruffle Fitted Crop Top by Urbanic is distinguished by its unique design featuring feminine ruffle details. These ruffles add a touch of charm and elegance to the fitted silhouette, making it stand out from typical crop tops.`,
    heading1:`Quality Fabric`,
    paragraph1:`Crafted from high-quality materials such as cotton, polyester, or a blend, this crop top offers a comfortable and breathable feel against the skin. The fabric is durable and maintains its shape even with regular wear.`,
    heading2:`Fitted Silhouette`,
    paragraph2:`The fitted cut of the crop top hugs the body in a flattering way, accentuating the curves while providing a sleek and stylish look.`,
    heading3:`Versatile Styling`,
    paragraph3:`This versatile piece can be styled in various ways. Pair it with high-waisted jeans, skirts, or trousers for a chic and trendy outfit suitable for different occasions.`,
    heading4:`Delicate Ruffle Details`,
    paragraph4:`The delicate ruffle embellishments along the neckline, sleeves, or hemline add a romantic and playful touch to the crop top, elevating its overall appeal.`,
    keyword:`red print ruffled top`,
    summary:`https://assets.ajio.com/medias/sys_master/images/images/hb2/h78/16750141276190/02052020-M-Pantaloons-topbanner-hottesttrends.jpg`,
    jlink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.ajio.com%2Fs%2F50-to-90-percent-off-5351-81591`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`115-Shirt-Collar-Crop-Top`,
    title:`95 Urbanic Shirt Collar Crop Top`,
    rate:'Rs 1,393',
    content: [  
      `The Urbanic Shirt Collar Crop Top blends sophistication with modern flair. This stylish piece features a classic shirt collar combined with a cropped silhouette, offering a contemporary twist on a traditional design. Crafted from quality fabric, it's comfortable and versatile. Ideal for pairing with high-waisted bottoms, it's perfect for creating chic and trendy outfits for any occasion.`
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-orange-500`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fshirt-collar-crop-top-117713%3Fvid%3D17`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/a7e80d75181144d39f8cebdb10a3384bUR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/d752fa1a52524bd480b3d75c2161338bUR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/508703456c604376ab3667dee711f0c8UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Design`,
    paragraph:`The Shirt Collar Crop Top by Urbanic stands out with its innovative design, combining a classic shirt collar with a cropped length. This unique fusion of styles adds a modern and stylish touch to your wardrobe.`,
    heading1:`Quality Construction`,
    paragraph1:`Crafted from high-quality materials like cotton, polyester, or a blend, this crop top offers a comfortable and breathable fit. The fabric is durable and retains its shape even after multiple wears.`,
    heading2:`Versatile Styling`,
    paragraph2:`The versatility of this crop top makes it a wardrobe essential. You can pair it with high-waisted jeans, skirts, or trousers for a chic and trendy look suitable for various occasions.`,
    heading3:`Classic Collar Detail`,
    paragraph3:`The inclusion of a shirt collar adds sophistication to the crop top, creating a polished appearance that can be dressed up or down.`,
    heading4:`Cropped Silhouette`,
    paragraph4:`The cropped length of this top accentuates the waistline and allows for easy layering with jackets or cardigans, making it suitable for year-round wear.`,
    keyword:`polo collar cotton fitted crop top`,
    summary:`https://assets.ajio.com/medias/sys_master/images/images/hb2/h78/16750141276190/02052020-M-Pantaloons-topbanner-hottesttrends.jpg`,
    jlink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.ajio.com%2Fs%2F50-to-90-percent-off-5351-81591`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`114-Ribbed-Fitted-Knit-Top`,
    title:`94 Urbanic Ribbed Fitted Knit Top`,
    rate:'Rs.1,990',
    content: [  
      `The Ribbed Fitted Knit Top by Urbanic is a chic and versatile wardrobe staple. Crafted from a stretchy ribbed knit fabric, it contours to the body beautifully, offering a flattering silhouette. This top features a crew neckline and long sleeves, making it ideal for layering or wearing on its own. Perfect for creating stylish casual looks, it seamlessly transitions from day to night with ease.`
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-stone-500`,
    wear:`Women's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fribbed-fitted-knit-top-117313%3Fvid%3D319`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/26f37c07d3a94cc3b1ed62e1239f07f5UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/e5349a63110d4a9d9224ec60fd6a6249UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/626a71d22f4b48ac95a9bc5b2036c152UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Quality Fabric`,
    paragraph:`The Ribbed Fitted Knit Top from Urbanic is made from high-quality ribbed knit fabric, typically a blend of cotton, polyester, or spandex. This combination offers a comfortable stretch while maintaining its shape and structure.`,
    heading1:`Flattering Fit`,
    paragraph1:`The top is designed to be fitted and body-hugging, accentuating your curves in a flattering way. The ribbed texture adds depth and dimension to the fabric, enhancing the overall look.`,
    heading2:`Versatile Styling`,
    paragraph2:`This knit top is incredibly versatile. You can pair it with high-waisted jeans, skirts, or trousers for a chic and put-together outfit. Layer it under jackets or cardigans for added warmth and style during cooler weather.`,
    heading3:`Comfortable Wear`,
    paragraph3:`Despite its fitted nature, the ribbed knit fabric is soft and comfortable against the skin. It allows for ease of movement, making it suitable for all-day wear.`,
    heading4:`Classic Crew Neckline`,
    paragraph4:`The top features a classic crew neckline, which is universally flattering and easy to style with different accessories.`,
    keyword:`Womens Sleeve Knitted top`,
    summary:`https://assets.ajio.com/medias/sys_master/images/images/hb2/h78/16750141276190/02052020-M-Pantaloons-topbanner-hottesttrends.jpg`,
    jlink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fwww.ajio.com%2Fs%2F50-to-90-percent-off-5351-81591`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`113-Knotted-Bodysuit`,
    title:`93 Urbanic Knotted Bodysuit`,
    rate:'Rs.1,290',
    content: [  
      `The Urbanic Knotted Bodysuit is a stylish and contemporary piece designed to elevate your wardrobe. Featuring a unique knotted detail at the front, this bodysuit offers a flattering silhouette and modern edge. Crafted from comfortable and stretchy fabric, it provides a seamless fit and is ideal for pairing with high-waisted jeans, skirts, or trousers. Perfect for both casual and dressed-up occasions, the Knotted Bodysuit adds a touch of sophistication to any outfit.`
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-red-500`,
    wear:`Women's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fknotted-bodysuit-115098%3Fvid%3D129`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/5c629193bf70444b9173fc8b5e0bd524UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/c5e239f63b7145de8ba949c03b8e93dcUR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/1c53d5b929e548339cf3dd0d8c26199eUR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Knotted Detail`,
    paragraph:`The Knotted Bodysuit by Urbanic features a striking and unique knotted detail at the front, adding a contemporary and stylish touch to the garment. This knot serves as a focal point, enhancing the overall design and creating visual interest.`,
    heading1:`Quality Fabric`,
    paragraph1:`Crafted from high-quality and stretchy fabric such as spandex or polyester blend, this bodysuit offers a comfortable and flattering fit. The fabric conforms to the body while allowing for ease of movement, making it suitable for all-day wear.`,
    heading2:`Flattering Silhouette`,
    paragraph2:`The knotted design accentuates the waistline, creating a flattering silhouette that enhances your curves. This bodysuit is designed to hug the body in all the right places, providing a sleek and chic look.`,
    heading3:`Versatile Styling`,
    paragraph3:`The Knotted Bodysuit is highly versatile and can be styled in numerous ways. Pair it with high-waisted jeans, skirts, or shorts for a casual and trendy look, or dress it up with tailored pants or a skirt for a more polished ensemble.`,
    heading4:`knotted bodysuit top trend'z`,
    paragraph4:`Despite its fitted silhouette, the bodysuit remains comfortable to wear throughout the day. The stretchy fabric allows for unrestricted movement, making it suitable for various activities.`,
    keyword:`Knotter body wear`,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-Closet-Essential-Shirts--1--1712726646.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fshirts`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`112-Printed-Fitted-TShirt`,
    title:`92 Urbanic Printed Fitted T-Shirt`,
    rate:'Rs.1,290',
    content: [  
      `The Printed Fitted T-Shirt by Urbanic offers a sleek and stylish look with its form-fitting design and eye-catching prints. Made from quality materials, this t-shirt combines comfort and fashion effortlessly. It features vibrant prints and patterns that add personality to any outfit. Ideal for casual or layered looks, this tee is a versatile wardrobe essential for those who appreciate contemporary style.
      `
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-amber-700`,
    wear:`Women's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fprinted-fitted-fitted-t-shirt-117311%3Fvid%3D21`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/1fc910061dff4095b5c862ec58d688c7UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/a86c962be4c6440ebad0f6931610ded8UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/29e0bf6f9f4c47f1bb790bc8efa11ceeUR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Material Quality`,
    paragraph:`The Urbanic Printed Fitted T-Shirt is crafted from high-quality fabric that ensures a soft and comfortable feel against the skin. The material blend typically includes cotton or a cotton-polyester mix, offering durability and breathability.`,
    heading1:`Fit and Style`,
    paragraph1:`This t-shirt is designed to be form-fitting, accentuating your body shape in a flattering way. It hugs the curves without feeling restrictive, creating a sleek and stylish look suitable for various body types.`,
    heading2:`Printed Designs`,
    paragraph2:`One of the standout features of this t-shirt is its printed designs. Urbanic offers a wide range of prints, from bold graphics to intricate patterns, allowing you to express your personal style.`,
    heading3:`Versatility`,
    paragraph3:`The versatility of this fitted t-shirt makes it a wardrobe staple. It can be dressed up with a blazer and jeans for a casual yet polished look, or worn with shorts for a laid-back vibe.`,
    heading4:`Dream Beauty Fashion Sania Top`,
    paragraph4:`Despite its fitted silhouette, the t-shirt remains comfortable throughout the day. The fabric is lightweight and allows for easy movement, making it suitable for various activities.`,
    keyword:`Girls trendsetter wear`,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-Closet-Essential-Shirts--1--1712726646.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fshirts`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`111-Sheer-Pullover-TShirt`,
    title:`91 Urbanic Sheer Pullover T-Shirt`,
    rate:'Rs.1,890',
    content: [  
      `The Sheer Pullover T-Shirt by Urbanic is a stylish blend of comfort and contemporary design. Crafted from lightweight, breathable fabric, it features a sheer texture that adds a touch of sophistication to any casual outfit. With a relaxed fit and versatile appeal, this pullover is ideal for layering or wearing on its own, offering effortless chic for everyday wear.`
    ],
    tcolor:`lg:text-xl text-lg mt-5 text-lg font-bold text-pink-500`,
    wear:`Women's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fsheer-pullover-t-shirt-113194%3Fvid%3D6`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/4ab2510f9ffd404aae428e30f41e8738UR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/0bbf920637274dd3b863a196678625e9UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/36f6ff973a7a4d69916597f744a6e1b7UR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Material and Construction`,
    paragraph:`The Urbanic Sheer Pullover T-Shirt is expertly crafted using a blend of lightweight and breathable fabrics. The material has a sheer texture that adds a stylish and contemporary element to the garment.`,
    heading1:`Design Details`,
    paragraph1:`This pullover T-shirt features a minimalist design with clean lines and a relaxed fit, making it versatile for various occasions. It typically has a crew neckline and short sleeves for a classic look.`,
    heading2:`Comfortable Fit`,
    paragraph2:`The loose and airy fit of the sheer pullover ensures maximum comfort, making it ideal for casual wear during warmer weather. The fabric drapes elegantly, offering a flattering silhouette.`,
    heading3:`Layering Piece`,
    paragraph3:`This T-shirt serves as a perfect layering piece. You can effortlessly pair it with a tank top or a bralette underneath for a trendy and layered outfit.`,
    heading4:`Versatile Styling`,
    paragraph4:`Whether you wear it with denim jeans, shorts, or a skirt, the Urbanic Sheer Pullover T-Shirt adds a touch of sophistication to any ensemble. It can be dressed up or down depending on the occasion.`,
    keyword:`talkies black self design sheer crop top`,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-Closet-Essential-Shirts--1--1712726646.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fshirts`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`110-Printed-Wrap-Skirt`,
    title:`90 Urbanic Printed Wrap Skirt`,
    rate:'Rs.2,090',
    content: [  
      `The Urbanic Printed Wrap Skirt epitomizes contemporary chic with its versatile design. Crafted from lightweight fabric, this skirt features a flattering wrap silhouette that ties at the waist for adjustable comfort. Its vibrant print adds a playful touch, making it perfect for both casual outings and evening gatherings. Pair it with a simple top and sandals for effortless style that transitions seamlessly from day to night.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Women's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/urbanic.png`,
    blink:`https://linksredirect.com/?cid=190459&source=linkkit&url=https%3A%2F%2Fin.urbanic.com%2Fdetails%2Fprinted-wrap-skirt-118174%3Fvid%3D11`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://img101.urbanic.com/v1/goods-pic/048282c8d6f14a02a0b6371f6676803fUR_w1440_q90.webp`,
    image:`https://img101.urbanic.com/v1/goods-pic/e76d3e6fa64e41be8f40dd6e08164853UR_w1440_q90.webp`,
    image1:`https://img101.urbanic.com/v1/goods-pic/2917011ca0e5497180650ef7b939b93cUR_w1440_q90.webp`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Design and Style`,
    paragraph:`The Urbanic Printed Wrap Skirt boasts a sophisticated design with a wrap-around silhouette that flatters various body shapes. Its adjustable tie closure at the waist ensures a comfortable fit while adding a touch of elegance to the overall look.`,
    heading1:`Fabric Quality`,
    paragraph1:`Crafted from premium lightweight fabric, this skirt is breathable and comfortable for all-day wear. The material drapes beautifully, enhancing the skirt's movement and flow with every step.`,
    heading2:`Print and Pattern`,
    paragraph2:`The skirt features eye-catching prints and patterns, ranging from bold florals to geometric motifs, adding a vibrant and trendy appeal to your outfit. The print is meticulously crafted, enhancing the skirt's aesthetic allure.`,
    heading3:`Versatility`,
    paragraph3:`Ideal for multiple occasions, this skirt effortlessly transitions from casual daytime outings to evening events. Pair it with a tank top and sandals for a relaxed day look, or dress it up with a blouse and heels for a more sophisticated ensemble.`,
    heading4:`Comfort and Fit`,
    paragraph4:`The wrap style allows for flexibility in sizing, accommodating various body types comfortably. The adjustable waist tie ensures a personalized fit that flatters the waistline.`,
    keyword:`printed wrap midi skirt`,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-Closet-Essential-Shirts--1--1712726646.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fshirts`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },




  {
    name:`109-Black-Kyuubi-Mode-Graphic`,
    title:`89 Bewakoof® Men's Black Kyuubi Mode Graphic Printed Oversized T-shirt`,
    rate:'Rs.579',
    content: [  
      `The Bewakoof® Men's Black Kyuubi Mode Graphic Printed Oversized T-shirt is a striking blend of style and comfort. Featuring a captivating Kyuubi Mode graphic print, this t-shirt exudes a unique charm inspired by pop culture. The oversized fit offers a relaxed silhouette, perfect for casual wear. Crafted from high-quality fabric, it ensures durability and softness, making it a standout addition to any modern wardrobe.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-black-kyuubi-mode-graphic-printed-oversized-t-shirt`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-black-kyuubi-mode-graphic-printed-oversized-t-shirt-565924-1708440556-1.jpg`,
    image:`https://images.bewakoof.com/t1080/men-s-black-kyuubi-mode-graphic-printed-oversized-t-shirt-565924-1672373786-2.jpg`,
    image1:`https://images.bewakoof.com/t1080/men-s-black-kyuubi-mode-graphic-printed-oversized-t-shirt-565924-1672373807-6.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Graphic Design`,
    paragraph:`The Bewakoof® Men's Black Kyuubi Mode Graphic Printed Oversized T-shirt features a captivating and unique Kyuubi Mode graphic. Inspired by anime and pop culture, the design showcases intricate details that make it stand out.`,
    heading1:`Oversized Fit`,
    paragraph1:`Enjoy a comfortable and relaxed feel with the oversized fit of this t-shirt. The loose silhouette allows for ease of movement and adds a trendy vibe to your outfit.`,
    heading2:`Premium Quality Fabric`,
    paragraph2:`Crafted from high-quality fabric, this t-shirt is soft, breathable, and durable. It offers exceptional comfort and maintains its shape even after multiple washes.`,
    heading3:`Versatile Black Color`,
    paragraph3:`The classic black color makes this t-shirt easy to style and pair with different bottoms and outerwear. It's a versatile piece for various occasions.`,
    heading4:`Statement Piece`,
    paragraph4:`Make a bold statement with the Kyuubi Mode graphic, expressing your love for anime and unique fashion.`,
    keyword:`Black T-Shirt`,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-epic-fandom-bundle--3--1712297990.jpg`,
    jlink:`https://bit.ly/4at9iwC`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`108-Black-Venomized-Graphic-Printed`,
    title:`88 Bewakoof® Men's Black Venomized Graphic Printed Oversized T-shirt`,
    rate:'Rs.799',
    content: [  
      `The Bewakoof® Men's Black Venomized Graphic Printed Oversized T-shirt is a bold and stylish choice. Featuring a venomized graphic design, this t-shirt combines comfort with a trendy oversized fit. The black color adds versatility, perfect for casual outings or lounging. Made from high-quality fabric, it offers both durability and softness. Elevate your streetwear look with this standout piece from Bewakoof®.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-black-venomized-graphic-printed-oversized-t-shirt-black`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-black-venomized-graphic-printed-oversized-t-shirt-633979-1710228591-1.jpg?tr=q-100`,
    image:`https://images.bewakoof.com/t1080/men-s-black-venomized-graphic-printed-oversized-t-shirt-633979-1710228624-7.jpg?tr=q-100`,
    image1:`https://images.bewakoof.com/t1080/men-s-black-venomized-graphic-printed-oversized-t-shirt-633979-1710228597-2.jpg?tr=q-100`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Trendy Design`,
    paragraph:`The Bewakoof® Men's Black Venomized Graphic Printed Oversized T-shirt showcases a striking and contemporary design. The venomized graphic, inspired by pop culture, adds a unique and edgy appeal to the overall look.`,
    heading1:`Oversized Fit`,
    paragraph1:`This t-shirt features an oversized fit, offering a relaxed and comfortable style. The loose silhouette provides freedom of movement and a laid-back vibe, making it perfect for casual wear.`,
    heading2:`High-Quality Fabric`,
    paragraph2:`Crafted from premium-quality fabric, the t-shirt ensures durability and comfort. The material is soft against the skin, making it suitable for all-day wear.`,
    heading3:`Versatile Black Color`,
    paragraph3:`The classic black color makes this t-shirt versatile and easy to style. It can be paired effortlessly with various bottoms and jackets, suitable for different occasions.`,
    heading4:`Statement Piece`,
    paragraph4:`With its bold graphic print and oversized silhouette, this t-shirt becomes a statement piece in your wardrobe. It adds personality and character to any outfit.`,
    keyword:``,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-epic-fandom-bundle--3--1712297990.jpg`,
    jlink:`https://bit.ly/4at9iwC`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`107-Black-Hope-Street-Typography`,
    title:`87 Bewakoof Men's Black Hope Street Typography T-shirt`,
    rate:'Rs.419',
    content: [  
      `The Bewakoof® Men's Black Hope Street Typography T-shirt is a stylish and versatile choice. Featuring a bold and eye-catching "Hope Street" typography design, this black tee offers a modern aesthetic. Crafted from high-quality fabric, it ensures comfort and durability. Ideal for casual outings or everyday wear, this T-shirt effortlessly combines fashion with a positive message, making it a must-have addition to any wardrobe.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fhope-street-half-sleeve-t-shirt-men`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-black-hope-street-typography-t-shirt-500392-1706598485-1.jpg?tr=q-100`,
    image:`https://images.bewakoof.com/t1080/men-s-black-hope-street-typography-t-shirt-500392-1694766453-4.jpg?tr=q-100`,
    image1:`https://images.bewakoof.com/t1080/men-s-black-hope-street-typography-t-shirt-500392-1694766442-2.jpg?tr=q-100`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Design and Color`,
    paragraph:`This T-shirt boasts a refreshing green hue, complemented by a prominent "Better & Better" graphic print on the front. The color is vibrant and eye-catching, perfect for casual outings.`,
    heading1:`Material and Comfort`,
    paragraph1:`Crafted from premium quality fabric, the T-shirt offers exceptional comfort and breathability. The soft material ensures a comfortable fit throughout the day.`,
    heading2:`Oversized Fit`,
    paragraph2:`The oversized silhouette provides a relaxed and laid-back vibe, making it suitable for various body types and ensuring freedom of movement.`,
    heading3:`Graphic Print`,
    paragraph3:`The "Better & Better" graphic print adds a stylish and motivational element to the T-shirt, making it more than just a casual outfit choice.`,
    heading4:`Versatility`,
    paragraph4:`This T-shirt is versatile and can be paired effortlessly with jeans, shorts, or even layered under a jacket for different looks.`,
    keyword:`Shed t-shirts`,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-epic-fandom-bundle--3--1712297990.jpg`,
    jlink:`https://bit.ly/4at9iwC`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },




  {
    name:`106-Green-Better-Better-Graphic`,
    title:`86 Bewakoof Men's Green Better & Better Graphic Printed Oversized T-shirt`,
    rate:'Rs.799',
    content: [  
      `The Bewakoof® Men's Green "Better & Better" Graphic Printed Oversized T-shirt is a stylish and comfortable garment designed for casual wear. Made from quality fabric, it features a vibrant green color with a bold graphic print displaying the phrase "Better & Better." The oversized fit adds a trendy and relaxed appeal, making it ideal for everyday use with jeans or shorts.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-lime-400`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-green-better-better-graphic-printed-oversized-t-shirt-men-green`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-green-better-better-graphic-printed-oversized-t-shirt-633981-1710228262-1.jpg?tr=q-100`,
    image:`https://images.bewakoof.com/t1080/men-s-green-better-better-graphic-printed-oversized-t-shirt-633981-1710228290-6.jpg`,
    image1:`https://images.bewakoof.com/t1080/men-s-green-better-better-graphic-printed-oversized-t-shirt-633981-1710228296-7.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Design and Color`,
    paragraph:`This T-shirt boasts a refreshing green hue, complemented by a prominent "Better & Better" graphic print on the front. The color is vibrant and eye-catching, perfect for casual outings.`,
    heading1:`Material and Comfort`,
    paragraph1:`Crafted from premium quality fabric, the T-shirt offers exceptional comfort and breathability. The soft material ensures a comfortable fit throughout the day.`,
    heading2:`Oversized Fit`,
    paragraph2:`The oversized silhouette provides a relaxed and laid-back vibe, making it suitable for various body types and ensuring freedom of movement.`,
    heading3:`Graphic Print`,
    paragraph3:`The "Better & Better" graphic print adds a stylish and motivational element to the T-shirt, making it more than just a casual outfit choice.`,
    heading4:`Versatility`,
    paragraph4:`This T-shirt is versatile and can be paired effortlessly with jeans, shorts, or even layered under a jacket for different looks.`,
    keyword:`Cartoon t-shirts`,
    summary:`https://images.bewakoof.com/uploads/grid/app/DESKTOP-mid-size-epic-fandom-bundle--3--1712297990.jpg`,
    jlink:`https://bit.ly/4at9iwC`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
  name:`105-OFFICIAL-CARTOON-NETWORK-MERCHANDISE`,
  title:`85 Bewakoof OFFICIAL CARTOON NETWORK MERCHANDISE Oversized T-Shirt`,
  rate:'Rs.439',
  content: [  
    `The Bewakoof Official Cartoon Network Merchandise Oversized T-Shirt is a product offered by Bewakoof, a clothing brand, featuring licensed designs from Cartoon Network properties. This oversized T-shirt provides a comfortable and relaxed fit, suitable for casual wear. With its officially licensed Cartoon Network graphics, it allows fans to showcase their love for iconic characters and shows from the network in a stylish and trendy manner.`
  ],
  tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-blue-800`,
  wear:`Men's Wear `,
  Scolor:"text-black text-lg font-semibold",
  buy:`/images/bewakoof.png`,
  blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmen-eddys-big-stash-oversized-graphic-printed-t-shirt`,
  buy1:`/images/nosite.jpg`,
  blink1:``,
  buy2:`/images/nosite.jpg`,
  blink2:`` ,
  thumbnail:`https://images.bewakoof.com/t1080/men-s-blue-eddys-big-stash-graphic-printed-oversized-t-shirt-608714-1709231274-1.jpg?tr=q-100`,
  image:`https://images.bewakoof.com/t1080/men-eddys-big-stash-oversized-graphic-printed-t-shirt-608714-1696418335-2.jpg?tr=q-100`,
  image1:`https://images.bewakoof.com/t1080/men-eddys-big-stash-oversized-graphic-printed-t-shirt-608714-1696418351-5.jpg?tr=q-100`,
  icon:`/images/instagram.png`,
  icon1:`/images/pinterest.png`,
  icon2:`/images/whatsapp.png`,
  instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
  pinterest:`https://in.pinterest.com/treind_z/`,
  whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
  heading:`Official Cartoon Network Merchandise`,
  paragraph:`This T-shirt is an officially licensed product, ensuring authenticity and quality in its design and production. Cartoon Network's stamp of approval guarantees that fans can trust the accuracy and integrity of the design.`,
  heading1:`Men's Blue Eddy's Big Stash Design`,
  paragraph1:`The graphic print on this T-shirt features Eddy, a beloved character from Cartoon Network, known for his cunning schemes and big personality. The design captures the essence of Eddy's character, making it a must-have for fans.`,
  heading2:`Oversized Fit`,
  paragraph2:`Designed for comfort and style, this T-shirt comes in an oversized fit. It offers a relaxed and laid-back look that's perfect for casual wear, whether you're lounging at home or hanging out with friends.`,
  heading3:`Graphic Print Detail`,
  paragraph3:`The high-quality graphic print is vibrant and eye-catching, showcasing Eddy's iconic look and personality. It adds a playful and nostalgic touch to any outfit, making it a standout piece in your wardrobe.`,
  heading4:`Blue Color`,
  paragraph4:`The T-shirt comes in a stylish blue color, which complements the vibrant graphic print. Blue is versatile and universally flattering, making it easy to incorporate into your everyday style.`,
  keyword:`oversized t-shirts`,
  summary:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/category/catban-1120240407120615.jpg?format=webp&w=1500&dpr=1.0`,
  jlink:`https://bit.ly/3U7W2aN`,
  bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
  
},




  {
    name:`104-Rick-Morty-Escape-From-Reality`,
    title:`84 The Shouled Store Rick & Morty: Escape From Reality
    Oversized T-Shirts`,
    rate:'Rs.999',
    content: [  
      `The Shouled Store Dungeons & Dragons Vintage Hooded T-Shirts are a must-have for any Dungeons & Dragons fan. These hooded t-shirts feature vintage-inspired designs that pay homage to the iconic role-playing game. With their comfortable fit and stylish look, they are perfect for casual wear or as part of a cosplay outfit. Express your love for D&D in style with these unique hooded t-shirts.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/theshouldstore.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Frick-and-morty-escape-from-reality-oversized-tshirts%3Fgte%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1711004068_5466159.jpg?format=webp&w=1000&dpr=1.0`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1685441682_1166933.jpg?format=webp&w=1080&dpr=1.0`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1683957386_1026068.jpg?format=webp&w=1080&dpr=1.0`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Official Licensed Merchandise`,
    paragraph:`The Shouled Store Dungeons & Dragons Vintage Hooded T-Shirts are officially licensed, ensuring authenticity and quality.`,
    heading1:`Unique Vintage Designs`,
    paragraph1:`These hooded t-shirts feature unique vintage-inspired designs that are perfect for fans of Dungeons & Dragons.`,
    heading2:`Comfortable Fabric`,
    paragraph2:`Made from high-quality fabric, these hooded t-shirts are comfortable to wear all day long.`,
    heading3:`Hooded Design`,
    paragraph3:`The hooded design adds a stylish and practical element to the t-shirt, making it perfect for all seasons.`,
    heading4:`Perfect for Fans`,
    paragraph4:`Whether you're a long-time fan of Dungeons & Dragons or just getting into the game, these hooded t-shirts are a great way to show off your love for the game.`,
    keyword:`oversized t-shirts`,
    summary:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/category/catban-1120240407120615.jpg?format=webp&w=1500&dpr=1.0`,
    jlink:`https://bit.ly/3U7W2aN`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },
  
  {
    name:`103-Dungeons-Dragons-Vintage-Hooded-TShirts`,
    title:`83 The Shouled Store Dungeons & Dragons Vintage Hooded T-Shirts`,
    rate:'Rs.1,199',
    content: [  
      `The Shouled Store Dungeons & Dragons Vintage Hooded T-Shirts are a must-have for any Dungeons & Dragons fan. These hooded t-shirts feature vintage-inspired designs that pay homage to the iconic role-playing game. With their comfortable fit and stylish look, they are perfect for casual wear or as part of a cosplay outfit. Express your love for D&D in style with these unique hooded t-shirts.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-orange-800`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/theshouldstore.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fdungeons-and-dragons-vintage-hooded-t-shirts%3Fgte%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1712557982_8609033.jpg?format=webp&w=1080&dpr=1.0`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1712557982_3059501.jpg?format=webp&w=1080&dpr=1.0`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1712557982_1777446.jpg?format=webp&w=1080&dpr=1.0`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Official Licensed Merchandise`,
    paragraph:`The Shouled Store Dungeons & Dragons Vintage Hooded T-Shirts are officially licensed, ensuring authenticity and quality.`,
    heading1:`Unique Vintage Designs`,
    paragraph1:`These hooded t-shirts feature unique vintage-inspired designs that are perfect for fans of Dungeons & Dragons.`,
    heading2:`Comfortable Fabric`,
    paragraph2:`Made from high-quality fabric, these hooded t-shirts are comfortable to wear all day long.`,
    heading3:`Hooded Design`,
    paragraph3:`The hooded design adds a stylish and practical element to the t-shirt, making it perfect for all seasons.`,
    heading4:`Perfect for Fans`,
    paragraph4:`Whether you're a long-time fan of Dungeons & Dragons or just getting into the game, these hooded t-shirts are a great way to show off your love for the game.`,
    keyword:`Hoodie t-shirts`,
    summary:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/category/catban-1120240407120615.jpg?format=webp&w=1500&dpr=1.0`,
    jlink:`https://bit.ly/3U7W2aN`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`102-OFFICIAL-MARVEL-MERCHANDISE`,
    title:`82 Bewakoof® OFFICIAL MARVEL MERCHANDISE Oversized T-shirt`,
    rate:'Rs.649',
    content: [  
      `Bewakoof® offers this Men's Black Moon Knight Graphic Printed Oversized T-shirt as part of their OFFICIAL MARVEL MERCHANDISE. This tee features a bold graphic print of the iconic Moon Knight character, perfect for fans of the Marvel Universe. The oversized fit adds a trendy touch, making it a versatile and stylish addition to any Marvel fan's wardrobe.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-gray-500`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-black-moon-knight-oversize-fit-t-shirt`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-black-moon-knight-graphic-printed-oversized-t-shirt-522479-1667506664-2.jpg?tr=q-100`,
    image:`https://images.bewakoof.com/t1080/men-s-black-moon-knight-graphic-printed-oversized-t-shirt-522479-1667506659-1.jpg?tr=q-100`,
    image1:`https://images.bewakoof.com/t1080/men-s-black-moon-knight-graphic-printed-oversized-t-shirt-522479-1667506670-3.jpg?tr=q-100`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Design`,
    paragraph:`The t-shirt features a striking graphic print of the Marvel character Moon Knight, known for his unique costume and mysterious persona.`,
    heading1:`Fabric`,
    paragraph1:`Made from high-quality cotton fabric, the t-shirt offers a comfortable and breathable feel, perfect for casual wear.`,
    heading2:`Fit`,
    paragraph2:`The oversized fit of the t-shirt provides a relaxed and trendy look, suitable for various body types and styling preferences.`,
    heading3:`Style`,
    paragraph3:`With its bold graphic and oversized design, this t-shirt is a great choice for those who want to make a statement with their outfit.`,
    heading4:`Versatility`,
    paragraph4:`This versatile piece can be paired with jeans, shorts, or joggers, making it suitable for different occasions and styles.`,
    keyword:`Marvel edition Oversized Tshirt`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Birthday-Bash_Inside_Desktop-Banner-1712212080.jpg`,
    jlink:`https://bit.ly/3J7mon4`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },




  {
    name:`101-Eternity-Graphic-Printed-Oversized-Tshirt`,
    title:`81 Bewakoof® Men's Grey Eternity Graphic Printed Oversized T-shirt`,
    rate:'Rs.699',
    content: [  
      `The Bewakoof® Men's Grey Eternity Graphic Printed Oversized T-shirt is a stylish and comfortable addition to your wardrobe. Made from high-quality fabric, this t-shirt features a unique graphic print that adds a trendy touch to your outfit. The oversized fit makes it perfect for casual wear, and the grey color ensures versatility and easy pairing with different bottoms.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-zinc-700`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-grey-eternity-graphic-printed-oversized-t-shirt`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-grey-eternity-graphic-printed-oversized-t-shirt-596129-1708957243-1.jpg?tr=q-100`,
    image:`https://images.bewakoof.com/t1080/men-s-grey-eternity-graphic-printed-oversized-t-shirt-596129-1694763995-2.jpg?tr=q-100`,
    image1:`https://images.bewakoof.com/t1080/men-s-grey-eternity-graphic-printed-oversized-t-shirt-596129-1694764000-3.jpg?tr=q-100`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Graphic Print`,
    paragraph:`The t-shirt features a one-of-a-kind graphic print that adds a touch of personality to your outfit. The print is vibrant and eye-catching, making it a statement piece.`,
    heading1:`Oversized Fit for Comfort`,
    paragraph1:`The oversized design of the t-shirt provides a relaxed and comfortable fit. It allows for easy movement and is perfect for casual, everyday wear.`,
    heading2:`Soft and Breathable Fabric`,
    paragraph2:`Made from high-quality fabric, the t-shirt is soft to the touch and breathable. It keeps you cool and comfortable throughout the day.`,
    heading3:`Durable Construction`,
    paragraph3:`The t-shirt is well-made with strong stitching, ensuring durability and long-lasting wear. It can withstand regular washing and everyday use.`,
    heading4:`Versatile Style`,
    paragraph4:`The t-shirt can be easily paired with jeans, shorts, or joggers for a stylish and casual look. It can be dressed up or down depending on the occasion.`,
    keyword:`Oversized Tshirt`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Birthday-Bash_Inside_Desktop-Banner-1712212080.jpg`,
    jlink:`https://bit.ly/3J7mon4`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`100-OFFICIAL-HARRY-POTTER-MERCHANDISE`,
    title:`80 Bewakoof OFFICIAL HARRY POTTER MERCHANDISE T-Shirt`,
    rate:'Rs.749',
    content: [  
      `Bewakoof's official Harry Potter merchandise T-shirts are a must-have for fans of the wizarding world. Made from high-quality fabrics, these T-shirts feature iconic Harry Potter designs, including house emblems and quotes. With a comfortable fit and durable construction, these shirts are perfect for everyday wear or showing off your love for the magical world of Harry Potter.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-gray-700`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/bewakoof.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-black-crest-mark-graphic-printed-oversized-t-shirt`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.bewakoof.com/t1080/men-s-black-crest-mark-graphic-printed-oversized-t-shirt-625622-1701150220-1.jpg?tr=q-100`,
    image:`https://images.bewakoof.com/t1080/men-s-black-crest-mark-graphic-printed-oversized-t-shirt-625622-1701150242-5.jpg?tr=q-100`,
    image1:`https://images.bewakoof.com/t1080/men-s-black-crest-mark-graphic-printed-oversized-t-shirt-625622-1701150231-3.jpg?tr=q-100`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Official Merchandise`,
    paragraph:`This T-shirt is officially licensed Harry Potter merchandise, ensuring authenticity and quality.`,
    heading1:`Unique Design`,
    paragraph1:`The graphic printed design featuring the iconic Harry Potter crest mark adds a touch of magic to your outfit.`,
    heading2:`Oversized Fit`,
    paragraph2:`The oversized fit provides a comfortable and relaxed look, perfect for casual outings or lounging at home.`,
    heading3:`Acid Wash Finish`,
    paragraph3:`The acid wash finish gives the T-shirt a unique, vintage-inspired look that sets it apart from regular tees.`,
    heading4:`High-Quality Fabric`,
    paragraph4:`Made from high-quality fabric, this T-shirt is soft, breathable, and durable..`,
    keyword:`Oversized T-shirt`,
    summary:`https://images.bewakoof.com/uploads/category/desktop/Birthday-Bash_Inside_Desktop-Banner-1712212080.jpg`,
    jlink:`https://bit.ly/3J7mon4`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },



  {
    name:`99-COTTON-CHECK-SHIRT`,
    title:`79 Rare Rabbit COTTON CHECK SHIRT`,
    rate:'Rs.3,599',
    content: [  
      `The rare rabbit Cotton Check Shirt in the Amos Grey color exudes timeless sophistication. Crafted from premium quality cotton, it features a classic check pattern that adds a touch of elegance to any outfit. With a tailored fit and versatile design, this shirt effortlessly transitions from day to night, making it a wardrobe essential for the modern man.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-gray-700`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/rarerabbit.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fthehouseofrare.com%2Fcollections%2Frare-rr-men-shirts%2Fproducts%2Famos-mens-shirt-grey`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://thehouseofrare.com/cdn/shop/files/AMOS-GREY00317_1452x1799.jpg?v=1710916657`,
    image:`https://thehouseofrare.com/cdn/shop/files/AMOS-GREY00308_1452x1799.jpg?v=1710916657`,
    image1:`https://thehouseofrare.com/cdn/shop/files/AMOS-GREY00291_1452x1799.jpg?v=1710916652`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Checkerboard Print`,
    paragraph:`The rare rabbit Cotton Check Shirt features a unique checkerboard print that adds a playful yet stylish element to your wardrobe. The print is bold and eye-catching, perfect for those who want to make a statement with their outfit.`,
    heading1:`Regular Collar`,
    paragraph1:`The shirt is designed with a regular collar that adds a touch of sophistication to the overall look. The collar is comfortable to wear and sits nicely against the neck, giving a polished appearance.`,
    heading2:`Full Sleeve`,
    paragraph2:`With full sleeves, this shirt offers versatility in styling. You can wear it rolled up for a casual look or down for a more formal appearance. The sleeves are designed to provide a comfortable fit without being too tight or restrictive.`,
    heading3:`Cotton Blend Fabric`,
    paragraph3:`Crafted from a breathable cotton blend fabric, this shirt is perfect for year-round wear. The fabric is soft against the skin and allows for easy movement, making it ideal for all-day wear.`,
    heading4:`Regular Fit`,
    paragraph4:`The shirt is tailored in a regular fit, providing a comfortable and relaxed silhouette. The fit is neither too tight nor too loose, ensuring that you look effortlessly stylish without compromising on comfort.`,
    keyword:`rare rr men shirts rareism`,
    summary:`https://media.fashionnetwork.com/cdn-cgi/image/fit=contain,width=1000,height=1000/m/4e82/1564/0d39/6b36/2c9e/92c8/9f9b/be77/77de/a592/a592.jpeg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.myntra.com%2Fmyntra-fashion-store`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  




  {
    name:`98-WINDOWPANE-CHECK-SHIRT`,
    title:`78 Rare rabbit WINDOWPANE CHECK SHIRT`,
    rate:'Rs.1,749',
    content: [  
      `The Rare Rabbit Windowpane Check Shirt combines classic style with modern sensibilities. Featuring a subtle windowpane check pattern, this shirt offers a sophisticated and versatile look. Made from high-quality fabric, it ensures comfort and durability. The shirt's tailored fit provides a flattering silhouette, suitable for both casual and semi-formal occasions.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-lime-700`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/rarerabbit.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fthehouseofrare.com%2Fproducts%2Fveneto-olive`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://thehouseofrare.com/cdn/shop/files/CORNETO-OLIVE01543_1452x1799.jpg?v=1697436900`,
    image:`https://thehouseofrare.com/cdn/shop/files/CORNETO-OLIVE01534_1452x1799.jpg?v=1709708998`,
    image1:`https://thehouseofrare.com/cdn/shop/files/CORNETO-OLIVE01549_1452x1799.jpg?v=1697436900`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Versatile Wardrobe Staple`,
    paragraph:`The Rare Rabbit Windowpane Check Shirt is a versatile piece that seamlessly transitions from the office to after-work gatherings. Its timeless design and sophisticated pattern make it a go-to choice for various occasions.`,
    heading1:`Ageless Appeal`,
    paragraph1:`This shirt appeals to a wide range of age groups, including fathers and sons, due to its classic style and modern fit. It exudes elegance and sophistication, making it a favorite among discerning gentlemen of all ages.`,
    heading2:`Classic Collar`,
    paragraph2:`The shirt features a regular collar, adding a touch of traditional charm. It's perfect for both formal and casual settings, offering versatility in styling options.`,
    heading3:`Premium Quality Fabric`,
    paragraph3:`Crafted from 100% premium cotton yarn-dyed twill checks, this shirt is not only stylish but also comfortable to wear. The fabric's quality ensures durability and longevity, making it a worthwhile investment for your wardrobe.`,
    heading4:`Full Sleeves for Added Sophistication`,
    paragraph4:`The full sleeves of the shirt enhance its sophistication, making it suitable for more formal occasions. The tailored fit and perfected pattern ensure a flattering silhouette that complements your body shape.`,
    keyword:`veneto olive rarerabbit check shirt`,
    summary:`https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/banner-images/Homepage_Banner_copy_1.jpg?format=webp&w=1500&dpr=1.5`,
    jlink:`https://bit.ly/3TU1o9u`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  


  {
    name:`97-Plaid-Black-Blue-and-Red`,
    title:`77 The Should Store Plaid Black, Blue and Red`,
    rate:'Rs.1,699',
    content: [  
      `The Should Store's Plaid Black, Blue, and Red Shirt blends classic patterns with contemporary style. Featuring a combination of black, blue, and red hues, this shirt offers a versatile and trendy look. The plaid pattern adds a touch of sophistication, making it suitable for both casual and semi-formal occasions.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-red-600`,
    wear:`Men's Wear `,
    Scolor:"text-blue-600 text-lg font-semibold",
    buy:`/images/theshouldstore.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fplaid-black-blue-and-red-men-relaxed-shirts%3Fgte%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_8593675.jpg?format=webp&w=1080&dpr=1.5`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_8755334.jpg?format=webp&w=1080&dpr=1.5`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_7722638.jpg?format=webp&w=1080&dpr=1.5`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Plaid Design`,
    paragraph:`The shirt features a timeless plaid pattern in black, blue, and red, offering a stylish and versatile look.`,
    heading1:`Comfortable Fabric`,
    paragraph1:`Made from high-quality fabric, it ensures comfort and breathability, making it suitable for all-day wear.`,
    heading2:`Versatile Style`,
    paragraph2:`This shirt can be easily dressed up or down, perfect for various occasions from casual outings to semi-formal events.`,
    heading3:`Modern Fit`,
    paragraph3:`With a modern fit, it offers a flattering silhouette that complements different body types.`,
    heading4:`Quality Construction`,
    paragraph4:`The shirt is well-constructed with attention to detail, ensuring durability and long-lasting wear.`,
    keyword:`Plaid: Black Blue and Red`,
    summary:`https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/banner-images/Homepage_Banner_copy_1.jpg?format=webp&w=1500&dpr=1.5`,
    jlink:`https://bit.ly/3TU1o9u`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },  



  {
    name:`96-plaid-blue-and-white-men-relaxed-shirts`,
    title:`76 the should store Plaid Blue & White Men Relaxed Shirts`,
    rate:'Rs.999',
    content: [  
      `The Plaid Blue & White Men's Relaxed Shirt from The Should Store offers a classic yet stylish look. Made from high-quality fabric, this shirt features a timeless plaid pattern in blue and white. It is designed for a relaxed fit, making it comfortable for everyday wear. The shirt is versatile and can be dressed up or down for various occasions.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-blue-600`,
    wear:`Men's Wear `,
    Scolor:"text-gray-600 text-lg font-semibold",
    buy:`/images/theshouldstore.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fplaid-blue-and-white-men-relaxed-shirts%3Fgte%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710310247_3270680.jpg?format=webp&w=1000&dpr=1.0`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710310247_2591131.jpg?format=webp&w=1000&dpr=1.0`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710310247_3270680.jpg?format=webp&w=1000&dpr=1.0`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Premium Quality`,
    paragraph:`Crafted from high-quality fabric, the Retro Red Men's Relaxed Shirt from The Shield Store offers superior comfort and durability.`,
    heading1:`Classic Plaid Pattern`,
    paragraph1:`The shirt features a timeless retro red plaid pattern, adding a touch of vintage charm to your wardrobe.`,
    heading2:`Relaxed Fit`,
    paragraph2:`Designed for comfort, the shirt offers a relaxed fit that allows for ease of movement, making it perfect for all-day wear.`,
    heading3:`Versatile Style`,
    paragraph3:`This shirt can be easily dressed up or down, making it suitable for a variety of occasions.`,
    heading4:`Breathable Fabric`,
    paragraph4:`The fabric is breathable, ensuring you stay cool and comfortable throughout the day.`,
    keyword:`Shouled Store plaid blue and white men relaxed shirts`,
    summary:`https://cdn.zoutons.com/images/originals/blog/1678790168548.jpg_1678790172.png`,
    jlink:`https://bit.ly/3TU1o9u`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`95-Plaid-Retro-Red-Men-Relaxed-Shirts`,
    title:`75 the should store Plaid Retro Red Men Relaxed Shirts`,
    rate:'Rs.1,499',
    content: [  
      `The Shield Store's Plaid: Retro Red Men's Relaxed Shirt offers a blend of classic style and modern comfort. The shirt features a timeless retro red plaid pattern that adds a touch of sophistication to any outfit. Made from high-quality fabric, it is designed for a relaxed fit, making it ideal for everyday wear.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-red-700`,
    wear:`Men's Wear `,
    Scolor:"text-black- text-lg font-semibold",
    buy:`/images/theshouldstore.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fplaid-retro-red-men-relaxed-shirts%3Fgte%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1708493308_1252510.jpg?format=webp&w=1080&dpr=1.0`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1708493308_4955800.jpg?format=webp&w=1080&dpr=1.0`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1708493308_4105127.jpg?format=webp&w=1000&dpr=1.0`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Premium Quality`,
    paragraph:`Crafted from high-quality fabric, the Retro Red Men's Relaxed Shirt from The Shield Store offers superior comfort and durability.`,
    heading1:`Classic Plaid Pattern`,
    paragraph1:`The shirt features a timeless retro red plaid pattern, adding a touch of vintage charm to your wardrobe.`,
    heading2:`Relaxed Fit`,
    paragraph2:`Designed for comfort, the shirt offers a relaxed fit that allows for ease of movement, making it perfect for all-day wear.`,
    heading3:`Versatile Style`,
    paragraph3:`This shirt can be easily dressed up or down, making it suitable for a variety of occasions.`,
    heading4:`Breathable Fabric`,
    paragraph4:`The fabric is breathable, ensuring you stay cool and comfortable throughout the day.`,
    keyword:`Plaid Retro Red Men Relaxed Shirts`,
    summary:`https://cdn.zoutons.com/images/originals/blog/1678790168548.jpg_1678790172.png`,
    jlink:`https://bit.ly/3TU1o9u`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`94-Plaid-Ivory-Meadow`,
    title:`74 The shouled Store Plaid Ivory Meadow `,
    rate:'Rs.1,499',
    content: [  
      `The Shield Store's Plaid: Ivory Meadow Men's Relaxed Shirt blends classic style with modern comfort. Crafted from premium fabric, this shirt features a timeless plaid pattern in ivory and meadow green. The relaxed fit ensures all-day comfort, while the versatile design makes it suitable for both casual and formal occasions.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-teal-900`,
    wear:`Men's Wear `,
    Scolor:"text-red-800 text-lg font-semibold",
    buy:`/images/theshouldstore.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fplaid-ivory-meadow-men-relaxed-shirts%3Fgte%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1708753918_9532011.jpg?format=webp&w=1080&dpr=1.0`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1708753918_8238610.jpg?format=webp&w=1080&dpr=1.0`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1708753918_3171533.jpg?format=webp&w=1080&dpr=1.0`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Premium Fabric`,
    paragraph:`Crafted from high-quality material, this shirt ensures long-lasting comfort and durability.`,
    heading1:`Classic Plaid Pattern`,
    paragraph1:`The timeless ivory and meadow green plaid pattern adds a touch of sophistication to your look.`,
    heading2:`Relaxed Fit`,
    paragraph2:`Designed for ultimate comfort, the shirt offers a relaxed fit that allows for ease of movement.`,
    heading3:`Versatile Style`,
    paragraph3:`Whether you're dressing up for a formal occasion or keeping it casual, this shirt is a versatile choice.`,
    heading4:`Breathable Fabric`,
    paragraph4:`The fabric is breathable, making it comfortable to wear all day long.`,
    keyword:`Souled Store Plaid Button Down Fashionable`,
    summary:`https://cdn.zoutons.com/images/originals/blog/1678790168548.jpg_1678790172.png`,
    jlink:`https://bit.ly/3TU1o9u`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`93-Plaid-Brown-And-Navy-Men-Relaxed-Shirts`,
    title:`73 The shouled Store Plaid Brown And Navy`,
    rate:'Rs.1,449',
    content: [  
      `The Shield Store's Plaid Brown and Navy Men's Relaxed Shirt exudes casual sophistication. Crafted with a blend of brown and navy hues, this shirt features a classic plaid pattern, offering a timeless appeal. The relaxed fit ensures comfort, while the quality fabric ensures durability. Ideal for both casual and semi-formal occasions, this shirt adds a touch of laid-back charm to any outfit.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-cyan-800`,
    wear:`Men's Wear `,
    Scolor:"text-yellow-900 text-lg font-semibold",
    buy:`/images/theshouldstore.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fplaid-brown-and-navy-men-relaxed-shirts%3Fgte%3D1`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1711180978_5641762.jpg?format=webp&w=1080&dpr=1.0`,
    image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1711180978_8175509.jpg?format=webp&w=1080&dpr=1.0`,
    image1:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1711180978_8509346.jpg?format=webp&w=1080&dpr=1.0`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Plaid Design`,
    paragraph:`The shirt features a timeless plaid pattern in brown and navy, adding a touch of sophistication to your look.`,
    heading1:`Relaxed Fit`,
    paragraph1:`Designed for comfort, the shirt offers a relaxed fit that allows for easy movement and all-day wearability.`,
    heading2:`Quality Fabric`,
    paragraph2:`Crafted from high-quality material, the shirt is durable and ensures long-lasting comfort.`,
    heading3:`Versatile Style`,
    paragraph3:`This shirt is versatile and can be dressed up or down, making it suitable for various occasions.`,
    heading4:`Easy to Maintain`,
    paragraph4:`The fabric is easy to care for, requiring minimal ironing and retaining its shape and color after washes`,
    keyword:`Plaid Brown And Navy Men Relaxed Shirts shouled store`,
    summary:`https://cdn.zoutons.com/images/originals/blog/1678790168548.jpg_1678790172.png`,
    jlink:`https://bit.ly/3TU1o9u`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`92-COASTAL-LINEN-WHITE-SHIRT`,
    title:`72 Snitch COASTAL LINEN WHITE SHIRT`,
    rate:'Rs.1,299',
    content: [
      `The Snitch Coastal Linen White Shirt is a wardrobe essential for its timeless appeal and versatile nature. Crafted from high-quality linen, it offers a lightweight and breathable feel, ideal for warmer weather. The crisp white color adds a touch of sophistication, making it suitable for both casual and formal occasions. This shirt's classic design ensures it remains a stylish choice for any outfit.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-cyan-700`,
    wear:`Men's Wear `,
    Scolor:"text-blue-800 text-lg font-semibold",
    buy:`/images/snitch.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fproducts%2Fcoastal-linen-white-shirt%3Fvariant%3D44205207290018`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.snitch.co.in/cdn/shop/files/4MSS2838-01-M24.jpg?v=1710483344&width=1800`,
    image:`https://www.snitch.co.in/cdn/shop/files/4MSS2838-01-M1.jpg?v=1710483344&width=1800`,
    image1:`https://cdn.shopify.com/s/files/1/0420/7073/7058/files/4MSS2838-01-M23.jpg?v=1710483344`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Premium Linen Fabric`,
    paragraph:`The Snitch Coastal Linen White Shirt is crafted from high-quality linen fabric, renowned for its breathability and lightweight feel, ensuring maximum comfort in warm weather.`,
    heading1:`Classic White Color`,
    paragraph1:`The crisp white hue adds a timeless and versatile element to your wardrobe, effortlessly complementing various styles and colors.`,
    heading2:`Comfortable Fit`,
    paragraph2:`This shirt boasts a relaxed fit, providing ample room for movement and ensuring all-day comfort.`,
    heading3:`Versatile Style`,
    paragraph3:`Whether paired with casual jeans or dressed up with trousers, this shirt is suitable for a range of occasions, from casual outings to more formal events.`,
    heading4:`Breathable Nature`,
    paragraph4:`Linen's natural breathability allows air to flow freely, keeping you cool and comfortable even on the hottest days.`,
    keyword:`beach wear Shirts for men`,
    summary:`https://images.puma.com/image/upload/q_auto,f_auto,w_1440/regional/~regional~SEA~others~KOP~001-+Collection+banners~002-SS24~FOREVER+PALERMO~PALERMO+SCARF+-GWP+1440x500.jpg/fmt/jpg/fmt/png`,
    jlink:`https://bitli.in/HooOea2`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`91-COASTAL-LINEN-BLACK-SHIRT`,
    title:`71 Snitch COASTAL LINEN BLACK SHIRT`,
    rate:'Rs.1299',
    content: [
      `The Snitch Coastal Linen Black Shirt is a timeless wardrobe essential. Made from high-quality linen fabric, it offers a luxurious feel and a lightweight, breathable texture. The black color adds a touch of sophistication, making it suitable for both casual and formal occasions. With its classic design and comfortable fit, this shirt is a versatile piece that complements any style.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Men's Wear `,
    Scolor:"text-red-800 text-lg font-semibold",
    buy:`/images/snitch.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fproducts%2Fcoastal-linen-black-shirt%3Fvariant%3D44205215842466`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.snitch.co.in/cdn/shop/files/4MSS2838-05-M47.jpg?v=1710403499&width=1800`,
    image:`https://www.snitch.co.in/cdn/shop/files/4MSS2838-05-M10.jpg?v=1710403499&width=1800`,
    image1:`https://www.snitch.co.in/cdn/shop/files/4MSS2838-05-M45.jpg?v=1710403499&width=1800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Premium Material`,
    paragraph:`Crafted from high-quality linen, the Snitch Coastal Linen Black Shirt offers a luxurious feel and superior breathability, perfect for warmer climates.`,
    heading1:`Classic Design`,
    paragraph1:`With its timeless black color and classic shirt design, this piece adds a touch of sophistication to any outfit.`,
    heading2:`Versatile Styling`,
    paragraph2:`This shirt can be dressed up with tailored pants or down with jeans, making it suitable for various occasions.`,
    heading3:`Seasonal Versatility`,
    paragraph3:`Suitable for both casual and formal settings, this shirt transitions effortlessly between seasons.`,
    heading4:`Wardrobe Essential`,
    paragraph4:`The Snitch Coastal Linen Black Shirt is a versatile and timeless piece that belongs in every man's wardrobe, offering both style and comfort.`,
    keyword:`Snitch COASTAL LINEN BLACK SHIRT`,
    summary:`https://images.puma.com/image/upload/q_auto,f_auto,w_1440/regional/~regional~SEA~others~KOP~001-+Collection+banners~002-SS24~FOREVER+PALERMO~PALERMO+SCARF+-GWP+1440x500.jpg/fmt/jpg/fmt/png`,
    jlink:`https://bitli.in/HooOea2`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },


  {
    name:`90-MOD-STRIPE-BEIGE-SHIRT`,
    title:`70 Snitch MOD STRIPE BEIGE SHIRT`,
    rate:'Rs.1,199',
    content: [
      `The Snitch Mod Stripe Beige Shirt is a stylish and versatile piece that adds a touch of sophistication to any outfit. Featuring a unique striped pattern in beige, this shirt offers a modern twist on a classic design. Made from high-quality fabric, it is comfortable to wear and easy to care for. Perfect for both casual and formal occasions, this shirt is a must-have for the modern man's wardrobe.`
    ],
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-amber-500`,
    wear:`Men's Wear `,
    Scolor:"text-red-800 text-lg font-semibold",
    buy:`/images/snitch.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fproducts%2Fmod-stripe-beige-shirt%3Fvariant%3D44055218651298`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.snitch.co.in/cdn/shop/files/4MSS2747-06-M17.jpg?v=1708168378&width=1800`,
    image:`https://www.snitch.co.in/cdn/shop/files/4MSS2747-06-M1.jpg?v=1708168378&width=1800`,
    image1:`https://www.snitch.co.in/cdn/shop/files/4MSS2747-06-M24.jpg?v=1708168378&width=1800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Design`,
    paragraph:`The Snitch Mod Stripe Beige Shirt stands out with its distinct striped pattern, adding a touch of flair to your wardrobe.`,
    heading1:`High-Quality Fabric`,
    paragraph1:`Crafted from premium materials, this shirt ensures durability and comfort, making it suitable for all-day wear.`,
    heading2:`Versatile Styling`,
    paragraph2:`This shirt can be dressed up or down, making it suitable for various occasions.`,
    heading3:`Stylish Details`,
    paragraph3:`Features such as a button-down collar and contrasting cuffs add a stylish touch to this shirt.`,
    heading4:`Timeless Appeal`,
    paragraph4:`The Snitch Mod Stripe Beige Shirt exudes timeless elegance, making it a wardrobe staple that you'll reach for time and time again.`,
    keyword:`trend'z MOD STRIPE BEIGE SHIRT`,
    summary:`https://images.puma.com/image/upload/q_auto,f_auto,w_1440/regional/~regional~SEA~others~KOP~001-+Collection+banners~002-SS24~FOREVER+PALERMO~PALERMO+SCARF+-GWP+1440x500.jpg/fmt/jpg/fmt/png`,
    jlink:`https://bitli.in/HooOea2`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    
  },

  {
    name:`89-STRIPY-WHITE-SHIRT`,
    title:`69 Snitch STRIPY WHITE SHIRT`,
    rate:'Rs.1,199',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-gray-600`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/snitch.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fproducts%2Fstripy-white-shirt-1%3Fvariant%3D43858624970914`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.snitch.co.in/cdn/shop/files/4MSS2538-01-M33.jpg?v=1700566924&width=1800`,
    image:`https://www.snitch.co.in/cdn/shop/files/4MSS2538-01-M2.jpg?v=1700566924&width=1800`,
    image1:`https://www.snitch.co.in/cdn/shop/files/4MSS2538-01-M41.jpg?v=1700566924&width=1800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Design`,
    paragraph:`The Snitch Stripy White Shirt features thin, elegant stripes in white, offering a timeless and sophisticated look that never goes out of style.`,
    heading1:`High-Quality Fabric`,
    paragraph1:`Crafted from premium cotton, this shirt ensures comfort, durability, and breathability, making it ideal for all-day wear in any season.`,
    heading2:`Slim Fit`,
    paragraph2:`Tailored for a modern silhouette, the shirt provides a flattering and stylish look that enhances the wearer's appearance.`,
    heading3:`Versatile Styling`,
    paragraph3:`This shirt is incredibly versatile, effortlessly transitioning from casual to formal settings. Pair it with jeans for a relaxed look or with trousers for a more polished appearance.`,
    heading4:`Comfortable Wear`,
    paragraph4:`The soft and smooth fabric of this shirt ensures a comfortable fit, allowing you to move freely throughout the day without feeling restricted.`,
    keyword:`snitch striped regular fit shirt`,
    summary:`https://images.puma.com/image/upload/q_auto,f_auto,w_1440/regional/~regional~SEA~others~KOP~001-+Collection+banners~002-SS24~FOREVER+PALERMO~PALERMO+SCARF+-GWP+1440x500.jpg/fmt/jpg/fmt/png`,
    jlink:`https://bitli.in/HooOea2`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Snitch Stripy White Shirt is a classic and versatile piece that adds sophistication to any outfit. Featuring timeless stripes in white, this shirt offers a clean and crisp look. Made from high-quality fabric, it ensures comfort and durability, making it a wardrobe essential for both casual and formal occasions.`
    ],
  },

  {
    name:`88-BREEZY-OLIVE-CHECKS-SHIRT`,
    title:`68 Snitch BREEZY OLIVE CHECKS SHIRT`,
    rate:'Rs.1,199',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-lime-700`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/snitch.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fproducts%2Fbreezy-olive-checks-shirt%3Fvariant%3D44074358341794`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.snitch.co.in/cdn/shop/files/4MSS2738-06-M16.jpg?v=1708500920&width=1800`,
    image:`https://www.snitch.co.in/cdn/shop/files/4MSS2738-06-M1.jpg?v=1708500943&width=1800`,
    image1:`https://www.snitch.co.in/cdn/shop/files/4MSS2738-06-M47.jpg?v=1708500943&width=1800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Design`,
    paragraph:`The shirt features a classic checkered pattern in olive green, adding a timeless appeal to your wardrobe.`,
    heading1:`Versatile Style`,
    paragraph1:`This shirt can be dressed up or down, making it suitable for a variety of occasions.`,
    heading2:`Comfortable Fit`,
    paragraph2:`The shirt is designed for comfort, with a relaxed fit that allows for ease of movement.`,
    heading3:`High-Quality Fabric`,
    paragraph3:`Made from high-quality fabric, this shirt is soft, breathable, and durable.`,
    heading4:`Stylish Detailing`,
    paragraph4:`The shirt features stylish detailing, such as contrast buttons and a chest pocket.`,
    keyword:`olive checks shirt trend'z`,
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2017/10/rare-rabbit-mens-urban-fashion-ad-times-of-india-delhi-29-09-2017.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fthehouseofrare.com%2Fpages%2Frare-rabbit`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Snitch Breezy Olive Checks Shirt is a stylish and comfortable addition to any wardrobe. With its olive green color and checkered pattern, this shirt offers a casual yet sophisticated look. Made from high-quality fabric, it ensures durability and comfort.`
    ],
  },

  {
    name:`87-LANE-GRID-NAVY-CHECKS-SHIRT`,
    title:`67 Snitch LANE GRID NAVY CHECKS SHIRT`,
    rate:'Rs.1,199',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-blue-800`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/snitch.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fproducts%2Flane-grid-navy-checks-shirt%3Fvariant%3D44219198963874`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://www.snitch.co.in/cdn/shop/files/4MSS2851-01-M31.jpg?v=1710501893&width=1800`,
    image:`https://www.snitch.co.in/cdn/shop/files/4MSS2851-01-M1.jpg?v=1710501904&width=1800`,
    image1:`https://www.snitch.co.in/cdn/shop/files/4MSS2851-01-M15.jpg?v=1710501904&width=1800`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Design`,
    paragraph:`The shirt features a timeless grid pattern in navy, adding a classic touch to your wardrobe.`,
    heading1:`Versatile Style`,
    paragraph1:`This shirt can be dressed up or down, making it suitable for a variety of occasions.`,
    heading2:`Comfortable Fit`,
    paragraph2:`The shirt is designed for comfort, with a relaxed fit that allows for ease of movement.`,
    heading3:`High-Quality Fabric`,
    paragraph3:`Made from high-quality fabric, this shirt is soft, breathable, and durable.`,
    heading4:`Stylish Detailing`,
    paragraph4:`The shirt features stylish detailing, such as contrast buttons and a chest pocket.`,
    keyword:`lane grid navy checks shirt`,
    summary:`https://newspaperads.ads2publish.com/wp-content/uploads/2017/10/rare-rabbit-mens-urban-fashion-ad-times-of-india-delhi-29-09-2017.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fthehouseofrare.com%2Fpages%2Frare-rabbit`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Snitch Lane Grid Navy Checks Shirt is a stylish and versatile piece for any wardrobe. It features a classic grid pattern in navy, adding a touch of sophistication to your look. The shirt is made from high-quality fabric, ensuring comfort and durability.`
    ],
  },




  {
    name:'86-Geometric-Print-Shirt-Freya-Black',
    title:`66 Rare Rabbit Geometric Print Shirt Freya Black`,
    rate:'Rs.1,999',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/rarerabbit.jpg`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fthehouseofrare.com%2Fcollections%2Frr-sale-aug%2Fproducts%2Ffrey-mens-shirt-black`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://thehouseofrare.com/cdn/shop/products/IMG_0063_b5c07cfd-3936-40fb-8a15-e25159f8a91b_1452x1799.jpg?v=1679922389`,
    image:`https://thehouseofrare.com/cdn/shop/products/IMG_0052_a2f729f4-8d5a-4821-8e15-e1619c68d974_1452x1799.jpg?v=1680070736`,
    image1:`https://thehouseofrare.com/cdn/shop/products/IMG_0055_4a437a74-7b05-47c3-87d4-938c2b50d9d8_1452x1799.jpg?v=1708155795`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Geometric Print`,
    paragraph:`The shirt features a bold and eye-catching geometric print that adds a modern twist to your outfit.`,
    heading1:`High-Quality Fabric`,
    paragraph1:`Made from premium quality fabric, the shirt is soft, comfortable, and durable, ensuring long-lasting wear.`,
    heading2:`Versatile Style`,
    paragraph2:`This shirt can be dressed up or down, making it suitable for a variety of occasions, from casual outings to formal events.`,
    heading3:`Comfortable Fit`,
    paragraph3:`The shirt is designed to provide a comfortable fit, allowing you to move freely and comfortably throughout the day.`,
    heading4:`Stylish Design rare rabbit articles`,
    paragraph4:`The geometric print and black color of the shirt make it a versatile and stylish choice for any wardrobe.`,
    keyword:`frey mens shirt black`,
    summary:`https://d3tdx3c7l2rexw.cloudfront.net/media/img/page_section/wrogn/section_2/tinywow_jpg_to_webp_4210444.webp`,
    jlink:`https://wrogn.com/collections/all`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Rare Rabbit Geometric Print Shirt Freya in Black is a sophisticated and stylish shirt featuring a unique geometric print. Made from high-quality fabric, this shirt offers a comfortable fit and is perfect for adding a touch of elegance to your wardrobe.`
    ],
  },




  {
    name:'85-Grids-And-Checks-Casual-Shirt',
    title:`65 Wrogn Grids And Checks Casual Shirt`,
    rate:'Rs.2,079',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-rose-500`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/wrogn.png`,
    blink:`https://wrogn.com/products/grids-and-checks-casual-shirt`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://wrogn.com/cdn/shop/files/1_7eb867e5-b5f3-4b09-9271-0c07ef65f996.jpg?v=1706790836&width=550`,
    image:`https://wrogn.com/cdn/shop/files/2_d5269c6e-712b-47a6-ad96-030033ebd788.jpg?v=1706790836&width=550`,
    image1:`https://wrogn.com/cdn/shop/files/5_704a09d2-4c58-4e07-b98e-baf38cecaed3.jpg?v=1708932650&width=375`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Design`,
    paragraph:`The shirt features a combination of grid and check patterns, offering a unique and contemporary look. The vibrant colors and bold patterns make it stand out.`,
    heading1:`High-Quality Fabric.`,
    paragraph1:`Made from high-quality fabric, the shirt is comfortable to wear and offers durability for long-lasting use.`,
    heading2:`Versatile Style`,
    paragraph2:`This shirt can be paired with jeans or chinos for a casual look, or with trousers for a more formal outfit. It transitions easily from day to night.`,
    heading3:`Comfortable Fit`,
    paragraph3:`Designed to provide a comfortable fit, the shirt allows for easy movement. The fabric is breathable, keeping you cool and comfortable all day long.`,
    heading4:`Easy Maintenance`,
    paragraph4:`The shirt is easy to care for and can be machine washed for convenience. It retains its shape and color even after multiple washes.`,
    keyword:`Trends article Check shirts`,
    summary:`https://cdn.shopify.com/s/files/1/0420/7073/7058/files/Banner.jpg?v=1615800637`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fcollections%2Fwinter-collection`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Wrogn Grids And Checks Casual Shirt is a stylish and versatile piece that adds a modern touch to your wardrobe. Made from high-quality fabric, this shirt features a unique grid and check pattern in vibrant colors, making it perfect for casual outings and social gatherings.`
    ],
  },


  {
    name:"84",
    title:`64 Wrogn Checked Squares Urban Navy Shirt`,
    rate:'Rs.1,999',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-blue-800`,
    wear:`Men's Wear `,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/wrogn.png`,
    blink:`https://wrogn.com/products/checked-squares-urban-navy-shirt`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:"https://wrogn.com/cdn/shop/files/1_ac9ca7d9-7a32-42aa-b42e-c8ac5e62b02b.jpg?v=1706790835",
    image:`https://wrogn.com/cdn/shop/files/4_9c8bc355-1b9e-4455-a86c-2928b3f5b1d6.jpg?v=1706790836&width=375`,
    image1:`https://wrogn.com/cdn/shop/files/5_1a897d0a-e8ed-4b18-b5a1-cb60dfbc8854.jpg?v=1708932017&width=375`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Unique Design`,
    paragraph:`The shirt features a distinctive checked squares pattern in navy blue, adding a modern and stylish touch to your outfit.`,
    heading1:`Quality Fabric`,
    paragraph1:`Made from high-quality fabric, the shirt is comfortable to wear and offers durability for long-lasting use.`,
    heading2:`Versatile Style`,
    paragraph2:`This shirt can be dressed up or down, making it suitable for various occasions, from casual outings to semi-formal events.`,
    heading3:`Perfect Fit`,
    paragraph3:`Designed to provide a flattering fit, the shirt enhances your silhouette and offers comfort throughout the day.`,
    heading4:`Easy Maintenance`,
    paragraph4:`The shirt is easy to care for, as it is machine washable, saving you time and effort on laundry day.`,
    keyword:`Trends article  Navy check shirts`,
    summary:`https://cdn.shopify.com/s/files/1/0420/7073/7058/files/Banner.jpg?v=1615800637`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.snitch.co.in%2Fcollections%2Fwinter-collection`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Wrogn Checked Squares Urban Navy Shirt is a stylish and versatile piece of clothing. It features a unique checked pattern in navy blue, perfect for casual and semi-formal occasions. The shirt is made from high-quality fabric, ensuring comfort and durability.`
    ],
  },


  {
    name:`83-Palermo-Unisex-Sneakers`,
    title:`63 Palermo Special Unisex Sneakers`,
    rate:'Rs.6,999',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-pink-500`,
    wear:`Unisex `,
    Scolor:"text-lime-500 text-lg font-semibold",
    buy:`/images/puma.png`,
    blink:`https://in.puma.com/in/en/pd/palermo-special-unisex-sneakers/397549?swatch=01&referrer=carousel&utm_medium=AFF&utm_source=OTH-CPO&utm_aud=MULT&utm_obj=OLC&utm_campaign=AFF_OTH_CPO_MULT_OLC_AdmitAd_e83af54b533cf8676cb3322a770f3930_401684_`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:"https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397549/01/sv01/fnd/IND/fmt/png/Palermo-Special-Unisex-Sneakers",
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397549/01/fnd/IND/fmt/png/Palermo-Special-Unisex-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397549/01/mod02/fnd/IND/fmt/png/Palermo-Special-Unisex-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Eye-catching Design`,
    paragraph:`The Pink Delight-PUMA Green-Gum color combination is vibrant and stands out, adding a pop of color to any outfit.`,
    heading1:`Unisex Appeal`,
    paragraph1:`Designed to be unisex, these sneakers are suitable for both men and women, offering a versatile option for various styles.`,
    heading2:`Comfortable Fit`,
    paragraph2:`With a cushioned footbed and padded collar, these sneakers provide a comfortable fit for all-day wear.`,
    heading3:`Durable Construction`,
    paragraph3:`Made with high-quality materials, these sneakers are durable and built to last.`,
    heading4:`Versatile Style`,
    paragraph4:`The classic design of the Palermo Special sneakers makes them easy to pair with a variety of outfits, from casual to sporty.`,
    keyword:`Trend'z Articles Nike palermo special unisex Sneakers`,
    summary:`https://jdsportsblog.s3.amazonaws.com/wp-content/uploads/2023/11/Blog_Header_1920x840-1920x840.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fcollaborations%2Fcollaborations-select%2Fcollaborations-select-palermo`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Palermo Special Unisex Sneakers in Pink Delight-PUMA Green-Gum colorway are a vibrant and stylish choice for footwear. With a comfortable fit, durable construction, and iconic Puma branding, these sneakers offer both style and functionality. The unique color combination adds a playful touch to any outfit, making them a standout choice.`
    ],
  },

  
  {
    name:"82-Palermo-Unisex-Sneakers",
    title:`62 Puma blue Palermo Unisex Sneakers`,
    rate:'Rs.6,999',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-blue-600`,
    wear:`Unisex `,
    Scolor:"text-pink-400 text-lg font-semibold",
    buy:`/images/puma.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fpalermo-unisex-sneakers%2F396463%3Freferrer-category%3Dcollaborations-select-palermo%26swatch%3D07%26utm_aud%3DMULT%26utm_campaign%3DAFF_OTH_CPO_MULT_OLC_AdmitAd_3bc89bc542f3b484dd9fe478f1445c1a_401684_%26utm_medium%3DAFF%26utm_obj%3DOLC%26utm_source%3DOTH-CPO`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:"https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/07/sv01/fnd/IND/fmt/png/Palermo-Unisex-Sneakers",
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/07/bv/fnd/IND/fmt/png/Palermo-Unisex-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/07/sv05/fnd/IND/fmt/png/Palermo-Unisex-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Vibrant Colorway`,
    paragraph:`The Vine-Clementine color combination is bold and vibrant, adding a fun and lively touch to your outfit.`,
    heading1:`Unisex Design`,
    paragraph1:`The sneakers are designed to be unisex, making them suitable for both men and women who appreciate stylish and comfortable footwear.`,
    heading2:`Comfortable Fit`,
    paragraph2:`With a cushioned footbed and padded collar, these sneakers provide a comfortable fit, ideal for all-day wear.`,
    heading3:`Durable Construction`,
    paragraph3:`Made with high-quality materials, these sneakers are built to last, ensuring long-term durability and performance.`,
    heading4:`Versatile Style`,
    paragraph4:`The classic design of the Palermo sneakers makes them versatile enough to be paired with a variety of outfits, from casual to sporty.`,
    keyword:`Trend'z Shoes Nike palermo Article, trendz blog nike palermo `,
    summary:`https://jdsportsblog.s3.amazonaws.com/wp-content/uploads/2023/11/Blog_Header_1920x840-1920x840.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fcollaborations%2Fcollaborations-select%2Fcollaborations-select-palermo`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Puma Palermo Unisex Sneakers are a versatile and stylish footwear option suitable for both men and women. Featuring a vibrant colorway and a comfortable fit, these sneakers are perfect for everyday wear. The sneakers are designed with a cushioned footbed and a padded collar, ensuring a comfortable feel all day long. With their durable construction and iconic Puma branding, these sneakers are a great choice for anyone looking for fashionable and functional footwear.`
    ],
  },

  {
    name:`81-Palermo-Unisex-Sneakers`,
    title:`61 Puma Palermo Unisex Sneakers`,
    rate:'Rs.6,999',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-lime-400`,
    wear:`Unisex `,
    Scolor:"text-pink-400 text-lg font-semibold",
    buy:`/images/puma.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fpalermo-unisex-sneakers%2F396463%3Fswatch%3D02%26referrer-category%3Dcollaborations-select-palermo`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:"https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/02/sv01/fnd/IND/fmt/png/Palermo-Unisex-Sneakers",
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/02/sv04/fnd/IND/fmt/png/Palermo-Unisex-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/02/mod01/fnd/IND/fmt/png/Palermo-Unisex-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Vibrant Colorway`,
    paragraph:`The Vine-Clementine color combination is bold and vibrant, adding a fun and lively touch to your outfit.`,
    heading1:`Unisex Design`,
    paragraph1:`The sneakers are designed to be unisex, making them suitable for both men and women who appreciate stylish and comfortable footwear.`,
    heading2:`Comfortable Fit`,
    paragraph2:`With a cushioned footbed and padded collar, these sneakers provide a comfortable fit, ideal for all-day wear.`,
    heading3:`Durable Construction`,
    paragraph3:`Made with high-quality materials, these sneakers are built to last, ensuring long-term durability and performance.`,
    heading4:`Versatile Style`,
    paragraph4:`The classic design of the Palermo sneakers makes them versatile enough to be paired with a variety of outfits, from casual to sporty.`,
    keyword:`Nike palermo , trendz blog Nike Palermo Shoes`,
    summary:`https://jdsportsblog.s3.amazonaws.com/wp-content/uploads/2023/11/Blog_Header_1920x840-1920x840.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fcollaborations%2Fcollaborations-select%2Fcollaborations-select-palermo`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Puma Palermo Unisex Sneakers are a versatile and stylish footwear option suitable for both men and women. Featuring a vibrant colorway and a comfortable fit, these sneakers are perfect for everyday wear. The sneakers are designed with a cushioned footbed and a padded collar, ensuring a comfortable feel all day long. With their durable construction and iconic Puma branding, these sneakers are a great choice for anyone looking for fashionable and functional footwear.`
    ],
  },

  {
    name:`80-Palermo-Unisex-Sneakers`,
    title:`60 Palermo Unisex Sneakers`,
    rate:'Rs.6,999',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-green-600`,
    wear:`Unisex `,
    Scolor:"text-orange-600 text-lg font-semibold",
    buy:`/images/puma.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fpalermo-unisex-sneakers%2F396463%3Fswatch%3D05%26referrer-category%3Dcollaborations-select-palermo`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/05/sv01/fnd/IND/fmt/png/Palermo-Unisex-Sneakers`,
    image:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/05/sv02/fnd/IND/fmt/png/Palermo-Unisex-Sneakers`,
    image1:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/396463/05/mod01/fnd/IND/fmt/png/Palermo-Unisex-Sneakers`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Vibrant Colorway`,
    paragraph:`The Vine-Clementine color combination is bold and vibrant, adding a fun and lively touch to your outfit.`,
    heading1:`Unisex Design`,
    paragraph1:`The sneakers are designed to be unisex, making them suitable for both men and women who appreciate stylish and comfortable footwear.`,
    heading2:`Comfortable Fit`,
    paragraph2:`With a cushioned footbed and padded collar, these sneakers provide a comfortable fit, ideal for all-day wear.`,
    heading3:`Durable Construction`,
    paragraph3:`Made with high-quality materials, these sneakers are built to last, ensuring long-term durability and performance.`,
    heading4:`Versatile Style`,
    paragraph4:`The classic design of the Palermo sneakers makes them versatile enough to be paired with a variety of outfits, from casual to sporty.`,
    keyword:`Trend'z Shoes Nike Article, trendz blog Nike Air Max `,
    summary:`https://i.ytimg.com/vi/Up-p0-japYo/maxresdefault.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fcollaborations%2Fcollaborations-select%2Fcollaborations-select-palermo`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Puma Palermo Unisex Sneakers are a versatile and stylish footwear option suitable for both men and women. Featuring a vibrant colorway and a comfortable fit, these sneakers are perfect for everyday wear. The sneakers are designed with a cushioned footbed and a padded collar, ensuring a comfortable feel all day long. With their durable construction and iconic Puma branding, these sneakers are a great choice for anyone looking for fashionable and functional footwear.`
    ],
  },


  {
    name:`79-Palermo-Unisex-Sneakers`,
    title:`59 Nike Air Max 90 LV8`,
    rate:'Rs.12,795',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-pink-300`,
    wear:`Women's Wear`,
    Scolor:"text-orange-600 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-max-90-lv8-shoes-5KhTdP%2FFD4328-001`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/79/79.1.jpg`,
    image:`/images/fashion/79/79.2.jpg`,
    image1:`/images/fashion/79/79.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Design`,
    paragraph:`The Air Max 90 LV8 features the classic design lines of the original Air Max 90, including the iconic Air Max unit in the heel, which provides lightweight cushioning and a comfortable feel.`,
    heading1:`Premium Materials`,
    paragraph1:`The shoe is made with premium materials, including leather, mesh, and synthetic overlays, which provide durability and support where you need it most.`,
    heading2:`Comfortable Fit`,
    paragraph2:`The Air Max 90 LV8 has a padded collar and tongue, as well as a soft lining, which provide a comfortable fit and feel throughout the day.`,
    heading3:`Versatile Style`,
    paragraph3:`The shoe's timeless design and neutral color options make it easy to pair with a variety of outfits, from athleisure wear to casual streetwear.`,
    heading4:`Enhanced Durability`,
    paragraph4:`The shoe's rubber outsole is durable and provides traction on a variety of surfaces, making it ideal for everyday wear.`,
    keyword:`palermo special unisex sneakers`,
    summary:`https://i.pinimg.com/736x/80/e4/a8/80e4a846a9a9c476aaa00157ad9218e7.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Fw%2Fwomens-sports-bras-40qgmz5e1x6`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Nike Air Max 90 LV8 women's shoe is a stylish and comfortable footwear option that combines the iconic Air Max design with modern materials and technology. `
    ],
  },



  {
    name:`78-Nike-Air-Max-90-LV8`,
    title:`58 Nike Air Max 90 LV8`,
    rate:'Rs.12,795',
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-amber-500`,
    wear:`Women's Wear`,
    Scolor:"text-orange-600 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-max-90-lv8-shoes-5KhTdP%2FFD4328-100`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/78/78.1.png`,
    image:`/images/fashion/78/78.2.png`,
    image1:`/images/fashion/78/78.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Design`,
    paragraph:`The Air Max 90 LV8 features the classic design lines of the original Air Max 90, including the iconic Air Max unit in the heel, which provides lightweight cushioning and a comfortable feel.`,
    heading1:`Premium Materials`,
    paragraph1:`The shoe is made with premium materials, including leather, mesh, and synthetic overlays, which provide durability and support where you need it most.`,
    heading2:`Comfortable Fit`,
    paragraph2:`The Air Max 90 LV8 has a padded collar and tongue, as well as a soft lining, which provide a comfortable fit and feel throughout the day.`,
    heading3:`Versatile Style`,
    paragraph3:`The shoe's timeless design and neutral color options make it easy to pair with a variety of outfits, from athleisure wear to casual streetwear.`,
    heading4:`Enhanced Durability`,
    paragraph4:`The shoe's rubber outsole is durable and provides traction on a variety of surfaces, making it ideal for everyday wear.`,
    keyword:`air max 90 lv8 shoes`,
    summary:`https://i.pinimg.com/originals/ea/90/93/ea90931d689a338e72a299cd1cdd583a.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Fw%2Fnewjeans-picks-84m1p`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Nike Air Max 90 LV8 women's shoe is a stylish and comfortable footwear option that combines the iconic Air Max design with modern materials and technology. `
    ],
  },


  {
    name:`77-Nike-Air-Max-90-LV8`,
    title:`57 Nike Air Max 90 LV8`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Women's Wear`,
    Scolor:"text-rose-600 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-max-90-lv8-shoes-5KhTdP%2FFD4328-101`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/77/77.1.png`,
    image:`/images/fashion/77/77.2.png`,
    image1:`/images/fashion/77/77.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Classic Design`,
    paragraph:`The Air Max 90 LV8 features the classic design lines of the original Air Max 90, including the iconic Air Max unit in the heel, which provides lightweight cushioning and a comfortable feel.`,
    heading1:`Premium Materials`,
    paragraph1:`The shoe is made with premium materials, including leather, mesh, and synthetic overlays, which provide durability and support where you need it most.`,
    heading2:`Comfortable Fit`,
    paragraph2:`The Air Max 90 LV8 has a padded collar and tongue, as well as a soft lining, which provide a comfortable fit and feel throughout the day.`,
    heading3:`Versatile Style`,
    paragraph3:`The shoe's timeless design and neutral color options make it easy to pair with a variety of outfits, from athleisure wear to casual streetwear.`,
    heading4:`Enhanced Durability`,
    paragraph4:`The shoe's rubber outsole is durable and provides traction on a variety of surfaces, making it ideal for everyday wear.`,
    keyword:`air max 90 lv8 shoes `,
    summary:`https://i.pinimg.com/736x/19/7e/c8/197ec8582ef36f5c74741d5124251d5c.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Fw%2Fnewjeans-picks-84m1p`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The Nike Air Max 90 LV8 women's shoe is a stylish and comfortable footwear option that combines the iconic Air Max design with modern materials and technology. `
    ],
  },





  {
    name:`76-Nike-Air-Force-1-Shadow-Womens-Shoes`,
    title:`56 Nike Air Force 1 Shadow Women's Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-rose-300`,
    wear:`Women's Wear`,
    Scolor:"text-rose-700 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-force-1-shadow-shoes-3d774m%2FDZ1847-103`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/76/76.1.png`,
    image:`/images/fashion/76/76.2.png`,
    image1:`/images/fashion/76/76.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Double the Style`,
    paragraph:`The Air Force 1 Shadow features double design details and layered pieces, adding depth and dimension to the classic Air Force 1 silhouette. It's a unique twist on a beloved classic.`,
    heading1:`Iconic Comfort`,
    paragraph1:`With a foam midsole and a padded collar, these shoes provide lightweight cushioning and all-day comfort, making them ideal for daily wear.`,
    heading2:`Durable Construction`,
    paragraph2:`Crafted with a leather and synthetic upper, these shoes are built to last, ensuring long-lasting durability and style.`,
    heading3:`Versatile Design`,
    paragraph3:`The Air Force 1 Shadow is versatile enough to pair with a variety of outfits, from jeans and a t-shirt to dresses and skirts, making it a versatile addition to your wardrobe.`,
    heading4:`Bold Colorways`,
    paragraph4:`Available in a range of bold and vibrant colorways, these shoes allow you to express your unique style and make a statement wherever you go.`,
    keyword:`Trend'z Shoes Nike Article`,
    summary:`https://www.exchange4media.com/news-photo/93427-FastrackReflexWav.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrack.in%2Fproduct%2Ffastrack-reflex-3.0-smart-band-with-10%2B-sports-modes-full-touch-colour-display-stylish-watch-faces-hrm-10-days-battery-life-ip68-water-resistance-swd90067pp01a.html%3Flang%3Den_IN%26plp%3Dtrue`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike Air Force 1 Shadow Women's Shoe" is a stylish and versatile footwear option designed to elevate your streetwear game. Here's why it's a must-have in your collection  `
    ],
  },



  {
    name:`75-Nike-Air-Force-1-Shadow-Womens-Shoes`,
    title:`55 Nike Air Force 1 Shadow Women's Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-sky-500`,
    wear:`Women's Wear`,
    Scolor:"text-emerald-600 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-force-1-shadow-shoes-3d774m%2FDZ1847-103`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/75/75.1.png`,
    image:`/images/fashion/75/75.2.png`,
    image1:`/images/fashion/75/75.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Double the Style`,
    paragraph:`The Air Force 1 Shadow features double design details and layered pieces, adding depth and dimension to the classic Air Force 1 silhouette. It's a unique twist on a beloved classic.`,
    heading1:`Iconic Comfort`,
    paragraph1:`With a foam midsole and a padded collar, these shoes provide lightweight cushioning and all-day comfort, making them ideal for daily wear.`,
    heading2:`Durable Construction`,
    paragraph2:`Crafted with a leather and synthetic upper, these shoes are built to last, ensuring long-lasting durability and style.`,
    heading3:`Versatile Design`,
    paragraph3:`The Air Force 1 Shadow is versatile enough to pair with a variety of outfits, from jeans and a t-shirt to dresses and skirts, making it a versatile addition to your wardrobe.`,
    heading4:`Bold Colorways`,
    paragraph4:`Available in a range of bold and vibrant colorways, these shoes allow you to express your unique style and make a statement wherever you go.`,
    keyword:`Trend'z Shoes Nike Article`,
    summary:`https://www.shopickr.com/wp-content/uploads/2021/07/ajio-reliance-trends-fashion-sale.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fs%2Fclothing-4461-75481`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike Air Force 1 Shadow Women's Shoe" is a stylish and versatile footwear option designed to elevate your streetwear game. Here's why it's a must-have in your collection  `
    ],
  },

  {
    name:`74-Nike-Air-Force-1-Shadow-Womens-Shoes`,
    title:`54 Nike Air Force 1 Shadow Women's Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-teal-600`,
    wear:`Women's Wear`,
    Scolor:"text-cyan-700 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-force-1-shadow-shoes-3d774m%2FDZ1847-102`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/74/74.1.png`,
    image:`/images/fashion/74/74.2.png`,
    image1:`/images/fashion/74/74.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Double the Style`,
    paragraph:`The Air Force 1 Shadow features double design details and layered pieces, adding depth and dimension to the classic Air Force 1 silhouette. It's a unique twist on a beloved classic.`,
    heading1:`Iconic Comfort`,
    paragraph1:`With a foam midsole and a padded collar, these shoes provide lightweight cushioning and all-day comfort, making them ideal for daily wear.`,
    heading2:`Durable Construction`,
    paragraph2:`Crafted with a leather and synthetic upper, these shoes are built to last, ensuring long-lasting durability and style.`,
    heading3:`Versatile Design`,
    paragraph3:`The Air Force 1 Shadow is versatile enough to pair with a variety of outfits, from jeans and a t-shirt to dresses and skirts, making it a versatile addition to your wardrobe.`,
    heading4:`Bold Colorways`,
    paragraph4:`Available in a range of bold and vibrant colorways, these shoes allow you to express your unique style and make a statement wherever you go.`,
    keyword:`Trend'z Shoes Nike Article`,
    summary:`https://assets.ajio.com/cms/AJIO/WEB/15062021-D-NewArrival-Topbanner-Newstylesadded.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fc%2Fexclusive-brands-5240-00931`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike Air Force 1 Shadow Women's Shoe" is a stylish and versatile footwear option designed to elevate your streetwear game. Here's why it's a must-have in your collection  `
    ],
  },


  {
    name:`73-Nike-G-T-Cut-3-ASW-EP-Basketball-Shoes`,
    title:`53 Nike G.T. Cut 3 ASW EP Basketball Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-sky-800`,
    wear:`Men's Wear`,
    Scolor:"text-teal-700 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fgt-cut-3-asw-ep-basketball-shoes-hMM20h%2FFZ5743-100`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/73/73.1.png`,
    image:`/images/fashion/73/73.2.png`,
    image1:`/images/fashion/73/73.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Advanced Cushioning`,
    paragraph:`The shoes are equipped with Nike's responsive cushioning technology, providing excellent impact protection and energy return for enhanced performance on the court.`,
    heading1:`Enhanced Traction`,
    paragraph1:`The outsole features a multidirectional traction pattern that offers exceptional grip on indoor and outdoor surfaces, allowing you to make quick cuts and explosive movements with confidence.`,
    heading2:`Durable Construction`,
    paragraph2:`With a combination of premium materials and expert craftsmanship, these shoes are built to withstand the rigors of the game, ensuring long-lasting performance and durability.`,
    heading3:`Supportive Design`,
    paragraph3:`The shoes feature a high-top design with a padded collar and tongue, providing excellent ankle support and stability to help reduce the risk of injuries.`,
    heading4:`Breathable Upper`,
    paragraph4:`The shoes feature a breathable upper that helps keep your feet cool and comfortable during intense gameplay, allowing you to stay focused and perform at your best.`,
    keyword:`Trend'z Shoes Nike Article`,
    summary:`https://miro.medium.com/v2/resize:fit:730/1*fDJxac8P20Jmyb4GFYzWKQ.jpeg`,
    jlink:`https://fktr.in/k5eSz6i`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike G.T. Cut 3 ASW EP Basketball Shoes" are a premium basketball footwear option designed for performance and style. `
    ],
  },


  {
    name:`72-nike-Jump-man-MVP-Men-Shoes`,
    title:`52 Nike Jumpman MVP Men's Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-sky-700`,
    wear:`Men's Wear`,
    Scolor:"text-red-700 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fjumpman-mvp-shoes-JV1HCs%2FDZ4475-026%3Fcp%3D53103873763_aff_FE4O7wtxe6g%26ranMID%3D41134%26ranEAID%3DFE4O7wtxe6g%26ranSiteID%3DFE4O7wtxe6g-.fX_MxYtdsJp3Ra4tNuGXw`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/72/72.1.png`,
    image:`/images/fashion/72/72.2.png`,
    image1:`/images/fashion/72/72.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Iconic Jumpman Design`,
    paragraph:`The shoes feature the iconic Jumpman logo, symbolizing Michael Jordan's legacy in basketball and Nike's commitment to excellence in athletic footwear.`,
    heading1:`Comfortable Cushioning`,
    paragraph1:`Equipped with Nike's signature cushioning technology, these shoes provide responsive and comfortable cushioning to support your feet during intense gameplay or everyday wear.`,
    heading2:`Durable Construction`,
    paragraph2:`Built to withstand the rigors of the game, the shoes feature a durable construction that can endure frequent use on the court or the streets.`,
    heading3:`Enhanced Traction`,
    paragraph3:`The outsole is designed with a multidirectional traction pattern, offering superior grip and traction on various surfaces, allowing you to make quick cuts and explosive movements with confidence.`,
    heading4:`Breathable Materials`,
    paragraph4:`The upper is constructed with breathable materials that help keep your feet cool and comfortable, reducing the risk of overheating during extended wear.`,
    keyword:`Trend'z Shoes Article`,
    summary:`https://stylehub.shoppersstop.com/wp-content/uploads/2021/12/Eoss-buyingGuide_web02-1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2F`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike Jumpman MVP Men's Shoes" is a stylish and performance-driven footwear option that combines iconic design with innovative technology. Here's an overview of its features and advantages`
    ],
  },


  {
    name:`71-Jumpman-MVP-Men-Shoes`,
    title:`51 Nike Jumpman MVP Men's Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-blue-800`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fjumpman-mvp-shoes-JV1HCs%2FDZ4475-041`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/71/71.1.png`,
    image:`/images/fashion/71/71.2.png`,
    image1:`/images/fashion/71/71.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Iconic Jumpman Design`,
    paragraph:`The shoes feature the iconic Jumpman logo, symbolizing Michael Jordan's legacy in basketball and Nike's commitment to excellence in athletic footwear.`,
    heading1:`Comfortable Cushioning`,
    paragraph1:`Equipped with Nike's signature cushioning technology, these shoes provide responsive and comfortable cushioning to support your feet during intense gameplay or everyday wear.`,
    heading2:`Durable Construction`,
    paragraph2:`Built to withstand the rigors of the game, the shoes feature a durable construction that can endure frequent use on the court or the streets.`,
    heading3:`Enhanced Traction`,
    paragraph3:`The outsole is designed with a multidirectional traction pattern, offering superior grip and traction on various surfaces, allowing you to make quick cuts and explosive movements with confidence.`,
    heading4:`Breathable Materials`,
    paragraph4:`The upper is constructed with breathable materials that help keep your feet cool and comfortable, reducing the risk of overheating during extended wear.`,
    keyword:`Trendz article on jordon shoes, jumpman, nike shoes ,`,
    summary:`https://sslimages.shoppersstop.com/sys-master/root/h2e/h7d/15094741106718/Eoss-Res.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2F`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike Jumpman MVP Men's Shoes" is a stylish and performance-driven footwear option that combines iconic design with innovative technology. Here's an overview of its features and advantages`
    ],
  },


  {
    name:`70-Jumpman-MVP-Men-Shoes`,
    title:`50 Nike Jumpman MVP Men's Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-red-800`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fjumpman-mvp-shoes-JV1HCs%2FDZ4475-107`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/70/70.1.png`,
    image:`/images/fashion/70/70.2.png`,
    image1:`/images/fashion/70/70.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Iconic Jumpman Design`,
    paragraph:`The shoes feature the iconic Jumpman logo, symbolizing Michael Jordan's legacy in basketball and Nike's commitment to excellence in athletic footwear.`,
    heading1:`Comfortable Cushioning`,
    paragraph1:`Equipped with Nike's signature cushioning technology, these shoes provide responsive and comfortable cushioning to support your feet during intense gameplay or everyday wear.`,
    heading2:`Durable Construction`,
    paragraph2:`Built to withstand the rigors of the game, the shoes feature a durable construction that can endure frequent use on the court or the streets.`,
    heading3:`Enhanced Traction`,
    paragraph3:`The outsole is designed with a multidirectional traction pattern, offering superior grip and traction on various surfaces, allowing you to make quick cuts and explosive movements with confidence.`,
    heading4:`Breathable Materials`,
    paragraph4:`The upper is constructed with breathable materials that help keep your feet cool and comfortable, reducing the risk of overheating during extended wear.`,
    keyword:`jordon shoes, jumpman, nike shoes ,`,
    summary:`https://adn-static1.nykaa.com/nykdesignstudio-images/pub/media/catalog/product/c/2/c24e12dNK_FASAA00002010_Banner_Image.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrack.in%2Fshop%2Fwatches-girls%3Flang%3Den_IN`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike Jumpman MVP Men's Shoes" is a stylish and performance-driven footwear option that combines iconic design with innovative technology. Here's an overview of its features and advantages`
    ],
  },


  {
    name:`69-Jumpman-MVP-Men-Shoes`,
    title:`49 Nike Jumpman MVP Men's Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-yellow-500`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fjumpman-mvp-shoes-JV1HCs%2FDZ4475-107`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/69/69.1.png`,
    image:`/images/fashion/69/69.2.png`,
    image1:`/images/fashion/69/69.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Iconic Jumpman Design`,
    paragraph:`The shoes feature the iconic Jumpman logo, symbolizing Michael Jordan's legacy in basketball and Nike's commitment to excellence in athletic footwear.`,
    heading1:`Comfortable Cushioning`,
    paragraph1:`Equipped with Nike's signature cushioning technology, these shoes provide responsive and comfortable cushioning to support your feet during intense gameplay or everyday wear.`,
    heading2:`Durable Construction`,
    paragraph2:`Built to withstand the rigors of the game, the shoes feature a durable construction that can endure frequent use on the court or the streets.`,
    heading3:`Enhanced Traction`,
    paragraph3:`The outsole is designed with a multidirectional traction pattern, offering superior grip and traction on various surfaces, allowing you to make quick cuts and explosive movements with confidence.`,
    heading4:`Breathable Materials`,
    paragraph4:`The upper is constructed with breathable materials that help keep your feet cool and comfortable, reducing the risk of overheating during extended wear.`,
    keyword:`Fastrack Watch, Luxury look, Fastrack Wedding Watch, Exclusive Deal,`,
    summary:`https://www.forwardparcel.com/images/myntra.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.myntra.com%2Fwomens-western-wear%3Frf%3DDiscount%20Range%3A50.0_100.0_50.0%20TO%20100.0`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `The "Nike Jumpman MVP Men's Shoes" is a stylish and performance-driven footwear option that combines iconic design with innovative technology. Here's an overview of its features and advantages
      `
    ],
  },


  {
    name:`68-Nike-GT-ut-3-EP-Basketball-Shoes`,
    title:`48.Nike G.T. Cut 3 EP Basketball Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-orange-700`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fgt-cut-3-ep-basketball-shoes-DGJp2L%2FDV2918-101%3Fcp%3D53103873763_aff_FE4O7wtxe6g%26ranMID%3D41134%26ranEAID%3DFE4O7wtxe6g%26ranSiteID%3DFE4O7wtxe6g-us_9dg6yqClH87H800n38A`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/68/68.1.png`,
    image:`/images/fashion/68/68.2.png`,
    image1:`/images/fashion/68/68.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Dynamic Fit`,
    paragraph:`The Nike G.T. Cut 3 EP features a dynamic fit system that wraps around your foot for a snug and secure feel. This ensures stability during quick cuts and agile movements on the court.`,
    heading1:`Responsive Cushioning`,
    paragraph1:`Equipped with Nike's advanced cushioning technology, these shoes provide responsive cushioning that absorbs impact and delivers energy back to your movements. It enhances your explosiveness and helps you stay quick on your feet throughout the game.`,
    heading2:`Enhanced Traction`,
    paragraph2:`The outsole of the Nike G.T. Cut 3 EP is designed with a multidirectional traction pattern, providing superior grip on the court surface. It allows you to make sharp cuts, pivot with ease, and maintain control during fast-paced play.`,
    heading3:`Breathable Support`,
    paragraph3:`Engineered mesh upper offers breathable support, keeping your feet cool and comfortable during intense gameplay. It prevents overheating and helps you stay focused on the game without distractions.`,
    heading4:`Streamlined Design`,
    paragraph4:`The Nike G.T. Cut 3 EP boasts a streamlined design that minimizes distractions and maximizes performance. It's lightweight yet durable, allowing you to move freely and confidently on the court.`,
    keyword:`Nike cut gt3, Shoes NIKE SHOES`,
    summary:`https://adn-static1.nykaa.com/nykdesignstudio-images/pub/media/catalog/product/c/2/c24e12dNK_FASAA00002010_Banner_Image.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrack.in%2Fshop%2Fwatches-girls%3Flang%3Den_IN`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Experience unmatched performance on the court with the Nike G.T. Cut 3 EP Basketball Shoes. Engineered for agility, support, and responsiveness, these shoes are designed to take your game to the next level. Here's what sets them apart.`
    ],
  },

  {
    name:`67-Nike-GT-ut-3-EP-Basketball-Shoes`,
    title:`47.Nike G.T. Cut 3 EP Basketball Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-zinc-600`,
    wear:`Men's Wear`,
    Scolor:"text-purple-700 text-lg font-semibold",
    buy:`/images/nike.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fgt-cut-3-ep-basketball-shoes-DGJp2L%2FDV2918-400`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/67/67.1.jpg`,
    image:`/images/fashion/67/67.2.jpg`,
    image1:`/images/fashion/67/67.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Dynamic Fit`,
    paragraph:`The Nike G.T. Cut 3 EP features a dynamic fit system that wraps around your foot for a snug and secure feel. This ensures stability during quick cuts and agile movements on the court.`,
    heading1:`Responsive Cushioning`,
    paragraph1:`Equipped with Nike's advanced cushioning technology, these shoes provide responsive cushioning that absorbs impact and delivers energy back to your movements. It enhances your explosiveness and helps you stay quick on your feet throughout the game.`,
    heading2:`Enhanced Traction`,
    paragraph2:`The outsole of the Nike G.T. Cut 3 EP is designed with a multidirectional traction pattern, providing superior grip on the court surface. It allows you to make sharp cuts, pivot with ease, and maintain control during fast-paced play.`,
    heading3:`Breathable Support`,
    paragraph3:`Engineered mesh upper offers breathable support, keeping your feet cool and comfortable during intense gameplay. It prevents overheating and helps you stay focused on the game without distractions.`,
    heading4:`Streamlined Design`,
    paragraph4:`The Nike G.T. Cut 3 EP boasts a streamlined design that minimizes distractions and maximizes performance. It's lightweight yet durable, allowing you to move freely and confidently on the court.`,
    keyword:`Nike cut gt3, Shoes NIKE SHOES`,
    summary:`https://cache.tradeinn.com/images/brand-page-premium/video-514_13.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fsports%2Fsports-training%3Foffset%3D24`,
    bclass:`h-full w-2/3 ml-[5rem] md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Experience unmatched performance on the court with the Nike G.T. Cut 3 EP Basketball Shoes. Engineered for agility, support, and responsiveness, these shoes are designed to take your game to the next level. Here's what sets them apart.`
    ],
  },


  {
    name:`66-Fastrack-Tick-Tock-Brown-Dial-Watch-for-Guys`,
    title:`46.Fastrack Tick Tock Brown Dial Watch for Guys`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-rose-700`,
    wear:`Men's Wear`,
    Scolor:"text-orange-600 text-lg font-semibold",
    buy:`/images/fastrack.png`,
    blink:`https://www.fastrack.in/product/fastrack-tick-tock-quartz-multifunction-watch-for-guys-with-brown-colour-metal-strap-3287qm03.html?lang=en_IN&plp=true`,
    buy1:`/images/nosite.png`,
    blink1:``,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/fashion/66/66.1.jpg`,
    image:`/images/fashion/66/66.2.jpg`,
    image1:`/images/fashion/66/66.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Sleek Design`,
    paragraph:`The Fastrack Tick Tock watch features a sleek and contemporary design that effortlessly complements any outfit. Its minimalist brown dial with bold numerals exudes sophistication and elegance.`,
    heading1:`Versatile Style`,
    paragraph1:`Whether you're dressing up for a formal occasion or keeping it casual for everyday wear, this watch seamlessly transitions between different looks, adding a touch of refinement to your ensemble.`,
    heading2:`Durable Construction`,
    paragraph2:`Crafted with high-quality materials, including a sturdy stainless steel case and a genuine leather strap, this watch is built to withstand the test of time. It's resistant to wear and tear, making it suitable for everyday use.`,
    heading3:`Precise Timekeeping`,
    paragraph3:`Equipped with a reliable quartz movement, the Fastrack Tick Tock watch ensures accurate timekeeping, so you never miss a beat. It's a dependable companion for keeping track of your busy schedule.`,
    heading4:`Water Resistance`,
    paragraph4:`With a water resistance rating of up to 30 meters, this watch is suitable for everyday wear, including splashes, rain, and handwashing. However, it's not recommended for swimming or diving activities.`,
    keyword:`Fastrack Watch,   Luxury look, Fastrack Wedding Watch, Exclusive Deal,`,
    summary:`https://i.pinimg.com/736x/b2/a4/c2/b2a4c2955f67434f70095822ea285429.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2F`,
    bclass:`w-2/3 h-full  ml-16 md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Elevate your style game with the Fastrack Tick Tock Brown Dial Watch for Guys. Combining functionality with fashion, this timepiece is a must-have accessory for any modern man. Here's why it stands out.`
    ],
  },



  {
    name:`65-Nitro-Pro-with-AMOLED-Display-AOD-Functional-Crown-BT-Calling-Smartwatch-with-Black-Strap`,
    title:`45.Fastrack Nitro Pro with 4.69 cm AMOLED Display and AOD, Functional Crown, BT Calling Smartwatch with Black Strap`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-zinc-700`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/fastrack.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrack.in%2Fproduct%2Ffastrack-nitro-pro-with-4.69-cm-amoled-display-with-aod-functional-crown-bt-calling-premium-smartwatch-38105pp03.html%3Flang%3Den_IN`,
    buy1:`/images/titan.png`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ffastrack-nitro-pro-with-4.69-cm-amoled-display-with-aod-functional-crown-bt-calling-premium-smartwatch-38105pp03.html%3Flang%3Den_IN%26plp%3Dtrue`,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/fashion/65/65.1.jpg`,
    image:`/images/fashion/65/65.2.jpg`,
    image1:`/images/fashion/65/65.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`4.69 cm AMOLED Display`,
    paragraph:`Immerse yourself in vibrant visuals with the 4.69 cm AMOLED Display. Whether you're checking notifications, tracking fitness metrics, or simply glancing at the time, every detail is vivid and clear.`,
    heading1:`Always-On Display (AOD)`,
    paragraph1:`Stay informed at a glance with the Always-On Display feature. Even when the watch face is inactive, important information is readily visible, ensuring you never miss a beat.`,
    heading2:`Functional Crown`,
    paragraph2:`Take control of your smartwatch experience with the Functional Crown. Navigate through menus, scroll effortlessly, and interact with precision, all with the intuitive control of the crown.`,
    heading3:`BT Calling Capability`,
    paragraph3:`Stay connected on the go with BT Calling capability. Answer calls directly from your wrist, thanks to the smartwatch's seamless integration with your smartphone, ensuring you're always reachable.`,
    heading4:`Sleek Black Strap`,
    paragraph4:`The sleek black strap complements the modern design of the smartwatch, adding a touch of sophistication to your wrist. It's not just a functional accessory; it's a statement piece.`,
    keyword:`Fastrack Watch, suare Dial, Luxury look, Faxtrack Wedding Watch, Exclusive Deal,`,
    summary:`https://i.pinimg.com/originals/f9/63/2d/f9632da088e8959fc0a0b2d6b1894212.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.skechers.in%2Fwomen%2Ffootwear%2Fslip-ons%3Fstart%3D0%26sz%3D24%26utm_source%3Dbanner%26utm_medium%3Dorganic%26utm_content%3Dbanner`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Introducing the Fastrack Nitro Pro Smartwatch, featuring a 4.69 cm AMOLED Display with Always-On Display (AOD), Functional Crown, and BT Calling capability. This cutting-edge smartwatch is not just a timepiece; it's a sophisticated companion that seamlessly blends style, functionality, and connectivity.`
    ],
  },


  {
    name:`64-Fastrack-UltraVU-Display-Functional-Crown-Rugged-Smartwatch-with-Auto-Multisport-Recognition`,
    title:`44.Fastrack Active with 1.83" UltraVU HD Display and Functional Crown Rugged Smartwatch with Auto Multisport Recognition`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-zinc-700`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg font-semibold",
    buy:`/images/fastrack.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrack.in%2Fproduct%2Ffastrack-active-smart-watch-unisex-with-black-colour-silicone-strap-38101pp01.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dwatches`,
    buy1:`/images/titan.png`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ffastrack-active-smart-watch-unisex-with-black-colour-silicone-strap-38101pp01.html%3Flang%3Den_IN`,
    buy2:`/images/tatacliq.png`,
    blink2:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.tatacliq.com%2Ffastrack-active-38101pp01-digital-watch%2Fp-mp000000019924364%3Fcid%3Dps%3ALF-TataCliq-STD-Watches%3AGoogle%3APLA_Lifestyle%26gad_source%3D1%26gclid%3DCjwKCAiAq4KuBhA6EiwArMAw1O-7t--vV2DycrKz_FF3GepwbvNuUWp-bAEzLXgVY86zt97TbSVf9RoCCpQQAvD_BwE` ,
    thumbnail:`/images/fashion/64/64.1.jpg`,
    image:`/images/fashion/64/64.2.jpg`,
    image1:`/images/fashion/64/64.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`83" UltraVU HD Display`,
    paragraph:`Immerse yourself in a crystal-clear display with the 1.83" UltraVU HD screen. Whether you're checking notifications or tracking your fitness metrics, the vibrant display enhances your overall smartwatch experience.`,
    heading1:`Functional Crown`,
    paragraph1:`Navigate effortlessly through your smartwatch features with the functional crown. This intuitive control adds a touch of sophistication to your interactions, making it easy to access various functions with precision.`,
    heading2:`Rugged Design`,
    paragraph2:`The Fastrack Active Smartwatch is built to withstand the demands of an active lifestyle. Its rugged design ensures durability, making it suitable for workouts, outdoor activities, and daily wear.`,
    heading3:`Auto Multisport Recognition`,
    paragraph3:`Let the smartwatch do the work with auto multisport recognition. Whether you're running, cycling, or engaged in other activities, the smartwatch intelligently detects and tracks your movements, providing accurate fitness data.`,
    heading4:`Health and Fitness Tracking`,
    paragraph4:`Keep a close eye on your health and fitness goals with comprehensive tracking features. Monitor your heart rate, track steps, analyze sleep patterns, and access valuable insights to optimize your well-being.`,
    keyword:`Fastrack Watch, suare Dial, Luxury look, Digital Watch, Exclusive Deal,`,
    summary:`https://www.adobomagazine.com/wp-content/uploads/2023/05/Reebok-launches-I-am-the-New-brand-campaign-insert1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Freebok.abfrl.in%2Fc%2Fmen`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Introducing the Fastrack Thor Quartz Multifunction Red Dial Metal Strap Watch—a timepiece that blends functionality with a bold design, perfect for guys who embrace a dynamic and energetic lifestyle. With its striking red dial and multifunction features, this watch is more than just an accessory; it's a statement of confidence and style`
    ],
  },

  {
    name:`63-Fastrack-Thor-Quartz-Multifunction-Red-Dial-Metal-Strap-Watch-for-Guys`,
    title:`43.Fastrack Thor Quartz Multifunction Red Dial Metal Strap Watch for Guys`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-rose-700`,
    wear:`Men's Wear`,
    Scolor:"text-red-400 text-lg font-semibold",
    buy:`/images/fastrack.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrack.in%2Fproduct%2Ffastrack-thor-quartz-multifunction-watch-for-guys-with-red-dial-metal-strap-3286km01.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dwatches`,
    buy1:`/images/titan.png`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ffastrack-thor-quartz-multifunction-watch-for-guys-with-red-dial-metal-strap-3286km01.html%3Flang%3Den_IN`,
    buy2:`/images/shopperstop.png`,
    blink2:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Ffastrack-ft-thor-44-50-x-51-50-x-12-10-mm-brown-dial-stainless-steel-analog-watch-for-men-nr3286km01%2Fp-WFTNR3286KM01_base%3Futm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3DSHOP_13245_adyogi_PerformanceMax_Watches-20307061150%26gad_source%3D1%26gclid%3DCjwKCAiAiP2tBhBXEiwACslfnl104DuCIOnd6T5Rgk9lMBPeI3P4xtdBXvHz0Zg9nNt9LZ0tQl2vNRoCFX0QAvD_BwE` ,
    thumbnail:`/images/fashion/63/63.1.jpg`,
    image:`/images/fashion/63/63.2.jpg`,
    image1:`/images/fashion/63/63.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Striking Red Dial`,
    paragraph:`The watch features a bold red dial that immediately grabs attention. The vibrant color adds a touch of energy and individuality to your overall look, making it a standout piece.`,
    heading1:`Multifunctional Design`,
    paragraph1:`Beyond telling time, this watch comes with multifunctional features such as day and date display. Stay organized and on top of your schedule with the convenience of additional functionalities.`,
    heading2:`Robust Metal Strap`,
    paragraph2:`The metal strap not only enhances the watch's durability but also adds a touch of sophistication. The robust construction ensures longevity, making it suitable for various occasions.`,
    heading3:`Quartz Movement`,
    paragraph3:`Powered by reliable quartz movement, this watch offers precision and accuracy. Enjoy the convenience of a timepiece that keeps up with your busy lifestyle without missing a beat.`,
    heading4:`Water-Resistant`,
    paragraph4:`The watch is designed to withstand daily activities, including exposure to water. While not suitable for deep-water activities, it provides water resistance to handle splashes and brief immersions.`,
    keyword:`Fastrack Watch, round Dial, Luxury look,`,
    summary:`https://www.skechers.in/dw/image/v2/BGNZ_PRD/on/demandware.static/-/Sites-skechersin-Library/default/dwe56be646/Campaign-Run-Landing/Desktop-Campaign-Run-Landing/Skechers%20Gowalk_OOH_1920%20x%20603_SET%203-01.jpg?sw=1600`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.skechers.in%2Fgo-walk-distance-walker%2F125129-GYLV.html`,
    bclass:`w-3/4 h-full ml-16 md:w-3/4 lg:w-3/4 xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Introducing the Fastrack Thor Quartz Multifunction Red Dial Metal Strap Watch—a timepiece that blends functionality with a bold design, perfect for guys who embrace a dynamic and energetic lifestyle. With its striking red dial and multifunction features, this watch is more than just an accessory; it's a statement of confidence and style`
    ],
  },

  {
    name:`62-Jack-Jones-Men-Grey-Printed-Sneakers.`,
    title:`42.Jack & Jones Men Perforations Sneakers`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-orange-500`,
    wear:`Men's Wear`,
    Scolor:"text-zinc-600 text-lg font-semibold",
    buy:`/images/myntra.png`,
    blink:`https://myntr.it/zGEQjP9`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/62/62.1.png`,
    image:`/images/fashion/62/62.2.png`,
    image1:`/images/fashion/62/62.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Captivating Printed Design`,
    paragraph:`The sneakers showcase a captivating printed design that adds a dynamic visual element. The prints are not just stylish but also a form of self-expression, allowing you to make a statement with your footwear.`,
    heading1:`Versatile Grey Hue`,
    paragraph1:`The versatile grey color of these sneakers makes them easy to pair with various outfits. Whether you prefer jeans, shorts, or chinos, these sneakers seamlessly integrate into your casual wardrobe.`,
    heading2:`Comfortable Insole`,
    paragraph2:`Engineered with a cushioned insole, these sneakers prioritize comfort for all-day wear. The cushioning provides support, making them an ideal choice for both casual outings and more extended periods of activity.`,
    heading3:`Durable Outsole`,
    paragraph3:`The sneakers come with a durable outsole that offers reliable traction and stability. Whether you're navigating city streets or enjoying a leisurely walk, these sneakers provide the grip you need.`,
    heading4:`Easy to Style`,
    paragraph4:`These sneakers effortlessly complement a range of casual outfits. Their printed design adds a touch of flair, making them suitable for various occasions, from laid-back weekends to casual outings with friends.`,
    keyword:`trendz Fashion Articles`,
    summary:`https://startupstorymedia.com/wp-content/uploads/2023/11/AJIO-forays.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fs%2Fr-and-b-5258-49651`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Jack & Jones Men Grey Printed Sneakers—a perfect fusion of contemporary design and everyday comfort. These sneakers are not just footwear; they are a canvas of self-expression, featuring captivating prints that make a bold statement. Step out in style and comfort with a pair that complements your casual and streetwear looks effortlessly.`
    ],
  },


  {
    name:`61-Men-Perforations-Sneakers`,
    title:`41.Jack & Jones Men Perforations Sneakers`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-black`,
    wear:`Men's Wear`,
    Scolor:"text-indigo-600 text-lg font-semibold",
    buy:`/images/myntra.png`,
    blink:`https://myntr.it/PJm4OX2`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/fashion/61/61.1.png`,
    image:`/images/fashion/61/61.2.png`,
    image1:`/images/fashion/61/61.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Perforated Design`,
    paragraph:`The sneakers showcase a perforated design that enhances breathability and adds a dynamic visual element. The perforations not only contribute to the shoes' style but also keep your feet comfortably ventilated.`,
    heading1:`Versatile Color Options`,
    paragraph1:`Available in a range of versatile color options, these sneakers can easily become a staple in your wardrobe. Choose from classic neutrals or make a statement with bold hues, catering to your personal style preferences.`,
    heading2:`Comfortable Insole`,
    paragraph2:`Designed with your comfort in mind, the sneakers feature a cushioned insole that provides support for all-day wear. Whether you're running errands or spending a casual day out, your feet will appreciate the extra comfort.`,
    heading3:`Durable Outsole`,
    paragraph3:`The sneakers are equipped with a durable outsole that offers traction and stability. Navigate various surfaces with confidence, knowing your footwear is up to the task.`,
    heading4:`Easy to Style`,
    paragraph4:`These sneakers effortlessly complement a range of outfits, from jeans and shorts to casual chinos. Their versatile design makes them suitable for everyday wear, ensuring you stay on-trend without compromising comfort.`,
    keyword:`trendz blog shoes articles`,
    summary:`https://i.ytimg.com/vi/-vz_JjErZ9w/maxresdefault.jpg`,
    jlink:`https://myntr.it/tnad6of`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Jack & Jones Men Perforated Sneakers—a perfect blend of contemporary style and everyday comfort. These sneakers are not just footwear; they are a statement of modern aesthetics, featuring perforations that add a touch of urban flair. Step out in confidence with a pair that complements your casual and streetwear looks effortlessly.`
    ],
  },

  {
    name:`60-Men-White-and-Grey-Court-Rider-Basketball-Shoes`,
    title:`40.PUMA Hoops Men White and Grey Court Rider 2.0 Basketball Shoes`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-lime-500`,
    wear:`Men's Wear`,
    Scolor:"text-zinc-600 text-lg  font-semibold",
    buy:`/images/myntra.png`,
    blink:`https://www.myntra.com/16880514`,
    buy1:`/images/tatacliqluxury.png`,
    blink1:`https://bitli.in/uF0cmjP`,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/fashion/60/60.1.png`,
    image:`/images/fashion/60/60.2.png`,
    image1:`/images/fashion/60/60.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Dynamic Performance`,
    paragraph:`The Court Rider 2.0 is engineered for dynamic performance on the basketball court. With features like responsive cushioning and optimal traction, these shoes provide the support you need for quick moves and high jumps.`,
    heading1:`Sleek White and Grey Design`,
    paragraph1:`The white and grey colorway not only enhances the shoes' aesthetic appeal but also makes them versatile enough to pair with your off-court urban style. Whether on the court or the streets, these shoes make a fashion-forward statement.`,
    heading2:`Cushioned Comfort`,
    paragraph2:`Equipped with responsive cushioning technology, these shoes deliver comfort during intense gameplay. The cushioned midsole absorbs impact, providing a smooth and comfortable ride throughout the game.`,
    heading3:`Breathable Construction`,
    paragraph3:`The shoes feature a breathable design to keep your feet cool and comfortable during the most heated moments on the court. Stay focused and perform at your best without sacrificing comfort.`,
    heading4:`Durable Outsole`,
    paragraph4:`Designed with a durable rubber outsole, the Court Rider 2.0 ensures excellent traction on various surfaces, giving you the confidence to make quick cuts and movements without compromising stability.`,
    keyword:`Trend'z Article White Shoes`,
    summary:`https://stylespoeseak.com/wp-content/uploads/2022/09/tata-clique.jpg`,
    jlink:`https://bitli.in/3D6220y`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      ` PUMA Hoops Court Rider 2.0 Basketball Shoes—a perfect blend of performance and style for the modern basketball enthusiast. These shoes are not just designed for the court; they are a statement of dynamic athleticism, combining functionality with a sleek white and grey colorway that adds a touch of urban flair.`
    ],
  },

  {
    name:`59-Men-Printed-Mesh-Sneakers`,
    title:`39.Jack & Jones Men Printed Mesh Sneakers`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-zinc-700`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg  font-semibold",
    buy:`/images/myntra.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.myntra.com%2Fcasual-shoes%2Fjack%2B%26%2Bjones%2Fjack--jones-men-printed-mesh-sneakers%2F21652410%2Fbuy`,
    buy1:`/images/nosite.png`,
    blink1:``,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/fashion/59/59.1.png`,
    image:`/images/fashion/59/59.2.png`,
    image1:`/images/fashion/59/59.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Printed Mesh Design`,
    paragraph:`These sneakers boast a distinctive printed mesh design, adding a touch of personality to your footwear collection. The prints are eye-catching and unique, making these sneakers a standout choice.`,
    heading1:`Breathable Mesh Construction`,
    paragraph1:`Crafted with a breathable mesh upper, these sneakers prioritize comfort. The mesh material allows air to circulate, keeping your feet cool and comfortable throughout the day.`,
    heading2:`Lightweight and Flexible`,
    paragraph2:`The sneakers are designed to be lightweight and flexible, ensuring easy movement and agility. Whether you're on a casual stroll or exploring the city, these sneakers provide the comfort you need.`,
    heading3:`Versatile Style`,
    paragraph3:`With their printed design, these sneakers effortlessly transition from casual to urban settings. Pair them with jeans for a laid-back look or use them to add a playful touch to your street style ensemble.`,
    heading4:`Durable Outsole`,
    paragraph4:`The sneakers feature a durable outsole that provides reliable traction. This ensures stability and support, making them suitable for various activities and terrains.`,
    summary:`https://i.ytimg.com/vi/vdGFXlWOecY/maxresdefault.jpg`,
    jlink:`https://myntr.it/2dt7Pys`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      ` Jack & Jones Men Printed Mesh Sneakers—a perfect fusion of comfort and style. These sneakers are not just footwear; they are a canvas of self-expression, featuring captivating prints that make a bold statement. Step into a world where fashion meets function with every step.`
    ],
  },


  {
    name:`58-Men-Woven-Design-Mesh-Sneakers`,
    title:`38.Jack & Jones Men Woven Design Mesh Sneakers`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-green-700`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg  font-semibold",
    buy:`/images/myntra.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.myntra.com%2Fcasual-shoes%2Fjack%2B%26%2Bjones%2Fjack--jones-men-woven-design-mesh-sneakers%2F21652456%2Fbuy%3Fmini%3Dtrue%26skuId%3D68259686%26sellerPartnerId%3D6596`,
    buy1:`/images/nosite.png`,
    blink1:``,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/fashion/58/58.1.png`,
    image:`/images/fashion/58/58.2.png`,
    image1:`/images/fashion/58/58.3.png`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Woven Design`,
    paragraph:`The sneakers feature a woven design that adds a touch of texture and visual interest. This intricate pattern enhances the overall aesthetic, making them stand out in the world of casual footwear.`,
    heading1:`Mesh Upper`,
    paragraph1:`Crafted with a mesh upper, these sneakers prioritize breathability. The mesh material allows air circulation, keeping your feet cool and comfortable, making them an ideal choice for all-day wear.`,
    heading2:`Versatile Style`,
    paragraph2:`The design of these sneakers seamlessly transitions from casual to semi-formal settings. Whether you pair them with jeans for a laid-back look or dress them up with chinos, these sneakers are a versatile addition to your wardrobe.`,
    heading3:`Comfortable Fit`,
    paragraph3:`Jack & Jones prioritize comfort without compromising on style. The sneakers are designed to provide a snug and comfortable fit, making them suitable for various activities—from casual outings to weekend adventures.`,
    heading4:`Durable Outsole`,
    paragraph4:`The sneakers are equipped with a durable outsole that offers traction and stability. Whether you're navigating city streets or exploring outdoor spaces, these sneakers provide the grip you need.`,
    keyword:``,
    summary:`https://storiesflistgv2.blob.core.windows.net/stories/2019/07/Banner.jpg`,
    jlink:`https://fktr.in/OlTK42s`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      ` Introducing the Jack & Jones Men Woven Design Mesh Sneakers—a perfect blend of fashion-forward design and comfort. These sneakers are not just footwear; they are a style statement that effortlessly combines versatility, breathability, and modern aesthetics for the contemporary man on the move.`
    ],
  },




  {
    name:`57-Kenneth-Cole-Automatic-Rose-Gold-Dial-Stainless-Steel-Strap-Watch-for-Women`,
    title:`37.Kenneth Cole Automatic Rose Gold Dial Stainless Steel Strap Watch for Women KCWLL0016402LD`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-zinc-500`,
    wear:`Women's Wear`,
    Scolor:"text-black text-lg  font-semibold",
    buy:`/images/titan.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Fkenneth-cole-automatic-watch-for-women-with-rose-gold-dial-rose-gold-colour-stainless-steel-strap-kcwll0016402ld.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dkenneth-cole-watches`,
    buy1:`/images/tatacliq.png`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.tatacliq.com%2Fkenneth-cole-kcwll0016402ld-automatics-analog-watch-for-women%2Fp-mp000000015994716%3Fcid%3Dps%3AW_Sok_TATACLiQ_DSA_Watches_07_11_2022%3AGoogle%3ASearch_Sok%3AWatches-Unisex%26gad_source%3D1%26gclid%3DCj0KCQiAqsitBhDlARIsAGMR1RisoNqXlEiDSI_09xqmS59bChEIZATwDrfz1ammJMRDJmZ8LgyxO7gaAmlDEALw_wcB`,
    buy2:`/images/shopperstop.png`,
    blink2:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lifestylestores.com%2Fin%2Fen%2FSHOP-Kenneth-Cole-KENNETH-COLE-Men-Solid-Skeletal-Automatic-Watch-with-Silicone-Strap--KCWGR0012804MN%2Fp%2F1000012456642` ,
    thumbnail: `/images/fashion/57/57.1.jpg`,
    image: `/images/fashion/57/57.2.jpg`,
    image1: `/images/fashion/57/57.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Rose Gold Dial`,
    paragraph:`The watch features a stunning rose gold dial that exudes warmth and sophistication. The subtle shimmer of the dial adds a touch of glamour, making it a perfect accessory for any occasion.`,
    heading1:`Automatic Movement`,
    paragraph1:`Powered by automatic movement, this watch is a testament to precision and convenience. The self-winding mechanism ensures that the watch stays in motion with the rhythm of your everyday life.`,
    heading2:`Stainless Steel Strap`,
    paragraph2:`The stainless steel strap not only provides durability but also enhances the overall aesthetic of the watch. The polished stainless steel complements the rose gold dial, creating a harmonious and timeless look.`,
    heading3:`Water Resistance`,
    paragraph3:`Built to withstand the demands of daily life, the watch offers water resistance, adding an extra layer of durability for the modern woman on the go.`,
    heading4:`Classic Design`,
    paragraph4:`The watch boasts a classic design that transcends trends. Whether paired with formal attire or worn casually, it effortlessly elevates your style with a touch of timeless elegance.`,
    summary:`/images/ad/ajio1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fs%2Fwinterwear-5307-75201`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-3/4  xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      ` the Kenneth Cole Automatic Rose Gold Dial Watch—a masterpiece of sophistication and style designed exclusively for women. This timepiece seamlessly combines the allure of automatic movement with the timeless beauty of a rose gold dial, creating a watch that's as elegant as it is functional`
    ],
  },



  {
    name:`56-Kenneth-Cole-Black-Dial-Automatic-Watch-for-Men`,
    title:`36.Kenneth Cole Black Dial Automatic Watch for Men KCWGR0012804MN`,
    tcolor:`lg:text-xl text-lg  mt-5 text-lg font-bold text-amber-500`,
    wear:`Men's Wear`,
    Scolor:"text-black text-lg  font-semibold",
    buy:`/images/titan.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Fkenneth-cole-automatic-black-colour-silicone-strap-watch-for-men-kcwgr0012804mn.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dkenneth-cole-watches`,
    buy1:`/images/tatacliqluxury.png`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fluxury.tatacliq.com%2Fkenneth-cole-kcwgr0012804mn-automatic-watch-for-men%2Fp-mp000000016014345`,
    buy2:`/images/lifestyle.png`,
    blink2:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lifestylestores.com%2Fin%2Fen%2FSHOP-Kenneth-Cole-KENNETH-COLE-Men-Solid-Skeletal-Automatic-Watch-with-Silicone-Strap--KCWGR0012804MN%2Fp%2F1000012456642` ,
    thumbnail: `/images/fashion/56/56.1.jpg`,
    image: `/images/fashion/56/56.2.jpg`,
    image1: `/images/fashion/56/56.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Mother of Pearl Dial`,
    paragraph:`The watch boasts a dial crafted from Mother of Pearl, known for its iridescent and captivating beauty. The soft and lustrous tones create a mesmerizing effect, making every glance at the dial a delightful experience.`,
    heading1:`Ornate Strap Design`,
    paragraph1:`The strap of the Raga Women's Charm watch is a work of art in itself. Intricately designed and ornate, it adds a touch of glamour and sophistication to your wrist. The attention to detail in the strap's design enhances the overall aesthetic of the timepiece.`,
    heading2:`Timeless Appeal`,
    paragraph2:`With its classic design and timeless appeal, the Raga Women's Charm watch is versatile enough to complement various styles and occasions. Whether you're dressing up for a special event or adding a touch of elegance to your everyday look, this watch is the perfect accessory.`,
    heading3:`Reliable Timekeeping`,
    paragraph3:`Beyond its aesthetic appeal, the watch is equipped with reliable timekeeping features. The precision and accuracy of the timekeeping mechanism ensure that you stay on schedule while wearing a piece of timeless beauty.`,
    heading4:`Quality Craftsmanship`,
    paragraph4:`Raga is known for its commitment to quality craftsmanship, and the Women's Charm watch is no exception. The attention to detail, the choice of materials, and the overall construction reflect a dedication to creating a timepiece that stands the test of time.`,
    summary:`/images/ad/myntra2.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.myntra.com%2Fwomens-western-wear%3Ff%3DCategories%3ABlazers%2CCoats%2CJumpsuit%2CShrug%2CTrousers%2CWaistcoat%26plaEnabled%3Dfalse%26rf%3DDiscount%20Range%3A40.0_100.0_40.0%20TO%20100.0`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4  xl:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `the Kenneth Cole Black Dial Automatic Watch—a striking fusion of style and functionality designed for the modern man. This timepiece boasts a sleek black dial and intricate automatic movement, embodying the brand's commitment to contemporary fashion and precision craftsmanship.`
    ],
  },

  {
    name:`55-Elegant-Mother-of-Pearl-Dial-with-Ornate-Strap-Watch`,
    title:`35.Raga Women's Charm: Elegant Mother of Pearl Dial with Ornate Strap Watch`,
    tcolor:`lg:text-xl  mt-5 text-lg font-bold text-pink-800`,
    wear:`Womens'wear`,
    Scolor:"text-yellow-600 text-lg  font-semibold",
    buy:`/images/titan.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-raga-moments-of-joy-quartz-analog-women-watch-mother-of-pearl-dial-with-rose-gold-colour-metal-strap-2606wm08.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dbestsellers`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail: `/images/fashion/55/55.1.jpg`,
    image: `/images/fashion/55/55.2.jpg`,
    image1:`/images/fashion/55/55.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Elegant Mother of Pearl Dial`,
    paragraph:`The watch boasts a dial crafted from Mother of Pearl, known for its iridescent and captivating beauty. The soft and lustrous tones create a mesmerizing effect, making every glance at the dial a delightful experience.`,
    heading1:`Ornate Strap Design`,
    paragraph1:`The strap of the Raga Women's Charm watch is a work of art in itself. Intricately designed and ornate, it adds a touch of glamour and sophistication to your wrist. The attention to detail in the strap's design enhances the overall aesthetic of the timepiece.`,
    heading2:`Timeless Appeal`,
    paragraph2:`With its classic design and timeless appeal, the Raga Women's Charm watch is versatile enough to complement various styles and occasions. Whether you're dressing up for a special event or adding a touch of elegance to your everyday look, this watch is the perfect accessory.`,
    heading3:`Reliable Timekeeping`,
    paragraph3:`Beyond its aesthetic appeal, the watch is equipped with reliable timekeeping features. The precision and accuracy of the timekeeping mechanism ensure that you stay on schedule while wearing a piece of timeless beauty.`,
    heading4:`Quality Craftsmanship`,
    paragraph4:`Raga is known for its commitment to quality craftsmanship, and the Women's Charm watch is no exception. The attention to detail, the choice of materials, and the overall construction reflect a dedication to creating a timepiece that stands the test of time.`,
    summary:`/images/ad/shopperstop1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Introducing the Raga Women's Charm watch—a captivating blend of sophistication and style that adds an extra layer of grace to your wrist. This timepiece is a statement of refined taste, featuring an elegant Mother of Pearl dial and an ornate strap that seamlessly combines beauty and functionality.
      `
    ],
  },



  {
    name:`54-Prada-LHomme-Eau-De-Toilette`,
    title:`34.Prada-L'Homme-Eau-De-Toilette`,
    tcolor:`lg:text-xl dark:text-zinc-300 mt-5 text-lg font-bold text-amber-800`,
    wear:`Perfum`,
    Scolor:"text-emrald-700 test-lg  font-semibold",
    buy:`/images/shopperstop.png`,
    blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fp-AW23PRA7749607_base%2FcolorChange%2FAW23PRA7749607_NoColour`,
    buy1:`/images/amazon.jpg`,
    blink1:`https://www.amazon.in/Prada-LHomme-Eau-Toilette-100ml/dp/B01IYC71Q6?tag=inrdeal123-21`,
    buy2:`/images/nykaa.png`,
    blink2:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nykaa.com%2Fprada-l-homme-eau-de-toilette%2Fp%2F464799%3FskuId%3D464797` ,
    thumbnail: `/images/fashion/54/54.1.jpg`,
    image: `/images/fashion/54/54.2.jpg`,
    image1: `/images/fashion/54/54.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Iris and Amber`,
    paragraph:`At the heart of L'Homme Eau De Toilette lies the exquisite blend of iris and amber. The powdery and floral notes of iris combine with the warmth of amber, creating a captivating and distinctive accord that is both refined and masculine.`,
    heading1:`Neroli and Geranium`,
    paragraph1:`The fragrance opens with a burst of freshness, thanks to the inclusion of neroli and geranium. These bright and citrusy notes add an invigorating quality, providing a balanced introduction to the deeper layers of the scent.`,
    heading2:`Patchouli and Sandalwood`,
    paragraph2:`The base notes feature the earthy and woody tones of patchouli and sandalwood. These elements contribute to the longevity of the fragrance, leaving a lasting impression that is both sensual and comforting.`,
    heading3:`Timeless Elegance`,
    paragraph3:`Prada L'Homme Eau De Toilette is a timeless masterpiece that effortlessly balances classic elegance with a modern edge. The carefully selected combination of notes creates a fragrance that is versatile and suitable for various occasions, whether formal or casual.`,
    heading4:`Sleek Bottle Design`,
    paragraph4:`The bottle design is as sleek and refined as the fragrance it holds. The clear glass bottle with clean lines and a silver nameplate reflects the minimalist and modern aesthetic that Prada is known for. The design is a visual representation of the understated luxury of L'Homme.`,
    
    summary:`/images/ad/ajio1.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fs%2Fwinterwear-5307-75201`,
    bclass:`w-3/4 h-full  ml-16 md:w-3/4 lg:w-2/4 md:ml-[10rem] rounded-xl`,
    content: [
      `Prada L'Homme Eau De Toilette is an embodiment of sophistication and contemporary elegance, curated for the modern man who appreciates refined fragrances. This timeless scent from Prada is a celebration of subtlety and complexity, making a statement with its distinctive and enduring aroma.`
    ],
  },




  






]

export default articles;