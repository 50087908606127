import React from 'react';
import { Carousel } from '@mantine/carousel';
import { IconStar } from '@tabler/icons-react';
import { MantineProvider, Card, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';
import classes from './mantineCss/BadgeCard.module.css';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';



const AmazonSecret = ({data}) => {
    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>

        
    <Carousel className='w-72  p-3'
     
 
 
                > 
        {data.map((article, index)=>(
            <Carousel.Slide>
    <Card withBorder radius="md" p="md" key={index} className={classes.card}>
      <Card.Section>
        <img src={article.image} alt={article.title} height={180} />
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
        <Group justify="apart">
          <Text fz="lg" fw={500}>
            {article.title}
          </Text>
          <Badge size="sm" variant="light" >
            {article.country}
          </Badge>
          <del className='text-sm' fz="sm" fw={600}>
            {article.mrprate}
          </del>
          <Text fz="lg" fw={500}>
            {article.traderate}
          </Text>
          <Text className='border-2 border-dashed border-orange-500 text-lg font-bold  ' fz="sm" mt="lg" fw={500}>
        {article.code}
        </Text>
          
          
          
        </Group>
        <Text fz="sm" mt="lg" fw={500}>
          {article.description.substring(0, 50)}...
        </Text>

        
        <Badge size="lg" variant="light" onClick={handleClickOpen}>
            How To Purchase ?
          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        <Badge size="xl" variant="light" >

          {"How To Purchase ?"}
          </Badge>

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ol className='my-2'>
                <div className='my-2'>
                <li className='text-lg text-gray-600 '>1. Copy the product code located below the product name. </li>
                <ul className='ml-5'>
                    <li className='text-sm text-gray-500'>for example.-.(B0855GGSN8)</li>
                </ul>
                </div>
                <li className='text-lg text-gray-600 my-2 '>2.Click on the "Visit Now" or "Buy Now" link provided.</li>
                <li className='text-lg text-gray-600 my-2'>3.Paste the product code into the search bar on Amazon's website.</li>
                <li className='text-lg text-gray-600 my-2'>4.Locate the product in the search results and click to open the product page.</li>
                <li className='text-lg text-gray-600 my-2'>5.Follow the prompts to add the item to your cart or proceed with the purchase by selecting the "Add to Cart" or "Buy Now" option.</li>
                <li className='text-lg text-gray-600 my-2'>6.Complete the checkout process by following the instructions provided on Amazon.</li>
            
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
           <h1 className='border-4 border-dashed bg-orange-300 border-orange-500 '>{article.code}</h1>
          <Button onClick={handleClose} autoFocus>
            <a href={article.blink}>Visit Now</a>
          </Button>
        </DialogActions>
        
      </Dialog>
          </Badge>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Text mt="md" className={classes.label} c="dimmed">
          {article.keyword}
        </Text>
        <Group gap={7} mt={5}>
          {article.features}
        </Group>
      </Card.Section>

      <Group mt="xs">
        <Button radius="lg" className={classes.button} style={{ flex: 1 }}>
          <a className='font-bold' href={article.blink}>Buy Now</a>
        </Button>
        <ActionIcon className='flex flex-row' variant="default" radius="md" size={45}>
        <h1 mt="md" className={classes.label} c="dimmed">
          {article.rating}
        </h1>
          <IconStar className={classes.like} stroke={1.5} />
          

        </ActionIcon>
      </Group>
    </Card>
    </Carousel.Slide>
        ))}

</Carousel>
    </MantineProvider>
  );
}

export default AmazonSecret;